import { ActionMatchingPattern } from '@redux-saga/types';
import { OverridedMixpanel } from 'mixpanel-browser';
import { TakeableChannel } from 'redux-saga';
import { ActionPattern, call, ForkEffect, getContext, takeEvery } from 'redux-saga/effects';

export function takeEveryWithMixpanel<T>(
  channel: TakeableChannel<T>,
  worker: (mixpanel: OverridedMixpanel, item: T) => any,
): ForkEffect;
export function takeEveryWithMixpanel<P extends ActionPattern>(
  pattern: P,
  worker: (mixpanel: OverridedMixpanel, action: ActionMatchingPattern<P>) => any,
): ForkEffect;
export function takeEveryWithMixpanel(pattern: any, worker: any): ForkEffect {
  return takeEvery(pattern, function* (action) {
    const mixpanel: OverridedMixpanel = yield getContext('mixpanel');
    yield call(worker, mixpanel, action);
  });
}
