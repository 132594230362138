
/**
 * @param timestamp timestamp is in seconds
 * @param duration seconds
 * @returns left css style to shift game event icons
 */
export function calcLeft(timestamp: number, duration: number): string {
  const time = timestamp;
  const left = `${time / duration * 100}%`;
  return left;
}
