
interface IFormatter<T> {
  format(t: T, s?: 'colons' | 'letters'): string;
  isValid(str: string): boolean;
  parse(str: string): T;
}

function formatDuration(s: number): string {
  s = Math.floor(s);
  let m = Math.floor(s / 60);
  s %= 60;
  let h = Math.floor(m / 60);
  m %= 60;
  let out = m.toString() + ':' + s.toString().padStart(2, '0');
  if (s >= 3600) {
      out = h.toString() + ':' + out.padStart(5, '0');
  }
  return out;
}

function formatSeconds(s: number): string {
  s = Math.floor(s);
  let m = Math.floor(s / 60);
  s %= 60;
  let h = Math.floor(m / 60);
  m %= 60;
  const a: string[] = [];
  if (h) {
    a.push(`${h}h`);
  }
  if (m) {
    a.push(`${m}m`);
  }
  if (s) {
    a.push(`${s}s`);
  }
  return a.join(' ');
}

export const DurationFormatter: IFormatter<number> = class {
  private static numberRegex = /(?:^|\s)(\d+)(?:\s|$)/;
  private static hmsRegex = /(?!$)(?:^|\s)\s?(\d+h)?\s?(\d+m)?\s?(\d+s)?(?:\s|$)/;
  public static format(time: number, style: 'colons' | 'letters' = 'letters') {
    return style === 'colons' ? formatDuration(time) : formatSeconds(time);
  }
  public static isValid(str: string): boolean {
    return this.hmsRegex.test(str) || this.numberRegex.test(str);
  }
  public static parse(str: string): number {
    if (this.isValid(str)) {
      const r1 = this.hmsRegex.exec(str);
      const r2 = this.numberRegex.exec(str);
      if (r1) {
        const [, h, m, s] = r1;
        let d = 0;
        if (s) {
          d += Number.parseInt(s);
        }
        if (m) {
          d += 60 * Number.parseInt(m);
        }
        if (h) {
          d += 3600 * Number.parseInt(h);
        }
        return d;
      }
      if (r2) {
        const [, s] = r2;
        return Number.parseInt(s);
      }
    }
    return -1;
  }
};
