import { Theme } from '@insights-gaming/theme';
import { StackProps, SxProps } from '@mui/material';
import { Component, createContext, useContext } from 'react';
import * as portals from 'react-reverse-portal';

export const UserPortalContext = createContext<portals.HtmlPortalNode<Component<any>> | undefined>(undefined);

export function UserPortal(props: { sx?: SxProps<Theme> } & Omit<StackProps, 'ref'>) {
  const portalNode = useContext(UserPortalContext);
  if (!portalNode) {
    return null;
  }
  return (
    <div>{/* load-bearing div */}
      <portals.OutPortal node={portalNode} {...props} />
    </div>
  );
}
