import { ApolloClient, useApolloClient, useMutation } from '@apollo/client';
import { useMemo } from 'react';

import { DocumentType, gql } from '@/graphql/__generated__';

export const TipsQuery = gql(`
  query TipsQuery($names: [String!]!) {
    tips(names: $names)
  }
`);

export const HideTipsMutation = gql(`
  mutation HideTipsMutation($input: HideTipsInput!) {
    hideTips(input: $input) {
      names
    }
  }
`);

export function makeCachedTipsQuery<C extends object>(client: ApolloClient<C>) {
  return async (names: string[]) => {
    const cached: Dictionary<boolean> = {};
    const uncached: string[] = [];
    names.forEach((name) => {
      const result = client.readQuery({
        query: TipsQuery,
        variables: { names: [name] },
      });
      if (result?.tips) {
        cached[name] = result.tips[0];
      } else {
        uncached.push(name);
      }
    });
    if (uncached.length > 0) {
      try {
        const result = await client.query({
          query: TipsQuery,
          variables: { names: uncached },
          fetchPolicy: 'network-only',
        });
        uncached.forEach((name, i) => {
          client.writeQuery({
            query: TipsQuery,
            variables: { names: [name] },
            data: { tips: [result.data.tips[i]] },
          });
          cached[name] = result.data.tips[i];
        });
      } catch (error) {
        console.error(error);
      }
    }
    return names.map((name) => cached[name]);
  };
}

export function useCachedTipsQuery() {
  const client = useApolloClient();
  return useMemo(() => makeCachedTipsQuery(client), [client]);
}

export function makeHideTipsMutationUpdater<C extends object>(client: ApolloClient<C>) {
  return (data: DocumentType<typeof HideTipsMutation>) => {
    if (!data.hideTips) {
      return;
    }
    data.hideTips.names.forEach((name) => {
      client.writeQuery({
        query: TipsQuery,
        variables: { names: [name] },
        data: { tips: [false] },
      });
    });
  };
}

export function useHideTipsMutation() {
  const client = useApolloClient();

  return useMutation(HideTipsMutation, {
    onCompleted: makeHideTipsMutationUpdater(client),
  });
}
