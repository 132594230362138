import { GameClassId, GameEventIconChooser } from '@insights-gaming/game-events';
import { InteractivePopover, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { Badge, List, popoverClasses, SxProps } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';

import { calcLeft } from '@/features/video-replay/seekbar-events/event-helpers';
import SeekbarEventBase from '@/features/video-replay/seekbar-events/SeekbarEventBase';
import { TeamVideoPlayerControls_VideoBookmarkFragmentFragment } from '@/graphql/__generated__/graphql';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

import { TeamVideoBookmarkListItem } from './TeamVideoBookmarkListItem';

interface TeamVideoBookmarkIndicatorProps {
  sx?: SxProps<Theme>;
  bookmarks: TeamVideoPlayerControls_VideoBookmarkFragmentFragment[];
  disableRestoreFocus?: boolean;
  disableEnforceFocus?: boolean;
  onPopoverOpen?: VoidFunction;
  onPopoverClose?: VoidFunction;
}

export function TeamVideoBookmarkIndicator(props: TeamVideoBookmarkIndicatorProps) {
  const {
    bookmarks,
    disableRestoreFocus = false,
    disableEnforceFocus = false,
    onPopoverOpen,
    onPopoverClose,
  } = props;

  const {
    state: { duration },
  } = useContext(VideoReplayContext);

  const fullscreenEl = useContext(FullscreenContext);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [firstBookmark] = bookmarks;

  const {
    seekTo,
  } = useContext(VideoReplayContext);

  const left = calcLeft(firstBookmark.time, duration);

  const handleOpen = useCallback(() => {
    setTooltipOpen(true);
    onPopoverOpen?.();
  }, [onPopoverOpen]);

  const handleClose = useCallback(() => {
    setTooltipOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    onPopoverClose?.();
  }, [onPopoverClose]);

  const onClick = useCallback(() => {
    seekTo({ type: 'seconds', amount: firstBookmark.time, seekCommitted: true });
  }, [firstBookmark.time, seekTo]);

  const bookmarkIcon = useMemo(() => {
    if (firstBookmark.bookmark.__typename !== 'GameBookmark') {
      return null;
    }
    const event = {
      name: firstBookmark.bookmark.event,
      data: firstBookmark.bookmark.data,
    };
    return (
      <GameEventIconChooser
      classId={firstBookmark.bookmark.gameId as GameClassId}
      event={event}
      />
    );
  }, [firstBookmark]);

  return (
    <InteractivePopover
    disableEnforceFocus={disableEnforceFocus}
    disableRestoreFocus={disableRestoreFocus}
    exitDelay={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    container={fullscreenEl}
    TransitionProps={{
      onEnter: handleOpen,
      onExit: handleClose,
      onExited: handleExited,
    }}
    sx={{
      margin: 0,
      zIndex: theme => theme.zIndex.tooltip + 1,
      [`& .${popoverClasses.paper}`]: {
        '&::-webkit-scrollbar-thumb': {
          '-webkit-box-shadow': theme => `inset 0 0 10px 10px ${theme.palette.background.dimmed}`,
        },
      },
    }}
    trigger={(
      <Badge
      color='primary'
      badgeContent={bookmarks.length}
      invisible={bookmarks.length <= 1}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      overlap='circular'
      sx={[
        {
          position: 'absolute',
          bottom: 0,
          marginLeft: '-15px',
          opacity: 0.5,
        },
        tooltipOpen && {
          opacity: 1,
        },
      ]}
      style={{ left }}
      >
        <SeekbarEventBase
        onClick={onClick}
        >
          {bookmarkIcon}
        </SeekbarEventBase>
      </Badge>
    )}
    >
      <List dense={true} sx={{ maxHeight: 386 }}>
        {bookmarks.map(bookmark => (
          <TeamVideoBookmarkListItem key={bookmark.id} bookmark={bookmark} />
        ))}
      </List>
    </InteractivePopover>
  );
}
