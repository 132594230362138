import { getCfAccessCredentials } from '@/utils/cf-helper';

import { ENDPOINT } from './common';

function createHttpServer(port: number, requestHandler: (info: overwolf.web.RequestEvent) => void): Promise<() => void> {
  return new Promise<() => void>((resolve, reject) => {
    overwolf.web.createServer(port, (result) => {
      if (!result.success || !result.server) {
        return reject(new Error(`failed to create server: ${result.error}`));
      }

      const { server } = result;
      server.onRequest.addListener(requestHandler);

      server.listen((info) => {
        if (!info.success) {
          return reject(new Error(`failed to listen on port ${port}: ${info.reason}`));
        }

        resolve(() => server.close());
      });
    });
  });
}

function *randomPort(): Generator<number> {
  const maxPort = (1 << 16) - 1;
  const minPort = 10000;
  while (true) {
    yield Math.floor(Math.random() * (maxPort - minPort)) + minPort;
  }
}

async function listenOnRandomPort(requestHandler: (info: overwolf.web.RequestEvent) => void, portIterable: Iterable<number> = randomPort()): Promise<[string, () => void]> {
  const nextPort = portIterable[Symbol.iterator]();
  let port: number;
  for (let i = 0; i < 10; i++) {
    const { done, value } = nextPort.next();
    if (done || typeof value !== 'number') {
      break;
    }

    port = value;

    try {
      return [`http://localhost:${port}`, await createHttpServer(port, requestHandler)];
    } catch {}
  }

  throw new Error('failed to find an open port');
}

export async function openInBrowserAndWaitForCallback<T>(
  urlFn: (callbackUrl: string) => string,
  onRequest: (req: Omit<overwolf.web.RequestEvent, 'url'> & { url: URL }) => T | Promise<T>,
  nextPort?: Iterable<number>,
): Promise<[T, string]> {
  let handler: (info: overwolf.web.RequestEvent) => void;
  const p = new Promise<T>((resolve, reject) => {
    handler = (info) => {
      const url = new URL(info.url);
      if (url.pathname !== '/callback') {
        return;
      }

      try {
        resolve(Promise.resolve(onRequest({ ...info, url })));
      } catch (err) {
        reject(err);
      }
    };
  });

  const [baseurl, close] = await listenOnRandomPort(handler!, nextPort);
  const callbackUrl = baseurl + '/callback';
  const url = urlFn(callbackUrl);
  overwolf.utils.openUrlInDefaultBrowser(url);

  try {
    return [await p, callbackUrl];
  } finally {
    close();
  }
}

export interface Session {
  refreshToken: string;
  accessToken: string;
  expiration: number;
  newuser?: boolean;
}

interface AuthResponse {
  refresh_token: string;
  access_token: string;
  expires_in: number;
  newuser: '0' | '1';
}

function convert(res: AuthResponse): Session & { newuser?: boolean } {
  return {
    refreshToken: res.refresh_token,
    accessToken: res.access_token,
    expiration: Date.now() + ((res.expires_in - 60) * 1000),
    newuser: res.newuser === '1',
  };
}

export async function loginWithProvider(provider: string, setUrl?: (url: string) => void): Promise<Session & { newuser?: boolean }> {
  return (await openInBrowserAndWaitForCallback(
    (callbackUrl) => {
      const url = ENDPOINT + '/authorize?' + new URLSearchParams({
        provider,
        redirect_uri: ENDPOINT + '/connected?' + new URLSearchParams({ ow_callback: callbackUrl }),
        redirect_mode: 'querystring',
        grant_type: 'refresh_token',
      }).toString();
      setUrl?.(url);
      return url;
    },
    ({ url }) => {
      const { error, ...session } = Object.fromEntries([...url.searchParams]);
      if (error) {
        throw new Error(error);
      }

      return convert(session as any);
    },
  ))[0];
}

async function callTokenEndpoint(params: Record<string, string>): Promise<Session> {
  const res = await fetch(ENDPOINT + '/oauth/token', {
    method: 'POST',
    body: new URLSearchParams(params).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...getCfAccessCredentials(),
    },
  });

  if (res.ok) {
    return convert(await res.json());
  }

  const text = await res.text();
  let msg;
  try {
    msg = JSON.parse(text).error;
  } catch {
    msg = `Sign in failed: ${res.status} - Please try again later.`;
  }
  throw new Error(msg);
}

export function loginWithPassword(email: string, password: string): Promise<Session> {
  return callTokenEndpoint({
    grant_type: 'refresh_token',
    username: email,
    password,
  });
}

export async function registerWithEmail(params: Record<string, string>): Promise<Session> {
  const res: Response = await fetch(ENDPOINT + '/oauth/register', {
    method: 'POST',
    body: new URLSearchParams(params).toString(),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...getCfAccessCredentials(),
    },
  });

  if (res.ok) {
    return convert(await res.json());
  }

  const text = await res.text();
  let msg;
  try {
    msg = JSON.parse(text).error;
  } catch {
    msg = `Sign in failed: ${res.status} - Please try again later.`;
  }
  throw new Error(msg);
}

export async function sendVerificationEmail(email: string): Promise<boolean> {
  const res: Response = await fetch(ENDPOINT + '/oauth/verify', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({ email }).toString(),
  });
  if (!res.ok) {
    const { error } = await res.json();
    throw new Error(error);
  }
  return true;
}

let refreshp: Promise<Session> | undefined;
export function refreshSession(refreshToken: string): Promise<Session> {
  if (refreshp) {
    return refreshp;
  }

  return refreshp = callTokenEndpoint({ refresh_token: refreshToken })
    .then((session) => {
      refreshp = undefined;
      return { ...session, refreshToken };
    });
}

export function callLogoutEndpoint() {
  return fetch(ENDPOINT + '/logout', {
    redirect: 'manual',
    headers: getCfAccessCredentials(),
  });
}
