import { ControlButton, VideoReplayContext } from '@insights-gaming/material-components';
import Crop75SharpIcon from '@mui/icons-material/Crop75Sharp';
import CropLandscapeSharpIcon from '@mui/icons-material/CropLandscapeSharp';
import { Fragment, memo, MouseEvent, useCallback, useContext } from 'react';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import VideoControlsTooltip from '@/features/video-replay/VideoControlsTooltip';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

interface VideoReplayTheaterModeControllerOwnProps {
  className?: string;
}

type VideoReplayTheaterModeControllerProps = VideoReplayTheaterModeControllerOwnProps;

function VideoReplayTheaterModeController(props: VideoReplayTheaterModeControllerProps) {
  const {
    state: { theaterMode },
    toggleTheaterMode,
  } = useContext(VideoReplayContext);

  const fullscreenEl = useContext(FullscreenContext);
  const keybindLookup = useContext(KeybindReverseLookupContext);

  const handleTheaterModeClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    toggleTheaterMode();
  }, [toggleTheaterMode]);

  return (
    <Fragment>
      <VideoControlsTooltip tKey={theaterMode ? 'hotkeys:labels.defaultView' : 'hotkeys:labels.theaterMode'} keybindings={keybindLookup['video.theatermode.toggle']} container={fullscreenEl}>
        <div>
          <ControlButton onClick={handleTheaterModeClick}>
            {theaterMode ? (
              <Crop75SharpIcon />
            ) : (
              <CropLandscapeSharpIcon />
            )}
          </ControlButton>
        </div>
      </VideoControlsTooltip>
    </Fragment>
  );
}

export default memo(VideoReplayTheaterModeController);
