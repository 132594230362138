
import { skipToken } from '@apollo/client';
import { PropsWithChildren, useMemo } from 'react';
import { useRouteMatch } from 'react-router';

import SquadRouting from '@/features/squad/squad.routing';
import { gql } from '@/graphql/__generated__';
import { makeBackgroundQueryContext, useBackgroundInjector } from '@/graphql/withBackgroundQueryContext';

const TeamVideoPageContext_VideoQuery = gql(`
  query TeamVideoPageContext_VideoQuery($id: ID!) {
    video(id: $id) {
      __typename
      id
      name
      remoteUrl
      streamUrls
      duration
      directory {
        id
        name
      }
      ...TeamVideoBottomToolbar_VideoFragment
      ...TeamVideoContextMenu_VideoFragment
      ...UseTeamVideoUrls_VideoFragment
      ...TeamVideoPublicFallback_VideoFragment
    }
  }
`);

export const TeamVideoPageContext = makeBackgroundQueryContext(TeamVideoPageContext_VideoQuery);

export function TeamVideoPageInjector({ children }: PropsWithChildren) {
  const isTeamVideoRoute = useRouteMatch<SquadRouting.TeamVideoParams>(SquadRouting.path.teamVideo);
  const videoId = isTeamVideoRoute?.params.videoId;
  const options = useMemo(() => !videoId ? skipToken : { variables: { id: videoId } }, [videoId]);
  const value = useBackgroundInjector(TeamVideoPageContext_VideoQuery, options, (variables) => variables?.id);
  return (
    <TeamVideoPageContext.Provider value={value}>
      {children}
    </TeamVideoPageContext.Provider>
  );
}
