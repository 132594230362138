import { darkPalette, insightsTheme, lightPalette, Theme } from '@insights-gaming/theme';
import { backdropClasses, lighten } from '@mui/material';
import { BreakpointsOptions, Components, createTheme, getContrastRatio, ThemeOptions } from '@mui/material/styles';

import CustomFade from './components/transitions/CustomFade';
import CustomGrow from './components/transitions/CustomGrow';
import CustomSlide from './components/transitions/CustomSlide';

declare module '@mui/system' {
  interface BreakpointOverrides {
    // Your custom breakpoints
    hd: true;
    hdplus: true;
    fhd: true;
    qhd: true;
    uhd: true;
    // Remove default breakpoints
    // xs: false;
    // sm: false;
    // md: false;
    // lg: false;
    // xl: false;
  }
}

const breakpoints: BreakpointsOptions = {
  values: {
    ...insightsTheme.breakpoints.values,
    hd: 1280,
    hdplus: 1600,
    fhd: 1920,
    qhd: 2560,
    uhd: 3840,
  },
};

const components: Components<Theme> = {
  MuiButton: {
    styleOverrides: {
      sizeSmall: {
        height: 32,
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: (theme) => ({
      '@global': {
        a: {
          color: theme.palette.text.primary,
        },
      },
      // begin scrollbar
      '@supports selector(::-webkit-scrollbar)': {
        '*::-webkit-scrollbar': {
          width: '12px',
          height: '12px',
        },
        '*::-webkit-scrollbar-thumb': {
          '-webkit-box-shadow': 'inset 0 0 10px 10px #585963',
          '&:hover': {
            '-webkit-box-shadow': `inset 0 0 10px 10px ${lighten('#585963', 0.2)}`,
          },
          border: 'solid 3px transparent',
          borderRadius: '12px',
        },
      },
      '@supports not selector(::-webkit-scrollbar)': {
        '*': {
          scrollbarWidth: 'thin',
          scrollbarColor: '#585963 transparent',
        },
      },
      // end scrollbar
    }),
  },
  MuiDialog: {
    defaultProps: {
      TransitionComponent: CustomFade,
    },
    ...(process.env.REACT_APP_BUILD_TARGET === 'app' && {
      styleOverrides: {
        root: {
          marginTop: 30,
        },
      },
    }),
  },
  MuiDrawer: {
    defaultProps: {
      TransitionComponent: CustomSlide,
    } as any, // TransitionComponent is not defined in MuiDrawerProps but it seems to work
    ...(process.env.REACT_APP_BUILD_TARGET === 'app' && {
      styleOverrides: {
        paper: {
          marginTop: 30,
          height: `calc(100% - ${30}px)`,
        },
      },
    }),
  },
  MuiListItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
      }),
      divider: {
        borderRadius: 0,
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: 'inherit',
      },
    },
  },
  // react-transition-group bug causes modal elements to never reach exited state, so this is a workaround to make them not clickable
  // https://github.com/mui/material-ui/issues/32286
  // https://github.com/reactjs/react-transition-group/issues/817
  MuiModal: {
    styleOverrides: {
      root: {
        [`&:has(> div.${backdropClasses.root}[style*="opacity: 0"])`]: {
          pointerEvents: 'none',
        },
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      TransitionComponent: CustomGrow,
    },
  },
  MuiStack: {
    defaultProps: {
      useFlexGap: true,
    },
  },
};

const theme = createTheme(
  {
    ...insightsTheme,
    breakpoints,
  },
  {
    components,
  } as ThemeOptions,
);

export default theme;

export const getContrastText = (theme: Theme) => (backgroundColor: string) => {
  const contrastRatio = getContrastRatio(theme.palette.text.primary, backgroundColor);
  if (contrastRatio >= 3) {
    return theme.palette.text.primary;
  }
  const darkText = darkPalette.text?.primary || '#000';
  const lightText = lightPalette.text?.primary || '#fff';
  const darkContrast = getContrastRatio(darkText, backgroundColor);
  const lightContrast = getContrastRatio(lightText, backgroundColor);
  return darkContrast > lightContrast ? darkText : lightText;
};
