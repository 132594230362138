import { VideoReplayContext } from '@insights-gaming/material-components';
import { VideoReplayContextValue } from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { buildKeystring } from '@insights-gaming/utils';
import { clamp } from 'lodash';
import { useContext, useEffect, useMemo, useRef } from 'react';

import { blockKeybind, KeyCommand } from '@/features/settings/keybinding';
import { makeGetKeybindings } from '@/features/settings/setting-selector';

import { MultiVideoReplayContext } from './MultiVideoReplayContext';

export function useVideoReplayKeybindings(
  keybindingContext: string[],
  additionalHandlers: Partial<Record<KeyCommand, VoidFunction>> = {},
  disableKeybindings?: boolean,
  replayContextOverride?: Partial<VideoReplayContextValue>,
) {
  const additionalHandlersRef = useRef(additionalHandlers);
  useEffect(() => {
    additionalHandlersRef.current = additionalHandlers;
  }, [additionalHandlers]);

  const videoReplayContext = useContext(VideoReplayContext);

  const {
    state: { progress, volume },
    changeVolume,
    decreasePlaybackRate,
    increasePlaybackRate,
    seekTo,
    toggleFullscreen,
    toggleMuteState,
    togglePlayState,
    toggleTheaterMode,
  } = useMemo(() => ({
    ...videoReplayContext,
    ...replayContextOverride,
  }), [replayContextOverride, videoReplayContext]);

  const playedSecondsRef = useRef(progress.playedSeconds);
  useEffect(() => {
    playedSecondsRef.current = progress.playedSeconds;
  }, [progress.playedSeconds]);

  const volumeRef = useRef(volume);
  useEffect(() => {
    volumeRef.current = volume;
  }, [volume]);

  const keybindingTables = useCreateSelector(makeGetKeybindings, { keybindingContext });
  const keybindingsRef = useRef(keybindingTables);
  useEffect(() => {
    keybindingsRef.current = keybindingTables;
  }, [keybindingTables]);

  useEffect(() => {
    if (disableKeybindings) {
      return undefined;
    }
    const keyListener = (e: KeyboardEvent) => {
      const keystring = buildKeystring(e);
      const { current: { lookupTable } } = keybindingsRef;
      const keybindings = lookupTable[keystring];
      if (!keybindings || keybindings.length === 0) {
        return;
      }

      if (blockKeybind(e)) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      keybindings.forEach(({ command }) => {
        switch (command as KeyCommand) {
          case 'video.playpause':
            togglePlayState();
            break;
          case 'video.seek.backward.small':
            seekTo({ type: 'offset', amount: -5, seekCommitted: true });
            break;
          case 'video.seek.backward.large':
            seekTo({ type: 'offset', amount: -10, seekCommitted: true });
            break;
          case 'video.seek.backward.frame':
            seekTo({ type: 'offset', amount: -1 / 60, seekCommitted: true });
            break;
          case 'video.seek.forward.small':
            seekTo({ type: 'offset', amount: 5, seekCommitted: true });
            break;
          case 'video.seek.forward.large':
            seekTo({ type: 'offset', amount: 10, seekCommitted: true });
            break;
          case 'video.seek.forward.frame':
            seekTo({ type: 'offset', amount: 1 / 60, seekCommitted: true });
            break;
          case 'video.seek.percent.0':
          case 'video.seek.percent.10':
          case 'video.seek.percent.20':
          case 'video.seek.percent.30':
          case 'video.seek.percent.40':
          case 'video.seek.percent.50':
          case 'video.seek.percent.60':
          case 'video.seek.percent.70':
          case 'video.seek.percent.80':
          case 'video.seek.percent.90':
          case 'video.seek.percent.100':
            const arr = command.split('.');
            const percent = +arr[arr.length - 1];
            seekTo({ type: 'fraction', amount: percent / 100, seekCommitted: true });
            break;
          case 'video.volume.mute.toggle':
            toggleMuteState();
            break;
          case 'video.volume.down':
            changeVolume({ amount: clamp(volumeRef.current - 0.05, 0, 1), changeCommitted: true });
            break;
          case 'video.volume.up':
            changeVolume({ amount: clamp(volumeRef.current + 0.05, 0, 1), changeCommitted: true });
            break;
          case 'video.fullscreen.toggle':
            toggleFullscreen();
            break;
          case 'video.theatermode.toggle':
            toggleTheaterMode();
            break;
          case 'video.playrate.decrease.step':
            decreasePlaybackRate();
            break;
          case 'video.playrate.increase.step':
            increasePlaybackRate();
            break;
          default:
            additionalHandlersRef.current[command as KeyCommand]?.();
            break;
        }
      });
    };
    document.addEventListener('keydown', keyListener);
    return () => {
      document.removeEventListener('keydown', keyListener);
    };
  }, [changeVolume, decreasePlaybackRate, increasePlaybackRate, seekTo, disableKeybindings, toggleFullscreen, toggleMuteState, togglePlayState, toggleTheaterMode]);

  return keybindingTables.reverseLookupTable;
}

export function useMultiVideoReplayKeybindings(
  keybindingContext: string[],
  additionalHandlers: Partial<Record<KeyCommand, VoidFunction>> = {},
  disableKeybindings?: boolean,
) {
  const {
    seekTo,
  } = useContext(MultiVideoReplayContext);

  const override = useMemo(() => ({
    seekTo,
  }), [seekTo]);

  return useVideoReplayKeybindings(keybindingContext, additionalHandlers, disableKeybindings, override);
}
