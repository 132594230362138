import { FloatingText } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { secondsToHms } from '@insights-gaming/utils';
import { Box, CircularProgress, SxProps } from '@mui/material';
import fileSize from 'filesize';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { videoUploadSelectors } from '@/features/video-upload/upload-queue/video-upload-selector';
import { DocumentType, FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { VideoStatus } from '@/graphql/__generated__/graphql';
import { affineTransform1D } from '@/utils/transform';


const TeamVideoCardStatus_VideoFragment = gql(`
  fragment TeamVideoCardStatus_VideoFragment on Video {
    id
    status
    progress {
      current
      rate
      total
    }
    error
    streamUrls
    uploaded
    filesize2
  }
`);

interface TeamVideoCardStatusProps {
  className?: string;
  sx?: SxProps<Theme>;

  video: FragmentType<typeof TeamVideoCardStatus_VideoFragment>;
}

export function TeamVideoCardStatus(props: TeamVideoCardStatusProps) {
  const { className, sx } = props;
  const video = getFragmentData(TeamVideoCardStatus_VideoFragment, props.video);

  const { t } = useTranslation(['common', 'squad_dashboard']);

  const uploadWithState = useCreateSelector(videoUploadSelectors.makeGetUploadWithStateByVideoId, { videoId: video.id });

  const [status, indicator] = useVideoClientStatus(video, uploadWithState);
  const { speed, eta } = useMemo(() => {
    if (!uploadWithState) {
      return {};
    }
    if (!(uploadWithState.state === 'PAUSED' || uploadWithState.state === 'UPLOADING')) {
      return {};
    }
    const speed = fileSize(uploadWithState.upload.rate * video.filesize2);
    const eta = uploadWithState.upload.rate > 0 ? secondsToHms((1 - uploadWithState.upload.progress) / uploadWithState.upload.rate) : undefined;
    return { speed, eta };
  }, [uploadWithState, video.filesize2]);

  const text = (function () {
    if (status === 'uploading' && eta) {
      return t('squad_dashboard:video.uploadStatus.uploadingWithEta', { speed, eta });
    }
    if (!status) {
      return undefined;
    }
    return t(`squad_dashboard:video.uploadStatus.${status}`);
  })();

  return (
    <Box className={className} sx={sx}>
      {indicator && (
        <FloatingText>
          {indicator}
        </FloatingText>
      )}
      {text && (
        <FloatingText horizontal='left' vertical='bottom' spacing={1}>
          {text}
        </FloatingText>
      )}
    </Box>
  );
}

function useVideoClientStatus(
  video: DocumentType<typeof TeamVideoCardStatus_VideoFragment>,
  uploadWithState: ReturnType<ReturnType<typeof videoUploadSelectors.makeGetUploadWithStateByVideoId>>,
) {
  const currentUpload = useSelector(videoUploadSelectors.getCurrentUpload);
  const isCurrentUpload = currentUpload?.upload.resumeInfo?.videoId === video.id;
  const status = (function () {
    if (video.status !== VideoStatus.Ready) {
      // TODO: add case for pending/verifying
      if (uploadWithState?.state === 'PAUSED' || uploadWithState?.state === 'UPLOADING') {
        return !isCurrentUpload ? 'paused' : 'uploading';
      }
      if (uploadWithState?.state === 'DONE') {
        return 'encoding';
      }
    }
    if (!video.uploaded) {
      return 'uploading';
    }
    if (!video.streamUrls) {
      return video.progress.current <= 0 ? 'queued' : 'encoding';
    }
  })();
  const indicator = (function () {
    if (uploadWithState?.state === 'PAUSED' || uploadWithState?.state === 'UPLOADING') {
      return <CircularProgress value={100 * uploadWithState.upload.progress} />;
    }
    switch (status) {
      case 'encoding': return <CircularProgress value={transformProgress(video.progress.current)} />;
      case 'queued': return <CircularProgress variant='indeterminate' />;
    }
    return null;
  })();
  return [status, indicator] as const;
}

function transformProgress(progress: number) {
  const halfway = 0.5;
  if (progress < halfway) {
    return affineTransform1D(progress, [0, halfway], [0, 100]);
  }
  return affineTransform1D(progress, [halfway, 1], [0, 100]);
}
