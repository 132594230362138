import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { endpoints } from '@/utils/insightsgg/common';

interface IWsLink {
  wsLink: GraphQLWsLink;
  ack: Promise<true>;
  gracefullyRestart: VoidFunction;
  dispose: VoidFunction;
}

export type ConnectionParams = Record<string, unknown> | (() => Promise<Record<string, unknown>>);

export function createWsLink(): IWsLink {
  let restartRequestedBeforeConnected = false;
  let gracefullyRestart = () => {
    restartRequestedBeforeConnected = true;
  };

  const client = createClient({
    url: `${endpoints.ws}/graphql`,
    connectionParams: async () => {
      return {
        access_token: window.sessionStorage.getItem('refreshToken'),
      };
    },
    on: {
      connected: (socket) => {
        gracefullyRestart = () => {
          if (socket instanceof WebSocket) {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close();
            }
          }
        };

        if (restartRequestedBeforeConnected) {
          restartRequestedBeforeConnected = false;
          gracefullyRestart();
        }
      },
    },
  });
  const ack: Promise<true> = new Promise((resolve, reject) => {
    client.on('connected', () => resolve(true));
    client.on('error', (error) => reject(error));
  });
  return {
    wsLink: new GraphQLWsLink(client),
    ack,
    gracefullyRestart: () => gracefullyRestart(), // TODO: this is a hack
    dispose: () => {
      client.terminate();
    },
  };
}
