import { ENDPOINT } from './common.prod';

export const endpoints = {
  cdn: (process.env.NODE_ENV === 'development' ? 'https://develop.insights.gg' : ENDPOINT)
    .split('//').join('//cdn.'), // proxy doesn't work with cdn?
  gql: ENDPOINT + '/graphql',
  telemetry: ENDPOINT + '/collect/telemetry',
  ws: `ws${ENDPOINT.slice(4)}`,
};

export {
  ENDPOINT,
};
