import { Theme } from '@insights-gaming/theme';
import { humanizeKeystring } from '@insights-gaming/utils';
import { Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo, ReactElement } from 'react';
import { TFuncKey, useTranslation } from 'react-i18next';

import { Keybinding } from '@/features/settings/keybinding';

interface VideoControlsTooltipOwnProps {
  className?: string;
  tKey: TFuncKey<'hotkeys'[]>;
  keybindings?: Keybinding[];
  container?: HTMLElement | null;
  children: ReactElement;
}

type VideoControlsTooltipProps = VideoControlsTooltipOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'VideoControlsTooltip' });

function VideoControlsTooltip(props: VideoControlsTooltipProps) {
  const classes = useStyles(props);
  const { children, className, container, keybindings, tKey } = props;

  const { t } = useTranslation(['hotkeys']);
  const wt = t as WTFunction;

  const keybinding = keybindings ? keybindings[0] : undefined;
  const title = keybinding
    ? wt(tKey + 'WithKeybind', { keybind: humanizeKeystring(keybinding.key) })
    : wt(tKey);

  return (
    <Tooltip
    title={title}
    placement='top'
    PopperProps={{
      container,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            boundariesElement: 'scrollParent',
          },
        },
      ],
    }}
    className={classNames(classes.root, className)}
    >
      {children}
    </Tooltip>
  );
}

export default memo(VideoControlsTooltip);
