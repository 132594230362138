import { Reference, useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';

export const DeleteVideoMutation_VideoFragment = gql(`
  fragment DeleteVideoMutation_VideoFragment on Video {
    id
    directory {
      id
    }
  }
`);

const DeleteVideoMutation = gql(`
  mutation DeleteVideoMutation($input: DeleteVideoInput!) {
    deleteVideo(input: $input) {
      videoId
    }
  }
`);

export function useDeleteVideoMutation() {
  return useMutation(DeleteVideoMutation, {
    optimisticResponse: ({ input }) => ({
      deleteVideo: {
        videoId: input.id,
      },
    }),
    update: (cache, { data }) => {
      const deletedVideoId = data?.deleteVideo?.videoId;
      if (!deletedVideoId) {
        return;
      }
      const video = cache.readFragment({
        id: `Video:${deletedVideoId}`,
        fragment: DeleteVideoMutation_VideoFragment,
      });
      if (!video) {
        return;
      }
      if (video.directory) {
        cache.modify({
          id: cache.identify(video.directory),
          fields: {
            queryVideos(existing, { readField }) {
              if (!existing) {
                return existing;
              }
              return {
                ...existing,
                videos: existing.videos.filter((videoRef: Reference) => readField('id', videoRef) !== deletedVideoId),
              };
            },
          },
        });
      }
      cache.evict({ id: cache.identify(video) });
      cache.gc();
    },
  });
}
