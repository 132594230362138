import { MouseEvent, RefObject, useCallback, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';

import { GlobalContext } from '@/features/global-context/GlobalContext';
import { authHelpers } from '@/mixpanel/helpers/auth-helpers';

import { getAuthSession } from './authentication-selector';
import { SignInSource } from './authentication-slice';
import { LoginDialogContext } from './LoginDialogContext';

interface UseAuthDialogProps<E extends HTMLElement> {
  onClick?: (event: MouseEvent<E>) => void;
  signInSource: SignInSource;
}

export function useAuthDialog<E extends HTMLElement>(
  ref: RefObject<E>,
  props: UseAuthDialogProps<E>,
) {
  const { onClick, signInSource } = props;
  const { openLoginDialog } = useContext(LoginDialogContext);
  const { mixpanel } = useContext(GlobalContext);
  const isAuthed = !!useSelector(getAuthSession);

  const blockTrackRef = useRef<boolean>(false);

  const eRef = useRef(ref.current);
  eRef.current = ref.current;

  const isAuthedRef = useRef(isAuthed);
  isAuthedRef.current = isAuthed;

  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;

  const openLoginDialogRef = useRef(openLoginDialog);
  openLoginDialogRef.current = openLoginDialog;

  const handleClick = useCallback((e: MouseEvent<E>) => {
    if (!isAuthedRef.current) {
      openLoginDialogRef.current(signInSource, {
        onLoggedIn: () => {
          blockTrackRef.current = true;
          // eRef.current?.click();
          onClickRef.current?.(e);
          mixpanel.track(...authHelpers.signInDialog(signInSource, 'Signed In'));
          blockTrackRef.current = false;
        },
        onCancel: () => {
          mixpanel.track(...authHelpers.signInDialog(signInSource, 'Cancelled'));
        },
      });
      mixpanel.track(...authHelpers.signInDialog(signInSource, 'Opened'));
    } else {
      onClickRef.current?.(e);
      if (blockTrackRef.current) {
        return;
      }
      mixpanel.track(...authHelpers.signInDialog(signInSource, 'Already Signed In'));
    }
  }, [mixpanel, signInSource]);

  return handleClick;
}
