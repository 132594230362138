import { Theme } from '@insights-gaming/theme';
import { Box, Stack, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, PropsWithChildren } from 'react';

type VideoLayoutProps = PropsWithChildren<{
  className?: string;
  fullscreen?: boolean;

  titlePanel?: JSX.Element | null;
  userPanel?: JSX.Element | null;
  subPanel?: JSX.Element | null;
  leftPanel?: JSX.Element | null;
  rightPanel?: JSX.Element | null;

  sx?: SxProps<Theme>;
}>;

export const VideoLayout2 = forwardRef<HTMLDivElement, VideoLayoutProps>(function VideoLayout(props: VideoLayoutProps, ref) {
  const {
    children,
    className,
    fullscreen,
    leftPanel,
    rightPanel,
    subPanel,
    sx,
    titlePanel,
    userPanel,
  } = props;

  return (
    <Box
    ref={ref}
    flex={1}
    className={className}
    sx={[
      {
        display: 'grid',
        // use if we want to make the title panel span the top of the layout
        gridTemplateAreas: process.env.REACT_APP_BUILD_TARGET === 'web' ? `
          "title user"
          "main right"
        ` : `
          "title right"
          "main right"
        `,
        gridTemplateRows: 'min-content auto',
        gridTemplateColumns: 'auto min-content',
        gap: 1,
        [`${MainPanelContainer}`]: {
          flex: 1,
          overflow: 'hidden',
        },
        [`${SidePanelContainer}`]: {
          display: 'flex',
        },
      },
      process.env.REACT_APP_BUILD_TARGET === 'app' && !titlePanel && {
        gridTemplateAreas: `
          "main right"
        `,
        gridTemplateRows: 'auto',
      },
      fullscreen && {
        [`${SidePanelContainer}, ${SubPanelContainer}`]: {
          display: 'none',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      {leftPanel && (
        <SidePanelContainer>
          {leftPanel}
        </SidePanelContainer>
      )}
      {titlePanel && (
        <SubPanelContainer gridArea='title'>
          {titlePanel}
        </SubPanelContainer>
      )}
      {userPanel && userPanel.type() != null && (
        <SubPanelContainer gridArea='user'>
          {userPanel}
        </SubPanelContainer>
      )}
      <MainPanelContainer gridArea='main'>
        {children}
        {subPanel && (
          <SubPanelContainer>
            {subPanel}
          </SubPanelContainer>
        )}
      </MainPanelContainer>
      {rightPanel && (
        <SidePanelContainer gridArea='right'>
          {rightPanel}
        </SidePanelContainer>
      )}
    </Box>
  );
});

VideoLayout2.displayName = 'VideoLayout2';

const MainPanelContainer = styled(Stack, { name: 'MainPanelContainer' })();

const SubPanelContainer = styled(Box, { name: 'SubPanelContainer' })();

export const SidePanelContainer = styled(Box, { name: 'SidePanelContainer' })();
