import { VideoReplayContext } from '@insights-gaming/material-components';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useWebSetting, webSettingPath } from '@/features/settings/WebSettingsContext';

export function useShowVideoControls(
  el: HTMLElement | null,
  variant: 'overlay' | 'default' = 'default',
) {
  const {
    state: {
      fullscreen,
      mouseInactive,
    },
    detectMouseInactivity,
    setMouseInactive,
  } = useContext(VideoReplayContext);

  const toggleMode = useWebSetting(webSettingPath.videoReplay.toggleFullscreenControls);
  const [showFullscreenVideoControls, setShowFullscreenVideoControls] = useState(toggleMode);

  useEffect(() => {
    if (!el) {
      return undefined;
    }
    el.addEventListener('click', detectMouseInactivity);
    el.addEventListener('mousedown', detectMouseInactivity);
    el.addEventListener('mousemove', detectMouseInactivity);
    el.addEventListener('mouseleave', setMouseInactive);
    return () => {
      el.removeEventListener('click', detectMouseInactivity);
      el.removeEventListener('mousedown', detectMouseInactivity);
      el.removeEventListener('mousemove', detectMouseInactivity);
      el.removeEventListener('mouseleave', setMouseInactive);
    };
  }, [detectMouseInactivity, el, setMouseInactive]);

  const showVideoControls = (variant === 'default' && !fullscreen) || (fullscreen && toggleMode ? showFullscreenVideoControls : !mouseInactive);

  const toggleFullscreenControls = useCallback(() => {
    setShowFullscreenVideoControls((prev) => !prev);
  }, []);

  return useMemo(() => [showVideoControls, toggleFullscreenControls] as const, [showVideoControls, toggleFullscreenControls]);
}
