import { VideoReplayContext } from '@insights-gaming/material-components';
import { SyntheticEvent, useCallback, useContext, useState } from 'react';

export function useSeekbar() {
  const {
    state: { duration, displayProgress, progress, _seekOptions },
    seekTo,
  } = useContext(VideoReplayContext);

  const [changeCommitted, setChangeCommitted] = useState(true);

  const onChange = useCallback((__: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setChangeCommitted(false);
      seekTo({
        type: 'seconds',
        amount: newValue,
      });
    }
  }, [seekTo]);

  const onChangeCommitted = useCallback((__: Event | SyntheticEvent, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setChangeCommitted(true);
      seekTo({
        type: 'seconds',
        amount: newValue,
        seekCommitted: true,
      });
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, [seekTo]);

  return {
    min: 0,
    max: duration,
    value: (changeCommitted && !_seekOptions) ? progress.playedSeconds : displayProgress,
    onChange,
    onChangeCommitted,
  } as const;
}
