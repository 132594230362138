import { Avatar, AvatarProps } from '@mui/material';

export type AvatarSize = 24 | 32 | 40 | 48 | 64 | 96 | 128;

interface AvatarWithFallbackOwnProps {
  size?: AvatarSize;
  src?: string | null | undefined;
}

type AvatarWithFallbackProps = AvatarWithFallbackOwnProps & Omit<AvatarProps, 'src'>;

export function AvatarWithFallback(props: AvatarWithFallbackProps) {
  const { imgProps, size = 40, src, sx, ...rest } = props;
  const fallback = props.alt ? props.alt.charAt(0).toUpperCase() : undefined;
  return (
    <Avatar
    {...rest}
    src={src ?? undefined}
    imgProps={{ ...imgProps, draggable: false }}
    sx={[
      { width: size, height: size },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      {props.children || fallback}
    </Avatar>
  );
}
