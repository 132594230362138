import { useVideoReplayContextValue, VideoReplayProvider } from '@insights-gaming/material-components';
import { Suspense } from 'react';
import { useParams } from 'react-router';

import { SuspenseLoader } from '@/components/SuspenseLoader';
import { useWebSetting, webSettingPath } from '@/features/settings/WebSettingsContext';
import { DisplayCenterIconProvider, useDisplayCenterIcon } from '@/features/video-replay/useDisplayCenterIcon';

import { PublicVideo } from './PublicVideo';

const publicVideoPath = '/v/:videoId';

const path = {
  publicVideo: publicVideoPath,
} as const;

const createPublicVideoUrl = (videoId: ID) => `/v/${videoId}`;

function PublicVideoRoutingComponent() {
  const { videoId } = useParams<PublicVideoRouting.PublicVideoParams>();

  const mutedSetting = useWebSetting(webSettingPath.videoReplay.muted);
  const volumeSetting = useWebSetting(webSettingPath.videoReplay.volume);
  const lastNonZeroVolumeSetting = useWebSetting(webSettingPath.videoReplay._lastNonZeroVolume);
  const autoplay = useWebSetting(webSettingPath.videoReplay.autoplay);
  const _contextValue = useVideoReplayContextValue({ muted: mutedSetting, volume: volumeSetting, playing: autoplay, _lastNonZeroVolume: lastNonZeroVolumeSetting }, { inactivityDelay: 2000 });
  const [contextValue, icon] = useDisplayCenterIcon(_contextValue);

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <VideoReplayProvider value={contextValue}>
        <DisplayCenterIconProvider value={icon}>
          <PublicVideo videoId={videoId} />
        </DisplayCenterIconProvider>
      </VideoReplayProvider>
    </Suspense>
  );
}

class PublicVideoRouting {
  public static readonly path = path;
  public static readonly paths = Object.values(this.path);
  public static readonly createUrl = {
    publicVideo: createPublicVideoUrl,
  };
  public static readonly Component = PublicVideoRoutingComponent;
}

declare namespace PublicVideoRouting {
  interface PublicVideoParams {
    videoId: ID;
  }
}

export default PublicVideoRouting;
