interface Dimensions {
  width : number;
  height: number;
}

interface Point {
  x: number;
  y: number;
}

type Gravity =
  | 'center'
  | 'forget'
  | 'east'
  | 'north'
  | 'south'
  | 'west'
  | 'northeast'
  | 'northwest'
  | 'southeast'
  | 'southwest';

export interface CdnOptions {
  width?: number;
  height?: number;
  crop?: Dimensions | (Dimensions & Point);
  crop_gravity?: Gravity;
  aspect_ratio?: string;
  quality?: number;
  fit?: 'scale-down';
  format?: 'png' | 'jpg' | 'webp';
}

export function createCdnSearchParams(
  { width, height, crop, crop_gravity, aspect_ratio, quality, fit, format }: CdnOptions,
  params?: URLSearchParams,
): URLSearchParams {
  params = new URLSearchParams(params);
  if (width) {
    params.set('width', width.toFixed(0));
  }
  if (height) {
    params.set('height', height.toFixed(0));
  }
  if (crop) {
    params.set('crop', `${crop.width},${crop.height}`);
  }
  if (crop_gravity) {
    params.set('crop_gravity', crop_gravity);
  }
  if (aspect_ratio) {
    params.set('aspect_ratio', aspect_ratio);
  }
  if (quality) {
    params.set('quality', quality.toString());
  }
  if (fit) {
    params.set('fit', fit);
  }
  if (format) {
    params.set('format', format);
  }
  return params;
}

const CDN_REGEX = /(?:cdn\.(?:[\w-]+\.)?insights.gg|(?:[\w-]+\.)?insightsgg-user-content.com)/;

export function createCdnUrl(_url: string | null | undefined, options: CdnOptions = {}): string | null | undefined {
  if (!_url || !CDN_REGEX.test(_url)) {
    return _url;
  }
  const [url, paramString] = _url.split('?');
  const params = createCdnSearchParams(options, new URLSearchParams(paramString));
  return `${url}?${params.toString()}`;
}
