import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { CreateTeamResult } from '@/features/squad/useCreateTeamMutation';
import { typedAssetPath } from '@/utils';


interface SquadFinishSlideProps {
  className?: string;
  sx?: SxProps<Theme>;
  createdSquad: CreateTeamResult;
  creatingDivision?: boolean;
  onDone?: VoidFunction;
}

export function SquadFinishSlide(props: SquadFinishSlideProps) {
  const { className, sx, createdSquad, creatingDivision, onDone } = props;

  const { t } = useTranslation(['squad_onboarding']);

  return (
    <Stack
    className={className}
    sx={[
      {
        p: 3,
        minWidth: 352,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    spacing={1.5}
    justifyContent='center'
    alignItems='center'
    >
      <Stack direction='row' spacing={1.5} alignItems='center'>
        <AvatarWithFallback src={createdSquad.picture} alt='insights' size={48} />
        <Typography variant='h5'>
          {createdSquad.name}
        </Typography>
      </Stack>
      <img src={typedAssetPath('/assets/squad/squad-ready.svg')} alt='squad ready' />
      <Typography variant='h5'>
        {t('squad_onboarding:finish.title')}
      </Typography>
      <AsyncButton
      loading={creatingDivision}
      disabled={creatingDivision}
      color='primary'
      onClick={onDone}
      variant='contained'
      >
        {t('squad_onboarding:finish.toSquad')}
      </AsyncButton>
    </Stack>
  );
}
