import { Theme } from '@insights-gaming/theme';
import { Dialog } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo } from 'react';

import AlertDialogContent, { AlertDialogProps } from './AlertDialogContent';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'AlertDialog' });

function AlertDialog(props: AlertDialogProps) {
  const { actions, cancel, confirm, children, description, className, titleText, showCloseButton, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Dialog className={classNames(classes.root, className)} {...rest}>
      <AlertDialogContent {...props} />
    </Dialog>
  );
}

export default memo(AlertDialog);
