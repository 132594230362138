import { Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { memo } from 'react';

const BadgeChip = withStyles({
  root: {
    backgroundColor: '#F04E23',
    height: 20,
    fontSize: '0.75rem',
  },
  label: {
    padding: '0 6px',
  },
})(Chip);

interface CustomTextChipOwnProps {
  className?: string;
  label: string;
}

type CustomTextChipProps = CustomTextChipOwnProps;

function CustomTextChip(props: CustomTextChipProps) {
  const { className, label, ...rest } = props;

  return (
    <BadgeChip size='small' label={label} {...rest} className={className} />
  );
}

export default memo(CustomTextChip);
