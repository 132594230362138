import { ApolloClient } from '@apollo/client';
import { Channel, channel, SagaIterator } from 'redux-saga';
import { all, call, fork, getContext, put, putResolve, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { ClientContext } from '@/apollo/client';
import { RECAPTCHA_SITE_KEY } from '@/app/constants';
import { getFragmentData, gql } from '@/graphql/__generated__';
import { replaceRecaptchaScript } from '@/utils/dom-manip';
import { ENDPOINT, loginWithPassword, loginWithProvider, refreshSession, Session } from '@/utils/insightsgg';
import { callLogoutEndpoint, registerWithEmail, sendVerificationEmail } from '@/utils/insightsgg/auth';
import { takeEveryPromise } from '@/utils/promisify-saga';
import { raceAsyncAC } from '@/utils/saga-effects';

import { authenticationApi } from './authentication-api';
import { getAuthSession } from './authentication-selector';
import {
  apolloStoreReset,
  authSessionChecked,
  authSessionUpdated,
  fetchUserNotificationCountAC,
  loginWithPasswordAC,
  loginWithProviderAC,
  logout,
  logoutFinished,
  refreshAuthSessionAC,
  registerWithEmailAC,
  resetPasswordAC,
  updateProfileAC,
} from './authentication-slice';
import { UserProfileQuery } from './UserProfileContext';

const { fetchUserNotificationCount, updateUser } = authenticationApi;

declare let grecaptcha: any;

async function getRecaptchaToken(action: string): Promise<string> {
  if (typeof grecaptcha === 'undefined' || grecaptcha === null) {
    await replaceRecaptchaScript();
  }

  await new Promise((resolve, _reject) => grecaptcha.ready(resolve));
  const recaptchaToken: string = await grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
  if (!recaptchaToken) {
    console.log('no recaptcha token');
  }

  return recaptchaToken;
}

function* getAndRefreshAuthSession(): SagaIterator<Session | undefined> {
  let session = (yield select(getAuthSession)) as Session | undefined;
  if (!session) {
    return;
  }

  const { expiration, refreshToken } = session;
  if (expiration >= Date.now()) {
    return session;
  }

  yield put(refreshAuthSessionAC.started({ refreshToken }));
  const { done, failed } = yield raceAsyncAC(refreshAuthSessionAC);

  if (failed) {
    throw failed.payload.error;
  }

  return done.payload.result;
}

export function* getSessionAccessToken(): SagaIterator<string | undefined> {
  const session = yield call(getAndRefreshAuthSession);
  return ((session || {}) as Partial<Session>).accessToken;
}

function* registerWithEmailWatcher(): SagaIterator {
  yield takeEveryPromise(registerWithEmailAC, function* ({ payload: params }): SagaIterator {
    let shouldAttempRegister = true;
    try {
      yield call(loginWithPassword, params.email, params.password);
      return false;
    } catch (error) {
      switch (error.message) {
        case 'NOT_VERIFIED':
          shouldAttempRegister = false;
          break;
      }
    }

    if (shouldAttempRegister) {
      const recaptchaToken: string = yield call(getRecaptchaToken, 'signup');

      yield call(registerWithEmail, {
        email: params.email,
        password: params.password,
        marketing: params.marketing ? 'true' : 'false',
        referrer: params.referrer ?? '',
        'g-recaptcha-response': recaptchaToken,
      });

      try {
        yield call(loginWithPassword, params.email, params.password);
        return false;
      } catch (error) {
        /* allow it to fall through */
      }
    }

    return yield call(sendVerificationEmail, params.email);
  });
}

function* loginWithPasswordWatcher(): SagaIterator {
  yield takeEveryPromise(loginWithPasswordAC, function* ({ payload: params }): SagaIterator {
    const result = yield call(loginWithPassword, params.email, params.password);
    yield call(authActionDoneWorker, result, true);
    return result;
  });
}

function* loginWithProviderWatcher(): SagaIterator {
  yield takeEveryPromise(loginWithProviderAC, function* ({ payload: params, meta }): SagaIterator {
    const result = yield call(loginWithProvider, params.provider, meta?.setUrl);
    yield call(authActionDoneWorker, result, true);
    return result;
  });
}

function* refreshAuthSessionWatcher(): SagaIterator {
  yield takeEveryPromise(refreshAuthSessionAC, function* ({ payload: params }): SagaIterator {
    const result = yield call(refreshSession, params.refreshToken);
    yield call(authActionDoneWorker, result);
    return result;
  });
}

function saveAuthSession(store: LocalForage, session: Session) {
  store.setItem('authSession', session);
}

function* resetApollo() {
  console.log('resetting apollo');
  const apollo: ClientContext = yield getContext('apollo');
  yield call(async function () {
    try {
      await apollo.client.resetStore();
    } catch {}
  });
  yield put(apolloStoreReset());
  if (apollo.gracefullyRestart) {
    yield call(apollo.gracefullyRestart);
  }
}

function* authActionDoneWorker(result: Session, shouldReset = false): SagaIterator {
  yield putResolve<any>(authSessionUpdated(result));

  const authSessionStore: LocalForage = yield getContext('authSessionStore');
  const { accessToken, expiration, refreshToken } = result; // strip out newuser without having to know about it
  yield call(saveAuthSession, authSessionStore, { accessToken, expiration, refreshToken });
  if (shouldReset) {
    yield call(resetApollo);
  }
}

function* authActionDoneWatcher(): SagaIterator {
  const chan: Channel<ReturnType<typeof loginWithPasswordAC.done>> = yield call(channel);
  yield takeEvery([loginWithPasswordAC.done, loginWithProviderAC.done, refreshAuthSessionAC.done], function* (action) {
    if (loginWithPasswordAC.done.match(action) || loginWithProviderAC.done.match(action)) {
      yield put(chan, action);
    }
  });

  // ensures that state is updated with auth session before attempting to refresh session.
  yield takeEvery(chan, function* () {
    yield put(fetchUserNotificationCountAC.started());
  });
}

enum NotificationState {
  DELETED = 'DELETED',
  READ = 'READ', // completely read
  UNREAD = 'UNREAD', // completely new
  VIEWED = 'VIEWED', // saw the thing in the popover but not actually read
}

function* getUserNotificationCountWatcher(): SagaIterator {
  yield takeLatest(fetchUserNotificationCountAC.started, function* (action) {
    const taskEffect = call(function* () {
      try {
        const { notificationCounts: [result] }: AsyncReturnType<typeof fetchUserNotificationCount> = yield call(fetchUserNotificationCount, {
          variables: {
            filters: [{ states: [NotificationState.UNREAD, NotificationState.VIEWED] }],
          },
        });
        yield put(fetchUserNotificationCountAC.done({ result }));
      } catch (error) {
        yield put(fetchUserNotificationCountAC.failed({ error }));
      }
    });
    yield race({
      task: taskEffect,
      cancel: take([loginWithPasswordAC.done, loginWithProviderAC.done, logout]),
    });
  });
}

function loadAuthSession(store: LocalForage): Promise<Session | null> {
  return store.getItem('authSession');
}

function getUserProfileWorker<C>(client: ApolloClient<C>) {
  return client.query({ query: UserProfileQuery });
}

function* loadAuthSessionWorker(): SagaIterator {
  const authSessionStore: LocalForage = yield getContext('authSessionStore');
  const result: AsyncReturnType<typeof loadAuthSession> = yield call(loadAuthSession, authSessionStore);
  if (result) {
    yield call(authActionDoneWorker, result);
    yield put(fetchUserNotificationCountAC.started());
  }
  yield put(authSessionChecked());
}

function deleteAuthSession(store: LocalForage) {
  return store.removeItem('authSession');
}

const LoginFlow_SelfFragment = gql(`
  fragment LoginFlow_SelfFragment on Self {
    id
    email
  }
`);

function* loginFlow(): SagaIterator {
  yield fork(function* (): SagaIterator {
    while (true) {
      yield take(authSessionUpdated.fulfilled);
      if (process.env.REACT_APP_BUILD_TARGET === 'app') {
        try {
          const { client }: ClientContext = yield getContext('apollo');
          const { data }: AsyncReturnType<typeof getUserProfileWorker> = yield call(getUserProfileWorker, client);
          if (data.me) {
            const user = getFragmentData(LoginFlow_SelfFragment, data.me);
            if (user.email) {
              overwolf.extensions.current.generateUserEmailHashes(user.email, result => {
                console.log('set hash success', result.success);
              });
            }
          }
        } catch {}
      }
      yield take(logout);
    }
  });

  yield takeEvery(logout, function* () {
    yield call(function () {
      window.sessionStorage.removeItem('refreshToken');
    });
    const { client, dispose }: ClientContext = yield getContext('apollo');
    dispose();
    const self = client.cache.identify({ __typename: 'Self' });
    client.cache.evict({ id: self });
    client.cache.reset();
    client.cache.gc();

    const authSessionStore: LocalForage = yield getContext('authSessionStore');
    yield call(deleteAuthSession, authSessionStore);
    yield call(callLogoutEndpoint);
    yield call(resetApollo);
    if (process.env.REACT_APP_BUILD_TARGET === 'app') {
      overwolf.extensions.current.setUserEmailHashes({} as any, result => {
        console.log('clear hash success', result.success);
      });
    }
    yield put(logoutFinished());
  });
}

function* updateUserWatcher(): SagaIterator {
  yield takeEveryPromise(updateProfileAC, function* (action): SagaIterator {
    const result: AsyncReturnType<typeof updateUser> = yield call(updateUser, action.payload);
    return result;
  });
}

function* resetPasswordWatcher(): SagaIterator {
  yield takeEveryPromise(resetPasswordAC, function* (action): SagaIterator {
    const { email } = action.payload;
    const recaptchaToken: string = yield call(getRecaptchaToken, 'signup');
    async function worker() {
      const res: Response = await fetch(ENDPOINT + '/oauth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          email,
          'g-recaptcha-response': recaptchaToken,
        }).toString(),
      });
      if (!res.ok) {
        const { error } = await res.json();
        throw new Error(error);
      }
      return true;
    }
    return yield call(worker);
  });
}

export default function* authenticationSaga() {
  yield all([
    loadAuthSessionWorker(),
    registerWithEmailWatcher(),
    loginWithPasswordWatcher(),
    loginWithProviderWatcher(),
    refreshAuthSessionWatcher(),
    authActionDoneWatcher(),
    // getUserFeaturesWatcher(),
    getUserNotificationCountWatcher(),
    loginFlow(),
    updateUserWatcher(),
    resetPasswordWatcher(),
  ]);
}
