import { useMemo } from 'react';
import { useLocation } from 'react-router';

import InvitationRouting from '@/features/squad/Invitation/invitation.routing';

import { LoginState } from './types';

export function useLoginState(): LoginState {
  const { pathname, state } = useLocation<LoginState>();

  return useMemo<LoginState>(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    const reload = (params.get('reload') === '1' || state?.reload === '1');
    const referPath = code
      ? InvitationRouting.createUrl.code(code)
      : params.get('referPath') || state?.redirectPath || state?.referPath || pathname;
    const referrer = params.get('referrer') || state?.referrer;
    const loginState: LoginState = {};
    if (reload) {
      loginState.reload = '1';
    }
    if (referPath) {
      loginState.referPath = referPath;
    }
    if (referrer) {
      loginState.referrer = referrer;
    }
    return loginState;
  }, [pathname, state?.redirectPath, state?.referPath, state?.referrer, state?.reload]);
}
