import { ApolloLink, InMemoryCache, Observable } from '@apollo/client';
import { FieldNode, OperationDefinitionNode } from 'graphql';

export function createIdListCacheLink() {
  return new ApolloLink((operation, forward) => {
    const operationDefinition = operation.query.definitions.find((def): def is OperationDefinitionNode => def.kind === 'OperationDefinition');
    if (operationDefinition?.operation === 'query') {
      if (operationDefinition.variableDefinitions) {
        const idsVariableDefinition = operationDefinition.variableDefinitions.find(variableDefinition => {
          return variableDefinition.kind === 'VariableDefinition'
            && variableDefinition.type.kind === 'NonNullType'
            && variableDefinition.type.type.kind === 'ListType'
            && variableDefinition.type.type.type.kind === 'NonNullType'
            && variableDefinition.type.type.type.type.kind === 'NamedType'
            && variableDefinition.type.type.type.type.name.value === 'ID';
        });
        if (idsVariableDefinition) {
          const variableName = idsVariableDefinition.variable.name.value;
          const fieldThatUsesIdsVariable = operationDefinition.selectionSet.selections.find((selection): selection is FieldNode => !!(selection.kind === 'Field' ? selection.arguments?.find(argument => argument.name.value === variableName) : undefined));
          if (fieldThatUsesIdsVariable) {
            const fieldName = fieldThatUsesIdsVariable.name.value;
            const ids: ID[] = operation.variables[variableName];
            const { cache } = operation.getContext() as { cache: InMemoryCache };
            const cached: Dictionary<unknown> = {};
            const uncached: ID[] = [];
            for (const id of ids) {
              const result = cache.readQuery({
                query: operation.query,
                variables: {
                  ...operation.variables,
                  [variableName]: [id],
                },
              });
              if (result) {
                cached[id] = (result as Dictionary<unknown[]>)[fieldName][0];
              } else {
                uncached.push(id);
              }
            }
            if (uncached.length > 0) {
              operation.variables[variableName] = uncached;
              return forward(operation).map(result => {
                operation.variables[variableName] = ids;
                if (result.data) {
                  const arr = result.data[fieldName];
                  if (Array.isArray(arr)) {
                    for (let i = 0; i < uncached.length; i++) {
                      const id = uncached[i];
                      const item = arr[i];
                      cache.writeQuery({
                        query: operation.query,
                        variables: {
                          ...operation.variables,
                          [variableName]: [id],
                        },
                        data: {
                          [fieldName]: [item],
                        },
                      });
                      cached[id] = item;
                    }
                    return {
                      ...result,
                      data: {
                        ...result.data,
                        [fieldName]: ids.map(id => cached[id]),
                      },
                    };
                  }
                }
                return result;
              });
            } else {
              return new Observable(subscriber => {
                subscriber.next({
                  data: {
                    [fieldName]: ids.map(id => cached[id]),
                  },
                });
                subscriber.complete();
              });
            }
          }
        }
      }
    }
    return forward(operation);
  });
}
