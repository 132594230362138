import { useAtomValue } from 'jotai';
import { createContext, PropsWithChildren, useCallback, useContext, useMemo, useRef } from 'react';

import { NOOP } from '@/utils';
import { isExistent } from '@/utils/guard';

import { MultiSelectAtomsContext } from './atoms/multiSelectAtoms';

export interface CustomDragLayerFolder {
  uuid: UUID;
  name: string;
}

export interface CustomDragLayerVideo {
  uuid: UUID;
  name: string;
  thumbnailUrl?: string;
}

interface CustomDragLayerContextValue {
  folderCardRefs: (readonly [CustomDragLayerFolder, HTMLDivElement])[];
  // setFolderCardRefs: Dispatch<SetStateAction<[CustomDragLayerFolder, HTMLDivElement][]>>;
  videoCardRefs: (readonly [CustomDragLayerVideo, HTMLDivElement])[];
  // setVideoCardRefs: Dispatch<SetStateAction<[CustomDragLayerVideo, HTMLDivElement][]>>;
  getFolderRefs: () => (readonly [CustomDragLayerFolder, HTMLDivElement])[];
  addFolderToCache: (folder: CustomDragLayerFolder, card: HTMLDivElement) => void;
  clearFolderCache: () => void;
  getVideoRefs: () => (readonly [CustomDragLayerVideo, HTMLDivElement])[];
  addVideoToCache: (video: CustomDragLayerVideo, card: HTMLDivElement) => void;
  clearVideoCache: () => void;
}

export const CustomDragLayerContext = createContext<CustomDragLayerContextValue>({
  folderCardRefs: [],
  // setFolderCardRefs: NOOP,
  videoCardRefs: [],
  // setVideoCardRefs: NOOP,
  getFolderRefs: () => [],
  addFolderToCache: NOOP,
  clearFolderCache: NOOP,
  getVideoRefs: () => [],
  addVideoToCache: NOOP,
  clearVideoCache: NOOP,
});

export function useCustomDragLayerContextValue(): CustomDragLayerContextValue {
  const folderCacheRef = useRef<Dictionary<{ folder: CustomDragLayerFolder; card: HTMLDivElement }>>({});
  const videoCacheRef = useRef<Dictionary<{ video: CustomDragLayerVideo; card: HTMLDivElement }>>({});

  const addFolderToCache = useCallback((folder: CustomDragLayerFolder, card: HTMLDivElement) => {
    folderCacheRef.current[folder.uuid] = { folder, card };
  }, []);

  const clearFolderCache = useCallback(() => {
    folderCacheRef.current = {};
  }, []);

  const addVideoToCache = useCallback((video: CustomDragLayerVideo, card: HTMLDivElement) => {
    videoCacheRef.current[video.uuid] = { video, card };
  }, []);

  const clearVideoCache = useCallback(() => {
    videoCacheRef.current = {};
  }, []);

  const { selectedVideosAtoms, selectedFoldersAtoms } = useContext(MultiSelectAtomsContext);
  const selectedVideos = useAtomValue(selectedVideosAtoms.selectedAtom);
  const selectedFolders = useAtomValue(selectedFoldersAtoms.selectedAtom);

  const selectedFoldersRef = useRef(selectedFolders);
  const selectedVideosRef = useRef(selectedVideos);

  if (selectedFoldersRef.current !== selectedFolders) {
    selectedFoldersRef.current = selectedFolders;
  }

  if (selectedVideosRef.current !== selectedVideos) {
    selectedVideosRef.current = selectedVideos;
  }

  const folderCardRefs = useMemo(() => {
    return Array.from(selectedFolders).map((uuid) => {
      const folder = folderCacheRef.current[uuid];
      return folder ? [folder.folder, folder.card] as const : null;
    }).filter(isExistent);
  }, [selectedFolders]);

  const videoCardRefs = useMemo(() => {
    return Array.from(selectedVideos).map((uuid) => {
      const video = videoCacheRef.current[uuid];
      return video ? [video.video, video.card] as const : null;
    }).filter(isExistent);
  }, [selectedVideos]);

  const getFolderRefs = useCallback(() => {
    return Array.from(selectedFoldersRef.current).map((uuid) => {
      const folder = folderCacheRef.current[uuid];
      return folder ? [folder.folder, folder.card] as const : null;
    }).filter(isExistent);
  }, []);

  const getVideoRefs = useCallback(() => {
    return Array.from(selectedVideosRef.current).map((uuid) => {
      const video = videoCacheRef.current[uuid];
      return video ? [video.video, video.card] as const : null;
    }).filter(isExistent);
  }, []);

  return useMemo(() => ({
    folderCardRefs,
    getFolderRefs,
    addFolderToCache,
    clearFolderCache,
    videoCardRefs,
    getVideoRefs,
    addVideoToCache,
    clearVideoCache,
  }), [addFolderToCache, addVideoToCache, clearFolderCache, clearVideoCache, folderCardRefs, getFolderRefs, getVideoRefs, videoCardRefs]);
}

export function CustomDragLayerInjector(props: PropsWithChildren) {
  const value = useCustomDragLayerContextValue();

  return (
    <CustomDragLayerContext.Provider value={value}>
      {props.children}
    </CustomDragLayerContext.Provider>
  );
}
