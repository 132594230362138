import { Theme } from '@insights-gaming/theme';
import { Box, SxProps } from '@mui/material';
import useMergedRef from '@react-hook/merged-ref';
import { debounce, throttle } from 'lodash';
import { forwardRef, ReactEventHandler, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

interface VideoCanvasPreviewProps {
  className?: string;
  sx?: SxProps<Theme>;

  videoPath?: string;
  canvasRef: React.Ref<HTMLCanvasElement>;
  onError?: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
}

export interface VideoPreviewControls {
  play: VoidFunction;
  pause: VoidFunction;
  unmute: VoidFunction;
}

export const VideoCanvasPreview = forwardRef(function VideoCanvasPreview(props: VideoCanvasPreviewProps, ref: React.Ref<VideoPreviewControls>) {
  const { className, onError, sx, videoPath } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const merged = useMergedRef(canvasRef, props.canvasRef);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { width, height } = useResizeDetector({ handleHeight: true, handleWidth: true, targetRef: canvasRef });

  const animationHandle = useRef<number>();
  const playPromiseRef = useRef<Promise<void>>();

  const play = useMemo(() => debounce(() => {
    if (videoRef.current && videoPath) {
      videoRef.current.setAttribute('src', videoPath);
      return playPromiseRef.current = videoRef.current.play().catch(onError);
    }
  }, 100), [onError, videoPath]);

  useEffect(() => {
    return () => {
      play.cancel();
    };
  }, [play]);

  const pause = useCallback(async () => {
    play.cancel();
    if (playPromiseRef.current !== undefined) {
      await playPromiseRef.current;
    }
    if (videoRef.current) {
      videoRef.current.removeAttribute('src');
      videoRef.current.load();
    }
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
    if (animationHandle.current) {
      cancelAnimationFrame(animationHandle.current);
    }
  }, [play]);

  const unmute = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  }, []);

  useEffect(() => {
    const throttled = throttle(pause, 300);
    window.addEventListener('scroll', throttled);
    return () => {
      window.removeEventListener('scroll', throttled);
    };
  }, [pause]);

  useEffect(() => {
    window.addEventListener('dragstart', pause);
    return () => {
      window.removeEventListener('dragstart', pause);
    };
  }, [pause]);

  useImperativeHandle(
    ref,
    () => ({
      play,
      pause,
      unmute,
    }),
    [play, pause, unmute],
  );

  const [aspectRatio, setAspectRatio] = useState<string>('16/9');
  const widthRef = useRef<number>(1920);
  const heightRef = useRef<number>(1080);

  const handlePlay: ReactEventHandler<HTMLVideoElement> = useCallback((e) => {
    const memoryCanvas = document.createElement('canvas');
    function _step() {
      if (!canvasRef.current || !videoRef.current) {
        return;
      }
      const ctx = canvasRef.current.getContext('2d', { colorSpace: 'display-p3' });
      if (!ctx) {
        return;
      }
      ctx.imageSmoothingQuality = 'high';
      ctx.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      animationHandle.current = requestAnimationFrame(_step);
    }

    function _resizeStep(time: number) {
      if (!canvasRef.current || !videoRef.current) {
        return;
      }
      const halfWidth = memoryCanvas.width = Math.floor(widthRef.current / 2);
      const halfHeight = memoryCanvas.height = Math.floor(heightRef.current / 2);
      const ctx = canvasRef.current.getContext('2d', { colorSpace: 'display-p3' });
      const memoryCtx = memoryCanvas.getContext('2d', { colorSpace: 'display-p3' });
      if (!ctx || !memoryCtx) {
        return;
      }
      memoryCtx.imageSmoothingQuality = 'high';
      ctx.imageSmoothingQuality = 'high';

      memoryCtx.drawImage(videoRef.current, 0, 0, halfWidth, halfHeight);
      let w = halfWidth;
      let h = halfHeight;
      for (let i = 0; i < 5; i++) { // perform 5 intermediate resizes at most
        const nw = Math.floor(w / 2);
        const nh = Math.floor(h / 2);
        if (nw < canvasRef.current.width || nh < canvasRef.current.height) {
          break;
        }
        memoryCtx.drawImage(memoryCanvas, 0, 0, w, h, 0, 0, nw, nh);
        w = nw;
        h = nh;
      }
      ctx.drawImage(memoryCanvas, 0, 0, w, h, 0, 0, canvasRef.current.width, canvasRef.current.height);
      animationHandle.current = requestAnimationFrame(_resizeStep);
    }

    function blurStep(time: number) {
      if (!canvasRef.current || !videoRef.current) {
        return;
      }
      memoryCanvas.width = widthRef.current;
      memoryCanvas.height = heightRef.current;
      const ctx = canvasRef.current.getContext('2d', { colorSpace: 'display-p3' });
      const memoryCtx = memoryCanvas.getContext('2d', { colorSpace: 'display-p3' });
      if (!ctx || !memoryCtx) {
        return;
      }
      memoryCtx.imageSmoothingQuality = 'high';
      ctx.imageSmoothingQuality = 'high';
      const steps = (memoryCanvas.width / canvasRef.current.width) >> 1;
      memoryCtx.filter = `blur(${steps}px)`;
      memoryCtx.drawImage(videoRef.current, 0, 0, memoryCanvas.width, memoryCanvas.height);
      ctx.drawImage(memoryCanvas, 0, 0, canvasRef.current.width, canvasRef.current.height);
      animationHandle.current = requestAnimationFrame(blurStep);
    }
    animationHandle.current = requestAnimationFrame(blurStep);
  }, []);

  return (
    <Box
    className={className}
    sx={[
      {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        '& > canvas': {
          width: '100%',
          aspectRatio,
        },
        '& > video': {
          position: 'absolute',
          opacity: 0,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <canvas ref={merged} width={width} height={height} className={className} />
      <video
      ref={videoRef}
      muted={true}
      preload='none'
      onPlay={handlePlay}
      onLoadedMetadata={({ currentTarget: { videoWidth, videoHeight } }) => {
        setAspectRatio(`${videoWidth}/${videoHeight}`);
        widthRef.current = videoWidth;
        heightRef.current = videoHeight;
      }}
      />
    </Box>
  );
});
