import { FieldMergeFunction, makeVar, Reference, StoreObject } from '@apollo/client';

import { gql } from '@/graphql/__generated__';
import { StrictTypedTypePolicies } from '@/graphql/__generated__/graphql';
import generatedIntrospection from '@/graphql/__generated__/possibleTypes.json';
import { NumberComparator } from '@/utils/compare';
import { isExistent } from '@/utils/guard';

const defaultMergeStrategy: FieldMergeFunction<(StoreObject | Reference)[]> = (
  existing = [],
  incoming = [],
  options,
) => {
  const { toReference, variables } = options;
  const after = variables?.after;
  // readField doesn't work sometimes so have to use this ref hack
  // https://github.com/apollographql/apollo-client/issues/9315
  const merged = after ? [...existing, ...incoming] : [...incoming, ...existing];
  const __refs = merged
    .map(item => toReference(item))
    .filter(isExistent)
    .map(item => item.__ref);
  const uniqueRefs = Array.from(new Set(__refs));

  return uniqueRefs
    .map(__ref => toReference(__ref))
    .filter(isExistent);
};

const directoryFragment = gql(`
  fragment TypePolicy_DirectoryFragment on Directory {
    id
  }
`);

export const typePolicies: StrictTypedTypePolicies = {
  Division: {
    merge(existing, incoming, { cache, mergeObjects, readField }) {
      if (incoming && !existing) {
        const incomingTeam: Reference | undefined = readField('team', incoming);
        if (incomingTeam) {
          cache.modify({
            id: cache.identify(incomingTeam),
            fields: {
              queryDivisions(prev, { toReference }) {
                if (!prev || !prev.divisions || prev.divisions.some((division: Reference) => readField('id', division) === readField('id', incoming))) {
                  return prev;
                }
                return {
                  ...prev,
                  divisions: [toReference(incoming), ...prev.divisions],
                };
              },
            },
          });
        }
      }
      return mergeObjects(existing, incoming);
    },
  },
  Folder: {
    merge(existing, incoming, { cache, mergeObjects, readField }) {
      const incomingId = incoming ? readField('id', incoming) : undefined;
      const existingId = existing ? readField('id', existing) : undefined;
      if (incomingId && existingId && incomingId !== existingId) {
        return incoming; // defend against merging as a result of moving video/folder
      }
      const incomingParent: Reference | undefined = incoming ? readField('parent', incoming) : undefined;
      const existingParent: Reference | undefined = existing ? readField('parent', existing) : undefined;

      if (existingParent && incomingParent && cache.identify(existingParent) !== cache.identify(incomingParent)) {
        cache.modify({
          id: cache.identify(existingParent),
          fields: {
            queryFolders(prev) {
              if (!prev || !prev.folders) {
                return prev;
              }
              return {
                ...prev,
                folders: prev.folders.filter((folder: Reference) => readField('id', folder) !== incomingId),
              };
            },
          },
        });
      }

      if (incomingParent && (!existingParent || cache.identify(existingParent) !== cache.identify(incomingParent))) {
        cache.modify({
          id: cache.identify(incomingParent),
          fields: {
            // queryFolders(prev, { readField, toReference }) {
            //   if (!prev || !prev.folders || prev.folders.some((folder: Reference) => readField('id', folder) === incomingId)) {
            //     return prev;
            //   }
            //   return {
            //     ...prev,
            //     folders: [toReference(incoming), ...prev.folders].sort((a: Reference, b: Reference) => {
            //       const aCreated: string | null | undefined = readField('created', a);
            //       const bCreated: string | null | undefined = readField('created', b);
            //       if (!aCreated || !bCreated) {
            //         return 0;
            //       }
            //       const aTime = new Date(aCreated).getTime();
            //       const bTime = new Date(bCreated).getTime();
            //       return NumberComparator.desc(aTime, bTime);
            //     }),
            //   };
            // },
            queryFolders(_, { DELETE }) {
              return DELETE;
            },
          },
        });
      }

      return mergeObjects(existing, incoming);
    },
  },
  GetTeamSubscriptionDetailPayload: {
    keyFields: ['subscriptionId'],
  },
  Profile: {
    // keyFields: ['id'],
    merge: true,
  },
  Query: {
    fields: {
      directory: {
        keyArgs: ['id'],
        read(_, { args, cache, toReference }) { // hack to see if we have any cached data for this directory
          for (const directoryType of generatedIntrospection.possibleTypes.Directory) {
            const frag = cache.readFragment({
              id: cache.identify({ __typename: directoryType, id: args?.id }),
              fragment: directoryFragment,
            });
            if (frag) {
              return toReference({
                __typename: directoryType,
                id: args?.id,
              });
            }
          }
          return _;
        },
      },
      getTeamSubscriptionDetail: {
        keyArgs: ['input', ['teamId']],
      },
      queryTeams: {
        keyArgs: false,
      },
      search: {
        keyArgs: ['query', 'teamId', 'folderIds', 'tagIds', 'types'],
      },
      team: {
        keyArgs: ['id'],
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Team',
            id: args?.id,
          });
        },
      },
      teamEdge: {
        keyArgs: ['id'],
        read(_, { args, toReference }) {
          if (!args?.id) {
            return undefined;
          }
          return toReference({
            __typename: 'TeamEdge',
            team: {
              id: args?.id,
            },
          });
        },
      },
      video: {
        keyArgs: ['id'],
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'Video',
            id: args?.id,
          });
        },
      },
    },
  },
  QueryDivisionsResult: {
    keyFields: false,
    fields: {
      divisions: {
        keyArgs: false,
        merge: defaultMergeStrategy,
      },
      pageInfo: {
        keyArgs: false,
      },
    },
  },
  QueryFoldersResult: {
    keyFields: false,
    merge: true,
    fields: {
      folders: {
        keyArgs: false,
        merge: defaultMergeStrategy,
      },
      pageInfo: {
        keyArgs: false,
      },
    },
  },
  QueryMembersResult: {
    keyFields: false,
    fields: {
      edges: {
        keyArgs: false,
        merge: defaultMergeStrategy,
      },
      pageInfo: {
        keyArgs: false,
      },
    },
  },
  QueryPageInfo: {
    keyFields: false,
    merge(existing, incoming, options) {
      if (!existing) {
        // first fetch; this should never be a backwards fetch
        return {
          ...incoming,
          moreBackward: false,
        };
      }
      const before = options.variables?.before;
      if (before) { // backwards fetch
        return {
          ...existing,
          moreBackward: incoming.more,
          start: incoming.start,
        };
      }
      return {
        ...existing,
        more: incoming.more,
        end: incoming.end,
      };
    },
  },
  QueryTeamsResult: {
    keyFields: [],
    fields: {
      teamEdges: {
        keyArgs: false,
        merge: defaultMergeStrategy,
      },
      pageInfo: {
        keyArgs: false,
      },
    },
  },
  QueryVideosResult: {
    keyFields: false,
    merge: true,
    fields: {
      videos: {
        keyArgs: false,
        merge: defaultMergeStrategy,
      },
      pageInfo: {
        keyArgs: false,
      },
    },
  },
  Self: {
    keyFields: [],
    merge: true,
    fields: {
      teamOrder: {
        keyArgs: false,
        merge: false,
      },
    },
  },
  Team: {
    // keyFields: ['id'],
    // merge: (existing, incoming, { mergeObjects, readField }) => {
    //   const merged = mergeObjects(existing, incoming);
    //   const name = readField('name', merged);
    //   const isVisible = name !== '_my_public_uploads';
    //   return mergeObjects(merged, { isVisible });
    // },
    merge: true,
    fields: {
      isVisible: {
        read: (_, { readField, storage }) => {
          // https://www.apollographql.com/docs/react/local-state/managing-state-with-field-policies/#reads-are-synchronous-by-design
          // make this behave like asyncronous action to get correct value when used with useSuspenseQuery
          if (!storage.isVisible) {
            storage.isVisible = makeVar(false);
          }
          setTimeout(() => {
            const name = readField('name');
            storage.isVisible(typeof name === 'string' && name !== '_my_public_uploads');
          });
          return storage.isVisible();
        },
      },
      queryDivisions: {
        keyArgs: false,
      },
      queryInvoices: {
        keyArgs: false,
      },
      queryMembers: {
        keyArgs: false,
      },
      queryTags: {
        keyArgs: ['type'],
      },
    },
  },
  TeamDirectory: {
    // keyFields: ['id'],
    merge: true,
    fields: {
      queryFolders: {
        keyArgs: false,
      },
      queryUsers: {
        keyArgs: false,
      },
      queryVideos: {
        keyArgs: ['tagIds'],
      },
    },
  },
  TeamEdge: {
    keyFields: [
      'team',
      // @ts-expect-error
      ['id'],
    ],
    merge: true,
  },
  Video: {
    // keyFields: ['id'],
    merge(existing, incoming, { cache, readField, mergeObjects, toReference }) {
      const incomingId = incoming ? readField('id', incoming) : undefined;
      const existingDirectory: Reference | undefined = existing ? readField('directory', existing) : undefined;
      const incomingDirectory: Reference | undefined = incoming ? readField('directory', incoming) : undefined;

      if (existingDirectory && incomingDirectory && cache.identify(existingDirectory) !== cache.identify(incomingDirectory)) {
        cache.modify({
          id: cache.identify(existingDirectory),
          fields: {
            queryVideos(prev) {
              if (!prev || !prev.videos) {
                return prev;
              }
              return {
                ...prev,
                videos: prev.videos.filter((video: Reference) => readField('id', video) !== incomingId),
              };
            },
          },
        });
      }

      if (incomingDirectory && (!existingDirectory || cache.identify(existingDirectory) !== cache.identify(incomingDirectory))) {
        cache.modify({
          id: cache.identify(incomingDirectory),
          fields: {
            queryVideos(prev, { readField }) {
              if (!prev || !prev.videos || prev.videos.some((video: Reference) => readField('id', video) === incomingId)) {
                return prev;
              }

              return {
                ...prev,
                videos: [toReference(incoming), ...prev.videos].sort((a: Reference, b: Reference) => {
                  const aCreated: string | null | undefined = readField('created', a);
                  const bCreated: string | null | undefined = readField('created', b);
                  if (!aCreated || !bCreated) {
                    return 0;
                  }
                  const aTime = new Date(aCreated).getTime();
                  const bTime = new Date(bCreated).getTime();
                  return NumberComparator.desc(aTime, bTime);
                }),
              };
            },
          },
        });
      }

      return mergeObjects(existing, incoming);
    },
    fields: {
      directory: {
        merge: false,
      },
      division: {
        merge: false,
      },
      isWatchable: {
        read: (_, { readField }) => {
          const streamUrls = readField('streamUrls');
          return Array.isArray(streamUrls) && streamUrls.length > 0;
        },
      },
    },
  },
};
