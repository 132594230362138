import './messages.prod';

import { ApolloClient, ApolloLink, GraphQLRequest, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { sha256 } from 'crypto-hash';
import { OperationDefinitionNode } from 'graphql';

import generatedIntrospection from '@/graphql/__generated__/possibleTypes.json';
import { NOOP } from '@/utils';
import { getCfAccessCredentials } from '@/utils/cf-helper';
import { endpoints } from '@/utils/insightsgg/common';

import { createIdListCacheLink } from './createIdListCacheLink';
import { ConnectionParams, createWsLink } from './createWsLink';
import { typePolicies } from './type-policies';

interface DisposeableLink {
  link: ApolloLink;
  gracefullyRestart?: VoidFunction;
  dispose: VoidFunction;
}

const LinkCtor = process.env.NODE_ENV === 'development' ? HttpLink : BatchHttpLink;
const httpLink: DisposeableLink = {
  link: new LinkCtor({
    uri: endpoints.gql,
    credentials: process.env.REACT_APP_BUILD_TARGET === 'app' ? 'omit' : undefined,
    batchMax: -1,
  }),
  dispose: NOOP,
};

// recursively check if any value is a Blob or File
function hasBlob(value: any) {
  if (!value) {
    return false;
  }

  if (typeof value !== 'object') {
    return false;
  }

  if (value instanceof Blob) {
    return true;
  }

  return (
    Array.isArray(value) ? value : Object.values(value)
  ).some(hasBlob);
}

const httpLinkWithUpload = split(
  ({ variables }) => Object.values(variables).some(hasBlob),
  createUploadLink({ uri: endpoints.gql }),
  httpLink.link,
);

function createLinkWithWs(): DisposeableLink {
  const { wsLink, dispose, gracefullyRestart } = createWsLink();
  const link = split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def.kind === 'OperationDefinition' && def.operation === 'subscription';
    },
    wsLink,
    httpLinkWithUpload,
  );
  return {
    link,
    gracefullyRestart,
    dispose,
  };
}

const cfAccessLink = new ApolloLink((operation, forward) => {
  const cf = getCfAccessCredentials();
  if (cf) {
    operation.setContext((prev: GraphQLRequest['context']) => ({
      ...prev,
      headers: {
        ...prev?.headers,
        ...cf,
      },
    }));
  }
  return forward(operation);
});

const authLink = new ApolloLink((operation, forward) => {
  const { token: contextToken } = operation.getContext();
  const token = contextToken ?? window.sessionStorage.getItem('refreshToken');

  operation.setContext((prev: GraphQLRequest['context']) => ({
    ...prev,
    headers: {
      ...prev?.headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }));

  return forward(operation);
});

const fetchOptsLink = new ApolloLink((operation, forward) => {
  const { signal } = operation.getContext();

  operation.setContext((prev: GraphQLRequest['context']) => ({
    ...prev,
    fetchOptions: {
      ...prev?.fetchOptions,
      signal,
    },
  }));

  return forward(operation);
});

export interface ClientContext {
  client: ApolloClient<NormalizedCacheObject>;
  gracefullyRestart?: VoidFunction;
  dispose: VoidFunction;
}

// recursively recreate File objects due to multi-window bullshit
function fixFileObjects(input: Record<string, any>) {
  Object.entries(input).forEach(([key, value]: [string, any]) => {
    if (!value) {
      return;
    }
    if (typeof value === 'object') {
      if (value.constructor.name === 'File') {
        input[key] = new File([value], value.name, value);
      } else if (value.constructor.name === 'Object') {
        fixFileObjects(value);
      }
    }
  });
}

export function createApolloClient(connectionParams?: ConnectionParams): ClientContext {
  const wsOrHttpLink = createLinkWithWs();

  const link = ApolloLink.from([
    cfAccessLink,
    authLink,
    fetchOptsLink,
    new ApolloLink((operation, forward) => {
      const operationDefinition = operation.query.definitions.find((def): def is OperationDefinitionNode => def.kind === 'OperationDefinition');
      if (operationDefinition?.operation === 'mutation') {
        if (operation.variables?.input) {
          fixFileObjects(operation.variables.input);
        }
      }
      return forward(operation);
    }),
    createIdListCacheLink(),
    wsOrHttpLink.link,
  ]);

  const client = new ApolloClient({
    link: process.env.NODE_ENV === 'production' ? createPersistedQueryLink({ sha256 }).concat(link) : link,
    cache: new InMemoryCache({
      possibleTypes: generatedIntrospection.possibleTypes,
      typePolicies,
    }),
    connectToDevTools: process.env.NODE_ENV !== 'production',
  });

  return {
    client,
    gracefullyRestart: wsOrHttpLink?.gracefullyRestart,
    dispose: () => {
      // client.clearStore();
      wsOrHttpLink.dispose();
    },
  };
}
