import { useSuspenseQuery } from '@apollo/client';
import invariant from 'ts-invariant';

import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { TeamFeature, TeamPrivilege } from '@/graphql/__generated__/graphql';

const UseAccessControl_TeamEdgeQuery = gql(`
  query UseAccessControl_TeamEdgeQuery($id: ID!) {
    teamEdge(id: $id) {
      ...UseAccessControl_TeamEdgeFragment
    }
  }
`);

const UseAccessControl_RoleFragment = gql(`
  fragment UseAccessControl_RoleFragment on Role {
    id
    privileges
  }
`);

const UseAccessControl_TeamEdgeFragment = gql(`
  fragment UseAccessControl_TeamEdgeFragment on TeamEdge {
    team {
      id
      features {
        feature
      }
      owner {
        id
      }
    }
    privileges
    roles {
      id
      ...UseAccessControl_RoleFragment
    }
  }
`);

const UseAccessControl_MemberFragment = gql(`
  fragment UseAccessControl_MemberFragment on Member {
    id
    user {
      id
    }
  }
`);

const UseAccessControl_DirectoryFragment = gql(`
  fragment UseAccessControl_DirectoryFragment on Directory {
    id
  }
`);

const RequireAll = 'all';
const RequireAny = 'any';
type RequireMode = typeof RequireAll | typeof RequireAny;

export function getAccessControl(_teamEdge: FragmentType<typeof UseAccessControl_TeamEdgeFragment>) {
  const teamEdge = getFragmentData(UseAccessControl_TeamEdgeFragment, _teamEdge);

  const isTeamOwner = teamEdge.team.owner.__typename === 'Self';
  const check = (mode: RequireMode, ...requiredPrivileges: TeamPrivilege[]) => {
    if (isTeamOwner) {
      return true;
    }
    return checkYourPrivilege(teamEdge.roles, teamEdge.privileges, mode, ...requiredPrivileges);
  };
  const hasAll = (...requiredPrivileges: TeamPrivilege[]) => check(RequireAll, ...requiredPrivileges);
  const hasAny = (...requiredPrivileges: TeamPrivilege[]) => check(RequireAny, ...requiredPrivileges);

  const canUpdateTeamSubscription = hasAll(TeamPrivilege.UpdateSubscription);
  const canAssignFeatures = hasAll(TeamPrivilege.AssignFeatures);
  const canCreateEmoji = hasAll(TeamPrivilege.CreateEmoji);
  const canUpdateEmoji = hasAll(TeamPrivilege.UpdateEmoji);
  const canDeleteEmoji = hasAll(TeamPrivilege.DeleteEmoji);

  // videos
  const canCreateVideo = hasAny(TeamPrivilege.CreateVod, TeamPrivilege.CreateRemoteVideo, TeamPrivilege.CreateUploadVideo);
  const canUploadVideo = canCreateVideo && teamEdge.team.features?.some((f) => f.feature === TeamFeature.UploadVideo);
  const canUpdateVideo = hasAll(TeamPrivilege.UpdateVod);
  const canDeleteVideo = hasAll(TeamPrivilege.DeleteVod);

  const canOpenVideoMenu = hasAny(
    TeamPrivilege.AssignTag,
    TeamPrivilege.AssignVideoTag,
    TeamPrivilege.UpdateVod,
    TeamPrivilege.DeleteVod,
    TeamPrivilege.UpdateVideo,
    TeamPrivilege.DeleteVideo,
  );

  const canCreateTeamInvitationLink = hasAny(TeamPrivilege.CreateInvitation, TeamPrivilege.SendTeamInvitation);
  const canSendVodInvitation = hasAll(TeamPrivilege.SendVodInvitation);
  const canRevokeInvitation = hasAll(TeamPrivilege.DeleteInvitation);

  const canViewTeamMember = hasAll(TeamPrivilege.ViewTeamMember);
  const canRemoveMember = hasAll(TeamPrivilege.RemoveMember);
  const checkMember = (_member: FragmentType<typeof UseAccessControl_MemberFragment>) => {
    const canOpenMenu = function () {
      const member = getFragmentData(UseAccessControl_MemberFragment, _member);
      if (member.user.__typename === 'Self') {
        return !isTeamOwner;
      }
      return hasAny(TeamPrivilege.AssignRole, TeamPrivilege.RemoveMember);
    }();
    return {
      canOpenMenu,
    };
  };

  // roles
  const canCreateRole = hasAll(TeamPrivilege.CreateRole);
  const canUpdateRole = hasAll(TeamPrivilege.UpdateRole);
  const canAssignRole = hasAll(TeamPrivilege.AssignRole);
  const canDeleteRole = hasAll(TeamPrivilege.DeleteRole);

  // tags
  const canCreateTag = hasAll(TeamPrivilege.CreateVideoTag);
  const canUpdateTag = hasAll(TeamPrivilege.UpdateVideoTag);
  const canAssignTag = hasAll(TeamPrivilege.AssignVideoTag);
  const canDeleteTag = hasAll(TeamPrivilege.DeleteVideoTag);
  const canOpenManageTagDialog = canCreateTag || canUpdateTag || canDeleteTag;

  const canAccessVideoComment = hasAll(TeamPrivilege.AccessVideoComment);
  const canCreateVideoComment = hasAny(TeamPrivilege.CreateComment, TeamPrivilege.CreateVideoComment);
  const canUpdateVideoComment = hasAny(TeamPrivilege.UpdateComment, TeamPrivilege.UpdateVideoComment);
  const canDeleteVideoComment = hasAny(TeamPrivilege.DeleteComment, TeamPrivilege.DeleteVideoComment);

  const canCreateCommentReply = hasAny(TeamPrivilege.CreateComment, TeamPrivilege.CreateVideoCommentReply);
  const canUpdateCommentReply = hasAny(TeamPrivilege.UpdateComment, TeamPrivilege.UpdateVideoCommentReply);
  const canDeleteCommentReply = hasAny(TeamPrivilege.DeleteComment, TeamPrivilege.DeleteVideoCommentReply);

  const canCreateCommentLabel = hasAll(TeamPrivilege.CreateVideoCommentTag);
  const canUpdateCommentLabel = hasAll(TeamPrivilege.UpdateVideoCommentTag);
  const canDeleteCommentLabel = hasAll(TeamPrivilege.DeleteVideoCommentTag);
  const canAssignCommentLabel = hasAll(TeamPrivilege.AssignVideoCommentTag);
  const canRemoveCommentLabel = hasAll(TeamPrivilege.RemoveVideoCommentTag);

  const canModerateComment = hasAll(TeamPrivilege.ModerateVideoComment);
  const canUpdateTeam = hasAll(TeamPrivilege.UpdateTeam);

  const canCreateDivision = hasAll(TeamPrivilege.CreateDivision);
  const canUpdateDivision = hasAll(TeamPrivilege.UpdateDivision);
  const canDeleteDivision = hasAll(TeamPrivilege.DeleteDivision);

  const canCreateFolder = hasAll(TeamPrivilege.CreateFolder);
  const canUpdateFolder = hasAll(TeamPrivilege.UpdateFolder);
  const canDeleteFolder = hasAll(TeamPrivilege.DeleteFolder);

  const canAccessBilling = hasAll(TeamPrivilege.AccessBilling, TeamPrivilege.CreateSubscription, TeamPrivilege.UpdateSubscription);
  const canSubscribe = hasAll(TeamPrivilege.CreateSubscription, TeamPrivilege.UpdateSubscription);

  const canAccessIntegration = hasAll(TeamPrivilege.UpdateTeam, TeamPrivilege.UpdateFolder);

  const checkDirectory = (directory: FragmentType<typeof UseAccessControl_DirectoryFragment>) => {
    switch (getFragmentData(UseAccessControl_DirectoryFragment, directory).__typename) {
      case 'Division':
        return {
          canUpdate: canUpdateDivision,
          canDelete: canDeleteDivision,
          canOpenMenu: canUpdateDivision || canDeleteDivision,
        };
      case 'Folder':
        return {
          canUpdate: canUpdateFolder,
          canDelete: canDeleteFolder,
          canOpenMenu: canUpdateFolder || canDeleteFolder,
        };
      default:
        return {};
    }
  };

  return {
    isTeamOwner,
    canCreateVideo,
    canUploadVideo,
    canUpdateVideo,
    canDeleteVideo,
    canOpenVideoMenu,
    canCreateTeamInvitationLink,
    canSendVodInvitation,
    canRevokeInvitation,
    canViewTeamMember,
    canRemoveMember,
    checkMember,
    canCreateRole,
    canUpdateRole,
    canAssignRole,
    canDeleteRole,
    canCreateTag,
    canUpdateTag,
    canAssignTag,
    canDeleteTag,
    canOpenManageTagDialog,
    canAccessVideoComment,
    canCreateVideoComment,
    canUpdateVideoComment,
    canDeleteVideoComment,
    canCreateCommentReply,
    canUpdateCommentReply,
    canDeleteCommentReply,
    canCreateCommentLabel,
    canUpdateCommentLabel,
    canDeleteCommentLabel,
    canAssignCommentLabel,
    canRemoveCommentLabel,
    canModerateComment,
    canUpdateTeam,
    canCreateDivision,
    canUpdateDivision,
    canDeleteDivision,
    canCreateFolder,
    canUpdateFolder,
    canDeleteFolder,
    checkDirectory,
    canUpdateTeamSubscription,
    canAssignFeatures,
    canCreateEmoji,
    canUpdateEmoji,
    canDeleteEmoji,
    canAccessBilling,
    canSubscribe,
    canAccessIntegration,
  };
}

export function useAccessControl(teamId: ID) {
  const { data } = useSuspenseQuery(UseAccessControl_TeamEdgeQuery,  { variables: { id: teamId } });

  invariant(data?.teamEdge, 'TeamEdge not found in cache');

  return getAccessControl(data.teamEdge);
}

export type UseAccessControl = ReturnType<typeof useAccessControl>;

function checkYourPrivilege(
  roles: FragmentType<typeof UseAccessControl_RoleFragment>[],
  privileges: TeamPrivilege[],
  mode: RequireMode,
  ...requiredPrivileges: TeamPrivilege[]
): boolean {
  const privSet = new Set(requiredPrivileges);
  for (const role of roles) {
    for (const privilege of getFragmentData(UseAccessControl_RoleFragment, role).privileges) {
      if (privSet.has(privilege)) {
        if (mode === RequireAny) {
          return true;
        }
        privSet.delete(privilege);
      }
    }
  }

  for (const privilege of privileges) {
    if (privSet.has(privilege)) {
      if (mode === RequireAny) {
        return true;
      }
      privSet.delete(privilege);
    }
  }

  return privSet.size === 0;
}
