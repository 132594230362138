import type { ftueCompleted, PCSpecLevel } from '@/features/appdata/app-data-slice';
import { loginWithPasswordAC, loginWithProviderAC, OAuth2Provider, SignInSource } from '@/features/authentication/authentication-slice';
import type { StreamRecorderPresets } from '@/features/settings/setting-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface SignUpCompletedProperties {
  [mpProps.eventSource]?: SignInSource;
  [mpProps.registrationDate]: string; // ISO Date string
  [mpProps.registrationMethod]: 'Email' | 'Google' | 'Discord';

}

interface OAuthStartedProperties {
  [mpProps.oAuthprovider]: OAuth2Provider;
  [mpProps.eventSource]?: SignInSource;
}

interface OAuthLinkCopiedProperties {}

interface SignInCompletedProperties {
  [mpProps.eventSource]?: SignInSource;
}

interface OnboardingCompletedProperties {
  [mpProps.pcSpecLevel]: PCSpecLevel;
  [mpProps.recordingPreset]: StreamRecorderPresets;
}

type SignInDialogAction = 'Opened' | 'Cancelled' | 'Signed In' | 'Already Signed In';

interface SignInDialogProperties {
  [mpProps.eventSource]?: SignInSource;
  [mpProps.signInDialogAction]?: SignInDialogAction;
}

export interface MixpanelAuthEventMap {
  'Sign Up Completed': SignUpCompletedProperties;
  'OAuth Started': OAuthStartedProperties;
  'OAuth Link Copied': OAuthLinkCopiedProperties;
  'Sign In Completed': SignInCompletedProperties;
  'Onboarding Completed': OnboardingCompletedProperties;
  'Sign In Dialog': SignInDialogProperties;
}

function humanizeOAuthProvider(provider: OAuth2Provider) {
  switch (provider) {
    case 'google-oauth2': return 'Google';
    case 'discordapp': return 'Discord';
  }
}

function signUpCompleted(
  action: ReturnType<typeof loginWithProviderAC.done | typeof loginWithPasswordAC.done>,
  registrationDate: Date,
): MixpanelEventParams<'Sign Up Completed'> {
  return [
    'Sign Up Completed',
    {
      [mpProps.eventSource]: action.meta?.signInSource,
      [mpProps.registrationDate]: registrationDate.toISOString(),
      [mpProps.registrationMethod]: loginWithPasswordAC.done.match(action) ? 'Email' : humanizeOAuthProvider(action.payload.params.provider),
    },
  ];
}

function oAuthStarted(
  action : ReturnType<typeof loginWithProviderAC.started>,
): MixpanelEventParams<'OAuth Started'> {
  return [
    'OAuth Started',
    {
      [mpProps.oAuthprovider]: action.payload.provider,
      [mpProps.eventSource]: action.meta?.signInSource,
    },
  ];
}

function oAuthLinkCopied(): MixpanelEventParams<'OAuth Link Copied'> {
  return [
    'OAuth Link Copied',
    {},
  ];
}

function signInCompleted(
  action: ReturnType<typeof loginWithPasswordAC.done | typeof loginWithProviderAC.done>,
): MixpanelEventParams<'Sign In Completed'> {
  return [
    'Sign In Completed',
    {
      [mpProps.eventSource]: action.meta?.signInSource,
    },
  ];
}

function onboardingCompleted(
  action: ReturnType<typeof ftueCompleted>,
): MixpanelEventParams<'Onboarding Completed'> {
  return [
    'Onboarding Completed',
    {
      [mpProps.pcSpecLevel]: action.payload.pcLevel,
      [mpProps.recordingPreset]: action.payload.recordingPreset,
    },
  ];
}

function signInDialog(
  signInSource?: SignInSource,
  action?: SignInDialogAction,
): MixpanelEventParams<'Sign In Dialog'> {
  return [
    'Sign In Dialog',
    {
      [mpProps.eventSource]: signInSource,
      [mpProps.signInDialogAction]: action,
    },
  ];
}

export const authHelpers = {
  oAuthStarted,
  oAuthLinkCopied,
  signInCompleted,
  signUpCompleted,
  onboardingCompleted,
  signInDialog,
};
