import { useMutation } from '@apollo/client';
import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button, FormControl, FormLabel, Stack, SxProps, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SquadRouting from '@/features/squad/squad.routing';
import { gql } from '@/graphql/__generated__';
import { useMixpanel } from '@/hooks/useMixpanel';
import { useNavigate } from '@/hooks/useNavigate';
import { mixpanelSquadHelpers } from '@/mixpanel/helpers/squad-helpers';
import { typedAssetPath } from '@/utils';
import { ENDPOINT } from '@/utils/insightsgg';

import { DialogSlideLayout, SlideDialogLayoutCommonProps } from './DialogSlideLayout';


const LINK_REGEX = new RegExp(`^${ENDPOINT}/code/.[a-zA-Z]*`);

const JoinSquad_ClaimInvitationMutation = gql(`
  mutation JoinSquad_ClaimInvitationMutation($input: ClaimInvitationInput!) {
    claimInvitation(input: $input) {
      target {
        __typename
        ... on Team {
          id
        }
      }
    }
  }
`);

interface JoinSquadSlideProps {
  className?: string;
  sx?: SxProps<Theme>;
  onBack?: VoidFunction;
}

export function JoinSquadSlide(props: JoinSquadSlideProps & SlideDialogLayoutCommonProps) {
  const { className, sx, onBack, onClose, variant } = props;

  const { t } = useTranslation(['common', 'squad_onboarding']);
  const mixpanel = useMixpanel();

  const onNavigate = useNavigate();

  const [claimInvitation, { loading: claiming }] = useMutation(JoinSquad_ClaimInvitationMutation);

  const [inviteLink, setInviteLink] = useState<string>();
  const [invalidLink, setInvalidLink] = useState<boolean>(false);

  const handleInviteLinkChange = useCallback(({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInviteLink(value);
  }, []);

  const handleJoinClick = useCallback(async () => {
    if (!inviteLink) {
      return;
    }
    const match = LINK_REGEX.test(inviteLink);
    try {
      if (!match) {
        setInvalidLink(true);
      } else {
        setInvalidLink(false);
        const code = inviteLink.split('/').pop();
        if (code) {
          const invitation = await claimInvitation({
            variables: {
              input: {
                code,
              },
            },
          });
          if (invitation.data?.claimInvitation?.target.__typename === 'Team') {
            mixpanel.track(...mixpanelSquadHelpers.teamInvitationClaimed());
            onClose?.({});
            onNavigate(SquadRouting.createUrl.team(invitation.data.claimInvitation.target.id));
          } else {
            setInvalidLink(true);
          }
        }
      }
    } catch (error) {
    }
  }, [claimInvitation, inviteLink, mixpanel, onClose, onNavigate]);

  return (
    <DialogSlideLayout
    variant={variant}
    onClose={onClose}
    className={className}
    sx={sx}
    graphic={(
      <img src={typedAssetPath('/assets/squad/join-squad.svg')} alt='join squad' />
    )}
    title={t('squad_onboarding:joinSquad.title')}
    actions={(
      <Stack flex={1} direction='row' justifyContent='space-between'>
        <Button startIcon={<ChevronLeftIcon />} onClick={onBack}>
          {t('common:back')}
        </Button>
        <AsyncButton
        color='primary'
        variant='contained'
        onClick={handleJoinClick}
        disabled={!inviteLink || claiming}
        loading={claiming}
        >
          {t('squad_onboarding:joinSquad.join')}
        </AsyncButton>
      </Stack>
    )}
    >
      <Stack justifyContent='space-between'>
        <Stack spacing={1.5}>
          <Typography>
            {t('squad_onboarding:joinSquad.enterLink')}
          </Typography>
          <FormControl>
            <FormLabel required={true}>
              {t('squad_onboarding:joinSquad.inviteLink')}
            </FormLabel>
            <TextField
            id='inviteLink'
            size='small'
            error={invalidLink}
            required={true}
            value={inviteLink || ''}
            onChange={handleInviteLinkChange}
            fullWidth={true}
            variant='outlined'
            />
          </FormControl>
        </Stack>
      </Stack>
    </DialogSlideLayout>
  );
}
