import { Theme } from '@insights-gaming/theme';
import { Box, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { memo, useMemo } from 'react';

import { makeAffineTransform } from '@/utils/transform';

interface SeekbarBufferOwnProps {
  className?: string;
  duration: number;
  timeRanges: TimeRanges;
  sx?: SxProps<Theme>;
}

type SeekbarBufferProps = SeekbarBufferOwnProps;

function SeekbarBuffer(props: SeekbarBufferProps) {
  const { className, duration, sx, timeRanges } = props;

  const { startTransform, endTransform } = useMemo(() => {
    const startTransform = makeAffineTransform([0, duration], [0, 100]);
    const endTransform = makeAffineTransform([0, duration], [100, 0]);
    return {
      startTransform,
      endTransform,
    };
  }, [duration]);

  return (
    <Box
    className={className}
    sx={[
      {
        borderRadius: theme => theme.shape.borderRadius,
        height: 'inherit',
        overflow: 'hidden',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      {Array.from({ length: timeRanges.length }).map((_, i) => (
        <Box
        key={i}
        sx={{
          position: 'absolute',
          height: 'inherit',
          backgroundColor: theme => theme.palette.secondary.dark,
          left: startTransform(timeRanges.start(i)) + '%',
          right: endTransform(timeRanges.end(i)) + '%',
        }}
        />
      ))}
    </Box>
  );
}

const StyledSeekbarBuffer = styled(SeekbarBuffer, { name: 'SeekbarBuffer' })({});

export default memo(StyledSeekbarBuffer);
