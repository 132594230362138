// Generated from LogicGrammar.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete generic visitor for a parse tree produced by LogicGrammarParser.

function LogicGrammarVisitor() {
	antlr4.tree.ParseTreeVisitor.call(this);
	return this;
}

LogicGrammarVisitor.prototype = Object.create(antlr4.tree.ParseTreeVisitor.prototype);
LogicGrammarVisitor.prototype.constructor = LogicGrammarVisitor;

// Visit a parse tree produced by LogicGrammarParser#prog.
LogicGrammarVisitor.prototype.visitProg = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#parens.
LogicGrammarVisitor.prototype.visitParens = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#NOT.
LogicGrammarVisitor.prototype.visitNOT = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#OR.
LogicGrammarVisitor.prototype.visitOR = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#bool.
LogicGrammarVisitor.prototype.visitBool = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#AND.
LogicGrammarVisitor.prototype.visitAND = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by LogicGrammarParser#XOR.
LogicGrammarVisitor.prototype.visitXOR = function(ctx) {
  return this.visitChildren(ctx);
};



exports.LogicGrammarVisitor = LogicGrammarVisitor;