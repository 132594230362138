import { shallowEqual } from '@react-dnd/shallowequal';
import { useState } from 'react';
import { DragLayerMonitor, useDragLayer } from 'react-dnd';

// https://github.com/react-dnd/react-dnd/issues/2414#issuecomment-811432858
export default function useEfficientDragLayer<CollectedProps>(collect: (monitor: DragLayerMonitor) => CollectedProps): CollectedProps {
  const collected = useDragLayer(collect);
  const [previousCollected, setPreviousCollected] = useState<CollectedProps>(collected);
  const [requestID, setRequestID] = useState<number>();
  if (requestID === undefined && !shallowEqual(collected, previousCollected)) {
    setPreviousCollected(collected);
    setRequestID(requestAnimationFrame(() => setRequestID(undefined)));
  }
  return previousCollected;
}
