import { makeProductTourSlice } from '@insights-gaming/product-tour';
import actionCreatorFactory from 'typescript-fsa';

import { ProductTourId } from './product-tour-ids';

const name = 'product-tour';
const actionCreator = actionCreatorFactory(name);

export const productTourReset = actionCreator<ProductTourId>('PRODUCT_TOUR_RESET');

const {
  productTourSlice,
  productTourActions,
} = makeProductTourSlice<ProductTourId>({
  extraReducers: builder => {
    builder.addCase(productTourReset, (state, action) => {
      delete state.viewedProductTours[action.payload];
    });
  },
});

export const productTourReducer = productTourSlice.reducer;
export const {
  productTourViewed,
  productTourSkipped,
} = productTourSlice.actions;
export const { loadViewedProductTourDatabaseAC } = productTourActions;
