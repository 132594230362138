import { Stack, Typography } from '@mui/material';

interface UploadQueueItemStatusProps {
  text: string;
  icon: React.ReactNode;
}

export function UploadQueueItemStatus(props: UploadQueueItemStatusProps) {
  const { text, icon } = props;

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Typography variant='caption'>
        {text}
      </Typography>
      <span style={{ lineHeight: 0 }}>
        {icon}
      </span>
    </Stack>
  );
}
