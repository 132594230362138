import { get } from 'lodash';
import { createContext, useContext } from 'react';
import { DefaultHandlers, typedPath, TypedPathWrapper } from 'typed-path';

import type { SettingsState } from '@/features/settings/setting-slice';

interface WebAppearance {
  appearance: {
    customTheme: {
      backgroundImage: string;
    };
  };
}

type WebSettingsContextValue = Pick<SettingsState, 'videoReplay'> & WebAppearance;

export const WebSettingsContext = createContext<WebSettingsContextValue>({
  appearance: {
    customTheme: {
      backgroundImage: '',
    },
  },
  videoReplay: {
    autoplay: true,
    muted: false,
    pauseOnComment: false,
    pauseOnCommentClick: true,
    volume: 1,
    rightPanelInitialTab: 'rememberLast',
    preseek: {
      enabled: false,
      amount: 10,
    },
    toggleFullscreenControls: false,
    _lastNonZeroVolume: 1,
    _lastRightPanel: {
      tab: 'comments',
      collapsed: true,
    },
    showApmGraph: true,
  },
});

export const webSettingPath = typedPath<DeepRequired<WebSettingsContextValue>>();

export function useWebSetting<T>(path: TypedPathWrapper<T, DefaultHandlers>): T {
  const context = useContext(WebSettingsContext);
  return get(context, path.$path) as T;
}
