import { Theme } from '@insights-gaming/theme';
import { Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { typedAssetPath } from '@/utils';

import { AddSquadContent } from './AddSquadContent';
import { DialogSlideLayout, SlideDialogLayoutCommonProps } from './DialogSlideLayout';

interface WelcomeSlideProps {
  className?: string;
  sx?: SxProps<Theme>;
  onInvitedClick?: VoidFunction;
  onCreateSquadClick?: VoidFunction;
}

export function WelcomeSlide(props: WelcomeSlideProps & SlideDialogLayoutCommonProps) {
  const { className, sx, onClose, onCreateSquadClick, onInvitedClick, variant } = props;

  const { t } = useTranslation(['squad_onboarding']);

  return (
    <DialogSlideLayout
    variant={variant}
    onClose={onClose}
    className={className}
    sx={sx}
    graphic={(
      <img src={typedAssetPath('/assets/squad/welcome.svg')} alt='squad welcome' />
    )}
    >
      <Stack spacing={1.5} justifyContent='center'>
        <Typography variant='h4'>
          {t('squad_onboarding:welcome.title')}
        </Typography>
        <Typography>
          {t('squad_onboarding:welcome.description')}
        </Typography>
        <AddSquadContent
        inviteText={t('squad_onboarding:welcome.gotInvite')}
        createSquadText={t('squad_onboarding:welcome.create')}
        onCreateSquadClick={onCreateSquadClick}
        onInvitedClick={onInvitedClick}
        />
      </Stack>
    </DialogSlideLayout>
  );
}
