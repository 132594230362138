import { VideoReplayContextValue } from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import { Theme, WithMakeStyles } from '@insights-gaming/theme';
import { Stack, SxProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {
  forwardRef,
  Fragment,
  memo,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';

import { CropOption } from '@/features/video-library/CropOption';

import FlashFadeIcon from './FlashFadeIcon';
import { useVideoInitialTime } from './useVideoInitialTime';

interface ReactPlayerWrapperOwnProps {
  className?: string;
  sx?: SxProps<Theme>;

  isWindowVisible?: boolean;
  isEndOfVideo?: boolean;
  reactPlayerRef?: RefObject<ReactPlayer>;
  showVideo?: boolean;
  url: string;
  videoReplayContextValue: VideoReplayContextValue;

  onClick?: MouseEventHandler;
  onTimeRanges?: (ranges: TimeRanges) => void;

  controlIcon?: ReactNode;
  endOfVideoOverlay?: ReactNode;
  cropOverlay?: ReactNode;
  noVideoPlaceholder?: ReactNode;
  videoControls?: ReactElement;
  cropOption?: CropOption;
}

type _ReactPlayerWrapperProps =
  ReactPlayerWrapperOwnProps
  & Pick<
    ReactPlayerProps,
    'onEnded' | 'onPlay'  | 'onReady' | 'onError'
  >;

type ReactPlayerWrapperProps = _ReactPlayerWrapperProps & WithMakeStyles<typeof useStyles>;

const useStyles = makeStyles<Theme, _ReactPlayerWrapperProps>((theme: Theme) => createStyles({
  root: {
    overflow: 'hidden', // hide slide when transitioning out
    position: 'relative',
  },
  reactPlayerWrapper: ({ cropOption }) => ({
    flex: 1,
    backgroundColor: 'black',
    position: 'relative',
    alignSelf: cropOption === 'cover' ? 'stretch' : undefined,
    '&$fullscreen': {
      height: '100%',
      paddingBottom: 'unset',
    },
    overflow: 'hidden',
  }),
  reactPlayer: ({ cropOption }) => ({
    lineHeight: 0,
    backgroundColor: 'black',
    height: 0,
    flex: '1 1 auto',
    alignSelf: 'center',
    aspectRatio: cropOption === 'contain' ? '9/16' : undefined,
  }),
  fullscreen: () => ({}),
}), { name: 'ReactPlayerWrapper' });

const ReactPlayerWrapper = forwardRef(function ReactPlayerWrapper(props: ReactPlayerWrapperProps, ref: Ref<HTMLDivElement>) {
  const classes = useStyles(props);
  const {
    className,
    sx,
    controlIcon,
    endOfVideoOverlay,
    isEndOfVideo,
    isWindowVisible = true,
    noVideoPlaceholder,
    onClick,
    onEnded,
    onError,
    onPlay,
    onReady,
    onTimeRanges,
    reactPlayerRef,
    showVideo = true,
    url,
    videoControls,
    videoReplayContextValue,
    cropOption,
    cropOverlay,
  } = props;

  const {
    state: { fullscreen, muted, playbackRate, playing,  volume, _seekOptions },
    pause,
    play,
    setBuffering,
    setBufferEnd,
    setProgress,
    setDuration,
  } = videoReplayContextValue;

  useEffect(() => {
    if (_seekOptions) {
      reactPlayerRef?.current?.seekTo(_seekOptions.amount, _seekOptions.type);
    }
  }, [_seekOptions, reactPlayerRef]);

  useEffect(() => {
    const player: HTMLVideoElement | undefined = reactPlayerRef?.current?.getInternalPlayer() as HTMLVideoElement;
    if (player) {
      player.playbackRate = playbackRate;
    }
  }, [playbackRate, reactPlayerRef]);

  useEffect(() => { // trick the browser into GCing the video
    const current = reactPlayerRef?.current;
    return () => {
      const player: HTMLVideoElement | undefined = current?.getInternalPlayer() as HTMLVideoElement;
      if (player) {
        player.muted = true;
      }
    };
  }, [reactPlayerRef]);

  const isWindowVisibleRef = useRef(isWindowVisible);
  isWindowVisibleRef.current = isWindowVisible;

  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);

  const handlePlay = useCallback(() => {
    play();
    onPlay?.();
  }, [onPlay, play]);

  const handlePause = useCallback(() => {
    if (isWindowVisibleRef.current) {
      pause();
    }
  }, [pause]);

  const seekToInitialTime = useVideoInitialTime();
  useEffect(() => {
    seekToInitialTime?.();
  }, [seekToInitialTime]);

  const handleError: ReactPlayerProps['onError'] = useCallback((err: Error, data?: any, hlsInstance?: any, hlsGlobal?: any) => {
    if (err.name === 'NotAllowedError') {
      pause();
      seekToInitialTime?.();
    } else {
      onError?.(err, data, hlsInstance, hlsGlobal);
    }
  }, [onError, pause, seekToInitialTime]);

  return (
    <Stack
    ref={ref}
    sx={sx}
    className={classNames(
      classes.root,
      className,
    )}
    >
      <Stack
      className={classNames(
        classes.reactPlayerWrapper,
        {
          [classes.fullscreen]: fullscreen,
        },
      )}
      >
        {showVideo ? (
          <Fragment>
            {isEndOfVideo && endOfVideoOverlay}
            {cropOption === 'cover' && cropOverlay}
            <ReactPlayer
            ref={reactPlayerRef}
            className={classes.reactPlayer}
            width='100%'
            height='100%'
            url={url}
            playing={isWindowVisible && playing && didMount}
            progressInterval={100}
            volume={volume}
            muted={muted}
            onClick={onClick}
            onBuffer={() => {
              const video = (reactPlayerRef?.current?.getInternalPlayer() as HTMLVideoElement | undefined);
              const buffered = video?.buffered;
              if (buffered) {
                onTimeRanges?.(buffered);
              }
              setBuffering();
            }}
            onBufferEnd={() => {
              const video = (reactPlayerRef?.current?.getInternalPlayer() as HTMLVideoElement | undefined);
              const buffered = video?.buffered;
              if (buffered) {
                onTimeRanges?.(buffered);
              }
              setBufferEnd();
            }}
            onDuration={setDuration}
            onEnded={onEnded}
            onPause={handlePause}
            onPlay={handlePlay}
            onProgress={setProgress}
            onReady={onReady}
            onError={handleError}
            />
            {controlIcon && (
              <FlashFadeIcon>
                {controlIcon}
              </FlashFadeIcon>
            )}
          </Fragment>
        ) : (
          noVideoPlaceholder
        )}
      </Stack>
      {videoControls}
    </Stack>
  );
});

export default memo(ReactPlayerWrapper);
