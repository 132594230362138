import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { getDisplayName } from '@insights-gaming/utils';
import { ChipProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {
  ComponentType,
  ExoticComponent,
  forwardRef,
  MouseEvent,
  MouseEventHandler,
  Ref,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CustomTextChip from '@/components/CustomTextChip';

import { ProductTourId } from './product-tour-ids';
import { makeShouldShowTourId } from './product-tour-selector';
import { productTourViewed } from './product-tour-slice';

interface CommonProps {
  onClick?: MouseEventHandler<any>;
}

interface InjectedProps extends CommonProps {}

interface AdditionalProps extends CommonProps {
  rootRef?: Ref<HTMLDivElement>;
  tourId: ProductTourId;
  ChipProps?: ChipProps;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
  },
  indicator: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
}), { name: 'WithNewIndicator' });

export function withNewIndicator<P extends InjectedProps, E>(
  Component: ComponentType<P> | ExoticComponent<P>,
) {
  type RemovedProps = Omit<InjectedProps, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  const WithNewIndicator = forwardRef<E, Props>(function WithNewIndicator(props: Props, forwardedRef) {
    const classes = useStyles(props);
    const {
      onClick,
      rootRef,
      tourId,
      ChipProps,
      ...rest
    } = props;

    const { className: chipClassName, ...chipProps } = useMemo(() => ChipProps ?? { className: undefined }, [ChipProps]);

    const { t } = useTranslation(['common']);

    const dispatch = useDispatch();
    const shouldShow = useCreateSelector(makeShouldShowTourId, { tourId });

    const handleClick = useCallback((e: MouseEvent) => {
      onClick?.(e);
      if (shouldShow) {
        dispatch(productTourViewed(tourId));
      }
    }, [dispatch, onClick, shouldShow, tourId]);

    return (
      <div ref={rootRef} className={classes.root}>
        <Component
        ref={forwardedRef}
        onClick={handleClick}
        {...rest as unknown as P}
        />
        {shouldShow && (
          <CustomTextChip {...chipProps} label={t('common:new')} className={classNames(classes.indicator, chipClassName)} />
        )}
      </div>
    );
  });
  WithNewIndicator.displayName = `WithNewIndicator(${getDisplayName(Component)})`;

  return WithNewIndicator;
}
