import { BackgroundQueryHookOptions, OperationVariables, QueryReference, skipToken, TypedDocumentNode, useApolloClient, useBackgroundQuery, useReadQuery, useSuspenseQuery } from '@apollo/client';
import { getDisplayName } from '@insights-gaming/utils';
import { ComponentType, Context, createContext, ExoticComponent, forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { DocumentType } from './__generated__';

interface BackgroundQueryContextValue<TData, TVariables extends OperationVariables> {
  query: TypedDocumentNode<TData, TVariables>;
  queryRef?: QueryReference<DocumentType<TypedDocumentNode<TData, TVariables>>>;
  shouldFetchBackwards?: boolean;
  variables?: TVariables;
}

export function makeBackgroundQueryContext<TData, TVariables extends OperationVariables>(query: TypedDocumentNode<TData, TVariables>) {
  return createContext<BackgroundQueryContextValue<TData, TVariables> | undefined>(undefined);
}

export function useBackgroundInjector<TData, TVariables extends OperationVariables>(
  query: TypedDocumentNode<TData, TVariables>,
  options: BackgroundQueryHookOptions<TData, TVariables> | typeof skipToken, // make sure this is memoized
  _getIdFromVariables: (variables: TVariables) => ID,
) {
  const getIdFromVariablesRef = useRef(_getIdFromVariables);

  const client = useApolloClient();

  const [fetchedQuery, setFetchedQuery] = useState<ID>();
  const fetchedQueryRef = useRef(new Set<ID>());
  const [shouldFetchBackwards, setShouldFetchBackwards] = useState(false);

  const variables = options !== skipToken ? options.variables : undefined;
  useEffect(() => {
    if (variables) {
      const previouslyFetched = fetchedQueryRef.current.has(getIdFromVariablesRef.current(variables));
      const queryHasData = client.readQuery({ query, variables }) != null;
      setShouldFetchBackwards(previouslyFetched && queryHasData);
    } else {
      setShouldFetchBackwards(false);
    }
  }, [client, query, variables]);

  useEffect(() => {
    if (fetchedQuery) {
      fetchedQueryRef.current.add(fetchedQuery);
    }
  }, [fetchedQuery]);

  const [queryRef] = useBackgroundQuery(query, options);

  useEffect(() => {
    if (fetchedQuery) {
      return;
    }
    if (!variables) {
      return;
    }
    client.query({ query, variables }).then((result) => {
      if (!result.data) {
        return;
      }
      const id = getIdFromVariablesRef.current(variables);
      if (id) {
        setFetchedQuery(id);
      }
    });
  }, [client, fetchedQuery, query, variables]);

  return useMemo(() => ({ query, queryRef, shouldFetchBackwards, variables }), [query, queryRef, shouldFetchBackwards, variables]);
}

interface CommonProps {}

interface InjectedProps<TData, TVariables extends OperationVariables> extends CommonProps, BackgroundQueryContextValue<TData, TVariables>{}

export type WithBackgroundQueryComponentProps<C extends BackgroundQueryContextValue<any, any> | undefined> = NonNullable<Required<C>>;

interface AdditionalProps extends CommonProps {}

export function withBackgroundQuery<
  TData,
  TVariables extends OperationVariables,
  P extends InjectedProps<TData, TVariables>,
  E = HTMLElement
>(
  context: Context<BackgroundQueryContextValue<TData, TVariables> | undefined>,
  Component: ComponentType<P> | ExoticComponent<P>,
) {
  type RemovedProps = Omit<InjectedProps<TData, TVariables>, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  const WithBackgroundQuery = forwardRef<E, Props>(function WithBackgroundQuery(props, forwardedRef) {
    const contextValue = useContext(context);
    if (!contextValue) {
      return null;
    }

    const { queryRef } = contextValue;
    if (!queryRef) {
      return null;
    }
    return <Component ref={forwardedRef} {...props as P} {...contextValue} />;
  });

  WithBackgroundQuery.displayName = `WithBackgroundQuery(${getDisplayName(Component)})`;

  return WithBackgroundQuery;
}

export function useReadQueryHack<TData, TVariables extends OperationVariables>(props: WithBackgroundQueryComponentProps<BackgroundQueryContextValue<TData, TVariables>>) {
  const { query, queryRef, variables } = props;
  useReadQuery(queryRef);
  return useSuspenseQuery(query, { variables });
}
