import { Theme } from '@insights-gaming/theme';
import { Box, SxProps } from '@mui/material';
import { ChangeEventHandler, cloneElement, ReactElement, useRef } from 'react';


interface FileInputProps {
  className?: string;
  sx?: SxProps<Theme>;

  accept?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  multiple?: boolean;
  children: ReactElement;
}

export function FileInput(props: FileInputProps) {
  const { accept, children, className, onChange, sx, multiple } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <Box
    className={className}
    sx={[
      {
        '& > input[type=file]': {
          position: 'absolute',
          zIndex: -1,
          width: 0,
          height: 0,
          opacity: 0,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      {cloneElement(children, {
        onClick: () => { inputRef.current?.click(); },
      })}
      <input ref={inputRef} type='file' accept={accept} tabIndex={-1} onChange={onChange} multiple={multiple} />
    </Box>
  );
}
