import { ProductInput } from '@/apollo/types/globalTypes';
import { elitePromoDialogShown, ElitePromoImageType, launchPromoDialogShown } from '@/features/appdata/app-data-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface SubscriptionCreatedProperties {
  [mpProps.products]: ProductInput[];
  [mpProps.eventSource]?: string;
}

interface EliteBadgeClickedProperties {
  [mpProps.eventSource]: string;
}

interface EliteLaunchPromoShownProperties {}
interface ElitePromoShownProperties {
  [mpProps.promotionName]: ElitePromoImageType;
  [mpProps.eventSource]?: string;
}

interface EliteThemePreviewClickedProperties {}

export interface MixpanelBillingEventMap {
  'Subscription Created': SubscriptionCreatedProperties;
  'Elite Badge Clicked': EliteBadgeClickedProperties;
  'Elite Launch Promo Shown': EliteLaunchPromoShownProperties;
  'Elite Promo Shown': ElitePromoShownProperties;
  'Elite Theme Preview Clicked': EliteThemePreviewClickedProperties;
}

function subscriptionCreated(
  products: ProductInput[],
  eliteFeature?: any,
): MixpanelEventParams<'Subscription Created'> {
  return [
    'Subscription Created',
    {
      [mpProps.products]: products,
      [mpProps.eventSource]: eliteFeature,
    },
  ];
}

function eliteFeatureClicked(
  eliteFeature: string,
): MixpanelEventParams<'Elite Badge Clicked'> {
  return [
    'Elite Badge Clicked',
    {
      [mpProps.eventSource]: eliteFeature,
    },
  ];
}

function eliteLaunchPromoShown(
  action: ActionFromActionCreator<typeof launchPromoDialogShown>,
): MixpanelEventParams<'Elite Launch Promo Shown'> {
  return [
    'Elite Launch Promo Shown',
    {},
  ];
}

function elitePromoShown(
  action: ActionFromActionCreator<typeof elitePromoDialogShown>,
): MixpanelEventParams<'Elite Promo Shown'> {
  return [
    'Elite Promo Shown',
    {
      [mpProps.promotionName]: action.payload.promo,
      [mpProps.eventSource]: action.payload.actionButton,
    },
  ];
}

function eliteThemePreviewClicked(): MixpanelEventParams<'Elite Theme Preview Clicked'> {
  return [
    'Elite Theme Preview Clicked',
    {},
  ];
}

export const billingHelpers = {
  eliteFeatureClicked,
  eliteLaunchPromoShown,
  subscriptionCreated,
  elitePromoShown,
  eliteThemePreviewClicked,
};
