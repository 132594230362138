import LegacyPigeonRouting from '@/features/pigeon-router/LegacyPigeonRouting';

import SquadRouting from './squad.routing';
import { useIsSquadEnabled } from './useIsSquadEnabled';

const LegacyPigeonRoutingAdapter = {
  base: () => '/dashboard',
  team: LegacyPigeonRouting.createUrl.team,
  teamFolder: (_teamId: ID, folderId: ID) => LegacyPigeonRouting.createUrl.folder(folderId),
  teamVideo: (_teamId: ID, videoId: ID) => LegacyPigeonRouting.createUrl.video(videoId),
  teamSettings: (teamId: ID) => LegacyPigeonRouting.createUrl.settings(teamId),
  teamSettingsTab: (teamId: ID, _tab: string) => LegacyPigeonRouting.createUrl.settings(teamId),
  teamSettingsAddReaction: (teamId: ID) => LegacyPigeonRouting.createUrl.settings(teamId),
};

export function useLegacyPigeonCompatibleCreateUrl() {
  const isSquadEnabled = useIsSquadEnabled();
  return isSquadEnabled ? SquadRouting.createUrl : LegacyPigeonRoutingAdapter;
}
