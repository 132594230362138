import { Theme } from '@insights-gaming/theme';
import { Stack, SxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AddSquadContent } from '@/features/squad/Onboarding/AddSquadContent';
import { DialogSlideLayout, SlideDialogLayoutCommonProps } from '@/features/squad/Onboarding/DialogSlideLayout';

interface AddSquadSlideProps {
  className?: string;
  sx?: SxProps<Theme>;
  onInvitedClick?: VoidFunction;
  onCreateSquadClick?: VoidFunction;
}

export function AddSquadSlide(props: AddSquadSlideProps & SlideDialogLayoutCommonProps) {
  const { className, sx, onClose, onCreateSquadClick, onInvitedClick, variant } = props;

  const { t } = useTranslation(['squad_dashboard']);

  return (
    <DialogSlideLayout
    variant={variant}
    onClose={onClose}
    title={t('squad_dashboard:createSquad.title')}
    className={className}
    sx={sx}
    >
      <Stack spacing={1.5} justifyContent='center'>
        <Typography>
          {t('squad_dashboard:createSquad.description')}
        </Typography>
        <AddSquadContent
        inviteText={t('squad_dashboard:createSquad.join')}
        createSquadText={t('squad_dashboard:createSquad.create')}
        onCreateSquadClick={onCreateSquadClick}
        onInvitedClick={onInvitedClick}
        />
      </Stack>
    </DialogSlideLayout>
  );
}
