import { PropsWithChildren, useMemo } from 'react';

import { gql } from '@/graphql/__generated__';
import { makeBackgroundQueryContext, useBackgroundInjector } from '@/graphql/withBackgroundQueryContext';

const TeamEmojiContextQuery = gql(`
  query TeamEmojiContextQuery($teamId: ID!, $after: Cursor, $before: Cursor, $limit: Int) {
    queryEmojis(teamId: $teamId, after: $after, before: $before, limit: $limit) {
      emojis {
        id
        name
        url
        creator {
          id
          alias
        }
        ...EmojiTable_EmojiFragment
      }
      pageInfo {
        ...QueryPageInfoFragment
      }
    }
  }
`);

export const TeamEmojiContext = makeBackgroundQueryContext(TeamEmojiContextQuery);

export function TeamEmojiInjector({ children, teamId }: PropsWithChildren<{ teamId: ID }>) {
  const options = useMemo(() => ({ variables: { teamId } }), [teamId]);
  const value = useBackgroundInjector(TeamEmojiContextQuery, options, (variables) => variables.teamId);
  return (
    <TeamEmojiContext.Provider value={value}>
      {children}
    </TeamEmojiContext.Provider>
  );
}
