import { useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';

const AddReactionEmojiMutation = gql(`
  mutation AddReactionEmojiMutation($input: CreateEmojiInput!) {
    createEmoji(input: $input) {
      emoji {
        id
      }
    }
  }
`);

export function useAddReactionEmojiMutation() {
  return useMutation(AddReactionEmojiMutation);
}
