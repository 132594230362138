import type { Atom } from 'jotai';

import { FragmentType, gql } from '@/graphql/__generated__';

export const ItemTypes = {
  CLIP_EDITOR: 'clipEditor',
  DIRECTORY_CARD: 'directoryCard',
  TIMELINE_VIDEO_CARD: 'timelineVideo',
  VIDEO_CARD: 'videoCard',
  TEAM: 'team',
  TEAM_VIDEO_CARD: 'teamVideoCard',
  TEAM_FOLDER_CARD: 'teamFolderCard',
} as const;

interface _CommonDragItem {
  uuid: UUID;
  width: number;
  height: number;
  currentItem: {
    videoUuid?: UUID;
    folderUuid?: UUID;
  };
  selectionAtoms: {
    videos: Atom<Set<ID>>;
    folders: Atom<Set<ID>>;
  };
}

export interface DirectoryCardDragItem extends _CommonDragItem {
  type: typeof ItemTypes.DIRECTORY_CARD;
  name: string;
  parentUuid?: UUID;
}

export interface VideoCardDragItem extends _CommonDragItem {
  type: typeof ItemTypes.VIDEO_CARD;
  name: string;
  parentUuid?: UUID;
  thumbnailUrl?: string;
}

interface _CommonTeamDragItem {
  id: ID;
  teamId: ID;
  parentId?: ID;
  width: number;
  height: number;
  currentItem: {
    videoUuid?: UUID;
    folderUuid?: UUID;
  };
  selectionAtoms: {
    videos: Atom<Set<ID>>;
    folders: Atom<Set<ID>>;
  };
}

export const TeamDragItem_TeamFragment = gql(`
  fragment TeamDragItem_TeamFragment on Team {
    id
    ...TeamAvatar_TeamFragment
  }
`);

export interface TeamDragItem {
  type: typeof ItemTypes.TEAM;
  team: FragmentType<typeof TeamDragItem_TeamFragment>;
}

export interface TeamVideoDragItem extends _CommonTeamDragItem {
  type: typeof ItemTypes.TEAM_VIDEO_CARD;
  name: string;
  thumbnailUrl?: string;
}

export interface TeamFolderDragItem extends _CommonTeamDragItem {
  type: typeof ItemTypes.TEAM_FOLDER_CARD;
  name: string;
}

interface _CommonReorderDragitem {
  uuid: UUID;
  index: number;
}

export interface ClipEditorDragItem extends _CommonReorderDragitem {
  type: typeof ItemTypes.CLIP_EDITOR;
}

export interface TimelineVideoCardDragItem extends _CommonReorderDragitem {
  type: typeof ItemTypes.TIMELINE_VIDEO_CARD;
  name: string;
  width: number;
  height: number;
  thumbnailUrl?: string;
}

export type ReorderDragItem = ClipEditorDragItem | TimelineVideoCardDragItem;

export type DragItem = DirectoryCardDragItem | VideoCardDragItem;

export type TeamResourceDragItem = TeamVideoDragItem | TeamFolderDragItem;
