import { Theme } from '@insights-gaming/theme';
import { ButtonBase, ButtonBaseProps, Stack, SxProps, Typography } from '@mui/material';

interface OnboardingButtonProps {
  className?: string;
  sx?: SxProps<Theme>;

  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export function OnboardingButton(props: OnboardingButtonProps & ButtonBaseProps) {
  const {
    children,
    className,
    endIcon,
    startIcon,
    sx,
    ...rest
  } = props;

  return (
    <ButtonBase
    focusRipple={true}
    {...rest}
    className={className}
    sx={[
      {
        padding: theme => theme.spacing(1, 1.5),
        background: theme => theme.palette.action.selected,
        '&:hover,&:focus-within': {
          background: theme => theme.palette.primary.light,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <Stack direction='row' alignItems='center' spacing={1} width='100%'>
        {startIcon}
        <Typography textAlign='start' sx={{ flex: 1 }}>
          {children}
        </Typography>
        {endIcon}
      </Stack>
    </ButtonBase>
  );
}
