import LinkIcon from '@mui/icons-material/Link';
import { Button, Stack, TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface CopyLinkProps {
  link: string;
}

export function CopyLink(props: CopyLinkProps) {
  const { link } = props;

  const { t } = useTranslation(['common']);

  const [showCopiedConfirmation, setShowCopiedConfirmation] = useState(false);
  const timeoutRef = useRef<number>();

  const handleCopyClick = useCallback(() => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    navigator.clipboard.writeText(link);
    setShowCopiedConfirmation(true);
    timeoutRef.current = window.setTimeout(() => setShowCopiedConfirmation(false), 1000);
  }, [link]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <Stack direction='row' spacing={1}>
      <TextField
      id='referralId'
      value={link}
      InputProps={{ readOnly: true }}
      sx={{ width: '100%' }}
      />
      <Button
      variant='contained'
      color='primary'
      size='small'
      startIcon={<LinkIcon />}
      onClick={handleCopyClick}
      sx={{
        whiteSpace: 'nowrap',
        flex: '0 0 auto',
      }}
      >
        {showCopiedConfirmation ? t('common:copied') : t('common:copyLink')}
      </Button>
    </Stack>
  );
}
