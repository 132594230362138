import { useMutation } from '@apollo/client';

import { DocumentType, gql } from '@/graphql/__generated__';
import { CreateTeam2Input } from '@/graphql/__generated__/graphql';
import { useMixpanel } from '@/hooks/useMixpanel';
import { mixpanelSquadHelpers } from '@/mixpanel/helpers/squad-helpers';

const CreateTeam2Mutation = gql(`
  mutation CreateTeam2Mutation($input: CreateTeam2Input!) {
    createTeam2(input: $input) {
      team {
        id
        name
        description
        picture
      }
    }
  }
`);

export type CreateTeamFn = ReturnType<typeof useCreateTeamMutation>[0];
export type CreateTeamResult = NonNullable<DocumentType<typeof CreateTeam2Mutation>['createTeam2']>['team'];

export function useCreateTeamMutation() {
  const mixpanel = useMixpanel();
  return useMutation(CreateTeam2Mutation, {
    update: (cache, { data }) => {
      cache.modify({
        id: cache.identify({ __typename: 'QueryTeamsResult' }),
        fields: {
          teamEdges(existingTeamEdges = []) {
            const team = data?.createTeam2?.team;
            if (!team) {
              return existingTeamEdges;
            }
            const newTeamEdgeRef = cache.writeFragment({
              fragment: gql(`
                fragment NewTeamEdge on TeamEdge {
                  team {
                    id
                    name
                    description
                    picture
                  }
                }
              `),
              data: {
                __typename: 'TeamEdge',
                team,
              },
            });
            return [...existingTeamEdges, newTeamEdgeRef];
          },
        },
      });
    },
    onCompleted: (data, options) => {
      const input: CreateTeam2Input | undefined = options?.variables?.input;
      mixpanel.track(...mixpanelSquadHelpers.teamCreated(input));
    },
  });
}
