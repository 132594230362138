/**
 * common get "x" from props used in selectors can go here
 */

import { RootReducer } from '@/app/rootReducer.app';
import { TimelineVideoMetadata } from '@/features/video-merge/video-merge-slice';

export const getVideoUuidFromProps = (_: RootReducer, props: { videoUuid: UUID }): UUID => props.videoUuid;
export const getVideoUuidsFromProps = (_: RootReducer, props: { videoUuids: UUID[] }) => props.videoUuids;
export const getHotkeyNameFromProps = (_: RootReducer, props: { hotkeyName: string }) => props.hotkeyName as HotkeyName;
export const getHotkeyNamesFromProps = (_: RootReducer, props: { hotkeyNames: HotkeyName[] }) => props.hotkeyNames;
export const getCustomHotkeyNameFromProps = (_: RootReducer, props: { hotkeyName:  string }) => props.hotkeyName as CustomTagHotkeyName;
export const getActivateHotkeyNameFromProps = (_: RootReducer, props: { activateName:  string }) => props.activateName as CustomTagActivateHotkeyName;
export const getGameClassIdFromProps = (_: RootReducer, props: { gameClassId?: number }) => props?.gameClassId;
export const getGameClassIdLikeFromProps = (_: RootReducer, props: { gameClassId?: number | string }) => props?.gameClassId;
export const getRequiredGameClassIdLikeFromProps = (_: RootReducer, props: { gameClassId: number | string }) => props.gameClassId;
export const getAdditionalEventsFromProps = (_: RootReducer, props: { additionalEvents?: string[] }) => props.additionalEvents;
export const getCommentUuidFromProps  = (_: RootReducer, props: { commentUuid: UUID }): UUID => props.commentUuid;
export const getTimelineMetadataFromProps = (_: RootReducer, props: { timelineMetadata: TimelineVideoMetadata[] }): TimelineVideoMetadata[] => props.timelineMetadata;
export const getVideoIdFromProps = (_: RootReducer, props: { videoId: ID }): ID => props.videoId;
export const getOptionalVideoIdFromProps = (_: RootReducer, props: { videoId?: ID }): ID | undefined => props.videoId;
export const getStreamIdFromProps = (_: RootReducer, props: { streamId?: number }): number | undefined => props.streamId;
export const getNameFromProps = (_: RootReducer, props: { name: string }) => props.name;
