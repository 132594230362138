import { VideoReplayContextValue } from '@insights-gaming/material-components/VideoReplayContext/VideoReplayContext';
import Forward5Icon from '@mui/icons-material/Forward5';
import Forward10Icon from '@mui/icons-material/Forward10';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Replay5Icon from '@mui/icons-material/Replay5';
import Replay10Icon from '@mui/icons-material/Replay10';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';

import { MultiVideoReplayContextValue } from './MultiVideoReplayContext';

type IconType = JSX.Element | null;

export function useDisplayCenterIcon<T extends VideoReplayContextValue | MultiVideoReplayContextValue>(_value: T): [T, IconType] {
  const { state: { playing }, seekTo } = _value;
  const [icon, setIcon] = useState<IconType>(null);

  const handleSeekTo = useCallback((payload: SeekPayload) => {
    if (payload.type === 'offset') {
      switch (payload.amount) {
        case 5:
          setIcon(<Forward5Icon />);
          break;
        case 10:
          setIcon(<Forward10Icon />);
          break;
        case -5:
          setIcon(<Replay5Icon />);
          break;
        case -10:
          setIcon(<Replay10Icon />);
          break;
      }
    }
    seekTo(payload);
  }, [seekTo, setIcon]);

  useEffect(() => {
    if (playing) {
      setIcon(<PlayArrowIcon />);
    } else {
      setIcon(<PauseIcon />);
    }
  }, [playing, setIcon]);

  const value = useMemo(() => ({ ..._value, seekTo: handleSeekTo }), [_value, handleSeekTo]);

  return [value, icon];
}

export const DisplayCenterIconContext = createContext<IconType>(null);
export const { Consumer: DisplayCenterIconConsumer, Provider: DisplayCenterIconProvider } = DisplayCenterIconContext;
