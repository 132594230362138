import { memo } from 'react';

import Thumbnail from './Thumbnail';
import { VideoCardBase } from './VideoCardBase';

interface VideoCardDragPreviewOwnProps {
  className?: string;
  name: string;
  thumbnailUrl?: string;
}

type VideoCardDragPreviewProps = VideoCardDragPreviewOwnProps;

function VideoCardDragPreview(props: VideoCardDragPreviewProps) {
  const { className, name, thumbnailUrl } = props;

  return (
    <VideoCardBase
    className={className}
    name={name}
    thumbnail={(thumbnailUrl ? <Thumbnail src={thumbnailUrl} alt={name} /> : undefined)}
    />
  );
}

export default memo(VideoCardDragPreview);
