import { Theme } from '@insights-gaming/theme';
import { Stack, SxProps, Typography } from '@mui/material';

import { Time } from '@/components/Time';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

const PublicVideoTitle_VideoFragment = gql(`
  fragment PublicVideoTitle_VideoFragment on Video {
    id
    name
    created
    creator {
      id
      alias
    }
  }
`);

interface PublicVideoTitlebarProps {
  className?: string;
  sx?: SxProps<Theme>;
  video: FragmentType<typeof PublicVideoTitle_VideoFragment>;
}

export function PublicVideoTitle(props: PublicVideoTitlebarProps) {
  const { className, sx } = props;
  const video = getFragmentData(PublicVideoTitle_VideoFragment, props.video);
  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center' className={className} sx={sx}>
      <Typography variant='h3'>
        {video.name}
      </Typography>
      <Stack alignItems='flex-end' spacing={1}>
        <Typography variant='caption'>
          <Time format='MMM d, yyyy'>{video.created}</Time>
        </Typography>
        <Typography>
          {video.creator.alias}
        </Typography>
      </Stack>
    </Stack>
  );
}
