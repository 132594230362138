import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { UpdateUserMutation } from '@/apollo/mutations/__generated__/UpdateUserMutation';
import { UpdateUser_Mutation } from '@/apollo/mutations/UpdateUserMutation';
import { GetUserFeaturesQuery } from '@/apollo/queries/__generated__/GetUserFeaturesQuery';
import { GetUserNotificationCountQuery, GetUserNotificationCountQueryVariables } from '@/apollo/queries/__generated__/GetUserNotificationCountQuery';
import { GetUserFeatures_Query } from '@/apollo/queries/GetUserFeaturesQuery';
import { GetUserNotificationCount_Query } from '@/apollo/queries/GetUserNotificationCountQuery';
import { UpdateUser2Input } from '@/apollo/types/globalTypes';
import { apolloMutateRefresh, apolloQueryRefresh, QueryConfig } from '@/utils/insightsgg/graphql';

function* fetchUserFeatures(config: QueryConfig): Generator<SagaIterator<GetUserFeaturesQuery>> {
  return yield apolloQueryRefresh<GetUserFeaturesQuery>({
    ...config,
    query: GetUserFeatures_Query,
  });
}

function* fetchUserNotificationCount(config: QueryConfig<GetUserNotificationCountQueryVariables>): Generator<SagaIterator<GetUserNotificationCountQuery>> {
  return yield apolloQueryRefresh<GetUserNotificationCountQuery>({
    ...config,
    query: GetUserNotificationCount_Query,
  });
}

function* updateUser(input: UpdateUser2Input) {
  const result: UpdateUserMutation = yield call(apolloMutateRefresh, {
    mutation: UpdateUser_Mutation,
    variables: {
      input,
    },
  });
  return result.updateUser2;
}

export const authenticationApi = {
  fetchUserFeatures,
  fetchUserNotificationCount,
  updateUser,
};
