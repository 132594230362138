
import { Theme } from '@insights-gaming/theme';
import { SxProps } from '@mui/material';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { createCdnUrl } from '@/utils/cdn';

const TeamAvatar_TeamFragment = gql(`
  fragment TeamAvatar_TeamFragment on Team {
    id
    name
    picture
  }
`);

interface TeamAvatarProps {
  className?: string;
  sx?: SxProps<Theme>;
  team: FragmentType<typeof TeamAvatar_TeamFragment>;
}

export function TeamAvatar(props: TeamAvatarProps) {
  const { className, sx } = props;
  const team = getFragmentData(TeamAvatar_TeamFragment, props.team);
  return (
    <AvatarWithFallback
    src={createCdnUrl(team.picture, { width: 40, aspect_ratio: '1:1', format: 'webp', fit: 'scale-down' })}
    alt={team.name}
    className={className}
    sx={sx}
    />
  );
}
