import { customThemeProductTourCompleted, WindowStateChangedSource } from '@/features/appdata/app-data-slice';
import { CaptureMode } from '@/features/settings/setting-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface AppLaunchedProperties {
  [mpProps.eventSource]: string | null;
}

interface AppExitedProperties {
  [mpProps.eventSource]: string;
}

interface AppUpdatedProperties {
  [mpProps.currentVersion]: string;
  [mpProps.previousVersion]?: string;
}

interface WindowOpenedProperties {
  [mpProps.windowName]: string;
  [mpProps.eventSource]?: WindowStateChangedSource;
  [mpProps.openedOnPrimary]?: boolean;
  [mpProps.windowWidth]?: number;
  [mpProps.windowHeight]?: number;
  [mpProps.devicePixelRatio]?: number;
}

interface WindowMaximizedProperties {
  [mpProps.windowName]: string;
}

interface WindowClosedProperties {
  [mpProps.windowName]: string;
  [mpProps.sentToTray]?: boolean;
}

type WindowMinimizedProperties = WindowClosedProperties;

interface AppUninstalledProperties {}

type WindowCollapsedProperties = WindowMaximizedProperties;
type WindowExpandedProperties = WindowCollapsedProperties;

interface AppLanguageChanged {
  [mpProps.language]: string;
}

interface CustomThemeSelectedProperties {
  [mpProps.customTheme]: string;
  [mpProps.backgroundImage]: string;
}

interface GameLaunchedProperties {
  [mpProps.gameClassId]: number;
  [mpProps.queueId]?: number;
  [mpProps.captureModeSetting]: CaptureMode;
}

interface BackupCreatedProperties {}

interface BackupRestoreStartedProperties {
  [mpProps.backupRestoreStrategy]: string;
}

type BackupRestoreCompletedProperties = BackupRestoreStartedProperties;

interface MigrateVideosStarted {}

interface MigrateVideosCompleted {}

interface FileConflictProperties {
  [mpProps.eventSource]?: string;
}

interface FileConflictsProperties {
  [mpProps.eventSource]?: string;
}

interface FileConflictResolvedProperties {
  [mpProps.conflictResolutionStrategy]: string;
}

interface FileConflictsResolvedProperties {
  [mpProps.conflictResolutionStrategy]: string;
}

export interface MixpanelAppEventMap {
  'App Launched': AppLaunchedProperties;
  'App Exited': AppExitedProperties;
  'App Updated': AppUpdatedProperties;
  'Window Opened': WindowOpenedProperties;
  'Window Maximized': WindowMaximizedProperties;
  'Window Minimized': WindowMinimizedProperties;
  'Window Closed': WindowClosedProperties;
  'App Uninstalled': AppUninstalledProperties;
  'Window Collapsed': WindowCollapsedProperties;
  'Window Expanded': WindowExpandedProperties;
  'App Language Changed': AppLanguageChanged;
  'Custom Theme Selected': CustomThemeSelectedProperties;
  'Game Launched': GameLaunchedProperties;
  'Backup Created': BackupCreatedProperties;
  'Backup Restore Started': BackupRestoreStartedProperties;
  'Backup Restore Completed': BackupRestoreCompletedProperties;
  'Migrate Videos Started': MigrateVideosStarted;
  'Migrate Videos Completed': MigrateVideosCompleted;
  'File Conflict': FileConflictProperties;
  'File Conflicts': FileConflictsProperties;
  'File Conflict Resolved': FileConflictResolvedProperties;
  'File Conflicts Resolved': FileConflictsResolvedProperties;
  'Migrate Cancelled': {};
}

function convertLaunchSource(launchSource: string | null) {
  switch (launchSource) {
    case 'dock': return 'Dock';
    case 'gamelaunchevent': return 'Game Launch Event';
    default: return launchSource;
  }
}

function launched(
  appLaunchSource: string | null,
): MixpanelEventParams<'App Launched'> {
  return [
    'App Launched',
    {
      [mpProps.eventSource]: convertLaunchSource(appLaunchSource),
    },
  ];
}

function exited(
  appExitSource: string,
): MixpanelEventParams<'App Exited'> {
  return [
    'App Exited',
    {
      [mpProps.eventSource]: appExitSource,
    },
  ];
}

function updated(
  currentVersion: string,
  previousVersion: string | undefined,
): MixpanelEventParams<'App Updated'> {
  return [
    'App Updated',
    {
      [mpProps.currentVersion]: currentVersion,
      [mpProps.previousVersion]: previousVersion,
    },
  ];
}

interface WindowOpenedParams {
  windowName: string;
  eventSource?: WindowStateChangedSource;
  openedOnPrimary?: boolean;
  windowWidth?: number;
  windowHeight?: number;
  devicePixelRatio?: number;
}

function windowOpened({
  windowName,
  eventSource,
  openedOnPrimary,
  windowWidth,
  windowHeight,
  devicePixelRatio = window.devicePixelRatio,
}: WindowOpenedParams): MixpanelEventParams<'Window Opened'> {
  return [
    'Window Opened',
    {
      [mpProps.windowName]: windowName,
      [mpProps.eventSource]: eventSource,
      [mpProps.openedOnPrimary]: openedOnPrimary,
      [mpProps.windowWidth]: windowWidth,
      [mpProps.windowHeight]: windowHeight,
      [mpProps.devicePixelRatio]: devicePixelRatio,
    },
  ];
}

function windowMinimized(
  windowName: string,
  sentToTray?: boolean,
): MixpanelEventParams<'Window Minimized'> {
  return [
    'Window Minimized',
    {
      [mpProps.windowName]: windowName,
      [mpProps.sentToTray]: sentToTray,
    },
  ];
}

function windowMaximized(
  windowName: string,
): MixpanelEventParams<'Window Maximized'> {
  return [
    'Window Maximized',
    {
      [mpProps.windowName]: windowName,
    },
  ];
}

function windowClosed(
  windowName: string,
  sentToTray?: boolean,
): MixpanelEventParams<'Window Closed'> {
  return [
    'Window Closed',
    {
      [mpProps.windowName]: windowName,
      [mpProps.sentToTray]: sentToTray,
    },
  ];
}

function windowExpanded(
  windowName: string,
): MixpanelEventParams<'Window Expanded'> {
  return [
    'Window Expanded',
    {
      [mpProps.windowName]: windowName,
    },
  ];
}

function windowCollapsed(
  windowName: string,
): MixpanelEventParams<'Window Collapsed'> {
  return [
    'Window Collapsed',
    {
      [mpProps.windowName]: windowName,
    },
  ];
}

function languageChanged(
  language: string,
): MixpanelEventParams<'App Language Changed'> {
  return [
    'App Language Changed',
    {
      [mpProps.language]: language,
    },
  ];
}

function customThemeSelected(
  action: ReturnType<typeof customThemeProductTourCompleted>,
): MixpanelEventParams<'Custom Theme Selected'> {
  return [
    'Custom Theme Selected',
    {
      [mpProps.customTheme]: action.payload.theme,
      [mpProps.backgroundImage]: action.payload.background,
    },
  ];
}

function gameLaunched(
  gameClassId: number,
  captureMode: CaptureMode,
  queueId?: number,
): MixpanelEventParams<'Game Launched'> {
  return [
    'Game Launched',
    {
      [mpProps.gameClassId]: gameClassId,
      [mpProps.queueId]: queueId,
      [mpProps.captureModeSetting]: captureMode,
    },
  ];
}

function backupCreated(): MixpanelEventParams<'Backup Created'> {
  return ['Backup Created', {}];
}

function backupRestoreStarted(restoreStrategy: string): MixpanelEventParams<'Backup Restore Started'> {
  return [
    'Backup Restore Started',
    {
      [mpProps.backupRestoreStrategy]: restoreStrategy,
    },
  ];
}

function backupRestoreCompleted(restoreStrategy: string): MixpanelEventParams<'Backup Restore Completed'> {
  return [
    'Backup Restore Completed',
    {
      [mpProps.backupRestoreStrategy]: restoreStrategy,
    },
  ];
}

function migrateVideosStarted(): MixpanelEventParams<'Migrate Videos Started'> {
  return ['Migrate Videos Started', {}];
}

function migrateVideosCompleted(): MixpanelEventParams<'Migrate Videos Completed'> {
  return ['Migrate Videos Completed', {}];
}

function fileConflict(eventSource?: string): MixpanelEventParams<'File Conflict'> {
  return [
    'File Conflict',
    {
      [mpProps.eventSource]: eventSource,
    },
  ];
}

function fileConflicts(eventSource?: string): MixpanelEventParams<'File Conflicts'> {
  return [
    'File Conflicts',
    {
      [mpProps.eventSource]: eventSource,
    },
  ];
}

function fileConflictResolved(resolutionStrategy: string): MixpanelEventParams<'File Conflict Resolved'> {
  return [
    'File Conflict Resolved',
    {
      [mpProps.conflictResolutionStrategy]: resolutionStrategy,
    },
  ];
}

function fileConflictsResolved(resolutionStrategy: string): MixpanelEventParams<'File Conflicts Resolved'> {
  return [
    'File Conflicts Resolved',
    {
      [mpProps.conflictResolutionStrategy]: resolutionStrategy,
    },
  ];
}

function migrateCancelled(): MixpanelEventParams<'Migrate Cancelled'> {
  return ['Migrate Cancelled', {}];
}

export const appHelpers = {
  launched,
  exited,
  updated,
  windowClosed,
  windowMaximized,
  windowMinimized,
  windowOpened,
  windowExpanded,
  windowCollapsed,
  languageChanged,
  customThemeSelected,
  gameLaunched,
  backupCreated,
  backupRestoreStarted,
  backupRestoreCompleted,
  migrateVideosStarted,
  migrateVideosCompleted,
  fileConflict,
  fileConflicts,
  fileConflictResolved,
  fileConflictsResolved,
  migrateCancelled,
};
