import { interpolateRainbow } from 'd3-scale-chromatic';
import { millisecondsToSeconds } from 'date-fns';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

import { isExistent } from '@/utils/guard';
import { infiniteBisector } from '@/utils/infiniteBisector';

const colors = colorGenerator();
const colorDict: Dictionary<string> = {};

function mixpanelLogger(names: string[], ...args: any[]) {
  const fn = names.filter(isExistent).join(' ');
  const strs = [
    '[mixpanel proxy]',
    fn,
  ];

  const formatted = strs.map(s => '%c' + s).join(' ');
  let formats = [
    'color: #7856ff;',
    `background: black; color: ${colorDict[fn] ?? (colorDict[fn] = colors.next().value)};`,
  ];

  formats = formats.concat(
    ...Array.from({ length: strs.length - formats.length }).map((_, i) => ''),
  );

  console.log(
    formatted,
    ...formats,
    ...args,
  );
}

function makeLogger(names: string | string[]) {
  return function (...args: any[]) {
    args = args.map(arg => {
      if (typeof arg === 'object') {
        return Object.fromEntries(
          Object.entries(arg).filter(([_, v]) => v != null),
        );
      }
      return arg;
    });
    mixpanelLogger((Array.isArray(names) ? names : [names]), ...args);
  };
}

const time_dict: Dictionary<number | undefined> = {};

export const mixpanelProxy: OverridedMixpanel = new Proxy(mixpanel, {
  get(target, name: keyof OverridedMixpanel) {
    if (name === 'time_event') {
      return function (event_name: string) {
        time_dict[event_name] = Date.now();
        makeLogger(name)(event_name);
      };
    }
    if (name === 'track') {
      return function (event_name: string, dict: { $duration?: number }, ...args: any[]) {
        const [optionsOrCallback, callback] = args;

        const time = time_dict[event_name];
        if (typeof time !== 'undefined') {
          const $duration = millisecondsToSeconds(Date.now() - time);
          dict.$duration = $duration;
          time_dict[event_name] = undefined;
        }
        makeLogger(name)(event_name, dict, ...args);
        window.setTimeout(() => {
          if (typeof optionsOrCallback === 'function') {
            optionsOrCallback();
          } else if (typeof callback === 'function') {
            callback();
          }
        }, 100);
      };
    }
    if (typeof target[name] === 'function') {
      return makeLogger(name);
    }
    return new Proxy({}, {
      get(_, n: keyof OverridedMixpanel[typeof name]) {
        if (typeof target[name][n] === 'function') {
          return makeLogger([name, n]);
        }
      },
    });
  },
});

function* colorGenerator(): IterableIterator<string> {
  const numberGen = infiniteBisector(1);
  while (true) {
    yield interpolateRainbow(numberGen.next().value);
  }
}
