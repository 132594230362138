import { ComponentType, ExoticComponent } from 'react';
import { ErrorBoundaryProps } from 'react-error-boundary';

import SuspenseBoundary from './SuspenseBoundary';

interface CommonProps {}
interface InjectedProps extends CommonProps {}
interface AdditionalProps extends CommonProps {
  resetKeys?: ErrorBoundaryProps['resetKeys'];
  fallback?: ErrorBoundaryProps['fallback'];
}

export function withDefaultErrorBoundary<P extends InjectedProps>(Component: ComponentType<P> | ExoticComponent<P>) {
  type RemovedProps = Omit<InjectedProps, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  return function WithDefaultErrorBoundary(props: Props) {
    const { fallback, resetKeys, ...rest } = props;
    return (
      <SuspenseBoundary fallback={fallback} resetKeys={resetKeys}>
        <Component {...rest as P} />
      </SuspenseBoundary>
    );
  };
}
