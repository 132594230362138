import { FetchMoreFunction } from '@apollo/client/react/hooks/useSuspenseQuery';
import { startTransition, useCallback } from 'react';

import { FragmentType, getFragmentData } from '@/graphql/__generated__';
import { InputMaybe } from '@/graphql/__generated__/graphql';
import { QueryPageInfoFragment } from '@/graphql/fragments/QueryPageInfoFragment';

export function useInfiniteLoaderProps<TItem, TData>(
  items: TItem[] | null | undefined,
  pageInfo: FragmentType<typeof QueryPageInfoFragment> | null | undefined,
  fetchMore: FetchMoreFunction<TData, {
    after?: InputMaybe<string> | undefined;
    before?: InputMaybe<string> | undefined;
    limit?: InputMaybe<number> | undefined;
  }>,
) {
  const { end, more } = getFragmentData(QueryPageInfoFragment, pageInfo) || {};
  const itemCount = (items?.length ?? 0) + (more ? 1 : 0);

  const isItemLoaded = useCallback((index: number) => {
    if (!items) {
      return false;
    }
    return !more || index < items.length;
  }, [items, more]);

  const loadMoreItems = useCallback(() => {
    if (!more) {
      return;
    }
    return new Promise<void>((resolve) => {
      startTransition(() => {
        fetchMore({ variables: { after: end } }).then(() => resolve());
      });
    });
  }, [end, fetchMore, more]);

  return {
    itemCount,
    isItemLoaded,
    loadMoreItems,
  };
}
