import { combineReducers } from '@reduxjs/toolkit';

import { authenticationReducer } from '@/features/authentication/authentication-slice';
import { videoUploadSlice } from '@/features/video-upload/upload-queue/video-upload-slice/video-upload-slice.web';

export const reducer = combineReducers({
  authentication: authenticationReducer,
  videoUpload: videoUploadSlice.reducer,
});

export type WebRootReducer = ReturnType<typeof reducer>;
