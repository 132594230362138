import { FacebookIcon, RedditIcon, TwitterIcon } from '@insights-gaming/material-icons';
import { Theme } from '@insights-gaming/theme';
import { createSocialMediaShareLink, SocialMedia } from '@insights-gaming/utils';
import { Link, Stack, SxProps } from '@mui/material';


interface SocialMediaData {
  name: SocialMedia;
  icon: React.ReactNode;
}

const socialMedias: SocialMediaData[] = [
  {
    name: 'Facebook',
    icon: <FacebookIcon fontSize='large' />,
  },
  {
    name: 'Reddit',
    icon: <RedditIcon fontSize='large' />,
  },
  {
    name: 'Twitter',
    icon: <TwitterIcon fontSize='large' />,
  },
];

interface SocialMediaShareProps {
  className?: string;
  sx?: SxProps<Theme>;
  link: string;
  videoTitle: string;
}

export function SocialMediaShare(props: SocialMediaShareProps) {
  const { className, sx, link, videoTitle } = props;
  return (
    <Stack className={className} sx={sx} direction='row' spacing={2} justifyContent='center'>
      {socialMedias.map((s) => {
        const shareLink = s.name === 'Facebook' ?
          createSocialMediaShareLink(s.name, link) :
          createSocialMediaShareLink(s.name, link, videoTitle);
        return (
          <Link
          href={shareLink}
          target='_blank'
          rel='noopener noreferrer'
          key={s.name}
          >
            {s.icon}
          </Link>
        );
      })}
    </Stack>
  );
}
