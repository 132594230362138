import { useSubscription } from '@apollo/client';
import { Fragment, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { getFragmentData, gql } from '@/graphql/__generated__';

import SquadRouting from './squad.routing';

interface TeamSubscriptionManagerProps {
}

export function TeamSubscriptionManager(props: TeamSubscriptionManagerProps) {
  const [visitedTeams, setVisitedTeams] = useState(new Set<ID>());
  const match = useRouteMatch<SquadRouting.TeamParams>(SquadRouting.path.team);
  const teamId = match ? match.params.teamId : undefined;

  useEffect(() => {
    if (teamId) {
      setVisitedTeams((prev) => {
        if (prev.has(teamId)) {
          return prev;
        };
        const next = new Set(prev);
        next.add(teamId);
        return next;
      });
    }
  }, [teamId]);

  return (
    <Fragment>
      {Array.from(visitedTeams).map((teamId) => (
        <TeamSubscription key={teamId} teamId={teamId} />
      ))}
    </Fragment>
  );
}

const TeamSubscription_VideoFragment = gql(`
  fragment TeamSubscription_VideoFragment on Video {
    id
    ...TeamVideoCard_VideoFragment
  }
`);

const TeamSubscription_DivisionFragment = gql(`
  fragment TeamSubscription_DivisionFragment on Division {
    id
    ...TeamChannelListItem_DivisionFragment
  }
`);

const TeamSubscription_FolderFragment = gql(`
  fragment TeamSubscription_FolderFragment on Folder {
    id
    ...TeamFolderCard_FolderFragment
  }
`);

const TeamSubscription_EventsSubscription = gql(`
  subscription TeamSubscription_EventsSubscription($teamId: ID!) {
    events(teamId: $teamId) {
      ... on VideoAddedEvent {
        videoAdded {
          id
          ...TeamSubscription_VideoFragment
        }
      }
      ... on VideoUpdatedEvent {
        videoUpdated {
          id
          name
          directory {
            id
          }
          division {
            id
          }
          status
          thumbnail
          streamUrls
          error
        }
      }
      ... on VideoDeletedEvent {
        videoDeleted
      }
      ... on DivisionAddedEvent {
        divisionAdded {
          id
          ...TeamSubscription_DivisionFragment
        }
      }
      ... on DivisionUpdatedEvent {
        divisionUpdated {
          id
          name
          picture
          banner
        }
      }
      ... on DivisionDeletedEvent {
        divisionDeleted
      }
      ... on FolderAddedEvent {
        folderAdded {
          id
          ...TeamSubscription_FolderFragment
        }
      }
      ... on FolderUpdatedEvent {
        folderUpdated {
          id
          name
          parent {
            id
          }
        }
      }
      ... on FolderDeletedEvent {
        folderDeleted
      }
    }
  }
`);

function TeamSubscription(props: { teamId: ID }) {
  const { teamId } = props;

  useSubscription(TeamSubscription_EventsSubscription, {
    variables: { teamId },
    onData: ({ data, client }) => {
      const events = data.data?.events;
      switch (events?.__typename) {
        case 'VideoAddedEvent':
          client.cache.writeFragment({
            id: client.cache.identify({ __typename: 'Video', id: events.videoAdded.id }),
            fragment: TeamSubscription_VideoFragment,
            fragmentName: 'TeamSubscription_VideoFragment',
            data: getFragmentData(TeamSubscription_VideoFragment, events.videoAdded),
          });
          break;
        case 'VideoUpdatedEvent':
          // we can let type policy handle this
          break;
        case 'VideoDeletedEvent':
          client.cache.evict({ id: client.cache.identify({ __typename: 'Video', id: events.videoDeleted }) });
          client.cache.gc();
          break;
        case 'DivisionAddedEvent':
          client.cache.writeFragment({
            id: client.cache.identify({ __typename: 'Division', id: events.divisionAdded.id }),
            fragment: TeamSubscription_DivisionFragment,
            fragmentName: 'TeamSubscription_DivisionFragment',
            data: getFragmentData(TeamSubscription_DivisionFragment, events.divisionAdded),
          });
          break;
        case 'DivisionUpdatedEvent':
          // we can let type policy handle this
          break;
        case 'DivisionDeletedEvent':
          client.cache.evict({ id: client.cache.identify({ __typename: 'Division', id: events.divisionDeleted }) });
          client.cache.gc();
          break;
        case 'FolderAddedEvent':
          client.writeFragment({
            id: client.cache.identify({ __typename: 'Folder', id: events.folderAdded.id }),
            fragment: TeamSubscription_FolderFragment,
            fragmentName: 'TeamSubscription_FolderFragment',
            data: getFragmentData(TeamSubscription_FolderFragment, events.folderAdded),
          });
          break;
        case 'FolderUpdatedEvent':
          // we can let type policy handle this
          break;
        case 'FolderDeletedEvent':
          client.cache.evict({ id: client.cache.identify({ __typename: 'Folder', id: events.folderDeleted }) });
          client.cache.gc();
          break;
      }
    },
  });

  return null;
}
