import { useCallback, useMemo, useState } from 'react';

import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

const UseTeamVideoUrls_VideoFragment = gql(`
  fragment UseTeamVideoUrls_VideoFragment on Video {
    id
    streamUrls
  }
`);

export function useTeamVideoUrls(_video: FragmentType<typeof UseTeamVideoUrls_VideoFragment> | null | undefined) {
  const video = getFragmentData(UseTeamVideoUrls_VideoFragment, _video);

  const urls = useMemo(() => {
    if (video) {
      if (video.streamUrls) {
        return video.streamUrls;
      }
      // if (video.streamUrl) {
      //   return [video.streamUrl];
      // }
      // if (video.remoteUrl) {
      //   return [video.remoteUrl];
      // }
    }
    return [];
  }, [video]);

  const [urlIndex, setUrlIndex] = useState(0);
  const url = urls[urlIndex];

  const onError = useCallback(() => {
    setUrlIndex(p => {
      if (p + 1 < urls.length) {
        return p + 1;
      }
      return p;
    });
  }, [urls.length]);

  return {
    url,
    onError,
  };
}
