import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';


interface SignUpVerifyDialogContentOwnProps {
  className?: string;
  onBackToSignInClick?: VoidFunction;
}

type SignUpVerifyDialogContentProps = SignUpVerifyDialogContentOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
  },
}), { name: 'SignUpVerifyDialogContent' });

function SignUpVerifyDialogContent(props: SignUpVerifyDialogContentProps) {
  const classes = useStyles(props);
  const { className, onBackToSignInClick } = props;

  const { t } = useTranslation(['common']);

  return (
    <FlexSpacer className={classNames(classes.root, className)} orientation='vertical' flexAlignItems='center' flexJustifyContent='center' spacing={2}>
      <FlexSpacer flexJustifyContent='center' flexAlignItems='center'>
        <CheckCircleIcon />
        <Typography>
          {t('common:authentication.verifyEmail.description')}
        </Typography>
      </FlexSpacer>
      <Typography textAlign='center'>
        {t('common:authentication.verifyEmail.emailExists')}
      </Typography>
      <Button
      fullWidth={true}
      variant='contained'
      color='primary'
      onClick={onBackToSignInClick}
      >
        {t('common:authentication.verifyEmail.backToSignIn')}
      </Button>
    </FlexSpacer>
  );
}

export default memo(SignUpVerifyDialogContent);
