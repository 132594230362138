import { Stack, StackProps } from '@mui/material';
import { Children, ReactNode, useMemo, useState } from 'react';
import type { ResizePayload } from 'react-resize-detector';
import { useResizeDetector } from 'react-resize-detector';

interface StackOverflowProps {
  fallback?: ReactNode;
  fallbackPlacement?: 'default' | 'opposite'; // opposite means the fallback is placed in the opposite direction of the other Stack children
}

export function StackOverflow(props: StackOverflowProps & StackProps) {
  const {
    children,
    direction: innerDirection = 'column', // match Stack default direction
    fallback,
    fallbackPlacement,
    ...rest
  } = props;

  const { outerDirection, rowOrColumn } = useMemo(() => {
    let outerDirection = innerDirection;
    let rowOrColumn: 'row' | 'column' = 'column';
    if (typeof outerDirection === 'string') {
      const match = (/^(row|column)(-reverse)?$/g).exec(outerDirection);
      if (match) {
        rowOrColumn = match[1] as 'row' | 'column';
        if (fallbackPlacement === 'opposite') {
          let reverse = false;
          reverse = !!match[2];
          outerDirection = (reverse ? rowOrColumn : `${rowOrColumn}-reverse`);
        }
      }
    }
    return {
      outerDirection,
      rowOrColumn,
    };
  }, [fallbackPlacement, innerDirection]);

  const isRow = rowOrColumn === 'row';
  const isColumn = !isRow;

  const [requiresOverflow, setRequiresOverflow] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });

  const { ref: innerRef } = useResizeDetector<HTMLDivElement>({
    handleHeight: true, // needs to handle both resize directions regardless of the Stack direction
    handleWidth: true,
    onResize: ({ width, height }: ResizePayload) => {
      const { current: inner } = innerRef;
      if (!inner) {
        return;
      }
      const { scrollHeight, scrollWidth } = inner;
      setRequiresOverflow(
        (isRow && typeof height === 'number' && scrollHeight > height)
        || (isColumn && typeof width === 'number' && scrollWidth > width),
      );

      function calculateTranslate() {
        const { current: inner } = innerRef;
        const { current: item } = itemRef;
        let x = 0;
        let y = 0;
        if (inner && item) {
          const { offsetHeight: innerOffsetHeight, offsetWidth: innerOffsetWidth } = inner;
          const { offsetHeight: itemOffsetHeight, offsetLeft: itemOffsetLeft, offsetTop: itemOffsetTop, offsetWidth: itemOffsetWidth } = item;
          if (isRow) {
            if (innerDirection === 'row') {
              x = itemOffsetLeft;
            } else {
              x = (itemOffsetLeft + itemOffsetWidth) - innerOffsetWidth;
            }
          }
          if (isColumn) {
            if (innerDirection === 'column') {
              y = itemOffsetTop;
            } else {
              y = (itemOffsetTop + itemOffsetHeight) - innerOffsetHeight;
            }
          }
        }
        return { x, y };
      };

      setTranslate(calculateTranslate());
    },
  });

  const { ref: itemRef, height = 0, width = 0 } = useResizeDetector<HTMLDivElement>({ handleHeight: true, handleWidth: true });

  return (
    <Stack
    alignItems='center'
    {...rest}
    direction={outerDirection}
    overflow='hidden'
    maxHeight='100%' // column/column-reverse layouts only works if container has a defined height
    >
      <Stack
      alignItems='center'
      {...rest}
      direction={outerDirection}
      overflow='hidden'
      sx={[
        props.justifyContent !== 'flex-end' && {
          transform: `translate(${-translate.x}px, ${-translate.y}px)`,
        },
      ]}
      >
        <Stack ref={innerRef} {...rest} position='relative' direction={innerDirection} overflow='hidden' height={isRow ? height : undefined} width={isColumn ? width : undefined} justifyContent='flex-end' flexWrap='wrap'>
          {Children.map(children, (child, index) => (
            child && (
              <Stack key={index} ref={index === 0 ? itemRef : undefined}>
                {child}
              </Stack>
            )
          ))}
        </Stack>
        {requiresOverflow && (
          <Stack
          direction={innerDirection}
          flexShrink={0}
          sx={[
            fallbackPlacement === 'opposite' && {
              transform: `translate(${translate.x}px, ${translate.y}px)`,
            },
          ]}
          >
            {fallback}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
