import { createSlice } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/types/types-external';
import actionCreatorFactory from 'typescript-fsa';

import { StreamProcessInfo, Video } from '@/features/video-library/video-library-slice';
import { MixpanelEventSource } from '@/mixpanel/common';

const name = 'remove-audio';
const actionCreator = actionCreatorFactory(name);

export const readAudioStreamsAC = actionCreator.async<Video, object, Error>('READ_AUDIO_STREAMS');
export const createVideoWithRemovedAudioTracksAC = actionCreator.async<CreateVideoWithRemovedAudioTracksParams, Video, Error>('CREATE_VIDEO_WITH_REMOVED_AUDIO_TRACKS');
/**
 * Params is parent video uuid.
 */
export const cancelCreateVideoWithRemovedAudioTracksAC = actionCreator.async<{ videoUuid: UUID }, void, Error>('CANCEL_CREATE_VIDEO_WITH_REMOVED_AUDIO_TRACKS');
export const cancelAllCreateVideoWithRemovedAudioTrackAC = actionCreator.async<void, void, Error>('CANCEL_ALL_CREATE_VIDEO_WITH_REMOVED_AUDIO_TRACKS');

export interface CreateVideoWithRemovedAudioTracksParams {
  videoUuid: UUID;
  tracks?: {
    audio?: (StreamProcessInfo & {
      selected: boolean;
    })[];
  };
  userProvidedName?: string;
}

export type RemoveAudioSource = MixpanelEventSource<'Video Replay Page'>;

export type CreateVideoWithRemovedAudioTracksMeta = {
  removeAudioSource: RemoveAudioSource;
};

/**
 * saveRemoveTrackInProgress: { [parentVideoUuid]: boolean }
 */
interface RemoveAudioSlice {
  saveRemoveTrackInProgress: Partial<Dictionary<boolean>>;
}

const initialState: RemoveAudioSlice = {
  saveRemoveTrackInProgress: {},
};

const removeAudioSlice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: builder => {
    let async = createVideoWithRemovedAudioTracksAC;
    builder.addCase(async.started, (state, action) => {
      const { videoUuid } = action.payload;
      state.saveRemoveTrackInProgress[videoUuid] = true;
    });

    function removeProgressVideo(state: WritableDraft<RemoveAudioSlice>, action: { payload: { params: { videoUuid: UUID }}}) {
      const { videoUuid } = action.payload.params;
      const prev = state.saveRemoveTrackInProgress[videoUuid];
      if (prev !== undefined) {
        delete state.saveRemoveTrackInProgress[videoUuid];
      }
    }

    builder.addCase(async.done, removeProgressVideo);
    builder.addCase(async.failed, removeProgressVideo);
    builder.addCase(cancelCreateVideoWithRemovedAudioTracksAC.done, removeProgressVideo);
  },
});

export const removeAudioReducer = removeAudioSlice.reducer;
