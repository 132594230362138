import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import EmailIcon from '@mui/icons-material/Email';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconTextField from '@/components/IconTextField';
import { usePromiseSagaCallback } from '@/utils/promisify-saga';

import { resetPasswordAC, SignInSource } from './authentication-slice';

interface ForgotPasswordProps {
  className?: string;
  sx?: SxProps<Theme>;

  signInSource?: SignInSource;
  onLoginClick?: VoidFunction;
}

export function ForgotPassword(props: ForgotPasswordProps) {
  const { onLoginClick } = props;
  const { t } = useTranslation(['common']);
  const [email, setEmail] = useState('');
  const [resetPassword, resetting] = usePromiseSagaCallback(resetPasswordAC);
  const [sentEmail, setSentEmail] = useState('');
  return (
    <form
    onSubmit={async (e: FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      try {
        await resetPassword({ email });
        setSentEmail(email);
      } catch (error) {
        console.error(error);
      }
    }}
    >
      {sentEmail ? (
        <Stack spacing={2}>
          <Typography>
            An email was sent to {sentEmail}. Please check your inbox to reset your password.
          </Typography>
          <Button variant='contained' color='primary' onClick={onLoginClick}>
            Login
          </Button>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <IconTextField
          name='email'
          icon={<EmailIcon />}
          value={email}
          autoFocus={true}
          fullWidth={true}
          label={t('common:authentication.email')}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
          />
          <AsyncButton type='submit' variant='contained' color='primary' disabled={resetting} loading={resetting}>
            Send Reset Email
          </AsyncButton>
        </Stack>
      )}
    </form>
  );
}
