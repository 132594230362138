import { useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';

const UpdateVideo2Mutation = gql(`
  mutation UpdateVideo2Mutation($input: UpdateVideo2Input!) {
    updateVideo2(input: $input) {
      video {
        ...TeamVideoContextMenu_VideoFragment
      }
    }
  }
`);

export function useUpdateVideo2Mutation() {
  const mutation = useMutation(UpdateVideo2Mutation);
  return mutation;
}
