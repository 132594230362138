import { Reference, useMutation, useQueryRefHandlers } from '@apollo/client';
import { Theme } from '@insights-gaming/theme';
import { Box, BoxProps, Stack, SxProps } from '@mui/material';
import update from 'immutability-helper';
import { ContextType, forwardRef, startTransition, useCallback, useContext, useEffect } from 'react';
import AutoSizer, { Size } from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { withDefaultErrorBoundary } from '@/components/withDefaultErrorBoundary';
import { getFragmentData, gql } from '@/graphql/__generated__';
import { QueryTeamsResult } from '@/graphql/__generated__/graphql';
import { QueryPageInfoFragment } from '@/graphql/fragments/QueryPageInfoFragment';
import { useReadQueryHack, withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';
import { useDialogState } from '@/hooks/useDialogState';
import { useInfiniteLoaderProps } from '@/hooks/useInfiniteLoaderProps';
import { useNavigate } from '@/hooks/useNavigate';

import { AddSquadDialog } from './AddSquad/AddSquadDialog';
import SquadRouting from './squad.routing';
import { SelectedTeamContext, TeamContext } from './TeamContext';
import { TeamSelectorItemRenderer } from './TeamSelectorItemRenderer';

const TeamSelector_UpdateTeamOrderMutation = gql(`
  mutation TeamSelector_UpdateTeamOrderMutation($input: UpdateTeamOrderInput!) {
    updateTeamOrder(input: $input) {
      teamOrder
    }
  }
`);

interface TeamSelectorOwnProps {
  className?: string;
  sx?: SxProps<Theme>;
}

type TeamSelectorProps = TeamSelectorOwnProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamContext>>;

function _TeamSelector(props: TeamSelectorProps) {
  const { className, queryRef, sx } = props;
  const { data } = useReadQueryHack(props);
  const { fetchMore } = useQueryRefHandlers(queryRef);
  const { end, more } = getFragmentData(QueryPageInfoFragment, data?.queryTeams.pageInfo) || {};

  const selectedTeamId = useContext(SelectedTeamContext);

  const onNavigate = useNavigate();

  useEffect(() => {
    if (more) {
      startTransition(() => {
        fetchMore({ variables: { after: end } });
      });
    }
  }, [end, fetchMore, more]);

  const handleClick = useCallback((teamId: ID) => {
    onNavigate(SquadRouting.createUrl.team(teamId));
  }, [onNavigate]);

  const [isCreateTeamDialogOpen, openCreateTeamDialog, closeCreateTeamDialog] = useDialogState();

  const [updateTeamOrder] = useMutation(TeamSelector_UpdateTeamOrderMutation);

  const handleUpdateTeamOrder = (dragId: ID, dropId: ID, insertAbove: boolean) => {
    if (dragId === dropId) {
      return;
    }
    const destinationId = insertAbove ? (function () {
      const index = data?.queryTeams.teamEdges.findIndex(({ team }) => team.id === dropId);
      return data?.queryTeams.teamEdges[index - 1]?.team.id;
    })() : dropId;
    if (destinationId === dragId) {
      return;
    }
    const destinationIndex = data?.queryTeams.teamEdges.findIndex(({ team }) => team.id === destinationId);
    const dragIndex = data?.queryTeams.teamEdges.findIndex(({ team }) => team.id === dragId);
    if (typeof destinationIndex === 'number' && typeof dragIndex === 'number' && destinationIndex === dragIndex - 1) { // inserting below the team immediately before it is a no-op
      return;
    }
    updateTeamOrder({
      variables: {
        input: {
          targetId: dragId,
          destinationId,
        },
      },
      update: (cache) => {
        cache.modify<QueryTeamsResult>({
          id: cache.identify({ __typename: 'QueryTeamsResult' }),
          fields: {
            teamEdges(existingTeamEdges, { readField }) {
              if (!existingTeamEdges) {
                return existingTeamEdges;
              }
              const oldPosition = existingTeamEdges.findIndex(teamEdge => {
                const team: Reference | undefined = readField('team', teamEdge);
                if (!team) {
                  return false;
                }
                const id = readField('id', team);
                return id === dragId;
              });
              if (oldPosition === -1) {
                return existingTeamEdges;
              }
              const removed = existingTeamEdges[oldPosition];
              existingTeamEdges = update(existingTeamEdges, { $splice: [[oldPosition, 1]] });
              const newPosition = existingTeamEdges.findIndex(teamEdge => {
                const team: Reference | undefined = readField('team', teamEdge);
                if (!team) {
                  return false;
                }
                const id = readField('id', team);
                return id === destinationId;
              });
              return update(existingTeamEdges, { $splice: [[newPosition + 1, 0, removed]] });
            },
          },
        });
      },
      optimisticResponse: {
        updateTeamOrder: {
          teamOrder: [], // empty array since we don't actually use the response to update the cache
        },
      },
    });
  };

  const visibleTeams = data?.queryTeams.teamEdges.filter(({ team }) => team.isVisible).map(({ team }) => team) ?? [];
  const { itemCount } = useInfiniteLoaderProps(visibleTeams, data?.queryTeams.pageInfo, fetchMore);

  return (
    <Stack flex={1} className={className} sx={sx}>
      <AutoSizer>
        {({ width, height }: Size) => (
          <FixedSizeList
          outerElementType={TeamSelectorOuterElement}
          width={width}
          height={height}
          itemCount={itemCount + 1} // add one for the create team button
          itemSize={48}
          overscanCount={5}
          initialScrollOffset={visibleTeams.findIndex(team => team.id === selectedTeamId) * 48}
          itemData={{
            onClick: handleClick,
            onUpdateTeamOrder: handleUpdateTeamOrder,
            openCreateTeamDialog,
            selectedTeamId,
            teams: visibleTeams,
          }}
          >
            {TeamSelectorItemRenderer}
          </FixedSizeList>
        )}
      </AutoSizer>
      <AddSquadDialog open={isCreateTeamDialogOpen} onClose={closeCreateTeamDialog} />
    </Stack>
  );
}

const TeamSelectorOuterElement = forwardRef<HTMLDivElement, BoxProps>(function TeamSelectorOuterElement(props, ref) {
  const { sx } = props;
  return (
    <Box
    ref={ref}
    {...props}
    tabIndex={-1}
    sx={[
      {
        alignItems: 'center',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    />
  );
});

export const TeamSelector = withDefaultErrorBoundary(withBackgroundQuery(TeamContext, _TeamSelector));
