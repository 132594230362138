import { useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';

const AddVideoReactionMutation = gql(`
  mutation AddVideoReactionMutation($input: AddVideoReactionInput!) {
    addVideoReaction(input: $input) {
      reaction {
        id
      }
    }
  }
`);

export function useAddVideoReactionMutation() {
  return useMutation(AddVideoReactionMutation);
}
