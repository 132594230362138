import { TextField, TextFieldProps, Tooltip } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function useClickToCopy(text: string, onClick?: React.MouseEventHandler) {
  const [copied, setCopied] = useState(false);
  const timerRef = useRef<number>();

  const handleClick: React.MouseEventHandler = useCallback((e) => {
    window.navigator.clipboard.writeText(text);
    setCopied(true);
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => setCopied(false), 1000);
    onClick?.(e);
  }, [onClick, text]);

  return {
    copied,
    onClick: handleClick,
  };
}

export function ClickToCopy(props: TextFieldProps & { value: string }) {
  const { value, ...rest } = props;

  const { onClick, copied } = useClickToCopy(value, props.onClick);
  const { t } = useTranslation(['common']);

  return (
    <Tooltip open={copied} title={t('common:copied')}>
      <TextField {...rest} value={value} onClick={onClick} />
    </Tooltip>
  );
}
