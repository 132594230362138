import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { ClusterFn, makeCluster } from '@insights-gaming/utils';
import { Box, SxProps } from '@mui/material';
import { forwardRef, Fragment, memo, Ref, useContext, useMemo } from 'react';

import { CustomSliderProps } from '@/components/CustomSlider';
import CustomSlider2 from '@/components/CustomSlider2';
import SeekbarBuffer from '@/components/SeekbarBuffer';
import { calcLeft } from '@/features/video-replay/seekbar-events/event-helpers';
import { TeamVideoPlayerControls_VideoBookmarkFragmentFragment } from '@/graphql/__generated__/graphql';

import { TeamVideoBookmarkIndicator } from './TeamVideoBookmarkIndicator';


function createBookmarkSplitFn(videoDuration: number, containerWidth: number): ClusterFn<TeamVideoPlayerControls_VideoBookmarkFragmentFragment> {
  return (
    p: TeamVideoPlayerControls_VideoBookmarkFragmentFragment[][],
    c: TeamVideoPlayerControls_VideoBookmarkFragmentFragment,
    i: number,
    a: TeamVideoPlayerControls_VideoBookmarkFragmentFragment[],
  ) => {
    const eventIconWidth = 32;
    const firstBookmarkOfPrevGroup = p[p.length - 1][0];
    const splitTime = eventIconWidth / containerWidth * videoDuration;
    return c.time - firstBookmarkOfPrevGroup.time >= splitTime;
  };
}

interface TeamVideoSeekbarProps {
  className?: string;
  sx?: SxProps<Theme>;
  filteredBookmarks: TeamVideoPlayerControls_VideoBookmarkFragmentFragment[];
  seekbarProps: CustomSliderProps;
  width?: number;
  timeRanges?: TimeRanges;
}

const TeamVideoSeekbar = forwardRef(function TeamVideoSeekbar(props: TeamVideoSeekbarProps, ref: Ref<HTMLDivElement>) {
  const { className, sx, filteredBookmarks, seekbarProps, timeRanges, width = 0 } = props;

  const { state: { duration } } = useContext(VideoReplayContext);

  const createClusters = useMemo(
    () => makeCluster(createBookmarkSplitFn(duration, width)),
    [width, duration],
  );

  const clusters = useMemo(() => createClusters(filteredBookmarks), [createClusters, filteredBookmarks]);

  return (
    <Box ref={ref} className={className} sx={sx}>
      {clusters.length > 0 && (
        <Box sx={{ height: 32, position: 'relative' }}>
          {clusters.map(c => <TeamVideoBookmarkIndicator key={c[0].id} bookmarks={c} />)}
        </Box>
      )}
      <CustomSlider2
      trackRadius={3}
      thumbRadius={8}
      step={1 / 30}
      {...seekbarProps}
      railOverlay={timeRanges && (
        <SeekbarBuffer duration={duration} timeRanges={timeRanges} />
      )}
      trackOverlay={(
        <Fragment>
          {clusters.map(bookmarks => (
            <Box
            key={bookmarks[0].id}
            sx={{
              position: 'absolute',
              opacity: 0.7,
              height: 12,
              bottom: 0,
              width: 2,
              left: calcLeft(bookmarks[0].time, duration),
              backgroundColor: theme => theme.palette.text.secondary,
            }}
            />
          ))}
        </Fragment>
      )}
      />
    </Box>
  );
});

export default memo(TeamVideoSeekbar);
