import { EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Dialog, DialogContent, DialogProps, Stack, Typography } from '@mui/material';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ClickToCopy } from '@/components/ClickToCopy';
import { useBackdropTransitionHack } from '@/hooks/useBackdropTransitionHack';
import { useMixpanel } from '@/hooks/useMixpanel';
import { authHelpers } from '@/mixpanel/helpers/auth-helpers';

import { SignInSource } from './authentication-slice';
import { ForgotPassword } from './ForgotPassword';
import LoginForm from './LoginForm';
import SignUpForm from './SignUpForm';
import SignUpVerifyDialogContent from './SignUpVerifyDialogContent';

interface LoginDialogOwnProps {
  signInSource?: SignInSource;
  className?: string;
  onClose?: VoidFunction;
  onLoggedIn?: VoidFunction;
  onCancel?: VoidFunction;
}

type LoginDialogProps = LoginDialogOwnProps & Pick<DialogProps, 'open'>;

function LoginDialog(props: LoginDialogProps) {
  const { open, onClose, onCancel } = props;

  const handleClose = useCallback(() => {
    onCancel?.();
    onClose?.();
  }, [onCancel, onClose]);

  const [exited, TransitionProps] = useBackdropTransitionHack(open);

  if (exited) { // forcefully unmount the dialog to avoid bug that causes dialog to remain open
    return null;
  }

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    fullWidth={true}
    TransitionProps={TransitionProps}
    >
      <LoginDialogContent  {...props} />
    </Dialog>
  );
}

function LoginDialogContent(props: LoginDialogProps) {
  const { className, onCancel, onClose, onLoggedIn, signInSource } = props;

  const { t } = useTranslation(['common']);

  const [thirdPartyProvider, setThirdPartyProvider] = useState(true);
  const [isSignUp, setIsSignUp] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [oAuthUrl, setOAuthUrl] = useState<string>();

  const handleBackClick = useMemo(() => {
    if (oAuthUrl) {
      return () => setOAuthUrl(undefined);
    }
    if (showVerifyEmail || thirdPartyProvider) {
      return undefined;
    }
    if (forgotPassword) {
      return () => {
        setForgotPassword(false);
      };
    }
    return () => {
      setThirdPartyProvider(true);
    };
  }, [forgotPassword, oAuthUrl, showVerifyEmail, thirdPartyProvider]);

  const handleEmailClick = () => {
    setThirdPartyProvider(false);
  };

  const toggleSignUp = useCallback(() => {
    setIsSignUp(prev => !prev);
    setThirdPartyProvider(true);
    setShowVerifyEmail(false);
    setForgotPassword(false);
  }, []);

  const handleClose = useMemo(() => !(onCancel || onClose) ? undefined : () => {
    onCancel?.();
    onClose?.();
  }, [onCancel, onClose]);

  const handleLoggedIn = useCallback(() => {
    onClose?.();
    onLoggedIn?.();
  }, [onClose, onLoggedIn]);

  const mixpanel = useMixpanel();

  return (
    <Stack spacing={1} className={className}>
      <EnhancedDialogTitle center={true} onBack={handleBackClick} onClose={handleClose}>
        {showVerifyEmail ? t('common:authentication.verifyEmail.title') : isSignUp ? t('common:authentication.signUpTitle') : t('common:authentication.title')}
      </EnhancedDialogTitle>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <Stack spacing={1} width='60%'>
          {!oAuthUrl && !forgotPassword && !showVerifyEmail && thirdPartyProvider && (
            <Typography>
              {isSignUp ? t('common:authentication.signUpWith') : t('common:authentication.signInWith')}
            </Typography>
          )}
          {oAuthUrl ? (
            <Fragment>
              <Typography>
                {t('common:authentication.oAuthCopyLink')}
              </Typography>
              <ClickToCopy value={oAuthUrl} onClick={() => mixpanel.track(...authHelpers.oAuthLinkCopied())} />
            </Fragment>
          ) : showVerifyEmail ? (
            <SignUpVerifyDialogContent onBackToSignInClick={toggleSignUp} />
          ) : isSignUp ? (
            <SignUpForm signInSource={signInSource} onLoggedIn={handleLoggedIn} thirdPartyProvider={thirdPartyProvider} onEmailClick={handleEmailClick} onSignInClick={toggleSignUp} setShowVerifyEmail={setShowVerifyEmail} />
          ) : forgotPassword ? (
            <ForgotPassword signInSource={signInSource} onLoginClick={() => setForgotPassword(false)} />
          ) : (
            <LoginForm signInSource={signInSource} onLoggedIn={handleLoggedIn} onOAuthClick={setOAuthUrl} thirdPartyProvider={thirdPartyProvider} onEmailClick={handleEmailClick} onSignUpClick={toggleSignUp} onForgotPasswordClick={() => setForgotPassword(true)} />
          )}
        </Stack>
      </DialogContent>
    </Stack>
  );
}

export default memo(LoginDialog);
