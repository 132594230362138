import { Button, Stack } from '@mui/material';
import { FallbackProps } from 'react-error-boundary';
import { Route, Switch } from 'react-router';

import { useNavigate } from '@/hooks/useNavigate';

import SquadRouting from './squad.routing';

export function SquadError(props: FallbackProps) {
  const { resetErrorBoundary } = props;
  const onNavigate = useNavigate();
  return (
    <Switch>
      <Route path={[SquadRouting.path.team, SquadRouting.path.teamFolder, SquadRouting.path.teamVideo]}>
        <Stack justifyContent='center' alignItems='center' flex={1}>
          No access
          <Button
          variant='contained'
          color='primary'
          onClick={() => {
            resetErrorBoundary();
            onNavigate('/');
          }}
          >
            Go Home
          </Button>
        </Stack>
      </Route>
    </Switch>
  );
}
