export let ENDPOINT = 'https://insights.gg';

if (process.env.REACT_APP_ENABLE_SQUAD) {
  ENDPOINT = 'https://alpha.insights.gg';
}

if (process.env.REACT_APP_BUILD_TARGET === 'web') {
  const { origin } = window.location;
  ENDPOINT = origin;
}

if (process.env.REACT_APP_ENDPOINT) {
  ENDPOINT = decodeURIComponent(process.env.REACT_APP_ENDPOINT.trim());
}
