import { useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';

const RemoveVideoReactionMutation = gql(`
  mutation RemoveVideoReactionMutation($input: RemoveVideoReactionInput!) {
    removeVideoReaction(input: $input) {
      video {
        id
      }
    }
  }
`);

export function useRemoveVideoReactionMutation() {
  return useMutation(RemoveVideoReactionMutation);
}
