import { OverridedMixpanel } from 'mixpanel-browser';
import { SagaIterator } from 'redux-saga';
import { call, getContext, race, SagaReturnType, take } from 'redux-saga/effects';

import { ClientContext } from '@/apollo/client';
import { authSessionChecked, authSessionUpdated, loginWithPasswordAC, loginWithProviderAC, logout } from '@/features/authentication/authentication-slice';
import { HideTipsMutation, makeCachedTipsQuery, TipsQuery } from '@/features/authentication/cachedTips';
import { UserProfileQuery } from '@/features/authentication/UserProfileContext';
import { getFragmentData, gql } from '@/graphql/__generated__';

import { MixpanelSuperProperties, MixpanelUserProperties } from './common';
import { mixpanelHelpers } from './helpers';
import { takeEveryWithMixpanel } from './mixpanel-effects';

const IdentityWorker_SelfFragment = gql(`
  fragment IdentityWorker_SelfFragment on Self {
    id
    name
    email
    created
    features
  }
`);

function checkAdFree(user: { features: string[] }) {
  return user.features.includes('ADFREE');
}

function* identityWorker(
  mixpanel: OverridedMixpanel,
  action: ReturnType<typeof loginWithPasswordAC.done | typeof loginWithProviderAC.done | typeof authSessionUpdated.fulfilled>,
  skipLanguage = false,
): SagaIterator {
  const { client }: ClientContext = yield getContext('apollo');
  const worker = async function () {
    return client.query({ query: UserProfileQuery }).catch(console.error);
  };
  const result: SagaReturnType<typeof worker> = yield call(worker);
  if (!result?.data) {
    return;
  }
  const user = getFragmentData(IdentityWorker_SelfFragment, result.data.me);
  const newuser: boolean = yield call(async function () {
    if (loginWithProviderAC.done.match(action)) {
      return (action.payload.result as { newuser?: boolean }).newuser;
    }
    if (loginWithPasswordAC.done.match(action)) {
      const [newuserTip] = await makeCachedTipsQuery(client)(['newuser']);
      const timeAfterSignUp = new Date().getTime() - new Date(user.created).getTime();
      return newuserTip && timeAfterSignUp <= 24 * 60 * 60 * 1000;
    }
    return false;
  });
  if (newuser) { // set alias if registering
    yield call([mixpanel, mixpanel.alias], user.id);
  }
  yield call([mixpanel, mixpanel.identify], user.id);
  if (newuser) {
    if (loginWithPasswordAC.done.match(action) || loginWithProviderAC.done.match(action)) {
      yield call(async function () {
        try {
          const result = await client.mutate({
            mutation: HideTipsMutation,
            variables: { input: { names: ['newuser'] } },
          });
          if (!result.data?.hideTips) {
            return;
          }
          result.data.hideTips.names.forEach((name) => {
            client.writeQuery({
              query: TipsQuery,
              variables: { names: [name] },
              data: { tips: [false] },
            });
          });
        } catch (error) {
          console.error('hide tips failed', error);
        }
      });
      yield call([mixpanel, mixpanel.track], ...mixpanelHelpers.auth.signUpCompleted(action, new Date()));
    }
  } else {
    if (loginWithPasswordAC.done.match(action) || loginWithProviderAC.done.match(action)) {
      yield call([mixpanel, mixpanel.track], ...mixpanelHelpers.auth.signInCompleted(action));
    }
  }
  const hasAdFree = checkAdFree(user);
  const superProps: MixpanelSuperProperties = {
    'Ad-free': hasAdFree,
    'App Version': process.env.APPVERSION!,
    'Commit SHA': process.env.COMMITSHA!,
    'Is Signed In': true,
  };
  yield call([mixpanel, mixpanel.register], superProps);

  const userProps: Partial<MixpanelUserProperties> = {
    ...superProps,
    $email: user.email || undefined,
    $name: user.name,
  };
  yield call([mixpanel.people, mixpanel.people.set], userProps);
}

function* nonAuthedWorker(
  mixpanel: OverridedMixpanel,
  skipLanguage = false,
) {
  const superProps: Partial<MixpanelSuperProperties> = {
    'App Version': process.env.APPVERSION!,
    'Commit SHA': process.env.COMMITSHA!,
    'Is Signed In': false,
  };
  yield call([mixpanel, mixpanel.register], superProps);

  const userProperties: Partial<MixpanelUserProperties> = {
    ...superProps,
  };
  yield call([mixpanel.people, mixpanel.people.set], userProperties);
}

export function* identityManagement() {
  const {
    authed,
    notAuthed,
  }: {
    authed: ReturnType<typeof loginWithPasswordAC.done | typeof loginWithProviderAC.done | typeof authSessionUpdated.fulfilled>;
    notAuthed: ReturnType<typeof authSessionChecked>;
  } = yield race({
    authed: take([loginWithPasswordAC.done, loginWithProviderAC.done, authSessionUpdated.fulfilled]),
    notAuthed: take(authSessionChecked),
  });
  const mixpanel: OverridedMixpanel = yield getContext('mixpanel');
  if (authed) {
    yield call(identityWorker, mixpanel, authed, true);
  } else if (notAuthed) {
    yield call(nonAuthedWorker, mixpanel, true);
  }
  yield takeEveryWithMixpanel(loginWithProviderAC.started, function* (mixpanel, action) {
    yield call([mixpanel, mixpanel.track], ...mixpanelHelpers.auth.oAuthStarted(action));
  });
  yield takeEveryWithMixpanel([loginWithPasswordAC.done, loginWithProviderAC.done], function* (mixpanel, action) {
    yield call(identityWorker, mixpanel, action);
  });
  yield takeEveryWithMixpanel(logout, function* (mixpanel) {
    yield call([mixpanel, mixpanel.reset]);
    yield call(nonAuthedWorker, mixpanel);
  });
}
