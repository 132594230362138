import { FloatingText } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { SecondsFormatter, useCombinedRef, useInnerElementHover } from '@insights-gaming/utils';
import { Box, Checkbox, SxProps } from '@mui/material';
import { forwardRef, Fragment, MouseEventHandler, useCallback, useContext, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useResizeDetector } from 'react-resize-detector';

import { TeamVideoReactions } from '@/features/squad/TeamVideo/TeamVideoReactions';
import { useTeamVideoUrls } from '@/features/squad/TeamVideo/useTeamVideoUrls';
import { useAccessControl } from '@/features/squad/useAccessControl';
import { useTeamCardSx } from '@/features/squad/useTeamCardSx';
import { MultiSelectAtomsContext } from '@/features/video-library/atoms/multiSelectAtoms';
import { ItemTypes, TeamVideoDragItem } from '@/features/video-library/item-types';
import Thumbnail from '@/features/video-library/video-card/Thumbnail';
import { VideoCanvasPreview, VideoPreviewControls } from '@/features/video-library/video-card/VideoCanvasPreview';
import { VideoCardBase } from '@/features/video-library/video-card/VideoCardBase';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { createCdnUrl } from '@/utils/cdn';

import { TeamVideoCardStatus } from './TeamVideoCardStatus';
import { TeamVideoContextMenu } from './TeamVideoContextMenu';

export const TeamVideoCard_VideoFragment = gql(`
  fragment TeamVideoCard_VideoFragment on Video {
    id
    name
    thumbnail
    created
    duration
    directory {
      id
    }
    ...TeamVideoContextMenu_VideoFragment
    ...UseTeamVideoUrls_VideoFragment
    ...TeamVideoReactions_VideoFragment
    ...TeamVideoCardStatus_VideoFragment
  }
`);

interface TeamVideoCardOwnProps {
  className?: string;
  sx?: SxProps<Theme>;

  teamId: ID;
  video: FragmentType<typeof TeamVideoCard_VideoFragment>;
  onClick?: MouseEventHandler<HTMLButtonElement>;

  selected?: boolean;
  onAddToSelection?: VoidFunction;
  onCheckboxChange?: VoidFunction;
}

type TeamVideoCardProps = TeamVideoCardOwnProps;

export const TeamVideoCard = forwardRef<HTMLDivElement, TeamVideoCardProps>(function TeamVideoCard(props, _ref) {
  const { className, onAddToSelection, onCheckboxChange, onClick, selected, sx, teamId } = props;
  const ref = useCombinedRef(_ref);

  const video = getFragmentData(TeamVideoCard_VideoFragment, props.video);
  const parentId = video.directory?.id;

  const { canUpdateVideo, canOpenVideoMenu } = useAccessControl(teamId);

  const { width = 0, height = 0 } = useResizeDetector({ handleWidth: true, handleHeight: true, targetRef: ref });

  const thumbnail = createCdnUrl(video.thumbnail, { width: 320, aspect_ratio: '16:9', format: 'webp' });

  const { selectedVideosAtoms, selectedFoldersAtoms } = useContext(MultiSelectAtomsContext);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    canDrag: canUpdateVideo,
    type: ItemTypes.TEAM_VIDEO_CARD,
    item: (): TeamVideoDragItem => ({
      type: ItemTypes.TEAM_VIDEO_CARD,
      id: video.id,
      parentId,
      teamId,
      width,
      height,
      name: video.name,
      thumbnailUrl: thumbnail ?? undefined,
      currentItem: {
        videoUuid: video.id,
      },
      selectionAtoms: {
        videos: selectedVideosAtoms.selectedAtom,
        folders: selectedFoldersAtoms.selectedAtom,
      },
    }),
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        onAddToSelection?.();
      }
    },
  }), [canUpdateVideo, height, onAddToSelection, parentId, selectedFoldersAtoms.selectedAtom, selectedVideosAtoms.selectedAtom, teamId, thumbnail, video.id, video.name, width]);

  drag(ref);
  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const videoPreviewRef = useRef<VideoPreviewControls>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const startPreview = useCallback(() => {
    videoPreviewRef.current?.play();
  }, []);

  const stopPreview = useCallback(() => {
    videoPreviewRef.current?.pause();
  }, []);

  const { onMouseMove, onPointerLeave } = useInnerElementHover(
    {
      ref: canvasRef,
      onMouseEnter: startPreview,
      onMouseLeave: stopPreview,
    },
  );

  const { url, onError } = useTeamVideoUrls(video);

  return (
    <VideoCardBase
    ref={ref}
    name={video.name}
    onMouseMove={onMouseMove}
    onPointerLeave={onPointerLeave}
    thumbnail={thumbnail && (
      <Thumbnail variant='rounded' src={thumbnail} />
    )}
    overlay={(
      <Checkbox
      checked={isDragging || selected}
      onChange={onCheckboxChange}
      />
    )}
    thumbnailOverlay={(
      <Fragment>
        <VideoCanvasPreview ref={videoPreviewRef} canvasRef={canvasRef} videoPath={url} onError={onError} />
        <TeamVideoCardStatus video={video} />
        {video.duration && (
          <FloatingText vertical='bottom' horizontal='right' spacing={1}>
            {SecondsFormatter.format(video.duration)}
          </FloatingText>
        )}
      </Fragment>
    )}
    secondaryAction={(canOpenVideoMenu && (
      <TeamVideoContextMenu teamId={teamId} video={video} />
    ))}
    metadata={(
      <Box height={34}  />
    )}
    onClick={onClick}
    className={className}
    sx={[
      ...useTeamCardSx(),
      isDragging && {
        opacity: 0.5,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <TeamVideoReactions
      teamId={teamId}
      videoId={video.id}
      video={video}
      sx={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        p: 1,
      }}
      />
    </VideoCardBase>
  );
},
);
