import { useSuspenseQuery } from '@apollo/client';
import { ContextType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, RouteChildrenProps } from 'react-router';
import invariant from 'ts-invariant';

import { PlaceholderErrorFallbackComponent } from '@/components/error-boundaries/PlaceholderErrorFallbackComponent';
import PublicVideoRouting from '@/features/squad/PublicVideo/public-video.routing';
import SquadRouting from '@/features/squad/squad.routing';
import { getFragmentData, gql } from '@/graphql/__generated__';
import { useReadQueryHack, withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';

import { TeamVideoPageContext } from './TeamVideoPageContext';

const TeamVideoPublicFallback_VideoFragment = gql(`
  fragment TeamVideoPublicFallback_VideoFragment on Video {
    id
    directory {
      id
    }
  }
`);

const TeamVideoPublicFallback_VideoQuery = gql(`
  query TeamVideoPublicFallback_VideoFragment($id: ID!) {
    video(id: $id) {
      id
    }
  }
`);

interface TeamVideoPublicFallbackProps {
  id: ID;
}

type TeamVideoPublicFallbackInternalProps = TeamVideoPublicFallbackProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamVideoPageContext>>;

function TeamVideoPublicFallbackInternal(props: TeamVideoPublicFallbackInternalProps) {
  const { data } = useReadQueryHack(props);
  const video = getFragmentData(TeamVideoPublicFallback_VideoFragment, data?.video);
  if (video && !video.directory) {
    return <Redirect to={PublicVideoRouting.createUrl.publicVideo(video.id)} />;
  }
  return null;
}

function TeamVideoPublicFallbackWithErrorBounds(props: TeamVideoPublicFallbackInternalProps) {
  return (
    <ErrorBoundary fallback={<PlaceholderErrorFallbackComponent />}>
      <TeamVideoPublicFallbackInternal {...props} />
    </ErrorBoundary>
  );
}

export const TeamVideoPublicFallback = withBackgroundQuery(TeamVideoPageContext, TeamVideoPublicFallbackWithErrorBounds);

export function TeamVideoRoutePublicFallback(props: RouteChildrenProps<SquadRouting.TeamVideoParams>) {
  const { match } = props;
  invariant(match !== null, 'RouteChildrenProps should always have match');
  const videoId = match.params.videoId;
  const { data: { video } } = useSuspenseQuery(TeamVideoPublicFallback_VideoQuery, { variables: { id: videoId } });
  if (video) {
    return <Redirect to={PublicVideoRouting.createUrl.publicVideo(video.id)} />;
  }
  return null;
}

export function TeamVideoRoutePublicFallbackWithErrorBounds(props: RouteChildrenProps<SquadRouting.TeamVideoParams>) {
  return (
    <ErrorBoundary fallback={<PlaceholderErrorFallbackComponent />}>
      <TeamVideoRoutePublicFallback {...props} />
    </ErrorBoundary>
  );
}
