
import { MixpanelSuperProperties, MixpanelUserProperties, MixpanelUserPropertiesOnce } from '@/mixpanel/common';

import { appHelpers } from './app-helpers';
import { authHelpers } from './auth-helpers';
import { billingHelpers } from './billing-helpers';
import { discoverHelpers } from './discover-helpers';
import { errorHelpers } from './error-helpers';
import { settingHelpers } from './setting-helpers';
import { shardHelpers } from './shard-helpers';
import { videoHelpers } from './video-helpers';

function superProperties(superProps: Partial<MixpanelSuperProperties>) {
  return superProps;
}

function userProperties(userProps: Partial<MixpanelUserProperties>) {
  return userProps;
}

function userPropertiesOnce(userProps: Partial<MixpanelUserPropertiesOnce>) {
  return userProps;
}

export const mixpanelHelpers = {
  superProperties,
  userProperties,
  userPropertiesOnce,
  app: appHelpers,
  auth: authHelpers,
  billing: billingHelpers,
  discover: discoverHelpers,
  setting: settingHelpers,
  video: videoHelpers,
  error: errorHelpers,
  shard: shardHelpers,
};
