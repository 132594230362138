import { InsightsGGIcon } from '@insights-gaming/material-icons';
import { Theme } from '@insights-gaming/theme';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, SxProps } from '@mui/material';

import { UploadQueueItem, UploadQueueItemProps } from './UploadQueueItem';
import { UploadQueueItemStatus } from './UploadQueueItemStatus';
import { videoUploadSelectors } from './video-upload-selector';

interface FailedUploadQueueItemProps {
  className?: string;
  sx?: SxProps<Theme>;

  detailedView?: UploadQueueItemProps['detailedView'];
  uploadItemUuid: UUID;
  failedUpload: ReturnType<typeof videoUploadSelectors.getFailedUploads2>[string];
  onRemoveFailed?: (uploadQueueItem: UUID) => void;
}

export function FailedUploadQueueItem(props: FailedUploadQueueItemProps) {
  const { className, failedUpload, onRemoveFailed, sx, uploadItemUuid, ...rest } = props;
  return (
    <UploadQueueItem
    destinationIcon={<InsightsGGIcon fontSize='small' />}
    name={failedUpload.options.displayName || failedUpload.options.video.file.name}
    className={className}
    sx={sx}
    secondaryAction={(
      <IconButton size='small' edge='end' onClick={() => onRemoveFailed?.(uploadItemUuid)}>
        <CloseIcon />
      </IconButton>
    )}
    {...rest}
    >
      <UploadQueueItemStatus text='Failed' icon={<ErrorIcon fontSize='small' color='error' />} />
    </UploadQueueItem>
  );
}
