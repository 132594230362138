import { QueryReference, useReadQuery, useSuspenseQuery } from '@apollo/client';
import emojiData from '@emoji-mart/data';
import { CustomData, CustomEmojiData } from '@emoji-mart/react';
import { init } from 'emoji-mart';
import { useEffect, useMemo } from 'react';

import { gql } from '@/graphql/__generated__';
import { TeamEmojiContextQueryQuery } from '@/graphql/__generated__/graphql';

const UseTeamEmojis_TeamQuery = gql(`
  query UseTeamEmojis_TeamQuery($teamId: ID!) {
    team(id: $teamId) {
      id
      name
    }
  }
`);

export function useTeamEmojis(teamId: ID, queryRef: QueryReference<TeamEmojiContextQueryQuery, unknown>) {
  const { data } = useReadQuery(queryRef);
  const { data: { team } } = useSuspenseQuery(UseTeamEmojis_TeamQuery, { variables: { teamId } });

  const _emojis = data?.queryEmojis?.emojis;
  const teamCategory = useMemo<CustomData>(() => {
    const emojis =  (_emojis ?? []).map<CustomEmojiData>(emoji => ({
      id: emoji.id,
      name: emoji.name,
      skins: [{ src: emoji.url }],
    }));
    return {
      id: teamId,
      name: team?.name ?? 'Team',
      emojis,
    };
  }, [_emojis, team?.name, teamId]);

  const custom = useMemo<CustomData[]>(() => {
    return [
      teamCategory,
    ];
  }, [teamCategory]);


  useEffect(() => {
    init({ data: emojiData, custom });
  }, [custom]);

  return {
    data: emojiData,
    custom,
    emojis: _emojis,
  };
}
