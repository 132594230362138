import { Theme } from '@insights-gaming/theme';
import { CircularProgress, CircularProgressProps, Stack, SxProps } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import { MessageRandomizer } from './MessageRandomizer';

interface LoaderProps {
  className?: string;
  sx?: SxProps<Theme>;

  inline?: boolean;
  progress?: ReactNode;
}

export function Loader(props: PropsWithChildren<LoaderProps & CircularProgressProps>) {
  const { children, className, inline = false, progress, sx, ...rest } = props;
  return (
    <Stack
    direction='row'
    flex={1}
    justifyContent='center'
    alignItems='center'
    className={className}
    sx={[
      inline && {
        display: 'inline-block',
        width: 'min-content',
        height: 'min-content',
        flex: 'unset',
      },
    ]}
    >
      <Stack width='100%' height='100%' justifyContent='center' alignItems='center'>
        <Stack>
          {progress !== undefined ? (
            progress
          ) : (
            <CircularProgress color='inherit' {...rest} />
          )}
          {children && (
            <MessageRandomizer>
              {children}
            </MessageRandomizer>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
