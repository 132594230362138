import { TooltipButton, TooltipButtonProps } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import { ButtonBase, Stack, SxProps, TooltipProps, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import {
  ChangeEvent,
  forwardRef,
  memo,
  MouseEvent,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { TooltipContainerContext } from '@/features/desktop-window/TooltipContainerContext';
import { withNewIndicator } from '@/features/product-tour/withNewIndicator';

interface NavigationButtonOwnProps {
  className?: string;
  sx?: SxProps<Theme>;
  hideLabel?: boolean;
  selected?: boolean;
  value?: string;
  onClick?: (e: ChangeEvent<{}>, value?: string) => void;
  TooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
  isSubCategory?: boolean;
}

type NavigationButtonProps = PropsWithChildren<NavigationButtonOwnProps> & Omit<TooltipButtonProps<typeof ButtonBase>, 'TooltipProps'>;

const useStyles = makeStyles<Theme, NavigationButtonProps>(theme => createStyles({
  root: {
    width: 76,
    flexShrink: 0,
    borderRadius: theme.shape.borderRadius,
    color: alpha(theme.palette.text.primary, 0.7),
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
    }),
    '&:not($selected)': {
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
        '&$disabled': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
      '&:not($isSubCategory)': {
        boxShadow: `inset 4px 0px ${theme.palette.primary.main}`,
      },
      // '&::after': {
      //   content: '" "',
      //   position: 'absolute',
      //   width: 4,
      //   height: '100%',
      //   top: 0,
      //   left: 0,
      //   backgroundColor: theme.palette.primary.main,
      // },
    },
    '&$isSubCategory': {
      width: 68,
      height: 48,
    },
  },
  selected: {},
  disabled: {},
  isSubCategory: {},
}), { name: 'NavigationButton' });

const tooltipProps = {
  placement: 'right',
};

const NavigationButton = forwardRef<HTMLButtonElement, NavigationButtonProps>(function NavigationButton(props: NavigationButtonProps, ref) {
  const classes = useStyles(props);
  const { className, children, hideLabel, title, onClick, selected, TooltipProps, value, isSubCategory, sx, ...rest } = props;

  const handleClick = useCallback((e: MouseEvent) => {
    onClick?.(e, value);
  }, [onClick, value]);

  const tooltipContainer = useContext(TooltipContainerContext);

  const memoizedTooltipProps = useMemo(() => {
    return { PopperProps: { container: tooltipContainer }, ...tooltipProps, ...TooltipProps };
  }, [TooltipProps, tooltipContainer]);

  return (
    <TooltipButton
    {...rest}
    ref={ref}
    title={title}
    TooltipProps={memoizedTooltipProps}
    component={ButtonBase}
    focusRipple={true}
    onClick={handleClick}
    className={classNames(classes.root, { [classes.selected]: selected, [classes.isSubCategory]: isSubCategory }, className)}
    sx={[
      {
        height: 60,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <Stack justifyContent='center' alignItems='center'>
        {children}
        {typeof title === 'string' && !hideLabel && (
          <Typography sx={{ fontSize: createRemFromPx(10) }}>
            {title}
          </Typography>
        )}
      </Stack>
    </TooltipButton>
  );
});
NavigationButton.displayName = 'NavigationButton';

export default memo(NavigationButton);

export const NavigationButtonWithNewIndicator = withNewIndicator(NavigationButton);
