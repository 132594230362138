import { GameEvent, GameInfoUpdate } from '@insights-gaming/game-events';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import actionCreatorFactory from 'typescript-fsa';

import { loadSettingDatabaseAC } from '@/features/settings/setting-slice';

import { cancelMigrateVideoFolderAC, migrateFreeSpaceWarningRetry, migrateNotEnoughFreeSpace, migrateResolveVideoAlreadyExists, migrateResolveVideosAlreadyExist, migrateVideoAlreadyExists, MigrateVideoAlreadyExistsPayload, migrateVideosAlreadyExist, MigrateVideosAlreadyExistPayload, migrationDoneAcknowledged, migrationInProgressFound, MigrationStatus } from './import-export-actions';

const name = 'background-window';
const actionCreator = actionCreatorFactory(name);
export const exitAppRequestAC = actionCreator.async<void, void, Error>('EXIT_APP_REQUEST');
export const relaunchAppRequestAC = actionCreator.async<void, void, Error>('RELAUNCH_APP_REQUEST');

interface OverwolfEventPayload {
  events: GameEvent[];
}
interface OverwolfInfoPayload {
  info: GameInfoUpdate;
}

// type AppLaunchSource = 'dock' | 'gamelaunchevent' | string;

interface ExitBlocker {
  uuid: UUID;
  title?: string;
  description?: string;
  promise: PromiseLike<void>;
}

interface BackgroundState {
  events: GameEvent[];
  info: GameInfoUpdate | {};
  appLaunchSearchString: string;
  appVersion?: string;
  lastLaunchedVersion?: string;
  online: boolean;
  cleanupActions: Dictionary<() => void | PromiseLike<void>>;
  exitBlockers: Dictionary<ExitBlocker>;
  isForceCloseDialogOpen: boolean;
  settingsLoaded?: boolean;
  foundIcDisabledGameFromRestore?: boolean;
  backup: {
    conflict?: MigrateVideoAlreadyExistsPayload;
    conflicts?: MigrateVideosAlreadyExistPayload;
    migrationInProgress?: MigrationStatus;
    freeSpaceWarning?: boolean;
  };
}

let initialState: BackgroundState = {
  events: [],
  info: {},
  appLaunchSearchString: window.location.search.slice(1),
  online: navigator.onLine,
  cleanupActions: {},
  exitBlockers: {},
  isForceCloseDialogOpen: false,
  backup: {},
};

const backgroundWindowSlice = createSlice({
  name,
  initialState,
  reducers: {
    appLaunched() {}, // for analytics
    gameLaunched(state, action: PayloadAction<{ gameClassId: number; queueId?: number }>) {}, // for analytics
    setEvents(state, action: PayloadAction<OverwolfEventPayload>) {
      const { events } = action.payload;
      state.events = events;
    },
    setInfo(state, action: PayloadAction<OverwolfInfoPayload>) {
      const { info } = action.payload;
      state.info = info;
    },
    networkOnline(state) {
      state.online = true;
    },
    networkOffline(state) {
      state.online = false;
    },
    registerCleanupAction(state, action: PayloadAction<{ name: string; cleanupAction: VoidFunction }>) {
      const { name, cleanupAction } = action.payload;
      state.cleanupActions[name] = cleanupAction;
    },
    unregisterCleanupAction(state, action: PayloadAction<string>) {
      delete state.cleanupActions[action.payload];
    },
    registerExitBlocker(state, action: PayloadAction<ExitBlocker>) {
      state.exitBlockers[action.payload.uuid] = action.payload;
    },
    unregisterExitBlocker(state, action: PayloadAction<UUID>) {
      delete state.exitBlockers[action.payload];
    },
    openForceCloseDialog(state) {
      state.isForceCloseDialogOpen = true;
    },
    closeForceCloseDialog(state) {
      state.isForceCloseDialogOpen = false;
    },
    appVersionRetrieved(state, action: PayloadAction<string>) {
      state.appVersion = action.payload;
    },
    lastLaunchedVersionRetrieved: (state, action: PayloadAction<string | undefined>) => {
      state.lastLaunchedVersion = action.payload;
    },
    foundIcDisabledGameFromRestore: (state) => {
      state.foundIcDisabledGameFromRestore = true;
    },
    clearFoundIcDisabledGameFromRestore: (state) => {
      delete state.foundIcDisabledGameFromRestore;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettingDatabaseAC.done, (state) => {
      state.settingsLoaded = true;
    });

    builder.addCase(migrateVideoAlreadyExists, (state, action) => {
      state.backup.conflict = action.payload;
    });

    builder.addCase(migrateVideosAlreadyExist, (state, action) => {
      state.backup.conflicts = action.payload;
    });

    builder.addCase(migrateResolveVideoAlreadyExists, (state) => {
      delete state.backup.conflict;
    });

    builder.addCase(migrateResolveVideosAlreadyExist, (state) => {
      delete state.backup.conflicts;
    });

    builder.addCase(migrationInProgressFound, (state, action) => {
      state.backup.migrationInProgress = action.payload;
    });

    builder.addCase(migrationDoneAcknowledged, (state) => {
      delete state.backup.migrationInProgress;
    });

    builder.addCase(cancelMigrateVideoFolderAC.done, (state) => {
      delete state.backup.freeSpaceWarning;
      delete state.backup.migrationInProgress;
    });

    builder.addCase(migrateNotEnoughFreeSpace, (state) => {
      state.backup.freeSpaceWarning = true;
    });

    builder.addCase(migrateFreeSpaceWarningRetry, (state) => {
      delete state.backup.freeSpaceWarning;
    });
  },
});

export const {
  appLaunched,
  appVersionRetrieved,
  clearFoundIcDisabledGameFromRestore,
  closeForceCloseDialog,
  gameLaunched,
  foundIcDisabledGameFromRestore,
  lastLaunchedVersionRetrieved,
  networkOffline,
  networkOnline,
  openForceCloseDialog,
  registerCleanupAction,
  registerExitBlocker,
  setEvents,
  setInfo,
  unregisterCleanupAction,
  unregisterExitBlocker,
} = backgroundWindowSlice.actions;
export const backgroundReducer = backgroundWindowSlice.reducer;
