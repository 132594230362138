import { useQuery } from '@apollo/client';
import { InsightsGGIcon } from '@insights-gaming/material-icons';
import { Theme } from '@insights-gaming/theme';
import CloseIcon from '@mui/icons-material/Close';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { IconButton, SxProps } from '@mui/material';
import { useCallback } from 'react';

import { pigeonRouter } from '@/features/pigeon-router';
import { useIsSquadEnabled } from '@/features/squad/useIsSquadEnabled';
import { useLegacyPigeonCompatibleCreateUrl } from '@/features/squad/useLegacyPigeonCompatibleCreateUrl';
import { gql } from '@/graphql/__generated__';
import { useNavigate } from '@/hooks/useNavigate';

import { UploadQueueItem, UploadQueueItemProps } from './UploadQueueItem';
import { UploadQueueItemStatus } from './UploadQueueItemStatus';
import { videoUploadSelectors } from './video-upload-selector';

const UploadedUploadQueueItem_VideoQuery = gql(`
  query UploadedUploadQueueItem_VideoQuery($videoId: ID!) {
    video(id: $videoId) {
      id
      streamUrls
      isWatchable @client
    }
  }
`);

interface UploadedUploadQueueItemProps {
  className?: string;
  sx?: SxProps<Theme>;

  detailedView?: UploadQueueItemProps['detailedView'];
  uploadItemUuid: UUID;
  uploadedVideo: ReturnType<typeof videoUploadSelectors.getUploadedVideosTemp2>[string];
  onRemoveUploaded?: (uploadQueueItem: UUID) => void;
}

export function UploadedUploadQueueItem(props: UploadedUploadQueueItemProps) {
  const { className, onRemoveUploaded, sx, uploadedVideo, uploadItemUuid, ...rest } = props;

  const { data } = useQuery(UploadedUploadQueueItem_VideoQuery, { variables: { videoId: uploadedVideo.videoId } });
  const isWatchable = !!data?.video?.isWatchable;
  const processing = !isWatchable;

  const onNavigate = useNavigate();

  const legacyPigeonCompatibleCreateUrl = useLegacyPigeonCompatibleCreateUrl();
  const isSquadEnabled = useIsSquadEnabled();

  const handleClick = useCallback(() => {
    const url = legacyPigeonCompatibleCreateUrl.teamVideo(uploadedVideo.teamId, uploadedVideo.videoId);
    if (isSquadEnabled) {
      onNavigate(url);
    } else {
      overwolf.utils.openUrlInDefaultBrowser(pigeonRouter.pigeonify(url));
    }
  }, [isSquadEnabled, legacyPigeonCompatibleCreateUrl, onNavigate, uploadedVideo.teamId, uploadedVideo.videoId]);

  return (
    <UploadQueueItem
    destinationIcon={<InsightsGGIcon fontSize='small' />}
    name={uploadedVideo.videoMetadata.name}
    onClick={handleClick}
    className={className}
    progress={1}
    processing={processing}
    sx={sx}
    secondaryAction={(
      <IconButton size='small' edge='end' onClick={() => onRemoveUploaded?.(uploadItemUuid)}>
        <CloseIcon />
      </IconButton>
    )}
    {...rest}
    >
      {!processing && (
        <UploadQueueItemStatus text='Completed' icon={<CloudDoneIcon fontSize='small' color='success' />} />
      )}
    </UploadQueueItem>
  );
}
