import { createContext, useCallback, useState } from 'react';

import { useDialogState } from '@/hooks/useDialogState';
import { NOOP } from '@/utils';

import { SignInSource } from './authentication-slice';

type OpenLoginFunction = (
  signInSource?: SignInSource,
  callbacks?: {
    onLoggedIn?: VoidFunction;
    onCancel?: VoidFunction;
  }
) => void;

export interface LoginDialogContextValue {
  isLoginDialogOpen: boolean;
  openLoginDialog: OpenLoginFunction;
  closeLoginDialog: VoidFunction;
  toggleLoginDialog: VoidFunction;

  onCancel?: VoidFunction;
  onLoggedIn?: VoidFunction;
  signInSource?: SignInSource;
}

export const LoginDialogContext = createContext<LoginDialogContextValue>({
  isLoginDialogOpen: false,
  openLoginDialog: NOOP,
  closeLoginDialog: NOOP,
  toggleLoginDialog: NOOP,
});

export const { Consumer: LoginDialogConsumer, Provider: LoginDialogProvider } = LoginDialogContext;

export function useLoginDialogContextValue(initialValue = false): LoginDialogContextValue {
  const [isLoginDialogOpen, _openLoginDialog, _closeLoginDialog, toggleLoginDialog] = useDialogState(initialValue);
  const [signInSource, setSignInSource] = useState<SignInSource>();
  const [onLoggedIn, setOnLoggedIn] = useState<VoidFunction>();
  const [onCancel, setOnCancel] = useState<VoidFunction>();

  const openLoginDialog = useCallback<OpenLoginFunction>((signInSource, { onLoggedIn, onCancel } = {}) => {
    setSignInSource(signInSource);
    setOnLoggedIn(() => onLoggedIn);
    setOnCancel(() => onCancel);
    _openLoginDialog();
  }, [_openLoginDialog]);

  const closeLoginDialog = useCallback(() => {
    setOnLoggedIn(undefined);
    _closeLoginDialog();
  }, [_closeLoginDialog]);

  return {
    isLoginDialogOpen,
    openLoginDialog,
    closeLoginDialog,
    toggleLoginDialog,
    onLoggedIn,
    onCancel,
    signInSource,
  };
}
