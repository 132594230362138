import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { createContext } from 'react';

export const TooltipContainerContext = createContext<HTMLDivElement | null>(null);

export const TooltipContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  pointerEvents: 'none',
});
