import { gql } from '@/graphql/__generated__';

export const QueryPageInfoFragment = gql(`
  fragment QueryPageInfoFragment on QueryPageInfo {
    start
    end
    more
    moreBackward @client
  }
`);
