import { makeVideoUploadSlice } from '@insights-gaming/video-upload-slice';

import { UploadVideoInput } from './types';

export const {
  allVideoUploadSagas,
  videoUploadActions,
  videoUploadSagas,
  videoUploadSlice,
} = makeVideoUploadSlice<{ id: ID }, UploadVideoInput>();
