import { GameClassId } from '@insights-gaming/game-events';
import { millisecondsToSeconds } from 'date-fns';

import { RemoveAudioSource } from '@/features/remove-audio/remove-audio-slice';
import { screenRecorderStarting } from '@/features/screen-recorder/screen-recorder-slice';
import { QuickShareDestination, quickShareUploadAC, TikTokUploadOptions } from '@/features/share/quick-share-slice';
import { streamStarting } from '@/features/stream-recorder/stream-recorder-slice';
import { createMergedVideoAC, DeleteSource, deleteVideosAC, FavoriteSource, isLeagueTFTVideo, MontageSource, RecordingSource, RecordingType, RecycleSource, RenameSource, replaySaved, TabChangedSource, Video, videoFavorited, videoMoved, videoRecycled, videoRenamed, videoSaved, videosMoved, videoUnfavorited } from '@/features/video-library/video-library-slice';
import { LinkCopiedSource, UploadSource, videoUploadActions } from '@/features/video-upload/upload-queue/video-upload-slice/video-upload-slice.app';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface VideoRecordingStartingProperties {
  [mpProps.eventSource]?: RecordingSource;
  [mpProps.recordingType]?: RecordingType;
  [mpProps.gameClassId]?: number;
  [mpProps.queueId]?: number;
  [mpProps.encoder]?: string;
  [mpProps.bitrate]?: number;
  [mpProps.resolution]?: string;
  [mpProps.framerate]?: number;
}

interface VideoRecordedProperties {
  [mpProps.eventSource]?: RecordingSource;
  [mpProps.recordingType]?: RecordingType;
  [mpProps.contentDuration]: number;
  [mpProps.gameClassId]?: number;
  [mpProps.queueId]?: number;
  [mpProps.encoder]?: string;
  [mpProps.bitrate]?: number;
  [mpProps.resolution]?: string;
  [mpProps.framerate]?: number;
  [mpProps.bookmarksCreated]?: number;
  [mpProps.commentLabelsCreated]?: number;
  [mpProps.notepadUsed]?: boolean;
  [mpProps.inputOverlayEnabled]?: boolean;
  [mpProps.webcamEnabled]?: boolean;
  [mpProps.audioProcessNames]?: string[];
  [mpProps.processCaptureEnabled]?: boolean;
}

interface InstantReplayCreatedProperties {
  [mpProps.eventSource]?: RecordingType;
  [mpProps.contentDuration]: number;
  [mpProps.gameClassId]?: number;
  [mpProps.queueId]?: number;
  [mpProps.encoder]?: string;
  [mpProps.bitrate]?: number;
  [mpProps.resolution]?: string;
  [mpProps.framerate]?: number;
  [mpProps.bookmarksCreated]?: number;
  [mpProps.commentLabelsCreated]?: number;
  [mpProps.notepadUsed]?: boolean;
  [mpProps.webcamEnabled]?: boolean;
  [mpProps.recordingType]?: RecordingType;
  [mpProps.audioProcessNames]?: string[];
}

interface VideoRecycledProperties {
  [mpProps.eventSource]?: RecycleSource;
  [mpProps.videoCount]: number;
}

interface VideosDeletedProperties {
  [mpProps.eventSource]?: DeleteSource;
  [mpProps.videoCount]: number;
}

interface VideosFavoritedProperties {
  [mpProps.eventSource]?: FavoriteSource;
  [mpProps.videoCount]: number;
}

interface VideosRenamedProperties {
  [mpProps.eventSource]?: RenameSource;
}

interface VideoMovedProperties {
  [mpProps.videoCount]: number;
}

type VideoType = 'Standard' | 'Instant Replay' | 'Highlight' | 'Clip' | 'Imported' | 'Merged' | 'Demo';

interface VideoUploadedProperties {
  [mpProps.eventSource]?: UploadSource;
  [mpProps.videoType]?: VideoType;
  [mpProps.filesize]: number;
}

interface VideoSocialSharedProperties {
  [mpProps.eventSource]?: UploadSource;
  [mpProps.contentDuration]: number;
  [mpProps.filesize]: number;
  [mpProps.shareDestination]: 'Gfycat' | 'YouTube' | 'Public' | 'TikTok' | 'Discord';
  [mpProps.clipRangeModified]: boolean;
  [mpProps.uploadOption]?: 'Direct' | 'Draft';
}

interface WatchVideoStartedProperties {
  [mpProps.contentDuration]: number;
  [mpProps.videoType]?: VideoType;
  [mpProps.gameClassId]?: GameClassId;
}

type WatchVideoEndedProperties = WatchVideoStartedProperties;

interface VideoMergedProperties {
  [mpProps.videoCount]: number;
}

interface VideoFullscreenedProperties {}

interface VideoExportedProperties {
  [mpProps.videoCount]: number;
  [mpProps.folderCount]: number;
}

interface CommentCreatedProperties {
  [mpProps.bookmarkAttached]: boolean;
}

interface ClipCreatedProperties {
  [mpProps.clipCombined]: boolean;
  [mpProps.segmentCount]: number;
}

interface HighlightCreatedProperties {
  [mpProps.eventSource]: MontageSource;
  [mpProps.contentDuration]: number;
  [mpProps.gameClassId]: number;
  [mpProps.deleteOriginal]: boolean;
}

interface BookmarkClickedProperties {
  [mpProps.bookmarkType]: 'Game Event' | 'User Bookmark';
}

interface VideoLinkCopiedProperties {
  [mpProps.eventSource]: LinkCopiedSource;
  [mpProps.contentDuration]?: number;
  [mpProps.gameClassId]?: GameClassId;
}

interface VideoReplayTabChangedProperties {
  [mpProps.eventSource]: TabChangedSource;
  [mpProps.tabName]: string;
  [mpProps.gameClassId]?: GameClassId;
}

interface VideoRemoveAudioSavedProperties {
  [mpProps.eventSource]: RemoveAudioSource;
}

export interface MixpanelVideoEventMap {
  'Video Recording Starting': VideoRecordingStartingProperties;
  'Screen Recording Starting': VideoRecordingStartingProperties;
  'Video Recorded': VideoRecordedProperties;
  'Instant Replay Created': InstantReplayCreatedProperties;
  'Video Recycled': VideoRecycledProperties;
  'Video Deleted': VideosDeletedProperties;
  'Video Favorited': VideosFavoritedProperties;
  'Video Unfavorited': VideosFavoritedProperties;
  'Video Renamed': VideosRenamedProperties;
  'Video Moved': VideoMovedProperties;
  'Video Uploaded': VideoUploadedProperties;
  'Video Social Shared': VideoSocialSharedProperties;
  'Watch Video Started': WatchVideoStartedProperties;
  'Watch Video Ended': WatchVideoEndedProperties;
  'Video Merged': VideoMergedProperties;
  'Video Fullscreened': VideoFullscreenedProperties;
  'Video Exported': VideoExportedProperties;
  'Comment Created': CommentCreatedProperties;
  'Clip Created': ClipCreatedProperties;
  'Highlight Created': HighlightCreatedProperties;
  'Bookmark Clicked': BookmarkClickedProperties;
  'Video Link Copied': VideoLinkCopiedProperties;
  'Video Replay Tab Changed': VideoReplayTabChangedProperties;
  'Video Remove Audio Saved': VideoRemoveAudioSavedProperties;
};

function starting(
  action: ReturnType<typeof streamStarting>,
  additionalData: { eventSource?: RecordingSource } = {},
): MixpanelEventParams<'Video Recording Starting'> {
  const videoSettings = action.payload.streamSettings.settings?.video;
  return [
    'Video Recording Starting',
    {
      [mpProps.eventSource]: additionalData.eventSource,
      [mpProps.bitrate]: videoSettings?.max_kbps,
      [mpProps.encoder]: videoSettings?.encoder?.name,
      [mpProps.resolution]: `${videoSettings?.width}x${videoSettings?.height}`,
      [mpProps.framerate]: videoSettings?.fps,
    },
  ];
}

function screenStarting(
  action: ReturnType<typeof screenRecorderStarting>,
  additionalData: { eventSource?: RecordingSource } = {},
): MixpanelEventParams<'Screen Recording Starting'> {
  const videoSettings = action.payload.streamSettings.settings?.video;
  return [
    'Screen Recording Starting',
    {
      [mpProps.eventSource]: additionalData.eventSource,
      [mpProps.bitrate]: videoSettings?.max_kbps,
      [mpProps.encoder]: videoSettings?.encoder?.name,
      [mpProps.resolution]: `${videoSettings?.width}x${videoSettings?.height}`,
      [mpProps.framerate]: videoSettings?.fps,
    },
  ];
}

interface VideoRecordedAdditionalData {
  eventSource?: RecordingSource;
  bookmarksCreated: number;
  commentLabelsCreated: number;
  notepadUsed: boolean;
  inputOverlayEnabled: boolean;
  webcamEnabled?: boolean;
  processCaptureEnabled?: boolean;
}

function recorded(
  action: ReturnType<typeof videoSaved>,
  streamSettings?: overwolf.streaming.StreamParams,
  additionalData: VideoRecordedAdditionalData = { bookmarksCreated: 0, commentLabelsCreated: 0, notepadUsed: false, inputOverlayEnabled: false, webcamEnabled: false, processCaptureEnabled: false },
): MixpanelEventParams<'Video Recorded'> {
  const video = action.payload;
  const videoSettings = streamSettings?.video;
  const audioSettings = streamSettings?.audio;
  return [
    'Video Recorded',
    {
      [mpProps.eventSource]: additionalData.eventSource,
      [mpProps.recordingType]: action.meta?.recordingType,
      [mpProps.contentDuration]: millisecondsToSeconds(video.result.duration),
      [mpProps.gameClassId]: video.gameClassId,
      [mpProps.queueId]: isLeagueTFTVideo(video) ? video.queueId : undefined,
      [mpProps.bitrate]: videoSettings?.max_kbps,
      [mpProps.encoder]: videoSettings?.encoder?.name,
      [mpProps.resolution]: `${videoSettings?.width}x${videoSettings?.height}`,
      [mpProps.framerate]: videoSettings?.fps,
      [mpProps.bookmarksCreated]: additionalData.bookmarksCreated,
      [mpProps.commentLabelsCreated]: additionalData.commentLabelsCreated,
      [mpProps.notepadUsed]: additionalData.notepadUsed,
      [mpProps.inputOverlayEnabled]: additionalData.inputOverlayEnabled,
      [mpProps.webcamEnabled]: additionalData.webcamEnabled,
      [mpProps.audioProcessNames]: audioSettings?.process_audio_capture?.map((p) => p.process_name),
      [mpProps.processCaptureEnabled]: additionalData.processCaptureEnabled,
    },
  ];
}

function replay(
  action: ReturnType<typeof replaySaved>,
  streamSettings?: overwolf.streaming.StreamParams,
): MixpanelEventParams<'Instant Replay Created'> {
  const video = action.payload;
  const meta = action.meta;
  const videoSettings = streamSettings?.video;
  return [
    'Instant Replay Created',
    {
      [mpProps.contentDuration]: millisecondsToSeconds(video.result.duration),
      [mpProps.gameClassId]: video.gameClassId,
      [mpProps.queueId]: isLeagueTFTVideo(video) ? video.queueId : undefined,
      [mpProps.encoder]: videoSettings?.encoder?.name,
      [mpProps.bitrate]: videoSettings?.max_kbps,
      [mpProps.resolution]: `${videoSettings?.width}x${videoSettings?.height}`,
      [mpProps.framerate]: videoSettings?.fps,
      [mpProps.recordingType]: meta?.recordingType,
      [mpProps.webcamEnabled]: meta?.webcamEnabled,
    },
  ];
}

function recycled(
  action: ReturnType<typeof videoRecycled>,
): MixpanelEventParams<'Video Recycled'> {
  return [
    'Video Recycled',
    {
      [mpProps.eventSource]: action.meta?.recycleSource,
      [mpProps.videoCount]: action.payload.videoUuids.length,
    },
  ];
}

function deleted(
  action: ReturnType<typeof deleteVideosAC.done>,
): MixpanelEventParams<'Video Deleted'> {
  return [
    'Video Deleted',
    {
      [mpProps.eventSource]: action.meta?.deleteSource,
      [mpProps.videoCount]: action.payload.params.length,
    },
  ];
}

function favorited(
  action: ReturnType<typeof videoFavorited>,
): MixpanelEventParams<'Video Favorited'> {
  return [
    'Video Favorited',
    {
      [mpProps.eventSource]: action.meta?.favoriteSource,
      [mpProps.videoCount]: action.payload.videoUuids.length,
    },
  ];
}

function unfavorited(
  action: ReturnType<typeof videoUnfavorited>,
): MixpanelEventParams<'Video Unfavorited'> {
  return [
    'Video Unfavorited',
    {
      [mpProps.eventSource]: action.meta?.favoriteSource,
      [mpProps.videoCount]: action.payload.videoUuids.length,
    },
  ];
}

function renamed(
  action: ReturnType<typeof videoRenamed>,
): MixpanelEventParams<'Video Renamed'> {
  return [
    'Video Renamed',
    {
      [mpProps.eventSource]: action.meta?.renameSource,
    },
  ];
}

function moved_single(
  action: ReturnType<typeof videoMoved>,
): MixpanelEventParams<'Video Moved'> {
  return [
    'Video Moved',
    {
      [mpProps.videoCount]: 1,
    },
  ];
}

function moved(
  action: ReturnType<typeof videosMoved>,
): MixpanelEventParams<'Video Moved'> {
  return [
    'Video Moved',
    {
      [mpProps.videoCount]: action.payload.videoUuids.length,
    },
  ];
}

export function convertVideoType(type: Video['type']): VideoType | undefined {
  switch (type) {
    case undefined: return 'Standard';
    case 'InstantReplay': return 'Instant Replay';
    case 'MontageClip': return 'Highlight';
    case 'ImportVideo': return 'Imported';
    case 'MergedVideo': return 'Merged';
    case 'VideoClip': return 'Clip';
    case 'DemoVideo': return 'Demo';
  }
}

function getVideoType(video: Video): VideoType | undefined {
  return convertVideoType(video.type);
}

function uploaded(
  action: ReturnType<typeof videoUploadActions.uploadVideoAC.done>,
): MixpanelEventParams<'Video Uploaded'> {
  return [
    'Video Uploaded',
    {
      [mpProps.eventSource]: action.payload.params.uploadSource as UploadSource,
      [mpProps.videoType]: getVideoType(action.payload.params.video),
      [mpProps.filesize]: action.payload.params.video.size,
    },
  ];
}

function formatDestination(dest: QuickShareDestination) {
  switch (dest) {
    case 'gfycat': return 'Gfycat';
    case 'youtube': return 'YouTube';
    case 'discord': return 'Discord';
    case 'public': return 'Public';
    case 'tiktok': return 'TikTok';
  }
}

function formatUploadOption(option: TikTokUploadOptions) {
  switch (option) {
    case 'direct': return 'Direct';
    case 'draft': return 'Draft';
  }
}

function socialShared(
  action: ReturnType<typeof quickShareUploadAC.done>,
  uploadSource: UploadSource,
): MixpanelEventParams<'Video Social Shared'> {
  const { destination, endTime, startTime } = action.payload.params;
  return [
    'Video Social Shared',
    {
      [mpProps.eventSource]: uploadSource,
      [mpProps.contentDuration]: endTime - startTime,
      [mpProps.filesize]: action.payload.result.size,
      [mpProps.shareDestination]: formatDestination(destination.type),
      [mpProps.clipRangeModified]: !!action.meta?.customRange,
      [mpProps.uploadOption]: destination.type === 'tiktok' ? formatUploadOption(destination.uploadOption) : undefined,
    },
  ];
}

interface WatchStartedParams {
  type: Video['type'];
  duration: Video['result']['duration'];
  gameClassId?: GameClassId;
}

function watchStarted(params: WatchStartedParams): MixpanelEventParams<'Watch Video Started'> {
  return [
    'Watch Video Started',
    {
      [mpProps.contentDuration]: millisecondsToSeconds(params.duration),
      [mpProps.videoType]: convertVideoType(params.type),
      [mpProps.gameClassId]: params.gameClassId,
    },
  ];
}

type WatchEndedParams = WatchStartedParams;

function watchEnded(
  params: WatchEndedParams,
): MixpanelEventParams<'Watch Video Ended'> {
  return [
    'Watch Video Ended',
    {
      [mpProps.contentDuration]: millisecondsToSeconds(params.duration),
      [mpProps.videoType]: convertVideoType(params.type),
      [mpProps.gameClassId]: params.gameClassId,
    },
  ];
}

function merged(
  action: ReturnType<typeof createMergedVideoAC.done>,
): MixpanelEventParams<'Video Merged'> {
  return [
    'Video Merged',
    {
      [mpProps.videoCount]: action.payload.params.videos.length,
    },
  ];
}

function fullscreened(): MixpanelEventParams<'Video Fullscreened'> {
  return [
    'Video Fullscreened',
    {},
  ];
}

function exported(params: {
  videoCount: number;
  folderCount: number;
}): MixpanelEventParams<'Video Exported'> {
  return [
    'Video Exported',
    {
      [mpProps.videoCount]: params.videoCount,
      [mpProps.folderCount]: params.folderCount,
    },
  ];
}

function commentCreated(bookmarkAttached: boolean): MixpanelEventParams<'Comment Created'> {
  return [
    'Comment Created',
    {
      [mpProps.bookmarkAttached]: bookmarkAttached,
    },
  ];
}

function clipCreated(segmentCount: number, clipCombined: boolean): MixpanelEventParams<'Clip Created'> {
  return [
    'Clip Created',
    {
      [mpProps.clipCombined]: clipCombined,
      [mpProps.segmentCount]: segmentCount,
    },
  ];
}

interface HighlightCreatedParams {
  montageSource: MontageSource;
  contentDuration: number;
  gameClassId: number;
  deleteOriginal: boolean;
}

function highlightCreated({
  montageSource,
  contentDuration,
  gameClassId,
  deleteOriginal,
}: HighlightCreatedParams): MixpanelEventParams<'Highlight Created'> {
  return [
    'Highlight Created',
    {
      [mpProps.eventSource]: montageSource,
      [mpProps.contentDuration]: contentDuration,
      [mpProps.gameClassId]: gameClassId,
      [mpProps.deleteOriginal]: deleteOriginal,
    },
  ];
}

function bookmarkClicked(
  bookmarkType: 'Game Event' | 'User Bookmark',
): MixpanelEventParams<'Bookmark Clicked'> {
  return [
    'Bookmark Clicked',
    {
      [mpProps.bookmarkType]: bookmarkType,
    },
  ];
}

function linkCopied(
  eventSource: LinkCopiedSource,
  contentDuration?: number,
  gameClassId?: GameClassId,
): MixpanelEventParams<'Video Link Copied'> {
  return [
    'Video Link Copied',
    {
      [mpProps.eventSource]: eventSource,
      [mpProps.contentDuration]: contentDuration,
      [mpProps.gameClassId]: gameClassId,
    },
  ];
}

function rightPanelTabChanged(
  eventSource: TabChangedSource,
  tabName: string,
  gameClassId?: GameClassId,
): MixpanelEventParams<'Video Replay Tab Changed'> {
  return [
    'Video Replay Tab Changed',
    {
      [mpProps.eventSource]: eventSource,
      [mpProps.tabName]: tabName,
      [mpProps.gameClassId]: gameClassId,
    },
  ];
}

function removeAudioSaved(
  eventSource: RemoveAudioSource,
): MixpanelEventParams<'Video Remove Audio Saved'> {
  return [
    'Video Remove Audio Saved',
    {
      [mpProps.eventSource]: eventSource,
    },
  ];
}

export const videoHelpers = {
  deleted,
  exported,
  favorited,
  fullscreened,
  merged,
  moved_single,
  moved,
  recorded,
  recycled,
  renamed,
  replay,
  socialShared,
  starting,
  screenStarting,
  unfavorited,
  uploaded,
  watchStarted,
  watchEnded,
  commentCreated,
  clipCreated,
  highlightCreated,
  bookmarkClicked,
  linkCopied,
  rightPanelTabChanged,
  removeAudioSaved,
};
