import { Dialog, DialogContentText } from '@mui/material';
import { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import AlertDialogContent, { AlertDialogProps } from '@/components/AlertDialogContent';

interface AbortUploadDialogOwnProps {
  className?: string;
  videoName: string;
  onAbort?: VoidFunction;
}

type AbortUploadDialogProps = AbortUploadDialogOwnProps & Pick<AlertDialogProps, 'open' | 'onClose'>;

function AbortUploadDialog(props: AbortUploadDialogProps) {
  const { open, onClose } = props;
  return (
    <Dialog open={open} onClose={onClose}>
      <AbortUploadDialogContent {...props} />
    </Dialog>
  );
}

function AbortUploadDialogContent(props: AbortUploadDialogProps) {
  const { videoName, onAbort, ...rest } = props;
  const { t } = useTranslation(['features']);

  return (
    <AlertDialogContent
    titleText={t('features:uploads.abort.title')}
    description={(
      <DialogContentText>
        <Trans t={t} i18nKey='features:uploads.abort.description'>
          <strong>{{ videoName }}</strong>
        </Trans>
      </DialogContentText>
    )}
    cancel={{
      children: t('features:uploads.abort.cancel'),
      onClick: props.onClose,
    }}
    confirm={{
      children: t('features:uploads.abort.confirm'),
      color: 'negative',
      onClick: onAbort,
    }}
    {...rest}
    />
  );
}

export default memo(AbortUploadDialog);
