import { Theme } from '@insights-gaming/theme';
import { Stack, SxProps, Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { TeamEmojiInjector } from '@/features/squad/TeamEmojiContext';
import { CreateTeamResult } from '@/features/squad/useCreateTeamMutation';
import { typedAssetPath } from '@/utils';

import { DialogSlideLayout, SlideDialogLayoutCommonProps } from './DialogSlideLayout';
import { ReactionCustomizer } from './ReactionCustomizer';

interface CustomizeReactionSlideProps {
  className?: string;
  sx?: SxProps<Theme>;
  createdSquad: CreateTeamResult;
  onDone?: VoidFunction;
}

export function CustomizeReactionSlide(props: CustomizeReactionSlideProps & SlideDialogLayoutCommonProps) {
  const { className, sx, createdSquad, onClose, onDone, variant } = props;

  const { t } = useTranslation(['squad_onboarding']);
  const actionsPortalRef = useRef<HTMLDivElement>(null);

  return (
    <TeamEmojiInjector teamId={createdSquad.id}>
      <DialogSlideLayout
      variant={variant}
      onClose={onClose}
      className={className}
      sx={sx}
      graphic={(
        <img src={typedAssetPath('/assets/squad/custom-reactions.svg')} alt='customize reactions' />
      )}
      actions={(
        <Stack ref={actionsPortalRef} flex={1} direction='row' justifyContent='space-between' />
      )}
      >
        <Stack>
          <Stack spacing={1}>
            <Stack direction='row' spacing={1}>
              <AvatarWithFallback src={createdSquad.picture} alt={createdSquad.name} size={64} />
              <Typography  variant='h5'>
                {createdSquad.name}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant='h5'>
                {t('squad_onboarding:reactions.customizeHeader')}
              </Typography>
              <Typography variant='caption'>
                {t('squad_onboarding:reactions.customizeCaption')}
              </Typography>
            </Stack>
          </Stack>
          <ReactionCustomizer actionsPortal={actionsPortalRef.current} teamId={createdSquad.id} onDone={onDone} />
        </Stack>
      </DialogSlideLayout>
    </TeamEmojiInjector>
  );
}
