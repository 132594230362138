import { AsyncButton, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogProps, FormControlLabel, Stack, Typography } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import SquadRouting from '@/features/squad/squad.routing';
import { useUpdateVideo2Mutation } from '@/features/squad/TeamDirectory/useUpdateVideo2Mutation';
import { TeamShareVideoDialog_VideoFragmentFragment, VideoPublicity } from '@/graphql/__generated__/graphql';

interface PrivacySettingsDialogProps {
  onClose?: VoidFunction;
  video: TeamShareVideoDialog_VideoFragmentFragment;
}

type PrivacySettingsDialogOwnProps = PrivacySettingsDialogProps & Pick<DialogProps, 'open'>;

export function PrivacySettingsDialog(props: PrivacySettingsDialogOwnProps) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth={true}>
      <PrivacySettingsDialogContent {...props} />
    </Dialog>
  );
}

function PrivacySettingsDialogContent(props: PrivacySettingsDialogOwnProps) {
  const { onClose, video } = props;
  const { teamId } = useParams<SquadRouting.TeamParams>();
  const { t } = useTranslation(['common', 'squad_videos']);

  const [isViewOnly, setIsViewOnly] = useState(video.publicity === VideoPublicity.ViewOnly);
  const [openComments, setOpenComments] = useState(video.publicity === VideoPublicity.OpenComments);

  const settingsUnchanged = useMemo(
    () => (isViewOnly === (video.publicity !== VideoPublicity.Private)) && (openComments === (video.publicity === VideoPublicity.OpenComments)),
    [isViewOnly, openComments, video.publicity],
  );

  const [updateVideo, { loading: updating }] = useUpdateVideo2Mutation();

  const handleSaveOnClick = useCallback(async () => {
    updateVideo({
      variables: {
        input: {
          teamId,
          id: video.id,
          publicity: openComments ? VideoPublicity.OpenComments : isViewOnly ? VideoPublicity.ViewOnly : VideoPublicity.Private,
        },
      },
    });
  }, [isViewOnly, openComments, teamId, updateVideo, video.id]);

  const handleIsPublicCheckboxOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setIsViewOnly(checked);
    if (!checked) {
      setOpenComments(false);
    }
  }, []);

  return (
    <Fragment>
      <EnhancedDialogTitle>{t('squad_videos:share.changePrivacySettings')}</EnhancedDialogTitle>
      <DialogContent>
        <Fragment>
          <FormControlLabel
          control={(
            <Checkbox
            name='isPublic'
            checked={isViewOnly}
            onChange={handleIsPublicCheckboxOnChange}
            />
          )}
          label={(
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography>{t('squad_videos:share.public')}</Typography>
              <Typography variant='caption'>{t('squad_videos:share.disclaimer')}</Typography>
            </Stack>
          )}
          />
        </Fragment>
      </DialogContent>
      <DialogActions>
        <Fragment>
          <Button
          onClick={onClose}
          variant='outlined'
          >
            {t('common:cancel')}
          </Button>
          <AsyncButton
          variant='contained'
          color='primary'
          disabled={updating || settingsUnchanged}
          loading={updating}
          onClick={handleSaveOnClick}
          >
            {t('common:save')}
          </AsyncButton>
        </Fragment>
      </DialogActions>
    </Fragment>
  );
}
