import { EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { Button, Dialog, DialogActions, DialogContent, DialogProps, Stack, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from '@/hooks/useNavigate';

interface ClaimInvitationErrorDialogOwnProps {
  sx?: SxProps<Theme>;
  onClose?: VoidFunction;
}

type ClaimInvitationErrorDialogProps = ClaimInvitationErrorDialogOwnProps & Pick<DialogProps, 'open'>;

function ClaimInvitationErrorDialog(props: ClaimInvitationErrorDialogProps) {
  const { sx, open } = props;
  return (
    <Dialog sx={sx} open={open} maxWidth='xs' fullWidth={true}>
      <ClaimInvitationErrorDialogContent {...props} />
    </Dialog>
  );
}

function ClaimInvitationErrorDialogContent(props: ClaimInvitationErrorDialogProps) {
  const { onClose } = props;

  const { t } = useTranslation(['squad_common']);
  const wt = t as WTFunction;
  const onNavigate = useNavigate();

  return (
    <Fragment>
      <EnhancedDialogTitle>
        {t('squad_common:invitation.invalid.notFound')}
      </EnhancedDialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant='h6'>
            {t('squad_common:invitation.invalid.reasons.title')}
          </Typography>
          <Typography component='div'>
            <ul>
              {Object.values(wt('squad_common:invitation.invalid.reasons.list', { returnObjects: true }) as unknown as string[]).map((text, i) => (
                <li key={i}>
                  {text}
                </li>
              ))}
            </ul>
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
        variant='contained'
        color='primary'
        onClick={() => {
          // TODO: Handle rerouting to home
          onClose?.();
          onNavigate('/');
        }}
        >
          {t('squad_common:invitation.invalid.backToHome')}
        </Button>
      </DialogActions>
    </Fragment>
  );
}

const StyledClaimInvitationErrorDialog = styled(ClaimInvitationErrorDialog, { name: 'ClaimInvitationErrorDialog' })({});

export default memo(StyledClaimInvitationErrorDialog);
