import { VideoReplayContext } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { Theme } from '@insights-gaming/theme';
import { SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, memo, Ref, useContext, useEffect, useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import { makeGetKeybindings } from '@/features/settings/setting-selector';
import { useVideoReplayKeybindings } from '@/features/video-replay/useVideoReplayKeybindings';
import VideoReplayFullScreenController from '@/features/video-replay/video-controls/VideoReplayFullScreenController';
import VideoReplayPlayPauseController from '@/features/video-replay/video-controls/VideoReplayPlayPauseController';
import VideoReplayTheaterModeController from '@/features/video-replay/video-controls/VideoReplayTheaterModeController';
import VideoReplayVolumeController from '@/features/video-replay/video-controls/VideoReplayVolumeController';
import VideoControls, { ExtendedVideoControlsProps } from '@/features/video-replay/VideoControls';
import { VideoSettingsMenuProps } from '@/features/video-replay/VideoSettingsMenu';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { useSeekbar } from '@/hooks/useSeekbar';

import TeamVideoSeekbar from './TeamVideoSeekbar';

const TeamVideoPlayerControls_VideoBookmarkFragment = gql(`
  fragment TeamVideoPlayerControls_VideoBookmarkFragment on VideoBookmark {
    id
    created
    bookmark {
      event
      ... on GameBookmark {
        data
        gameId
      }
    }
    time
  }
`);

interface TeamVideoPlayerControlsOwnProps {
  sx?: SxProps<Theme>;
  className?: string;

  bookmarks: FragmentType<typeof TeamVideoPlayerControls_VideoBookmarkFragment>[];
  timeRanges?: TimeRanges;
}

type TeamVideoPlayerControlsProps = TeamVideoPlayerControlsOwnProps
  & Omit<ExtendedVideoControlsProps, 'bookmarks'>
  & Pick<VideoSettingsMenuProps, 'useInvisibleAnchor'>;

const resizeDetectorOptions = {
  handleHeight: false,
};

const TeamVideoPlayerControls = forwardRef(function TeamVideoPlayerControls(props: TeamVideoPlayerControlsProps, ref: Ref<HTMLDivElement>) {
  const {
    seekNextBookmark,
    seekPreviousBookmark,
    className,
    forceHiddenControls,
    isEndOfVideo,
    showVideoControls,
    timeRanges,
    variant,
    // useInvisibleAnchor,
  } = props;

  // const fullscreenEl = useContext(FullscreenContext);

  const {
    state: { duration, progress },
  } = useContext(VideoReplayContext);

  const seekbarProps = useSeekbar();

  const { width, ref: resizeRef } = useResizeDetector(resizeDetectorOptions);

  const playedSecondsRef = useRef(progress.playedSeconds);
  useEffect(() => {
    playedSecondsRef.current = progress.playedSeconds;
  }, [progress.playedSeconds]);

  const bookmarks = getFragmentData(TeamVideoPlayerControls_VideoBookmarkFragment, props.bookmarks);
  const filteredBookmarks = bookmarks.filter(bookmark => bookmark.bookmark.__typename === 'GameBookmark');
  const videoBookmarks = filteredBookmarks.map(bookmark => ({
    timestamp: bookmark.time,
  }));

  const keybindingTables = useCreateSelector(makeGetKeybindings, { keybindingContext: ['video.replay'] });

  const keybindingsRef = useRef(keybindingTables);
  useEffect(() => {
    keybindingsRef.current = keybindingTables;
  }, [keybindingTables]);

  useVideoReplayKeybindings(['video.replay'], {
    'video.seek.backward.bookmark': () => seekPreviousBookmark?.(videoBookmarks, playedSecondsRef.current),
    'video.seek.forward.bookmark': () => seekNextBookmark?.(videoBookmarks, playedSecondsRef.current),
  }, false);

  return (
    <KeybindReverseLookupContext.Provider value={keybindingTables.reverseLookupTable}>
      <VideoControls
      ref={ref}
      className={className}
      duration={duration}
      bookmarks={videoBookmarks}
      playedSeconds={progress.playedSeconds}
      FullScreenController={<VideoReplayFullScreenController />}
      seekNextBookmark={seekNextBookmark}
      seekPreviousBookmark={seekPreviousBookmark}
      Seekbar={(
        <TeamVideoSeekbar
        ref={resizeRef}
        filteredBookmarks={filteredBookmarks}
        width={width}
        seekbarProps={seekbarProps}
        timeRanges={timeRanges}
        />
      )}
      TheaterModeController={<VideoReplayTheaterModeController />}
      // VideoReplaySettingsController={<VideoSettingsMenu container={fullscreenEl} useInvisibleAnchor={useInvisibleAnchor} />}
      VideoVolumeController={<VideoReplayVolumeController />}
      VideoReplayPlayPauseController={<VideoReplayPlayPauseController endOfVideo={isEndOfVideo} />}
      variant={variant}
      showVideoControls={showVideoControls}
      forceHiddenControls={forceHiddenControls}
      />
    </KeybindReverseLookupContext.Provider>
  );
});

const StyledTeamVideoPlayerControls = styled(TeamVideoPlayerControls, { name: 'TeamVideoPlayerControls' })({});

export default memo(StyledTeamVideoPlayerControls);
