import { debounce } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';

export function useHandleClickActions(onClick: VoidFunction, onDoubleClick?: VoidFunction) {
  const clickRef = useRef(0);
  const debouncedClick = useMemo(() => {
    return debounce(
      () => {
        if (clickRef.current % 2) {
          onClick();
        }
        clickRef.current = 0;
      },
      250,
      { leading: false, trailing: true },
    );
  }, [onClick]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    clickRef.current++;
    debouncedClick();
    if (clickRef.current % 2 === 0) {
      // double clicking a video card fullscreens the video, but doesn't affect playstate
      onDoubleClick?.();
      debouncedClick.cancel();
      clickRef.current = 0;
    }
  }, [debouncedClick, onDoubleClick]);

  return handleClick;
}
