import { VideoReplayContext } from '@insights-gaming/material-components';
import { buildKeystring, useDidUpdate } from '@insights-gaming/utils';
import { useContext, useEffect } from 'react';
import screenfull from 'screenfull';

import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

export function useFullscreen() {
  const {
    state: { _fullscreenRequested, theaterMode },
    detectMouseInactivity,
    exitFullscreen,
    exitTheaterMode,
    setFullscreenEntered,
    setFullscreenExited,
    setMouseInactive,
  } = useContext(VideoReplayContext);

  const fullscreenEl = useContext(FullscreenContext);

  useEffect(() => { // theater mode
    const escListener = (e: KeyboardEvent) => {
      const keystring = buildKeystring(e);
      if (keystring === 'escape') {
        exitTheaterMode();
      }
    };
    if (theaterMode) {
      document.addEventListener('keydown', escListener);
    }
    return () => {
      document.removeEventListener('keydown', escListener);
    };
  }, [exitTheaterMode, theaterMode]);

  useEffect(() => { // fullscreen related things
    const escListener = (e: KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      const keystring = buildKeystring(e);
      if (keystring === 'escape') {
        exitFullscreen();
      }
    };
    const addListeners = () => {
      document.addEventListener('click', detectMouseInactivity);
      document.addEventListener('mousedown', detectMouseInactivity);
      document.addEventListener('mousemove', detectMouseInactivity);
      document.addEventListener('keydown', escListener);
      document.addEventListener('mouseleave', setMouseInactive);
    };
    const removeListeners = () => {
      document.removeEventListener('click', detectMouseInactivity);
      document.removeEventListener('mousedown', detectMouseInactivity);
      document.removeEventListener('mousemove', detectMouseInactivity);
      document.removeEventListener('keydown', escListener);
      document.removeEventListener('mouseleave', setMouseInactive);
    };
    const fullscreenListener = () => {
      if (screenfull.isEnabled) {
        if (screenfull.isFullscreen) {
          setFullscreenEntered();
          addListeners();
        } else {
          setFullscreenExited();
          removeListeners();
        }
      }
    };
    if (screenfull.isEnabled) {
      screenfull.on('change', fullscreenListener);
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', fullscreenListener);
      }
      removeListeners();
    };
  }, [detectMouseInactivity, exitFullscreen, setFullscreenEntered, setFullscreenExited, setMouseInactive]);

  useDidUpdate(_fullscreenRequested, (curr, prev) => {
    if (prev === undefined) {
      return;
    }
    if (screenfull.isEnabled) {
      if (curr) {
        screenfull.request(fullscreenEl || undefined);
      } else {
        screenfull.exit();
      }
    }
  });
}
