import { unflatten } from 'flat';
import { AnyAction } from 'typescript-fsa';

import { settingPath, SettingsState } from '@/features/settings/setting-slice';
import { createDeferredPromise } from '@/utils/DeferedPromise';
import { endpoints } from '@/utils/insightsgg/common';
import customUpdate from '@/utils/update';

const blacklisted = [
  settingPath.videoUpload.defaultDest,
  settingPath.videoUpload.autoUpload.destination,
].map(p => p.$path);

export class Telemetry {
  private readonly endpoint = endpoints.telemetry;
  private readonly machineId: Promise<string>;
  private readonly currentVersion: Promise<string>;
  private readonly resolveMachineId: (value: string | PromiseLike<string>) => void;
  private readonly resolveCurrentVersion: (value: string | PromiseLike<string>) => void;

  constructor() {
    ({ promise: this.machineId, resolve: this.resolveMachineId } = createDeferredPromise());
    ({ promise: this.currentVersion, resolve: this.resolveCurrentVersion } = createDeferredPromise());
  }

  public async getMachineId() {
    return await this.machineId;
  }

  public setMachineId(machineId: string) {
    this.resolveMachineId(machineId);
  }

  public setCurrentVersion(currentVersion: string) {
    this.resolveCurrentVersion(currentVersion);
  }

  public async sendAction<A extends AnyAction>(action: A) {
    const formData = await this.makeFormData('action', action);
    this.sendTelemetry(formData);
  }

  public async sendGA(event: EventArgs) {
    const formData = await this.makeFormData('ga', event);
    this.sendTelemetry(formData);
  }

  public async sendSettings(settingResult: Dictionary<{}>) {
    let settings: Partial<SettingsState> = unflatten(settingResult);
    settings = customUpdate(settings, { $deepunset: blacklisted }); // first pass: remove blacklisted from global settings
    settings = Object.fromEntries( // second pass: remove blacklisted from per game settings
      Object.entries(settings)
        .map(([key, value]) => {
          if (!isNaN(Number.parseInt(key))) { // per game
            return [key, customUpdate(value, { $deepunset: blacklisted })];
          }
          return [key, value];
        })
        .filter(([, value]) => Object.keys(value || {}).length > 0),
    );

    const formData = await this.makeFormData('settings', settings);
    this.sendTelemetry(formData);
  }

  public async sendWindowOpen(windowName: WindowName, width: number, height: number) {
    this.sendWindowEvent({
      type: 'open',
      windowName,
      width,
      height,
    });
  }

  public async sendWindowResize(windowName: WindowName, width: number, height: number) {
    this.sendWindowEvent({
      type: 'resize',
      windowName,
      width,
      height,
    });
  }

  public async sendWindowEvent<D extends { type: string; windowName: WindowName }>(data: D) {
    const formData = await this.makeFormData('window', data);
    this.sendTelemetry(formData);
  }

  /**
   *
   * @param gameClassId
   * @param queueId Number from the League Launcher. Only sending for TFT games to differentiate LoL and TFT since they share the same gameId.
   */
  public async sendGameLaunch(gameClassId: number, queueId?: number) {
    const formData = await this.makeFormData('gameLaunch', { gameClassId, queueId });
    this.sendTelemetry(formData);
  }

  public async makeFormData<D extends {}>(type: string, data: D) {
    const machineId = await this.machineId;
    const currentVersion = await this.currentVersion;

    const formData = new FormData();
    formData.set('user_id', machineId);
    formData.set('type', type);
    formData.set('version', currentVersion);
    formData.set('app', 'Insights Capture');
    formData.set('data', JSON.stringify(data));
    return formData;
  }

  public sendTelemetry(formData: FormData) {
    if (process.env.NODE_ENV === 'development') {
      const obj = Object.fromEntries(Array.from(formData.entries()));
      console.log('telemetry', obj.type, obj);
    }

    if (process.env.NODE_ENV === 'production') {
      fetch(this.endpoint, {
        method: 'POST',
        body: formData,
      }).catch(console.error);
    }
  }
}
