import { gql } from '@apollo/client';

export const GetUserFeatures_Query = gql`
  query GetUserFeaturesQuery {
    me {
      __typename
      features
    }
  }
`;
