import Picker, { CustomEmoji, EmojiSelectHandler, SelectedEmoji } from '@emoji-mart/react';
import { Theme } from '@insights-gaming/theme';
import { Box, Button, decomposeColor, SxProps, useTheme } from '@mui/material';
import { ContextType } from 'react';
import { useTranslation } from 'react-i18next';

import SquadRouting from '@/features/squad/squad.routing';
import { TeamEmojiContext } from '@/features/squad/TeamEmojiContext';
import { useAccessControl } from '@/features/squad/useAccessControl';
import { withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';
import { useNavigate } from '@/hooks/useNavigate';

import { useTeamEmojis } from './useTeamEmojis';

interface TeamEmojiPickerProps {
  className?: string;
  sx?: SxProps<Theme>;

  teamId: ID;
  onEmojiSelect?: EmojiSelectHandler;
}

function _TeamEmojiPicker(props: TeamEmojiPickerProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamEmojiContext>>) {
  const { onEmojiSelect, queryRef, teamId } = props;

  const onNavigate = useNavigate();
  const { data, custom } = useTeamEmojis(teamId, queryRef);

  const { t } = useTranslation(['squad_videos']);

  const paletteMode = useTheme().palette.mode;

  const { canCreateEmoji } = useAccessControl(teamId);

  return (
    <Box
    sx={{
      'em-emoji-picker': {
        '--rgb-accent': theme => hexToRgbComponents(theme.palette.primary.main),
        '--rgb-background': theme => hexToRgbComponents(theme.palette.background.paper),
      },
    }}
    >
      {canCreateEmoji && (
        <Button
        onClick={() => { onNavigate(SquadRouting.createUrl.teamSettingsAddReaction(teamId)); }}
        sx={{ ml: 1 }}
        >
          {t('squad_videos:reactions.addCustomReaction')}
        </Button>
      )}
      <Picker data={data} custom={custom} theme={paletteMode} autoFocus={true} onEmojiSelect={onEmojiSelect} />
    </Box>
  );
}

export const TeamEmojiPicker = withBackgroundQuery(TeamEmojiContext, _TeamEmojiPicker);

export function isCustomEmoji(emoji: SelectedEmoji): emoji is CustomEmoji {
  return 'src' in emoji;
}

function hexToRgbComponents(color: string) {
  return decomposeColor(color).values.join(',');
}
