import { VideoMetadata } from '@insights-gaming/upload-queue';
import { LocalVideoLike, VideoUploadOptions, VideoUploadState } from '@insights-gaming/video-upload-slice';
import { createSelector } from '@reduxjs/toolkit';

import SquadRouting from '@/features/squad/squad.routing';

interface Overrides<Root, LV extends LocalVideoLike, AdditionalUploadItem extends VideoUploadOptions<LV>> {
  additionalItemsSelector?: (state: Root) => AdditionalUploadItem[];
  additionalCountsSelector?: (state: Root) => {
    pendingCount: number;
    uploadedCount: number;
    failedCount: number;
  };
}

export function makeVideoUploadSelectors<Root, LV extends LocalVideoLike, AdditionalUploadItem extends VideoUploadOptions<LV>>(
  selector: (state: Root) => VideoUploadState<LV>,
  getMetadata: (options: VideoUploadOptions<LV>) => VideoMetadata,
  {
    additionalCountsSelector,
    additionalItemsSelector,
  }: Overrides<Root, LV, AdditionalUploadItem> = {},
) {
  const _getUploadQueue = (state: Root) => selector(state).uploadQueue;
  const _getCurrentUpload = (state: Root) => selector(state).currentUpload;
  const _getStartedUploads = (state: Root) => selector(state).startedUploads;
  const _getUploadedVideos = (state: Root) => selector(state).uploadedVideos;
  const _getUploadedVideos2 = (state: Root) => selector(state).uploadedVideos2;
  const _getFailedUploads = (state: Root) => selector(state).failedUploads;

  const getUploadedVideosTemp2 = (state: Root) => selector(state).uploadedVideosTemp2;
  const getFailedUploads2 = (state: Root) => selector(state).failedUploads2;

  const getCurrentUpload = createSelector(
    [_getCurrentUpload, _getStartedUploads],
    (currentUpload, startedUploads) => {
      const startedUpload = currentUpload?.resumeInfo?.videoId ? startedUploads[currentUpload.resumeInfo.videoId] : undefined;
      return currentUpload ? { upload: currentUpload, videoMetadata: getMetadata(currentUpload), startedUpload } : undefined;
    },
  );

  // const makeGetAdditionalItems = () => {
  //   if (!additionalItemsSelector) {
  //     return () => [];
  //   }
  //   return createSelector(
  //     [additionalItemsSelector],
  //     (additionalItems) => additionalItems,
  //   );
  // };

  function filterUpload(upload: VideoUploadOptions<LV>): boolean {
    return upload.uploadSource !== 'Discord' && upload.uploadSource !== 'Public';
  }

  const getUploadQueue = createSelector(
    [_getUploadQueue, _getStartedUploads],
    (uploadQueue, startedUploads) => uploadQueue.filter(filterUpload).map((upload) => {
      const videoId = upload.resumeInfo?.videoId;
      const startedUpload = videoId ? startedUploads[videoId] : undefined;
      return ({ upload, videoMetadata: getMetadata(upload), startedUpload });
    }),
  );

  const getIsQueueEmpty = createSelector(
    [getUploadQueue],
    (uploadQueue) => uploadQueue.length === 0,
  );

  const makeGetIsAnySelectionEnqueued = () => createSelector(
    [getCurrentUpload, getUploadQueue, (_: unknown, props: { videoUuids: UUID[] }) => props.videoUuids],
    (currentUpload, uploadQueue, videoUuids) => {
      return videoUuids.some((videoUuid) => {
        const isCurrent = videoUuid === currentUpload?.upload.video.uuid;
        return isCurrent || uploadQueue.some(({ upload }) => upload.video.uuid === videoUuid);
      });
    },
  );

  const _getSumOfQueuedFileSizes = createSelector(
    [getUploadQueue],
    (uploadQueue) => uploadQueue.reduce((sum, { videoMetadata }) => sum + videoMetadata.size, 0),
  );

  const getEtaInSeconds = createSelector(
    [_getSumOfQueuedFileSizes, getCurrentUpload],
    (sumOfQueuedFileSizes, currentUpload) => {
      const startedUpload = currentUpload?.startedUpload;
      if (!(currentUpload && startedUpload && startedUpload.rate > 0)) {
        return {};
      }
      const currentUploadSize = currentUpload.videoMetadata.size;
      if (!(currentUploadSize > 0)) {
        return {};
      }
      const total = (
        ((1 - startedUpload.progress) * currentUploadSize + sumOfQueuedFileSizes)
        / currentUploadSize
        / startedUpload.rate
      );
      const current = ((1 - startedUpload.progress) / startedUpload.rate);
      return { current, total };
    },
  );

  const makeGetAdditionalCounts = () => {
    if (!additionalCountsSelector) {
      return () => ({
        pendingCount: 0,
        uploadedCount: 0,
        failedCount: 0,
        startedOrPending: false,
        itemCount: 0,
      });
    }
    return createSelector(
      [additionalCountsSelector],
      (additionalCounts) => additionalCounts,
    );
  };

  const getAdditionalCounts = makeGetAdditionalCounts();

  const getUploadQueueInfo = createSelector(
    [getUploadQueue, getCurrentUpload, _getStartedUploads, getUploadedVideosTemp2, getFailedUploads2, getAdditionalCounts],
    (uploadQueue, currentUpload, getStartedUploads, uploadedVideosTemps2, failedUploads2, additionalCounts) => {
      const pendingCount = (currentUpload && filterUpload(currentUpload.upload) ? 1 : 0) + uploadQueue.length + additionalCounts.pendingCount;
      const startedCount = Object.keys(getStartedUploads).length;
      const uploadedCount = Object.keys(uploadedVideosTemps2).length + additionalCounts.uploadedCount;
      const failedCount = Object.keys(failedUploads2).length + additionalCounts.failedCount;
      const startedOrPending = pendingCount > 0 || startedCount > 0;
      const itemCount = pendingCount + uploadedCount + failedCount;
      return { pendingCount, uploadedCount, failedCount, startedOrPending, itemCount };
    },
  );

  const makeGetUploadWithState = () => createSelector(
    [getUploadQueue, _getStartedUploads, _getUploadedVideos2, getFailedUploads2, (_: unknown, props: { uploadItemUuid?: UUID }) => props.uploadItemUuid],
    (uploadQueue, startedUploads, uploadedVideos2, failedUploads2, uploadItemUuid) => {
      if (!uploadItemUuid) {
        return undefined;
      }
      const failedUpload = failedUploads2[uploadItemUuid];
      if (failedUpload) {
        return {
          state: 'FAILED',
          upload: failedUpload,
          videoMetadata: getMetadata(failedUpload.options),
        } as const;
      }
      const startedUpload = Object.values(startedUploads).find((upload) => upload.options.uploadItemUuid === uploadItemUuid);
      const queuedVideo = uploadQueue.find(({ upload }) => upload.uploadItemUuid === uploadItemUuid);
      if (startedUpload) {
        return {
          state: startedUpload.status === 'paused' ? 'PAUSED' : 'UPLOADING',
          upload: startedUpload,
          videoMetadata: queuedVideo?.videoMetadata,
        } as const;
      }
      if (queuedVideo) {
        return {
          state: 'QUEUED',
          upload: queuedVideo.upload,
          videoMetadata: queuedVideo.videoMetadata,
        } as const;
      }
      const uploadInfo = uploadedVideos2[uploadItemUuid];
      if (uploadInfo) {
        return {
          state: 'DONE',
          upload: uploadInfo,
        } as const;
      }
    },
  );

  function getMostRecentUploadWithStateByVideoUuid(
    uploadQueue: ReturnType<typeof getUploadQueue>,
    startedUploads: VideoUploadState<LV>['startedUploads'],
    uploadedVideos: VideoUploadState<LV>['uploadedVideos'],
    failedUploads: VideoUploadState<LV>['failedUploads'],
    videoUuid: UUID | undefined,
  ) {
    if (!videoUuid) {
      return undefined;
    }
    const failedUpload = failedUploads[videoUuid];
    function test(options: Omit<VideoUploadOptions<LV>, 'uploadItemUuid'> & { uploadItemUuid?: UUID }): options is VideoUploadOptions<LV> {
      return !!options.uploadItemUuid;
    }
    if (failedUpload) {
      return {
        state: 'FAILED',
        upload: failedUpload,
        videoMetadata: test(failedUpload.options) ? getMetadata(failedUpload.options) : undefined,
      } as const;
    }
    const startedUpload = Object.values(startedUploads).find((upload) => upload.videoUuid === videoUuid);
    const queuedVideo = uploadQueue.find(({ upload }) => upload.video.uuid === videoUuid);
    if (startedUpload) {
      return {
        state: startedUpload.status === 'paused' ? 'PAUSED' : 'UPLOADING',
        upload: startedUpload,
        videoMetadata: queuedVideo?.videoMetadata,
      } as const;
    }
    if (queuedVideo) {
      return {
        state: 'QUEUED',
        upload: queuedVideo.upload,
        videoMetadata: queuedVideo.videoMetadata,
      } as const;
    }
    const uploadInfo = uploadedVideos[videoUuid];
    if (uploadInfo) {
      return {
        state: 'DONE',
        upload: uploadInfo,
      } as const;
    }
  }

  const makeGetMostRecentUploadWithStateByVideoUuid = () => createSelector(
    [getUploadQueue, _getStartedUploads, _getUploadedVideos, _getFailedUploads, (_: unknown, props: { videoUuid?: UUID }) => props.videoUuid],
    getMostRecentUploadWithStateByVideoUuid,
  );

  const makeGetMostRecentUploadsWithStateByVideoUuids = () => createSelector(
    [getUploadQueue, _getStartedUploads, _getUploadedVideos, _getFailedUploads, (_: unknown, props: { videoUuids: UUID[] }) => props.videoUuids],
    (uploadQueue, startedUploads, uploadedVideos, failedUploads, videoUuids) => {
      return videoUuids.map((videoUuid) => getMostRecentUploadWithStateByVideoUuid(uploadQueue, startedUploads, uploadedVideos, failedUploads, videoUuid));
    },
  );

  const makeGetUploadWithStateByVideoId = () => createSelector(
    [getUploadQueue, _getStartedUploads, _getUploadedVideos2, getFailedUploads2, (_: unknown, props: { videoId?: ID }) => props.videoId],
    (uploadQueue, startedUploads, uploadedVideos2, failedUploads2, videoId) => {
      if (!videoId) {
        return undefined;
      }
      const failedUpload = Object.values(failedUploads2).find((upload) => upload.options.resumeInfo?.videoId === videoId);
      if (failedUpload) {
        return {
          state: 'FAILED',
          upload: failedUpload,
          videoMetadata: getMetadata(failedUpload.options),
        } as const;
      }
      const startedUpload = Object.values(startedUploads).find((upload) => upload.id === videoId);
      const queuedVideo = uploadQueue.find(({ upload }) => upload.resumeInfo?.videoId === videoId);
      if (startedUpload) {
        return {
          state: startedUpload.status === 'paused' ? 'PAUSED' : 'UPLOADING',
          upload: startedUpload,
          videoMetadata: queuedVideo?.videoMetadata,
        } as const;
      }
      if (queuedVideo) {
        return {
          state: 'QUEUED',
          upload: queuedVideo.upload,
          videoMetadata: queuedVideo.videoMetadata,
        } as const;
      }
      const uploadInfo = Object.values(uploadedVideos2).find((upload) => upload.videoId === videoId);
      if (uploadInfo) {
        return {
          state: 'DONE',
          upload: uploadInfo,
        } as const;
      }
    },
  );

  function getVideoWebUrlFromByVideoUuid(uploadedVideos: VideoUploadState<LV>['uploadedVideos'], startedUploads: VideoUploadState<LV>['startedUploads'], videoUuid: UUID) {
    const uploadedVideo = uploadedVideos[videoUuid];
    if (uploadedVideo) {
      return SquadRouting.createUrl.teamVideo(uploadedVideo.teamId, uploadedVideo.videoId);
    }
    const startedUpload = Object.values(startedUploads).find((upload) => upload.videoUuid === videoUuid);
    if (startedUpload) {
      return SquadRouting.createUrl.teamVideo(startedUpload.options.dest.teamId, startedUpload.id);
    }
  }

  const makeGetVideoWebUrlByVideoUuid = () => createSelector(
    [_getUploadedVideos2, _getStartedUploads, (_: unknown, props: { videoUuid: UUID }) => props.videoUuid],
    getVideoWebUrlFromByVideoUuid,
  );

  const makeGetVideoWebUrlsByVideoUuids = () => createSelector(
    [_getUploadedVideos2, _getStartedUploads, (_: unknown, props: { videoUuids: UUID[] }) => props.videoUuids],
    (uploadedVideos, startedUploads, videoUuids) => {
      return videoUuids.map((videoUuid) => getVideoWebUrlFromByVideoUuid(uploadedVideos, startedUploads, videoUuid));
    },
  );

  return {
    getCurrentUpload,
    getEtaInSeconds,
    getUploadQueue,
    getUploadQueueInfo,
    getUploadedVideosTemp2,
    getFailedUploads2,
    getIsQueueEmpty,
    makeGetIsAnySelectionEnqueued,
    makeGetUploadWithState,
    makeGetMostRecentUploadWithStateByVideoUuid,
    makeGetMostRecentUploadsWithStateByVideoUuids,
    makeGetUploadWithStateByVideoId,
    makeGetVideoWebUrlByVideoUuid,
    makeGetVideoWebUrlsByVideoUuids,
  };
}
