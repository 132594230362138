import { ControlButton, VideoReplayContext } from '@insights-gaming/material-components';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import { memo, useContext, useMemo } from 'react';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import VideoControlsTooltip from '@/features/video-replay/VideoControlsTooltip';
import { makeAutoBlurHandler } from '@/utils';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

interface VideoReplayPlayPauseControllerOwnProps {
  className?: string;
  endOfVideo: boolean;
}

type VideoReplayPlayPauseControllerProps = VideoReplayPlayPauseControllerOwnProps;

function VideoReplayPlayPauseController(props: VideoReplayPlayPauseControllerProps) {
  const { endOfVideo } = props;

  const {
    state: { playing },
    togglePlayState,
  } = useContext(VideoReplayContext);

  const fullscreenEl = useContext(FullscreenContext);
  const keybindLookup = useContext(KeybindReverseLookupContext);

  const handlePlayPause = useMemo(() => makeAutoBlurHandler(togglePlayState), [togglePlayState]);
  return (
    <VideoControlsTooltip tKey={playing ? 'hotkeys:labels.pause' : 'hotkeys:labels.play'} keybindings={keybindLookup['video.playpause']} container={fullscreenEl}>
      <div>
        <ControlButton onClick={handlePlayPause}>
          {endOfVideo ? (
            <ReplayIcon />
          ) : playing ? (
            <PauseIcon />
          ) : (
            <PlayArrowIcon />
          )}
        </ControlButton>
      </div>
    </VideoControlsTooltip>
  );
}

export default memo(VideoReplayPlayPauseController);
