import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Button, FormControl, Stack, SxProps, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { MouseEventHandler, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { FileInput } from '@/components/FileInput';
import { CreateTeamFn } from '@/features/squad/useCreateTeamMutation';
import { typedAssetPath } from '@/utils';

import { DialogSlideLayout } from './DialogSlideLayout';
import { CreateSquadFormProps, useCreateSquadFormProps } from './useCreateSquadFormProps';


const acceptedFileTypes = '.png, .jpg, .jpeg, .gif';

interface CreateSquadSlideProps {
  className?: string;
  sx?: SxProps<Theme>;

  variant?: 'dialog' | 'onboarding';
  onClose?: CloseHandler;

  onBack?: MouseEventHandler<HTMLButtonElement>;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onCreateTeam?: CreateTeamFn;
  onNext?: MouseEventHandler<HTMLButtonElement>;
}

export function CreateSquadSlide(props: CreateSquadSlideProps) {
  const { className, sx, onBack, onCancel, onClose, onCreateTeam, onNext, variant = 'dialog' } = props;

  const { t } = useTranslation(['common', 'squad_onboarding']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const { onPictureChange, onTeamNameChange, picture, preview, teamName } = useCreateSquadFormProps();

  const handleCreateSquadClick = useCallback<MouseEventHandler<HTMLButtonElement>>(async (e) => {
    if (!teamName || loading) {
      return;
    }
    setLoading(true);
    try {
      await onCreateTeam?.({
        variables: {
          input: {
            name: teamName,
            picture,
          },
        },
      });
      onNext?.(e);
    } catch {
      enqueueSnackbar(t('squad_onboarding:createSquad.createFailed'), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, loading, onCreateTeam, onNext, picture, t, teamName]);

  const actions = (
    <Stack flex={1} direction='row' justifyContent='space-between'>
      <Button startIcon={<ChevronLeftIcon />} onClick={onBack}>
        {t('common:back')}
      </Button>
      <Stack direction='row' justifyContent='flex-end' spacing={1}>
        {variant === 'dialog' && (
          <Button variant='outlined' onClick={onCancel}>
            {t('common:cancel')}
          </Button>
        )}
        <AsyncButton
        type='submit'
        color='primary'
        variant='contained'
        loading={loading}
        disabled={!teamName || loading}
        onClick={handleCreateSquadClick}
        >
          {t('squad_onboarding:createSquad.create')}
        </AsyncButton>
      </Stack>
    </Stack>
  );

  return (
    <DialogSlideLayout
    variant={variant}
    onClose={onClose}
    graphic={(
      <img src={typedAssetPath('/assets/squad/customize-team.svg')} alt='customize team' />
    )}
    title={t('squad_onboarding:createSquad.title')}
    actions={actions}
    className={className}
    sx={sx}
    >
      <CreateSquadContent teamName={teamName} picture={picture} preview={preview} onPictureChange={onPictureChange} onTeamNameChange={onTeamNameChange} />
    </DialogSlideLayout>
  );
}

interface CreateSquadContentProps {
  className?: string;
  sx?: SxProps<Theme>;
}

function CreateSquadContent(props: CreateSquadContentProps & CreateSquadFormProps) {
  const { className, onPictureChange, onTeamNameChange, preview, sx, teamName } = props;

  const { t } = useTranslation(['common', 'squad_onboarding']);

  return (
    <Stack justifyContent='center' spacing={1.5} className={className} sx={sx}>
      <Stack>
        <Typography variant='caption'>
          {t('squad_onboarding:createSquad.description')}
        </Typography>
      </Stack>
      <Stack spacing={1} direction='row' alignItems='center'>
        <AvatarWithFallback src={preview} alt='insights' size={64} />
        <Stack>
          <Box>
            <FileInput accept={acceptedFileTypes} onChange={onPictureChange}>
              <Button variant='outlined'>
                Upload
              </Button>
            </FileInput>
          </Box>
          <Typography variant='caption'>
            {t('squad_onboarding:createSquad.recommendedSize')}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant='h5'>
          {t('squad_onboarding:createSquad.squadName')}
        </Typography>
        <FormControl>
          <TextField
          fullWidth={true}
          id='teamname'
          required={true}
          value={teamName || ''}
          onChange={onTeamNameChange}
          variant='outlined'
          placeholder='Enter Squad Name'
          />
        </FormControl>
      </Stack>
    </Stack>
  );
}
