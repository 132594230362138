// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let grecaptcha: any;

// https://stackoverflow.com/a/37098508
const innerHtmlContains = (selector: string, text: RegExp | string): Element[] => {
  const elements = document.querySelectorAll(selector);
  return Array.prototype.filter.call(elements, (element: Element) => {
      return RegExp(text).test(element.innerHTML);
  });
};

const createScriptElement = (
  id: string,
  url: string,
  onloadCallback: GlobalEventHandlers['onload'] = () => {},
  onerrorCallback: OnErrorEventHandler = () => {},
) => {
  const scriptEl = document.createElement('script');
  scriptEl.onload = onloadCallback;
  scriptEl.onerror = onerrorCallback;
  scriptEl.id = id;
  scriptEl.async = true;
  scriptEl.defer = true;
  scriptEl.src = url;

  return scriptEl;
};

const replaceOrAppendScriptById = (
  originalScriptId: string,
  newScriptId: string,
  url: string,
) => new Promise((resolve, reject) => {
    const newScriptEl = createScriptElement(newScriptId, url, resolve, reject);
    const oldScriptEl = document.getElementById(originalScriptId);
    if (oldScriptEl) {
      // Invalidate grecaptcha variable so the new recaptcha script will create a new grecaptcha variable
      // We will get a Timeout error if we remove the badge and try to do grecaptcha.execute() later using the "old" grecaptcha variable
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      grecaptcha = null;

      // Remove all elements the recaptcha script appends to the DOM
      const timeoutTextEls = innerHtmlContains('div', /^<div>Could not connect to the reCAPTCHA service./);
      for (const el of timeoutTextEls) {
        el.remove();
      }

      const grecaptchaBadge = document.getElementsByClassName('grecaptcha-badge').item(0)?.parentElement;
      if (grecaptchaBadge?.tagName === 'DIV') {
        grecaptchaBadge.remove();
      }

      const selfDownloadedRecaptchaScript = oldScriptEl.previousElementSibling;
      if (selfDownloadedRecaptchaScript?.tagName === 'SCRIPT') {
        selfDownloadedRecaptchaScript.remove();
      }

      oldScriptEl.replaceWith(newScriptEl);
    } else {
      document.head.appendChild(newScriptEl);
    }
});

export const replaceRecaptchaScript = async () => {
  const recaptchaUrl = 'https://www.google.com/recaptcha/api.js?render=6LfKwqUUAAAAALlstwRsDowqbQHqRaxb62CqZfoX';
  const res = await fetch(recaptchaUrl, {
    method: 'HEAD',
    mode: 'no-cors',
  });
  if (res.type !== 'opaque') {
    throw new Error('Network Error');
  }
  await replaceOrAppendScriptById('recaptchaScriptEl', 'recaptchaScriptEl', recaptchaUrl);
};
