import { ApolloClient, ApolloQueryResult, FetchResult, MutationOptions, NormalizedCacheObject, OperationVariables, QueryOptions, SubscriptionOptions } from '@apollo/client';
import { createBackoffFunction } from '@insights-gaming/backoff';
import { END, eventChannel } from '@redux-saga/core';
import { call, delay, getContext, select, StrictEffect } from 'redux-saga/effects';

import { ClientContext } from '@/apollo/client';
import { getSessionAccessToken } from '@/features/authentication/authentication-saga';
import { getAuthSession } from '@/features/authentication/authentication-selector';

import { Session } from './auth';

export type QueryConfig<TVariables = never, TData = any> = Omit<QueryOptions<TVariables, TData>, 'query'>;

const defaultBackoff = createBackoffFunction({
  jitter: 0,
  max: 1000,
  multiplier: 1000,
});

interface RetryOptions {
  maxAttempts?: number;
  backoff?: (i: number) => number;
  abortSignal?: AbortSignal;
}

interface ApolloQueryOptions extends RetryOptions {
  disableAuth?: boolean;
}

export function* apolloQueryRefresh<T = any, TVariables extends OperationVariables = OperationVariables>(opts: QueryOptions<TVariables, T>, retryOptions?: ApolloQueryOptions): Generator<StrictEffect, T, T> {
  return yield queryRefresh<T, TVariables>(opts, retryOptions);
}

export function queryRefresh<T = any, TVariables extends OperationVariables = OperationVariables>(opts: QueryOptions<TVariables, T>, retryOptions?: ApolloQueryOptions) {
  return call(function* (opts: QueryOptions<TVariables, T>, retryOptions?: ApolloQueryOptions) {
    if (!opts.fetchPolicy) {
      opts.fetchPolicy = 'no-cache';
    }

    let session: Session | undefined = (yield select(getAuthSession));
    let accessToken: string | undefined = session?.accessToken;
    if (session?.expiration && session.expiration < Date.now()) {
      accessToken = yield call(getSessionAccessToken);
    } else if (!accessToken && !retryOptions?.disableAuth) {
      accessToken = yield call(getSessionAccessToken);
      if (!accessToken) {
        throw new Error('Couldn\'t refresh token.');
      }
    }

    const { client }: ClientContext = yield getContext('apollo');

    let { maxAttempts = 1, backoff = defaultBackoff, abortSignal } = retryOptions || {};
    if (maxAttempts < 1) {
      maxAttempts = 1;
    }

    const options: QueryOptions<TVariables, T> = {
      ...opts,
      context: {
        token: accessToken,
        signal: abortSignal,
      },
    };

    let err;
    for (let i = 0; i < maxAttempts; i++) {
      try {
        if (i > 0) {
          yield delay(backoff(i));
        }
        const result: ApolloQueryResult<T> = yield call([client, client.query as (options: QueryOptions<TVariables, T>) => Promise<ApolloQueryResult<T>>], options);
        if (result.error) {
            throw new Error(`Apollo error: ${result.error.message}`);
        }
        if (result.errors) {
          throw new Error(`Graphql error: ${result.errors.map(e => e.message).join('\n')}`);
        }
        return result.data;
      } catch (error) {
        err = error as Error;
        console.error(err);
      }
    }
    throw err;
  }, opts, retryOptions);
}

export function queryRefreshWithRetry<T = any, TVariables extends OperationVariables = OperationVariables>(opts: QueryOptions<TVariables, T>, retryOptions?: ApolloQueryOptions) {
  return queryRefresh(opts, { maxAttempts: 5, ...retryOptions });
}

export function* apolloMutateRefresh<T = any, TVariables extends OperationVariables = OperationVariables>(opts: MutationOptions<T, TVariables>, refreshOpts?: RetryOptions): Generator<StrictEffect, T, T> {
  return yield mutateRefresh<T, TVariables>(opts, refreshOpts);
}

export function mutateRefresh<T = any, TVariables extends OperationVariables = OperationVariables>(opts: MutationOptions<T, TVariables>, refreshOpts?: RetryOptions) {
  return call(function* (opts: MutationOptions<T, TVariables>, refreshOpts?: RetryOptions) {
    const accessToken: AsyncReturnType<typeof getSessionAccessToken> = yield call(getSessionAccessToken);
    if (!accessToken) {
      throw new Error('Couldn\'t refresh token.');
    }

    const { client }: ClientContext = yield getContext('apollo');

    let { maxAttempts = 1, backoff = defaultBackoff, abortSignal } = refreshOpts || {};
    if (maxAttempts < 1) {
      maxAttempts = 1;
    }

    const options: MutationOptions<T, TVariables> = {
      ...opts,
      context: {
        token: accessToken,
        signal: abortSignal,
      },
    };

    let err;
    for (let i = 0; i < maxAttempts; i++) {
      try {
        if (i > 0) {
          yield delay(backoff(i));
        }
        const result: FetchResult<T> = yield call([client, client.mutate as (options: MutationOptions<T, TVariables>) => Promise<FetchResult<T>>], options);
        if (result.errors) {
          throw new Error(`Graphql error: ${result.errors.map(e => e.message).join('\n')}`);
        }
        return result.data;
      } catch (error) {
        err = error as Error;
        console.error(err);
      }
    }
    throw err;
  }, opts, refreshOpts);
}

export function apolloQueryRefreshWithRetry<T = any, TVariables extends OperationVariables = OperationVariables>(opts: QueryOptions<TVariables, T>, retryOptions?: ApolloQueryOptions) {
  return apolloQueryRefresh(opts, { maxAttempts: 5, ...retryOptions });
}

export function apolloMutateRefreshWithRetry<T = any, TVariables extends OperationVariables = OperationVariables>(opts: MutationOptions<T, TVariables>, retryOptions?: RetryOptions) {
  return apolloMutateRefresh(opts, { maxAttempts: 5, ...retryOptions });
}

export function subscriptionChannel<Result extends {} | null, Variables extends OperationVariables = OperationVariables>(
  client: ApolloClient<NormalizedCacheObject>,
  options: SubscriptionOptions<Variables, Result>,
) {
  return eventChannel<Result>(emit => {
    const obs = client.subscribe(options);
    const sub = obs.subscribe({
      next: (result: ApolloQueryResult<Result>) => {
        emit(result.data);
      },
      complete: () => {
        emit(END);
      },
    });
    return () => {
      sub.unsubscribe();
    };
  });
}
