import { useReadQuery } from '@apollo/client';
import { InteractivePopover, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Box, Button, Paper, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { getEmojiDataFromNative } from 'emoji-mart';
import { ContextType, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { Time } from '@/components/Time';
import { withDefaultErrorBoundary } from '@/components/withDefaultErrorBoundary';
import { Reaction } from '@/features/squad/Reaction';
import { getFragmentData, gql } from '@/graphql/__generated__';
import { withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';

import { TeamVideoPageContext } from './TeamVideoPageContext';
import { AddReactionProps, ReactionGroup_ReactionFragment, ReactionGroupProps, TeamVideoReactions } from './TeamVideoReactions';

const TeamVideoBottomToolbar_VideoFragment = gql(`
  fragment TeamVideoBottomToolbar_VideoFragment on Video {
    id
    name
    created
    directory {
      id
    }
    creator {
      picture
      alias
    }
    ...TeamVideoDeleteDialog_VideoFragment
    ...TeamVideoReactions_VideoFragment
  }
`);

interface TeamVideoBottomToolbarOwnProps {
  sx?: SxProps<Theme>;
  teamId: ID;
}

type TeamVideoBottomToolbarProps = TeamVideoBottomToolbarOwnProps;

function _TeamVideoBottomToolbar(props: TeamVideoBottomToolbarProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamVideoPageContext>>) {
  const { queryRef, sx, teamId } = props;
  const { data } = useReadQuery(queryRef);
  const video = getFragmentData(TeamVideoBottomToolbar_VideoFragment, data.video);

  const {
    state: { fullscreen, theaterMode },
  } = useContext(VideoReplayContext);

  if (!video) {
    return null;
  }

  return (
    <Stack
    sx={[
      {
        p: 1,
        background: theme => theme.palette.background.container,
      },
      fullscreen && {
        display: 'none',
      },
      theaterMode && {
        display: 'none',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    spacing={1}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h3' fontWeight='bold'>
          {video.name}
        </Typography>
        <Typography variant='caption'>
          <Time format='MMM dd, yyyy'>
            {video.created}
          </Time>
        </Typography>
      </Stack>
      <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1}>
        <Stack direction='row' spacing={0.5} alignItems='center'>
          <AvatarWithFallback src={video.creator.picture} alt={video.creator.alias} size={32} />
          <Typography>
            {video.creator.alias}
          </Typography>
        </Stack>
        <TeamVideoReactions
        teamId={teamId}
        videoId={video.id}
        displayShowAllButton={true}
        video={video}
        AddReactionComponent={ReactionButton}
        ReactionGroupComponent={ReactionGroupButton}
        />
      </Stack>
    </Stack>
  );
}

export const TeamVideoBottomToolbar = withDefaultErrorBoundary(withBackgroundQuery(TeamVideoPageContext, _TeamVideoBottomToolbar));

function ReactionButton(props: AddReactionProps) {
  const { t } = useTranslation(['squad_videos']);
  return (
    <Tooltip title={t('squad_videos:reactions.add')}>
      <Button variant='outlined' size='small' {...props}>
        <InsertEmoticonIcon />
      </Button>
    </Tooltip>
  );
}

export const MAX_USERS_TO_SHOW = 5;

function ReactionGroupButton(props: ReactionGroupProps) {
  const { className, sx, onOthersClick, emojiCode, ...rest } = props;
  const reactions = getFragmentData(ReactionGroup_ReactionFragment, props.reactions);
  const reaction = reactions[0];

  const { t } = useTranslation(['squad_videos']);

  const [emojiName, setEmojiName] = useState<string>('');

  const firstFiveReactions = reactions.slice(0, MAX_USERS_TO_SHOW);
  const remainingReactions = reactions.slice(MAX_USERS_TO_SHOW);

  const handleOthersClick = useCallback(() => {
    onOthersClick?.(emojiCode);
  }, [onOthersClick, emojiCode]);

  useEffect(() => {
    const getEmojiName = async () => {
      switch (reaction.__typename) {
        case 'CustomReaction':
          return setEmojiName(reaction.emoji.name);
        case 'UnicodeReaction':
          const emojiNativeData = await getEmojiDataFromNative(reaction.character);
          return setEmojiName(emojiNativeData ? emojiNativeData.shortcodes : '');
        default:
          return setEmojiName('');
      }
    };
    getEmojiName();
  }, [reaction]);

  return (
    <Fragment>
      <InteractivePopover
      disableEnforceFocus={true}
      exitDelay={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      trigger={(
        <Button
        className={className}
        variant='outlined'
        size='small'
        {...rest}
        >
          <Stack direction='row' alignItems='center' spacing={0.5}>
            <Reaction reaction={reaction} />
            <Typography>
              {reactions.length}
            </Typography>
          </Stack>
        </Button>
      )}
      >
        <Paper
        sx={{
          p: 1,
          maxWidth: 320,
        }}
        >
          <Stack direction='row' alignItems='center'>
            <Box sx={{ fontSize:'2em' }}>
              <Reaction reaction={reaction} />
            </Box>
            <Typography>
              {t('squad_videos:reactions.reactedBy', { emojiName, usernames: firstFiveReactions.map(reaction => reaction.user.alias).join(', ') })}
              {remainingReactions.length > 0 && (
                <Typography component='span'>
                  <Trans
                  ns={['squad_videos']}
                  i18nKey='squad_videos:reactions.remainingReactions'
                  values={{
                    reaction: reaction,
                    count: remainingReactions.length,
                  }}
                  components={{
                    a: (
                      <Typography
                      component='span'
                      onClick={handleOthersClick}
                      sx={{
                        color: theme => theme.palette.primary.main,
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      />
                    ),
                  }}
                  />
                </Typography>
              )}
            </Typography>
          </Stack>
        </Paper>
      </InteractivePopover>
    </Fragment>
  );
}
