import { Theme } from '@insights-gaming/theme';
import { secondsToHms } from '@insights-gaming/utils';
import { LinearProgress, ListItem, ListItemButton, Stack, SxProps, Typography } from '@mui/material';
import { Fragment, PropsWithChildren, ReactNode } from 'react';

export interface UploadQueueItemProps {
  className?: string;
  sx?: SxProps<Theme>;

  nameWidth?: number;
  detailedView?: boolean;
  destinationIcon?: React.ReactNode;
  name: ReactNode;
  eta?: number;
  processing?: boolean;
  progress?: number;
  secondaryAction?: React.ReactNode;

  onClick?: React.MouseEventHandler;
  onPause?: React.MouseEventHandler;
  onPromote?: React.MouseEventHandler;
}

export function UploadQueueItem(props: PropsWithChildren<UploadQueueItemProps>) {
  const {
    className,
    children,
    destinationIcon,
    detailedView = false,
    eta,
    name,
    nameWidth,
    onClick,
    processing,
    progress,
    secondaryAction,
    sx,
  } = props;

  const inner = (
    <Stack direction='row' justifyContent='space-between' alignItems='center' flex={1} overflow='hidden'>
      <span style={{ lineHeight: 0 }}>
        {destinationIcon}
      </span>
      <Typography
      noWrap={true}
      sx={[
        {
          width: 98,
        },
        detailedView && {
          width: 170,
        },
        typeof nameWidth === 'number' && {
          width: nameWidth,
        },
      ]}
      >
        {name}
      </Typography>
      {children}
      <Stack direction='row' position='relative' justifyContent='space-between' flexWrap='wrap' width={100}>
        {typeof progress === 'number' && (
          <LinearProgress variant={processing ? 'indeterminate' : 'determinate'} value={progress * 100} sx={{ width: '100%', height: 6 }} />
        )}
        {eta && (
          <Typography variant='caption'>
            {secondsToHms(eta)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );

  return (
    <ListItem
    disablePadding={!!onClick}
    className={className}
    sx={sx}
    secondaryAction={secondaryAction}
    >
      {onClick ? (
        <ListItemButton onClick={onClick}>
          {inner}
        </ListItemButton>
      ) : (
        <Fragment>
          {inner}
        </Fragment>
      )}
    </ListItem>
  );
}
