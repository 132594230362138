import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router';

import SquadRouting from '@/features/squad/squad.routing';
import { useNavigate } from '@/hooks/useNavigate';

import { logout } from './authentication-slice';

export function useLogout() {
  const dispatch = useDispatch();
  const onNavigate = useNavigate();
  const isSquadRoute = !!useRouteMatch(SquadRouting.paths);
  return useCallback(() => {
    dispatch(logout());
    if (isSquadRoute) {
      onNavigate('/');
    }
  }, [dispatch, isSquadRoute, onNavigate]);
}
