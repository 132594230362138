import { useSuspenseQuery } from '@apollo/client';
import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import LinkIcon from '@mui/icons-material/Link';
import { AppBar, Box, Button, Container, Link, Stack, SxProps, Toolbar, Tooltip, Typography } from '@mui/material';
import { MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

import { VideoLayout2 } from '@/components/VideoLayout2';
import { pigeonRouter } from '@/features/pigeon-router';
import TeamVideoPlayerControls from '@/features/squad/TeamVideo/TeamVideoPlayerControls';
import { useTeamVideoUrls } from '@/features/squad/TeamVideo/useTeamVideoUrls';
import ReactPlayerWrapper from '@/features/video-replay/ReactPlayerWrapper';
import { DisplayCenterIconContext } from '@/features/video-replay/useDisplayCenterIcon';
import { useFullscreen } from '@/features/video-replay/useFullscreen';
import { useHandleClickActions } from '@/features/video-replay/useHandleClickActions';
import { useShowVideoControls } from '@/features/video-replay/video-controls/useShowVideoControls';
import { gql } from '@/graphql/__generated__';
import { PublicVideo_VideoQueryQuery } from '@/graphql/__generated__/graphql';
import { FullscreenProvider } from '@/utils/fullscreen/FullscreenContext';

import PublicVideoRouting from './public-video.routing';
import { PublicVideoTitle } from './PublicVideoTitle';

export const PublicVideo_VideoQuery = gql(`
  query PublicVideo_VideoQuery($videoId: ID!) {
    video(id: $videoId) {
      id
      duration

      # queryReactions {} # maybe?
      # might need these to show error message
      error
      status
      progress {
        current
        rate
        total
      }
      uploaded
      ...PublicVideoTitle_VideoFragment
      ...UseTeamVideoUrls_VideoFragment
    }
  }
`);

interface PublicVideoProps {
  className?: string;
  sx?: SxProps<Theme>;

  videoId: ID;
}

const downloadName = 'Insights Capture - Installer.exe';

function createInsightsCaptureDownloadLink(appChannel?: 'Beta' | 'Alpha' | 'Canary') {
  const params = new URLSearchParams();
  params.set('Name', 'Insights Capture');
  params.set('ExtensionId', 'okmohcjfmchpapljmoineeecekojmbbheniohgnp');
  if (appChannel) {
    params.set('app_channel', appChannel);
  }
  return 'https://download.overwolf.com/install/Download?' + params.toString();
}

export function PublicVideo(props: PublicVideoProps) {
  return (
    <ErrorBoundary fallback={<PublicVideoInternal {...props} />}>
      <PublicVideoWithData {...props} />
    </ErrorBoundary>
  );
}

function PublicVideoWithData(props: PublicVideoProps) {
  const { videoId } = props;
  const { data } = useSuspenseQuery(PublicVideo_VideoQuery, { variables: { videoId } });
  return <PublicVideoInternal {...props} data={data} />;
}

type PublicVideoInternalProps = PublicVideoProps & { data?: PublicVideo_VideoQueryQuery };

function PublicVideoInternal(props: PublicVideoInternalProps) {
  const { className, sx, data, videoId } = props;

  const videoContainerRef = useRef<HTMLDivElement>(null);
  const reactPlayerRef = useRef<ReactPlayer>(null);
  const fullscreenElRef = useRef<HTMLDivElement>(null);
  const contextValue = useContext(VideoReplayContext);
  const {
    state: { duration, fullscreen, progress },
    toggleFullscreen,
    togglePlayState,
    setDuration,
  } = contextValue;
  const controlIcon = useContext(DisplayCenterIconContext);

  const handleClick = useHandleClickActions(togglePlayState, toggleFullscreen);

  const [endOfVideo, setEndOfVideo] = useState(false);

  useEffect(() => {
    if (progress.playedSeconds < duration) {
      setEndOfVideo(false);
    }
  }, [duration, progress]);

  const [showVideoControls] = useShowVideoControls(videoContainerRef.current, 'overlay');

  const { t } = useTranslation(['common']);
  const [copied, setCopied] = useState(false);
  const timerRef = useRef<number>();

  const href = pigeonRouter.createPigeonRouter(PublicVideoRouting.createUrl.publicVideo)(videoId);
  const handleCopy = useCallback<MouseEventHandler<HTMLElement>>((e) => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
    }
    e.preventDefault();
    e.stopPropagation();
    setCopied(true);
    window.navigator.clipboard.writeText(href);
    window.setTimeout(() => {
      setCopied(false);
    }, 1000);
  }, [href]);

  const [timeRanges, setTimeRanges] = useState<TimeRanges>();

  return (
    <Stack
    sx={{
      flex: 1,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url(${('https://insights.gg/public-video-replay.png')})`,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    }}
    >
      <AppBar position='static' color='transparent' elevation={0}>
        <Toolbar>
          <Box
          display='grid'
          flex={1}
          sx={[
            {
              gridTemplateColumns: '1fr 1fr',
            },
            theme => ({
              [`${theme.breakpoints.up('md')}`]: {
                gridTemplateColumns: '1fr 3fr 1fr',
              },
            }),
          ]}
          >
            <Button component={Link} href={window.location.origin} sx={{ justifySelf: 'flex-start' }}>
              <img src='https://insights.gg/insights-logo-text-white.png' alt='Insights' draggable={false}></img>
            </Button>
            <Stack direction='row' justifySelf='center' alignItems='center' spacing={1}>
              <Typography
              variant='h3'
              sx={[
                {
                  display: 'none',
                  textTransform: 'capitalize',
                },
                theme => ({
                  [`${theme.breakpoints.up('lg')} and (orientation: landscape)`]: {
                    display: 'unset',
                  },
                }),
              ]}
              >
                {t('common:recordAndShare')}
              </Typography>
              <Button variant='contained' color='primary' href={createInsightsCaptureDownloadLink('Beta')} download={downloadName}>
                {t('common:downloadApp')}
              </Button>
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Container
      maxWidth={false}
      sx={{
        flex: 1,
        display: 'flex',
      }}
      >
        <Stack justifyContent='center' alignItems='center' flex={1}>
          <FullscreenProvider value={fullscreenElRef.current}>
            <Box
            className={className}
            sx={{
              display: 'flex',
              flex: 1,
              overflow: 'hidden',
              maxWidth: 1280,
              width: '100%',
            }}
            >
              <Fullscreen />
              <VideoLayout2
              ref={fullscreenElRef}
              className={className}
              sx={[
                !fullscreen && {
                  alignItems: 'center',
                },
                ...(Array.isArray(sx) ? sx : [sx]),
              ]}
              >
                <Stack
                spacing={1}
                sx={[
                  fullscreen && {
                    flex: 1,
                  },
                ]}
                >
                  {data?.video && !fullscreen && (
                    <PublicVideoTitle video={data.video} />
                  )}
                  <ReactPlayerWrapper
                  ref={videoContainerRef}
                  reactPlayerRef={reactPlayerRef}
                  videoReplayContextValue={contextValue}
                  controlIcon={controlIcon}
                  isEndOfVideo={endOfVideo}
                  onClick={handleClick}
                  onPlay={() => setEndOfVideo(false)}
                  onEnded={() => setEndOfVideo(true)}
                  onReady={(e) => setDuration(e.getDuration())}
                  onTimeRanges={setTimeRanges}
                  {...useTeamVideoUrls(data?.video)}
                  videoControls={(
                    <TeamVideoPlayerControls
                    variant='fullscreen'
                    showVideoControls={showVideoControls}
                    useInvisibleAnchor={fullscreen}
                    seekNextBookmark={() => {}}
                    seekPreviousBookmark={() => {}}
                    bookmarks={[]}
                    timeRanges={timeRanges}
                    isEndOfVideo={endOfVideo}
                    />
                  )}
                  sx={[
                    fullscreen && {
                      flex: 1,
                    },
                    !fullscreen && {
                      aspectRatio: '16 / 9',
                    },
                  ]}
                  />
                  {!fullscreen && (
                    <Stack direction='row' justifyContent='flex-end'>
                      <Tooltip title={copied ? t('common:copied') : t('common:copyLink')}>
                        <Button
                        component={Link}
                        variant='contained'
                        color='inherit'
                        href={href}
                        onClick={handleCopy}
                        sx={{
                          minWidth: 'unset',
                          padding: 0.5,
                        }}
                        >
                          <LinkIcon fontSize='large' />
                        </Button>
                      </Tooltip>
                    </Stack>
                  )}
                </Stack>
              </VideoLayout2>
            </Box>
          </FullscreenProvider>
        </Stack>
      </Container>
    </Stack>
  );
}

function Fullscreen() {
  useFullscreen();
  return null;
}
