import { Theme } from '@insights-gaming/theme';
import { SecondsFormatter } from '@insights-gaming/utils';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Stack, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
  forwardRef,
  memo,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import { BookmarkSeekerProps } from '@/hooks/useBookmarkSeekers';
import { getContrastText } from '@/theme';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

import VideoControlsTooltip from './VideoControlsTooltip';

interface VideoControlsOwnProps {
  className?: string;
  sx?: SxProps<Theme>;

  duration: number;
  playedSeconds: number;

  ApmController?: ReactNode;
  BookmarkFilterController?: ReactNode;
  FullScreenController?: ReactNode;
  Seekbar?: ReactNode;
  TheaterModeController?: ReactNode;
  VideoReplaySettingsController?: ReactNode;
  VideoVolumeController?: ReactNode;
  VideoReplayPlayPauseController?: ReactNode;
  onSeekToBookmark?: VoidFunction;
}

/**
 * @param {number} playedSeconds seconds
 * @param {number} duration seconds
 */
export interface CommonVideoControlProps extends BookmarkSeekerProps {
  variant?: 'fullscreen' | 'default';
  showVideoControls: boolean;
  forceHiddenControls?: boolean;
}

export interface ExtendedVideoControlsProps extends CommonVideoControlProps {
  isEndOfVideo: boolean;
}

type Props = VideoControlsOwnProps & CommonVideoControlProps;

const VideoControls = forwardRef<HTMLDivElement, Props>(function VideoControls(props: Props, ref) {
  const {
    ApmController,
    bookmarks = [],
    BookmarkFilterController,
    className,
    duration,
    forceHiddenControls = false,
    FullScreenController,
    onSeekToBookmark,
    playedSeconds,
    Seekbar,
    seekNextBookmark,
    seekPreviousBookmark,
    showVideoControls,
    sx,
    TheaterModeController,
    variant,
    VideoReplayPlayPauseController,
    VideoReplaySettingsController,
    VideoVolumeController,
  } = props;

  const fullscreenEl = useContext(FullscreenContext);
  const keybindLookup = useContext(KeybindReverseLookupContext);

  const shouldDisableBackwardSeek = bookmarks.length === 0 || playedSeconds <= bookmarks[0].timestamp;
  const shouldDisableForwardSeek = bookmarks.length === 0 || playedSeconds >= bookmarks[bookmarks.length - 1].timestamp;

  const playedSecondsRef = useRef(playedSeconds);
  useEffect(() => {
    playedSecondsRef.current = playedSeconds;
  }, [playedSeconds]);

  const handlePreviousBookmarkSeekClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();
    seekPreviousBookmark?.(bookmarks, playedSecondsRef.current);
    onSeekToBookmark?.();
  }, [bookmarks, onSeekToBookmark, seekPreviousBookmark]);

  const handleNextBookmarkSeekClick = useCallback((e: MouseEvent<HTMLElement>) => {
    e.currentTarget.blur();
    seekNextBookmark?.(bookmarks, playedSecondsRef.current);
    onSeekToBookmark?.();
  }, [bookmarks, onSeekToBookmark, seekNextBookmark]);

  const formattedProgress = useMemo(() => SecondsFormatter.format(playedSeconds), [playedSeconds]);
  const formattedDuration = useMemo(() => SecondsFormatter.format(duration), [duration]);

  return (
    <Stack
    ref={ref}
    onFocus={(e) => { // hack to prevent the page from scrolling when the user clicks on the seekbar
      e.currentTarget.parentElement?.scrollTo(0, 0);
    }}
    className={className}
    sx={[
      {
        padding: 1,
        width: '100%',
        backgroundColor: theme => theme.palette.background.paper,
      },
      variant === 'fullscreen' && {
        position: 'absolute',
        bottom: 0,
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) -0.97%, rgba(0, 0, 0, 0.37) 20%, rgba(0, 0, 0, 0.88) 100%)',
        color: theme => getContrastText(theme)('rgba(0, 0, 0, 0.88)'),
        transform: 'translateY(100%)',
        transition: theme => theme.transitions.create('transform', {
          duration: theme.transitions.duration.standard,
        }),
      },
      variant === 'fullscreen' && showVideoControls && !forceHiddenControls && {
        '&, &:hover, &:focus-within': {
          transform: 'unset',
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <Stack position='relative' spacing={1} sx={{ containerType: 'inline-size' }}>
        <Box paddingY='6px' lineHeight={0}>
          {Seekbar}
        </Box>
        {bookmarks.length > 0 && (
          <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          sx={{
            pointerEvents: 'none',
            '& > *': {
              pointerEvents: 'all',
            },
            '@container (width > 600px)': {
              position: 'absolute',
              width: '100%',
              bottom: 0,
            },
          }}
          >
            <ToggleButtonGroup size='small'>
              <VideoControlsTooltip tKey='hotkeys:labels.previousBookmark' keybindings={keybindLookup['video.seek.backward.bookmark']} container={fullscreenEl}>
                <span>
                  <StyledToggleButton value='left' onClick={handlePreviousBookmarkSeekClick} disabled={shouldDisableBackwardSeek}>
                    <KeyboardArrowLeftIcon />
                  </StyledToggleButton>
                </span>
              </VideoControlsTooltip>
              <VideoControlsTooltip tKey='hotkeys:labels.nextBookmark' keybindings={keybindLookup['video.seek.forward.bookmark']} container={fullscreenEl}>
                <span>
                  <StyledToggleButton value='right' onClick={handleNextBookmarkSeekClick} disabled={shouldDisableForwardSeek}>
                    <KeyboardArrowRightIcon />
                  </StyledToggleButton>
                </span>
              </VideoControlsTooltip>
            </ToggleButtonGroup>
          </Stack>
        )}
        <Stack direction='row' justifyContent='space-between' spacing={1}>
          <Stack direction='row' alignItems='center' spacing={1}>
            {VideoReplayPlayPauseController}
            {VideoVolumeController}
            <Typography noWrap={true}>
              {formattedProgress} / {formattedDuration}
            </Typography>
          </Stack>
          <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          sx={[
            {
              gap: 1,
            },
            bookmarks.length > 0 && {
              '@container (width <= 600px)': {
                gap: 0.25,
              },
            },
          ]}
          >
            {ApmController}
            {BookmarkFilterController}
            {VideoReplaySettingsController}
            {TheaterModeController}
            {FullScreenController}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
});

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
}));

export default memo(VideoControls);
