import { VideoReplayContext } from '@insights-gaming/material-components';
import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clamp } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import ReactPlayer, { Progress } from 'react-player';

import { makeGetVideosByUuids } from '@/features/video-library/video-library-selector';
import { Video } from '@/features/video-library/video-library-slice';
import { TimelineVideoMetadata } from '@/features/video-merge/video-merge-slice';
import { NOOP } from '@/utils';
import update from '@/utils/update';

interface MultiVideoSeekOptions {
  type: 'seconds' | 'fraction';
  amount: number;
  playerIndex: number;
}

/**
 * @param {number} displayProgress - seconds to display during seeking before it is committed and before react player updates progress.
 * @example
 * ```
 * (changeCommitted && !_seekOptions) ? progress : displayProgress,
 * ```
 * @param {number} progress - seconds played of all videos
 */
interface MultiVideoReplayState {
  _seekOptions?: MultiVideoSeekOptions;
  duration: number;
  offsetMap: number[];
  displayProgress: number;
  progress: number;
  playing: boolean; // re-export from VideoReplayContext. Not actually used in state
  playingIndex: number;
  timeline: TimelineVideoMetadata[];
  videos: Video[];
}

interface MultiVideoReplayActionWrappers {
  setPlayingIndex: (index: number) => void;
  setProgress: (progress: number) => void;
  makeSetVideoProgress: (i: number) => (progress: Progress) => void;
  loadNextVideo: VoidFunction;
  seekTo: (payload: SeekPayload) => void;
}

export type MultiVideoReplayContextValue = MultiVideoReplayActionWrappers & {
  state: MultiVideoReplayState;
  reactPlayerRefs: ReactPlayer[];
  reactPlayerRefHandlers: ((el: ReactPlayer | null) => void)[];
};

const _initialState: MultiVideoReplayState = {
  playing: false,
  playingIndex: 0,
  duration: 0,
  offsetMap: [],
  displayProgress: 0,
  progress: 0,
  timeline: [],
  videos: [],
};

const multiVideoReplayContextState = createSlice({
  name: 'multiVideoReplayContext',
  initialState: _initialState,
  reducers: {
    playingIndexSet(state, action: PayloadAction<number>) {
      const indexToSet = action.payload;
      state.playingIndex = indexToSet;
    },
    displayProgressSet(state, action: PayloadAction<number>) {
      state.displayProgress = action.payload;
    },
    progressSet(state, action: PayloadAction<number>) {
      state.progress = action.payload;
      state._seekOptions = undefined;
    },
    durationChanged(state, action: PayloadAction<number>) {
      state.duration = action.payload;
    },
    offsetMapChanged(state, action: PayloadAction<number[]>) {
      state.offsetMap = action.payload;
    },
    timelineChanged(state, action: PayloadAction<TimelineVideoMetadata[]>) {
      state.timeline = action.payload;
    },
    videosChanged(state, action: PayloadAction<Video[]>) {
      state.videos = action.payload;
    },
    nextVideoLoaded(state, action: PayloadAction<void>) {
      state.playingIndex = (state.playingIndex + 1) % state.timeline.length;
    },
    seekOptionsSet(state, action: PayloadAction<MultiVideoSeekOptions>) {
      state._seekOptions = action.payload;
    },
  },
});

const {
  playingIndexSet,
  displayProgressSet,
  progressSet,
  durationChanged,
  offsetMapChanged,
  timelineChanged,
  videosChanged,
  nextVideoLoaded,
  seekOptionsSet,
} = multiVideoReplayContextState.actions;

const defaultContext: MultiVideoReplayContextValue = {
  state: _initialState,
  reactPlayerRefs: [],
  reactPlayerRefHandlers: [],
  setPlayingIndex: NOOP,
  setProgress: NOOP,
  makeSetVideoProgress: () => NOOP,
  loadNextVideo: NOOP,
  seekTo: NOOP,
};

/**
 * Use this context when dealing with multiple unmerged videos.
 */
export const MultiVideoReplayContext = createContext<MultiVideoReplayContextValue>(defaultContext);

export const { Provider: MultiVideoReplayProvider } = MultiVideoReplayContext;

/**
 *
 * @param timeline - video uuids need to be in playback order
 * @returns
 */
export const useMultiVideoReplayContextValue = (timeline: TimelineVideoMetadata[]): MultiVideoReplayContextValue => {
  const videoUuids = useMemo(() => (
    timeline.map(({ videoUuid }) => videoUuid)
  ), [timeline]);
  const videos = useCreateSelector(makeGetVideosByUuids, { videoUuids });
  const {
    state: { playing },
    seekTo,
    setProgress: setVideoReplayContextProgress,
  } = useContext(VideoReplayContext);

  const initialState: MultiVideoReplayState = {
    ..._initialState,
    timeline,
    videos,
  };

  const [reactPlayerRefs, setReactPlayerRefs] = useState<ReactPlayer[]>(Array.from({ length: timeline.length }));
  const reactPlayerRefHandlers = useMemo(() => {
    return Array.from({ length: timeline.length })
      .map((_, i) => (
        (el: ReactPlayer | null) => {
          setReactPlayerRefs(p => update(p, { [i]: { $set: el } }));
        }
      ));
  }, [timeline.length]);

  const [state, dispatch] = useReducer(multiVideoReplayContextState.reducer, initialState);

  const setPlayingIndex = useCallback((index: number) => {
    dispatch(playingIndexSet(index));
  }, []);

  const setProgress = useCallback((progress: number) => {
    dispatch(progressSet(progress));
  }, []);

  const offsetMapRef = useRef(state.offsetMap);
  useEffect(() => {
    offsetMapRef.current = state.offsetMap;
  }, [state.offsetMap]);

  const playingIndexRef = useRef(state.playingIndex);
  useEffect(() => {
    playingIndexRef.current = state.playingIndex;
  }, [state.playingIndex]);

  /**
   * Pass into ReactPlayer to handle onProgress.
   */
  const makeSetVideoProgress = useCallback((i: number) => (progress: Progress) => {
    if (i === playingIndexRef.current) {
      dispatch(progressSet(offsetMapRef.current[i] + progress.playedSeconds));
      setVideoReplayContextProgress(progress);
      setSeekToIndex(undefined);
    }
  }, [setVideoReplayContextProgress]);

  useEffect(() => {
    dispatch(timelineChanged(timeline));
  }, [timeline]);

  useEffect(() => {
    dispatch(videosChanged(videos));
  }, [videos]);

  useEffect(() => {
    setPlayingIndex(clamp(state.playingIndex, 0, videoUuids.length - 1));
  }, [setPlayingIndex, state.playingIndex, videoUuids.length]);

  useEffect(() => {
    const { duration, offsetMap } = videos.reduce<{
      duration: number;
      offsetMap: number[];
    }>(({ duration, offsetMap }, video) => ({
      duration: duration + video.result.duration / 1000,
      offsetMap: offsetMap.concat(duration),
    }), {
      duration: 0,
      offsetMap: [],
    });
    dispatch(durationChanged(duration));
    dispatch(offsetMapChanged(offsetMap));
  }, [videos]);

  const loadNextVideo = useCallback(() => {
    dispatch(nextVideoLoaded());
  }, []);

  const [seekToIndex, setSeekToIndex] = useState<number>();
  const seekToIndexRef = useRef(seekToIndex);
  useEffect(() => {
    seekToIndexRef.current = seekToIndex;
  }, [seekToIndex]);

  const multiVideoSeekTo = useCallback(({ type, amount, seekCommitted }: SeekPayload) => {
    // Convert everything into seconds for easier comparison.
    let amountSeconds: number;
    switch (type) {
      case 'fraction':
        amountSeconds = amount * state.duration;
        break;
      case 'offset':
        amountSeconds = state.progress + amount;
        break;
      case 'seconds':
        amountSeconds = amount;
        break;
      default:
        console.error('"type" param passed into multiVideoSeekTo is invalid. type = ', type);
        return;
    }

    const videoIndex = state.offsetMap.reduce((videoIndex, offset, i) => {
      if (amountSeconds > offset) {
        videoIndex = i;
      }
      return videoIndex;
    }, 0);
    const adjustedAmount = amountSeconds - state.offsetMap[videoIndex];

    if (videoIndex !== state.playingIndex) {
      const currentPlayer = reactPlayerRefs[state.playingIndex];
      currentPlayer.seekTo(0, 'seconds');
      dispatch(playingIndexSet(videoIndex));
    }

    setSeekToIndex(videoIndex);

    // Set MultiVideoReplayContext _seekOptions instead of relying on useEffect to update, so it doesn't lag behind by  one update cycle
    dispatch(seekOptionsSet({
      type: 'seconds',
      amount: adjustedAmount,
      playerIndex: videoIndex,
    }));

    dispatch(displayProgressSet(amountSeconds));

    // Call VideoReplayContext's `seekTo` function to get the pause on seek feature.
    seekTo({
      type: 'seconds',
      amount: adjustedAmount,
      seekCommitted,
    });
  }, [reactPlayerRefs, seekTo, state.duration, state.progress, state.offsetMap, state.playingIndex]);

  return useMemo(() => ({
    reactPlayerRefs,
    reactPlayerRefHandlers,
    state: {
      ...state,
      playing,
    },
    setPlayingIndex,
    setProgress,
    makeSetVideoProgress,
    loadNextVideo,
    seekTo: multiVideoSeekTo,
  } as const), [reactPlayerRefs, reactPlayerRefHandlers, state, playing, setPlayingIndex, setProgress, makeSetVideoProgress, loadNextVideo, multiVideoSeekTo]);
};
