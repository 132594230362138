import { EnhancedDialogTitle } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { DialogActions, DialogContent, SxProps } from '@mui/material';
import { Fragment, PropsWithChildren } from 'react';

import { SlideLayout } from './SlideLayout';

type DialogVariant = 'dialog';
type OnboardingVariant = 'onboarding';
export type DialogSlideVariant = DialogVariant | OnboardingVariant;

interface SlideDialogLayoutProps {
  className?: string;
  sx?: SxProps<Theme>;

  variant?: DialogSlideVariant;
  onClose?: CloseHandler;
  title?: React.ReactNode;
  graphic?: React.ReactNode;
  actions?: React.ReactNode;
}

export interface SlideDialogLayoutCommonProps {
  variant?: DialogSlideVariant;
  onClose?: CloseHandler;
}

export function DialogSlideLayout(props: PropsWithChildren<SlideDialogLayoutProps>) {
  const { actions, children, className, graphic, onClose, sx, title, variant } = props;

  if (variant === 'dialog') {
    return (
      <Fragment>
        <EnhancedDialogTitle onClose={onClose}>
          {title}
        </EnhancedDialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        {actions && (
          <DialogActions>
            {actions}
          </DialogActions>
        )}
      </Fragment>
    );
  }
  if (variant === 'onboarding') {
    return (
      <SlideLayout
      className={className}
      sx={sx}
      graphic={graphic}
      actions={actions}
      >
        {children}
      </SlideLayout>
    );
  }
  return null;
}
