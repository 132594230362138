import { VideoReplayContext } from '@insights-gaming/material-components';
import { DurationFormatter } from '@insights-gaming/utils';
import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';

export function useVideoInitialTime() {
  const { seekTo } = useContext(VideoReplayContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const timestamp = searchParams.get('t');
  const initialTime = timestamp ? DurationFormatter.parse(timestamp) : undefined;

  return useMemo(() => initialTime === undefined ? undefined : () => {
    seekTo({ type: 'seconds', amount: initialTime, seekCommitted: true });
  }, [initialTime, seekTo]);
}
