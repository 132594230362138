import { AnyGameEvent, AnyGameInfoUpdate, GameClassIdLike } from '@insights-gaming/game-events';
import EventEmitter from 'events';
import { LocationState, Path } from 'history';
import { OverridedMixpanel } from 'mixpanel-browser';
import { createContext } from 'react';
import TypedEventEmitter from 'typed-emitter';

import { VideoBookmark } from '@/features/bookmarks/bookmark-slice';
import { VideoReplayRightPanelTab } from '@/features/settings/setting-slice';
import { Telemetry } from '@/features/telemetry/Telemetry';
import { RecordingType } from '@/features/video-library/video-library-slice';
import { mixpanelProxy } from '@/mixpanel/mixpanel-proxy';

export interface AppEventMap {
  addClipSegment: VoidFunction;
  associateCommentDraftWithBookmark: (bookmark: VideoBookmark) => void;
  bookmarkSaved: VoidFunction;
  currentStreamTagAdded: VoidFunction;
  openForReview: VoidFunction;
  openStorageManagment: VoidFunction;
  selectVideoReplayRightPanel: (tab: VideoReplayRightPanelTab, callback?: VoidFunction) => void;
  streamStartFailed: (result: TypedAggregateError<overwolf.streaming.StreamResult>) => void;
  streamingError: (result: overwolf.Result & overwolf.streaming.StreamEvent) => void;
  streamRestart: (recordingType: RecordingType) => void;
  replayDisabled: VoidFunction;
  replaySaved: VoidFunction;
  replayError: (result: GetOverwolfEventParameters<typeof overwolf.media.replays.onCaptureError>) => void;
  autoRecordAttemptedWhileDesktopRecording: VoidFunction;
  autoRecordCancel: VoidFunction;
  videoGameClassViewed: (gameClassId?: GameClassIdLike) => void;
  desktopWindowDragging: (dragging: boolean) => void;
  checkForSessionStreamStart: VoidFunction;
  event: (events: AnyGameEvent[]) => void;
  info: (info: AnyGameInfoUpdate) => void;
  closeWindow: (windowName: WindowName) => void;
}

interface RouteEventMap {
  push: (path: string, args?: any) => void;
  replace: (path: Path, state?: LocationState) => void;
  go: (n: number) => void;
  goBack: () => void;
  goForward: () => void;
}

export type RouteEventEmitter = TypedEventEmitter<RouteEventMap>;
export type AppEventEmitter = TypedEventEmitter<AppEventMap>;

interface GlobalContextState {
  routeChan: RouteEventEmitter;
  eventChan: AppEventEmitter;
  telemetry: Telemetry;
  mixpanel: OverridedMixpanel;
  overwolfLanguage: string;
}

export const GlobalContext = createContext<GlobalContextState>({
  routeChan: new EventEmitter(),
  eventChan: new EventEmitter(),
  telemetry: new Telemetry(),
  mixpanel: mixpanelProxy,
  overwolfLanguage: 'en',
});

export const { Consumer: GlobalConsumer, Provider: GlobalProvider } = GlobalContext;
