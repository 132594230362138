import { FlexSpacer } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { TextField, TextFieldProps } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { forwardRef, memo, ReactNode } from 'react';

interface IconTextFieldOwnProps {
  className?: string;
  icon?: ReactNode;
}

type IconTextFieldProps = IconTextFieldOwnProps & TextFieldProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  textField: {
    flex: 1,
  },
}), { name: 'IconTextField' });

const IconTextField = forwardRef<HTMLDivElement, IconTextFieldProps>(function IconTextField(props: IconTextFieldProps, ref) {
  const classes = useStyles(props);
  const { className, icon, ...rest } = props;
  return (
    <FlexSpacer flexAlignItems='flex-end' className={classNames(classes.root, className)}>
      {icon}
      <TextField ref={ref} {...rest} className={classes.textField} />
    </FlexSpacer>
  );
});
IconTextField.displayName = 'IconTextField';

export default memo(IconTextField);
