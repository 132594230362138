import './wdyr';

import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import EntryPoint from './entry/Entry.REACT_APP_BUILD_TARGET';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <Suspense>
    <EntryPoint />
  </Suspense>,
);
