import { Theme } from '@insights-gaming/theme';
import { SxProps } from '@mui/material';
import useMergedRef from '@react-hook/merged-ref';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useDrag, useDragDropManager, useDrop, XYCoord } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import NavigationButton from '@/features/desktop-window/navigation/NavigationButton';
import { ItemTypes, TeamDragItem, TeamDragItem_TeamFragment } from '@/features/video-library/item-types';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

import { TeamAvatar } from './TeamAvatar';

const TeamAvatarButton_TeamFragment = gql(`
  fragment TeamAvatarButton_TeamFragment on Team {
    id
    name
    ...TeamAvatar_TeamFragment
    ...TeamDragItem_TeamFragment
  }
`);

interface TeamAvatarButtonOwnProps {
  sx?: SxProps<Theme>;
  team: FragmentType<typeof TeamAvatarButton_TeamFragment>;
  selected?: boolean;
  onClick?: (teamId: ID) => void;
  onUpdateTeamOrder?: (dragId: ID, dropId: ID, isAboveMiddle: boolean) => void;
}

type TeamAvatarButtonProps = TeamAvatarButtonOwnProps;

interface Collected {
  canDrop: boolean;
  isOver: boolean;
}

export const TeamAvatarButton = forwardRef(function TeamAvatarButton(props: TeamAvatarButtonProps, forwardedRef) {
  const { sx, onClick, onUpdateTeamOrder, selected } = props;
  const team = getFragmentData(TeamAvatarButton_TeamFragment, props.team);

  const ref = useRef<HTMLButtonElement>(null);
  const mergedRef = useMergedRef(ref, forwardedRef);

  const [, drag, preview] = useDrag<TeamDragItem>(() => ({
    type: ItemTypes.TEAM,
    item: {
      type: ItemTypes.TEAM,
      team,
    },
  }), [team]);

  const [middle, setMiddle] = useState<number>();
  const [offset, setOffset] = useState<XYCoord | null>(null);

  const [{ canDrop, isOver }, drop] = useDrop<TeamDragItem, void, Collected>(() => ({
    accept: ItemTypes.TEAM,
    collect: (monitor) => {
      const canDrop = monitor.canDrop();
      const isOver = monitor.isOver();
      return {
        canDrop,
        isOver,
      };
    },
    hover: () => {
      const cardBoundingRect = ref.current?.getBoundingClientRect();
      if (!cardBoundingRect) {
        return;
      }
      const middle = cardBoundingRect.top + cardBoundingRect.height / 2;
      setMiddle(middle);
    },
    drop: (item, monitor) => {
      const clientOffset = monitor.getClientOffset();
      const cardBoundingRect = ref.current?.getBoundingClientRect();
      if (!clientOffset || !cardBoundingRect) {
        return;
      }
      const middle = cardBoundingRect.top + cardBoundingRect.height / 2;
      const isAboveMiddle = clientOffset.y < middle;
      const droppedTeam = getFragmentData(TeamDragItem_TeamFragment, item.team);
      onUpdateTeamOrder?.(droppedTeam.id, team.id, isAboveMiddle);
    },
  }), [onUpdateTeamOrder, team.id]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  drag(drop(ref));

  const manager = useDragDropManager();
  const monitor = manager.getMonitor();
  useEffect(() => {
    if (isOver) {
      return monitor.subscribeToOffsetChange(() => {
        setOffset(monitor.getClientOffset());
      });
    }
  }, [isOver, monitor]);

  const showDropIndicator = canDrop && isOver;
  const isAboveMiddle = offset && typeof middle === 'number' && offset?.y < middle;

  return (
    <NavigationButton
    ref={mergedRef}
    title={team.name}
    selected={selected}
    hideLabel={true}
    onClick={() => { onClick?.(team.id); }}
    isSubCategory={process.env.REACT_APP_BUILD_TARGET === 'app'}
    sx={[
      {
        height: 48,
      },
      showDropIndicator && {
        '&::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          right: 0,
          height: 4,
          backgroundColor: theme => theme.palette.primary.main,
          ...(isAboveMiddle ? {
            top: -2,
            bottom: 'auto',
          } : {
            top: 'auto',
            bottom: -2,
          }),
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <TeamAvatar team={team} />
    </NavigationButton>
  );
});
