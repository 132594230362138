import { ControlButton, VideoReplayContext } from '@insights-gaming/material-components';
import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Fragment, memo, MouseEvent, useCallback, useContext } from 'react';
import screenfull from 'screenfull';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import VideoControlsTooltip from '@/features/video-replay/VideoControlsTooltip';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

interface VideoReplayFullScreenControllerOwnProps {
  className?: string;
}

type VideoReplayFullScreenControllerProps = VideoReplayFullScreenControllerOwnProps;

function VideoReplayFullScreenController(props: VideoReplayFullScreenControllerProps) {
  const {
    state: { fullscreen },
    toggleFullscreen,
  } = useContext(VideoReplayContext);

  const fullscreenEl = useContext(FullscreenContext);
  const keybindLookup = useContext(KeybindReverseLookupContext);

  const handleFullscreenClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    toggleFullscreen();
  }, [toggleFullscreen]);

  return (
    <Fragment>
      {screenfull.isEnabled && (
        <VideoControlsTooltip tKey={fullscreen ? 'hotkeys:labels.exitFullscreen' : 'hotkeys:labels.fullscreen'} keybindings={keybindLookup['video.fullscreen.toggle']} container={fullscreenEl}>
          <div>
            <ControlButton onClick={handleFullscreenClick}>
              {fullscreen ? (
                <FullScreenExitIcon />
              ) : (
                <FullScreenIcon />
              )}
            </ControlButton>
          </div>
        </VideoControlsTooltip>
      )}
    </Fragment>
  );
}

export default memo(VideoReplayFullScreenController);
