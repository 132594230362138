import { GameClassId, getFilterFn } from '@insights-gaming/game-events';
import { AlertColor } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import { fromPairs } from 'lodash';

import { allGameFeatures } from '@/app/constants';
import { RootReducer } from '@/app/rootReducer.app';
import { isExistent } from '@/utils/guard';
import { sanitizeGameClassId } from '@/utils/overwolf';
import { getGameClassIdLikeFromProps, getHotkeyNameFromProps, getHotkeyNamesFromProps } from '@/utils/props-selector';
import customUpdate from '@/utils/update';

import { castOWSuccessResult } from './castowresult';
import { EventHealthLevel, OverwolfGameEventHealth } from './overwolf-data-slice';

export const getHotkeys = (state: RootReducer) => state.overwolfData.settings.hotkeys;

export const getOverwolfStreamEncodersResult = (state: RootReducer) => state.overwolfData.streaming.streamEncoders;
export const getOverwolfAudioDevicesResult = (state: RootReducer) => state.overwolfData.streaming.audioDevices;
export const getOverwolfVideoCaptureSettingsResult = (state: RootReducer) => state.overwolfData.settings.videoCaptureSettings;
export const getOverwolfAudioCaptureSettingsResult = (state: RootReducer) => state.overwolfData.settings.audioCaptureSettings;
export const getConflictingRecordingApps = (state: RootReducer) => state.overwolfData.extensions.conflictingApps;
export const getVideosFolder = (state: RootReducer) => state.overwolfData.settings.videosFolder;
export const getBadVideoFolder = (state: RootReducer) => state.overwolfData.settings.badVideoFolder;
export const getCurrentUserResult = (state: RootReducer) => state.overwolfData.profile.currentUser;
export const getExtensionSettings = (state: RootReducer) => state.overwolfData.settings.extensionSettings;
export const getOverwolfStreamingCapabilities = (state: RootReducer) => state.overwolfData.streaming.capabilities;
export const getAudioProcessCaptureSupported = (state: RootReducer) => state.overwolfData.streaming.capabilities?.audio.audioProcessCaptureSupported;
export const getSystemInformation = (state: RootReducer) => state.overwolfData.utils.systemInformation;
export const getMonitorsList = (state: RootReducer) => state.overwolfData.utils.monitorsList;
export const getCurrentGameState = (state: RootReducer) => state.overwolfData.games.currentGameState;
export const getCurrentGameClassId = (state: RootReducer) => state.overwolfData.games.currentGameState.classId;
export const getCurrentGameIdLike = (state: RootReducer) => state.overwolfData.games.currentGameState.classIdLike;
export const gameEventHealthDict = (state: RootReducer) => state.overwolfData.games.gameEventHealthDict;
export const getWebcams = (state: RootReducer) => state.overwolfData.media.webcams;

const hevcRegex = /_HEVC$/i;

export const getEnabledStreamEncoders = createSelector(
  [getOverwolfStreamEncodersResult],
  (result) => {
    if (result?.success) {
      return result.encoders.filter(e => e.enabled && e.valid && !hevcRegex.test(e.name));
    }
    return [];
  },
);

export const getOverwolfPlaybackDevices = createSelector(
  [getOverwolfAudioDevicesResult],
  (result) => {
    if (result?.success) {
      return result.devices.filter(d => d.can_playback);
    }
    return [];
  },
);

export const getOverwolfRecordingDevices = createSelector(
  [getOverwolfAudioDevicesResult],
  (result) => {
    if (result?.success) {
      return result.devices.filter(d => d.can_record);
    }
    return [];
  },
);

export const getOverwolfDefaultPlaybackDeviceId = createSelector(
  [getOverwolfAudioDevicesResult],
  (result) => {
    if (result?.success) {
      return result.default_playback_device_id;
    }
  },
);

export const getOverwolfDefaultPlaybackDevice = createSelector(
  [getOverwolfPlaybackDevices, getOverwolfDefaultPlaybackDeviceId],
  (devices, id) => devices.find(device => device.device_id === id),
);

export const getOverwolfDefaultRecordingDeviceId = createSelector(
  [getOverwolfAudioDevicesResult],
  (result) => {
    if (result?.success) {
      return result.default_recording_device_id;
    }
  },
);

export const getOverwolfDefaultRecordingDevice = createSelector(
  [getOverwolfRecordingDevices, getOverwolfDefaultRecordingDeviceId],
  (devices, id) => devices.find(device => device.device_id === id),
);

const getOverwolfGameDBInfoResults = (state: RootReducer) => state.overwolfData.games.gameDBInfoResults;
const getGameDBInfos = createSelector(
  [getOverwolfGameDBInfoResults],
  (results) =>
    Object.entries(results)
      .filter((p): p is [string, overwolf.games.GetGameDBInfoResult] => !!p[1])
      .map(([k, v]): [string, overwolf.games.GameInfo | undefined] => ([k, v.gameInfo || v.installedGameInfo?.GameInfo])),
);

const getInstalledGameDBInfos = createSelector(
  [getOverwolfGameDBInfoResults],
  (results) =>
    Object.entries(results)
      .filter((p): p is [string, overwolf.games.GetGameDBInfoResult] => !!p[1] && !!p[1].installedGameInfo)
      .map(([k, v]): [string, overwolf.games.GameInfo | undefined] => ([k, v.installedGameInfo?.GameInfo])),
);

export const getInstalledGameClassIds = createSelector(
  [getInstalledGameDBInfos],
  (info) => new Set(info.map(([id]) => id)),
);

export const getGameTitles = createSelector(
  [getGameDBInfos],
  (gameInfos) => fromPairs(gameInfos.map(([k, v]) => ([k, v?.GameTitle]))),
);


// const createHealthDict = (state: RootReducer) => state.background.health.games.reduce<Partial<Dictionary<number>>>((p, c) => {
//   p[c.game_id] = c.state;
//   return p;
// }, {});

// const getHealthAsDict = createSelector(
//   [createHealthDict],
//   (health) => health,
// );

const getGameHealthArray = (state: RootReducer) => state.overwolfData.health.games;

type StatusDict = Record<EventHealthLevel, number[]>;
const groupByHealth = (games: OverwolfGameEventHealth[]) => {
  const initial: StatusDict = {
    [EventHealthLevel.UNSUPPORTED]: [],
    [EventHealthLevel.HEALTHY]: [],
    [EventHealthLevel.DEGRADED]: [],
    [EventHealthLevel.UNHEALTHY]: [],
  };
  return games.reduce((p, c) => {
    return update(p, {
      [c.state]: (dict: number[]) => c.game_id ? update(dict, { $push: [c.game_id] }) : dict,
    });
  }, initial);
};

export const getHealthCheckStatus = createSelector(
  [(state: RootReducer) => state.overwolfData.health.status],
  (status) => status,
);

export const getGamesGroupedByHealth = createSelector(
  [getGameHealthArray],
  groupByHealth,
);

export const getInstalledGamesGroupedByHealth = createSelector(
  [getGameHealthArray, getInstalledGameDBInfos],
  (healthArray, installedGames) => {
    const installedGameIds = installedGames.map(game => Number(game[0]));
    return groupByHealth(healthArray.filter(gameHealth => installedGameIds.includes(gameHealth.game_id)));
  },
);

const _getHighestSeverity = (groups: StatusDict): AlertColor => {
    if (groups[EventHealthLevel.UNHEALTHY].length) {
      return 'error';
    }
    if (groups[EventHealthLevel.DEGRADED].length) {
      return 'warning';
    }
    return 'success';
  };

export const getHighestSeverity = createSelector(
  [getGamesGroupedByHealth],
  _getHighestSeverity,
);

export const getInstalledHighestSeverity = createSelector(
  [getInstalledGamesGroupedByHealth],
  _getHighestSeverity,
);

export const getUnsupportedGames = createSelector(
  [getGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.UNSUPPORTED],
);

export const getHealthyGames = createSelector(
  [getGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.HEALTHY],
);

export const getDegradedGames = createSelector(
  [getGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.DEGRADED],
);

export const getUnhealthyGames = createSelector(
  [getGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.UNHEALTHY],
);

export const getInstalledUnsupportedGames = createSelector(
  [getInstalledGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.UNSUPPORTED],
);

export const getInstalledHealthyGames = createSelector(
  [getInstalledGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.HEALTHY],
);

export const getInstalledDegradedGames = createSelector(
  [getInstalledGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.DEGRADED],
);

export const getInstalledUnhealthyGames = createSelector(
  [getInstalledGamesGroupedByHealth],
  (groups) => groups[EventHealthLevel.UNHEALTHY],
);

export const makeGetMergedOWHotkeyDict = () => createSelector(
  [getHotkeys, getGameClassIdLikeFromProps],
  (hotkeys, gameClassId) => {
    const globalDict = Object.fromEntries(hotkeys.globals.map((hotkey) => [hotkey.name, hotkey]));
    if (gameClassId) {
      const gameHotkeys = hotkeys.games[sanitizeGameClassId(gameClassId) as GameClassId];
      if (gameHotkeys) {
        const gameDict = Object.fromEntries(gameHotkeys.map(hotkey => [hotkey.name, hotkey]));
        return customUpdate(globalDict, { $deepmerge: gameDict });
      }
    }
    return globalDict;
  },
);

export function getOWHotkeyByName(
  hotkeys: ReturnType<typeof getHotkeys>,
  gameClassId: ReturnType<typeof getGameClassIdLikeFromProps>,
  hotkeyName: ReturnType<typeof getHotkeyNameFromProps>,
) {
  if (gameClassId) { // try to get the game-specific hotkey
    const hotkey = hotkeys.games[sanitizeGameClassId(gameClassId) as GameClassId]?.find(hotkey => hotkey.name === hotkeyName);
    if (hotkey) {
      return hotkey;
    }
  }

  // fallback to global
  return hotkeys.globals.find(hotkey => hotkey.name === hotkeyName);
}

export const makeGetHotkeyByName = () => createSelector(
  [getHotkeys, getGameClassIdLikeFromProps, getHotkeyNameFromProps],
  getOWHotkeyByName,
);

export const makeGetHotkeysByNames = () => createSelector(
  [getHotkeys, getGameClassIdLikeFromProps, getHotkeyNamesFromProps],
  (hotkeys, gameClassId, hotkeyNames) => {
    return hotkeyNames.map(name => getOWHotkeyByName(hotkeys, gameClassId, name));
  },
);

export const getVideoFolderString = createSelector(
  [getVideosFolder],
  (videoFolder) => {
    if (videoFolder?.success) {
      return videoFolder?.path.Value;
    }
  },
);

const getRecentlyPlayed = (state: RootReducer) => state.overwolfData.games.recentlyPlayed;
export const getRecentlyPlayedGameClassIds = createSelector(
  [getRecentlyPlayed],
  (result) => (result.games || []).map(gameId => Math.floor(gameId / 10)),
);

export const getReleaseChannel = createSelector(
  [getExtensionSettings],
  (settings) => {
    if (!settings || !settings.success) {
      return '';
    }
    return settings.settings.channel;
  },
);

const getGamesOverlayEnabledResult = (state: RootReducer) => state.overwolfData.games.gameOverlayEnabledResults;
const getGamesOverlayEnabledStatus = (state: RootReducer) => state.overwolfData.games.gameOverlayEnabledStatus;

export const getGamesOverlayDisabledGameIdsAsArray = createSelector(
  [getGamesOverlayEnabledResult],
  (result) => Object.values(result).filter((game) => game && !game.enabled).filter(isExistent),
);

export const getGamesOverlayEnabledFetchStatus = createSelector(
  [getGamesOverlayEnabledStatus],
  (status) => status,
);

const getGamesAutoLaunchEnabledResult = (state: RootReducer) => state.overwolfData.games.gameAutoLaunchEnabledResults;

export const makeGetGameAutoLaunchEnabled = () => createSelector(
  [getGamesAutoLaunchEnabledResult, getGameClassIdLikeFromProps],
  (result, gameClassId) => {
    if (!gameClassId) {
      return;
    }
    return result[sanitizeGameClassId(gameClassId) as GameClassId];
  },
);

export const getReferrerId = createSelector(
  [getCurrentUserResult],
  (result) => {
    if (!result) {
      return;
    }

    try {
      const campaign = castOWSuccessResult(result).installParams?.campaign;
      if (campaign) {
        return campaign.split(':')[1];
      }
    } catch {
      return;
    }
    return;
  },
);

export const getGameSpecificHealthState = createSelector(
  [gameEventHealthDict, getCurrentGameState],
  (dict, gameState) => (gameState.gameRunning && gameState.classId) ? dict[gameState.classId] : undefined,
);

export const matchStartOrEndEventNames = new Set(['matchState',  'match_start', 'matchStart', 'match_end', 'matchEnd']);
export const matchStartOrEndEventNamesArray = Array.from(matchStartOrEndEventNames);

export const getUnhealthyFeaturesByCurrentGame = createSelector(
  [getGameSpecificHealthState],
  (gameHealth) => {
    const gameState = gameHealth?.state;
    const unavailableFeatures = gameHealth?.features.filter(feature => feature.state !== EventHealthLevel.HEALTHY);

    const supportedUnhealthyFeatures = unavailableFeatures?.filter(feature => allGameFeatures.includes(feature.name));
    const supportedUnhealthyEvents = new Set<{name: string; state: EventHealthLevel}>();
    supportedUnhealthyFeatures?.forEach(feature => {
      feature.keys.forEach(event => {
        if (event.state !== EventHealthLevel.HEALTHY) {
          supportedUnhealthyEvents.add(event);
        }
      });
    });

    const filterFn = getFilterFn(gameHealth?.game_id, Array.from(matchStartOrEndEventNames));

    return {
      gameState,
      supportedUnhealthyEvents: Array.from(supportedUnhealthyEvents).filter(filterFn),
    };
  },
);
