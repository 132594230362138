import AppWrapper from '@/components/AppWrapper';
import { SuspenseLoader } from '@/components/SuspenseLoader';

export default function Loading() {
  return (
    <AppWrapper>
      <SuspenseLoader />
    </AppWrapper>
  );
}
