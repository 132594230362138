import { EnhancedDialogTitle, VideoReplayContext } from '@insights-gaming/material-components';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Checkbox, Dialog, DialogContent, DialogProps, FormControl, FormControlLabel, Stack, TextField, Typography } from '@mui/material';
import { Fragment, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { pigeonRouter } from '@/features/pigeon-router';
import { DurationFormatter } from '@/features/settings/v3/NumberSettingEditor/duration-formatter';
import SquadRouting from '@/features/squad/squad.routing';
import { CopyLink } from '@/features/squad/TeamVideo/CopyLink';
import { useAccessControl } from '@/features/squad/useAccessControl';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { VideoPublicity } from '@/graphql/__generated__/graphql';
import { useDialogState } from '@/hooks/useDialogState';

import { PrivacySettingsDialog } from './PrivacySettingsDialog';
import { SocialMediaShare } from './SocialMediaShare';

const TeamShareVideoDialog_VideoFragment = gql(`
  fragment TeamShareVideoDialog_VideoFragment on Video {
    id
    name
    publicity
  }
`);

interface TeamShareVideoDialogProps {
  onClose?: VoidFunction;
  teamId: ID;
  video: FragmentType<typeof TeamShareVideoDialog_VideoFragment>;
}

type TeamShareVideoDialogOwnProps = TeamShareVideoDialogProps & Pick<DialogProps, 'open'>;

function isProbablyATimestamp(str: string): boolean {
  return /:/.test(str);
};

function isANumber(str: string): boolean {
  return /^\d+$/.test(str);
};

function secondsFromTimestamp(s: string): number {
  const timestampRegex = /(?:^|\s)(\d+(?::\d{2}){1,2})(?:\s|$)/;
  if (!timestampRegex.test(s)) { return -1; }
  return s.split(':').reverse().reduce((p: number, c: any, i: number) => p + c * Math.pow(60, i), 0);
}

export function TeamShareVideoDialog(props: TeamShareVideoDialogOwnProps) {
  const { onClose, open } = props;
  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth={true}>
      <TeamShareVideoDialogContent {...props} />
    </Dialog>
  );
}

function TeamShareVideoDialogContent(props: TeamShareVideoDialogOwnProps) {
  const { onClose, teamId } = props;
  const {
    state: {
      progress: { playedSeconds },
    },
  } = useContext(VideoReplayContext);

  const { canUpdateVideo } = useAccessControl(teamId);

  const video = getFragmentData(TeamShareVideoDialog_VideoFragment, props.video);

  const { t } = useTranslation(['squad_videos']);

  const [includeTimestampInCopiedLink, setIncludeTimestampInCopiedLink] = useState(false);
  const [userTimestamp, setUserTimestamp] = useState(() => DurationFormatter.format(playedSeconds, 'colons'));

  const shareUrl = useMemo(() => {
    let route = pigeonRouter.createPigeonRouter(SquadRouting.createUrl.teamVideo)(teamId, video.id);
    if (includeTimestampInCopiedLink) {
      if (userTimestamp && isProbablyATimestamp(userTimestamp)) {
        route += '?t=' + secondsFromTimestamp(userTimestamp);
      } else if (userTimestamp && isANumber(userTimestamp)) {
        route += '?t=' + parseInt(userTimestamp, 10);
      } else {
        // Just use what the video is set to
        route += '?t=' + playedSeconds;
      }
      route += 's';
    }
    return route;
  }, [includeTimestampInCopiedLink, playedSeconds, teamId, userTimestamp, video.id]);

  const handleChangeUserTimestamp = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setUserTimestamp(e.currentTarget.value);
  }, []);

  const resetTimeIfInvalid = useCallback(() => {
    if (secondsFromTimestamp(userTimestamp) <= 0 && !isANumber(userTimestamp)) {
      setUserTimestamp(DurationFormatter.format(playedSeconds, 'colons'));
    }
  }, [userTimestamp, playedSeconds]);

  const handleCheckboxOnChange = useCallback(() => {
    setIncludeTimestampInCopiedLink(includeTimestampInCopiedLink => !includeTimestampInCopiedLink);
  }, []);

  const [isPrivacySettingDialogOpen, openPrivacySettingsDialog, closePrivacySettingsDialog] = useDialogState();

  return (
    <Fragment>
      <EnhancedDialogTitle onClose={onClose}>
        {t('squad_videos:share.shareVideo')}
      </EnhancedDialogTitle>
      <DialogContent>
        <SocialMediaShare link={shareUrl || DurationFormatter.format(playedSeconds, 'colons')} videoTitle={video.name} />
        <Stack direction='row' alignItems='center' spacing={1}>
          {video.publicity === VideoPublicity.Private ? <LockIcon fontSize='small' /> : <LockOpenIcon fontSize='small' />}
          <Typography variant='caption'>
            {t(`squad_videos:share.publicity.${video.publicity}`)}
          </Typography>
        </Stack>
        <CopyLink link={shareUrl} />
        <Stack direction='row' spacing={1}>
          <FormControl margin='dense'>
            <TextField
            type='text'
            value={userTimestamp}
            onBlur={resetTimeIfInvalid}
            onChange={handleChangeUserTimestamp}
            fullWidth={true}
            />
          </FormControl>
          <FormControlLabel
          control={(
            <Checkbox
            checked={includeTimestampInCopiedLink}
            onChange={handleCheckboxOnChange}
            />
          )}
          label={t('squad_videos:share.shareAtTime')}
          />
        </Stack>
        {canUpdateVideo && (
          <Button
          startIcon={<SettingsIcon />}
          onClick={openPrivacySettingsDialog}
          size='small'
          >
            {t('squad_videos:share.changePrivacySettings')}
          </Button>
        )}
        <PrivacySettingsDialog
        open={isPrivacySettingDialogOpen}
        video={video}
        onClose={closePrivacySettingsDialog}
        />
      </DialogContent>
    </Fragment>
  );
}
