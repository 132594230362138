import { styled } from '@mui/material/styles';

interface ThumbnailOwnProps {
  className?: string;
  alt?: string;
  src: string;
  variant?: 'circular' | 'rounded' | 'square';
}

type ThumbnailProps = ThumbnailOwnProps;

function Thumbnail(props: ThumbnailProps) {
  const { alt = 'thumbnail', className, src } = props;
  return (
    <img src={src} alt={alt} draggable={false} className={className}></img>
  );
}

export default styled(Thumbnail, { name: 'Thumbnail' })(({ theme, variant }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: variant === 'circular' ? '50%' : variant === 'rounded' ? theme.spacing(1) : 0,
}));
