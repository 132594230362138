import { ENDPOINT } from '@/utils/insightsgg';

function pigeonify(url: string) {
  return ENDPOINT + url;
}

function createPigeonRouter<Args extends string[]>(
  createUrl: (...args: Args) => string,
) {
  return function pigeonRouter(...args: Args): string {
    return pigeonify(createUrl(...args));
  };
}

function createBillingUrl(teamId: ID): string {
  return ENDPOINT + `/dashboard/team/${teamId}/settings/billing/overview`;
}

function createVideoUrl(videoId: ID): string {
  return ENDPOINT + `/dashboard/video/${videoId}/replay`;
}

function createReferralLink(userId: ID): string {
  return ENDPOINT + `/c/${userId}`;
}

function createDashboardUrl(utm_campaign: 'igg_no_team', utm_medium: 'team_picker'): string {
  const search = new URLSearchParams();
  search.set('utm_campaign', utm_campaign);
  search.set('utm_medium', utm_medium);
  search.set('utm_source', 'insights_capture');
  return `${ENDPOINT}/dashboard?${search}`;
}

const utmSource = 'utm_source=insights_capture';
const notificationsUrl = ENDPOINT + `/dashboard/settings/notifications?${utmSource}&utm_medium=noti_icon&utm_campaign=ic_igg_sync`;
const tosUrl = ENDPOINT + '/terms';

export const pigeonRouter = {
  createReferralLink,
  createBillingUrl,
  createVideoUrl,
  createDashboardUrl,
  notificationsUrl,
  tosUrl,
  surveyUrl: `${ENDPOINT}/collect/survey`,
  timeUrl: `${ENDPOINT}/external/time`,
  feedbackUrl: `${ENDPOINT}/collect/feedback`,
  recordingEndUrl: `${ENDPOINT}/recordingEnd`,
  createPigeonRouter,
  pigeonify,
} as const;
