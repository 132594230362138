import actionCreatorFactory from 'typescript-fsa';

import { FileInfo, MigrationPreviewInfo } from '@/utils/io';

import { InsightsCaptureBackup } from './InsightsCaptureBackup';

export interface MigrateOutPayload {
  migrationType: 'out';
  destination: string;
}

export interface MigrateInPayload {
  migrationType: 'in-replace' | 'in-merge';
  backupFile: string;
}

export type MigratePayload = MigrateOutPayload | MigrateInPayload;

export interface MigrationStatus {
  processed: UUID[];
  destinations: Dictionary<string>;
  done?: boolean;
  failures: string[];
  migration: MigratePayload & {
    backupFile: string;
  };
}

const ac = actionCreatorFactory();
export const importDBAC = ac('__IMPORT_DB');
export const exportDBAC = ac('__EXPORT_DB');

const actionCreator = actionCreatorFactory('import-export');
export const backupDBAC = actionCreator.async<void, void, Error>('BACKUP_DB');
export const manualBackupDBAC = actionCreator.async<string, void, Error>('MANUAL_BACKUP_DB');
export const restoreDBAC = actionCreator.async<void, void, Error>('RESTORE_DB');

export const getBackupsAC = actionCreator.async<void, GetBackupsResult, Error>('GET_BACKUPS');
export const checkForBackupsAC = actionCreator.async<void, boolean, Error>('CHECK_FOR_BACKUPS');
export const getBackupFileListAC = actionCreator.async<void, overwolf.io.FileInDir[], Error>('GET_BACKUP_FILE_LIST');
export const parseAndValidateBackupsAC = actionCreator.async<overwolf.io.FileInDir[], GetBackupsResult, Error>('PARSE_AND_VALIDATE_BACKUPS');

export const restoreFromObject = actionCreator<InsightsCaptureBackup>('RESTORE_FROM_OBJECT');

export const cleanupBackupsAC = actionCreator.async<string, void, Error>('CLEANUP_BACKUPS');
export const migrateVideoFolderAC = actionCreator.async<MigratePayload, void, Error>('MIGRATE_VIDEO_FOLDER');
export const cancelMigrateVideoFolderAC = actionCreator.async<void, void, Error>('CANCEL_MIGRATE_VIDEO_FOLDER');
export const migrationInProgressFound = actionCreator<MigrationStatus>('MIGRATION_IN_PROGRESS_FOUND');
export const migrationDoneAcknowledged = actionCreator<void>('MIGRATION_DONE_ACKNOWLEDGED');

export interface MigrateVideoAlreadyExistsPayload {
  filename: string;
  sourceFileInfo: FileInfo;
  destinationFileInfo: FileInfo;
}
export const migrateVideoAlreadyExists = actionCreator<MigrateVideoAlreadyExistsPayload>('MIGRATE_VIDEO_ALREADY_EXISTS');

export type MigrateVideosAlreadyExistPayload = Required<MigrationPreviewInfo>[];
export const migrateVideosAlreadyExist = actionCreator<MigrateVideosAlreadyExistPayload>('MIGRATE_VIDEOS_ALREADY_EXIST');

export type ConflictResolutionMethod = 'keep-both' | 'overwrite' | 'skip';
export interface MigrateResolveVideoAlreadyExistsPayload {
  method: ConflictResolutionMethod;
  applyToRemaining: boolean;
}
export const migrateResolveVideoAlreadyExists = actionCreator<MigrateResolveVideoAlreadyExistsPayload>('MIGRATE_RESOLVE_VIDEO_ALREADY_EXISTS');
export const migrateNotEnoughFreeSpace = actionCreator<void>('MIGRATE_NOT_ENOUGH_FREE_SPACE');
export const migrateFreeSpaceWarningRetry = actionCreator<void>('MIGRATE_FREE_SPACE_WARNING_RETRY');

interface MigrateResolveVideosAlreadyExistSinglePayload {
  type: 'single';
  uuid: UUID;
  method: ConflictResolutionMethod;
}
interface MigrateResolveVideosAlreadyExistAllPayload {
  type: 'all';
  method: ConflictResolutionMethod;
}

interface MigrateResolveVideosAlreadyExistMultiPayload {
  type: 'multi';
  methods: Record<UUID, ConflictResolutionMethod>;
}

export type MigrateResolveVideosAlreadyExistPayload = MigrateResolveVideosAlreadyExistSinglePayload | MigrateResolveVideosAlreadyExistAllPayload | MigrateResolveVideosAlreadyExistMultiPayload;
export const migrateResolveVideosAlreadyExist = actionCreator<MigrateResolveVideosAlreadyExistPayload>('MIGRATE_RESOLVE_VIDEOS_ALREADY_EXIST');

export type GetBackupsResultObject = /* overwolf.io.FileInDir &  */overwolf.io.ReadFileContentsResult & {
  name: string;
  created?: number;
  filePath: string;
  parsedContent: InsightsCaptureBackup;
  backupErrors: boolean;
  settingsErrors: boolean;
};

export type GetBackupsResult = PromiseFulfilledResult<GetBackupsResultObject>[];
