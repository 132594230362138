import { AsyncButton } from '@insights-gaming/material-components';
import { Dialog, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';

import Loading from '@/app/Loading';
import { useNavigate } from '@/hooks/useNavigate';
import { ENDPOINT } from '@/utils/insightsgg';

type ResponseError =
  | 'BAD_REQUEST'  // either code or password empty
  | 'INVALID_CODE'  // not base64
  | 'NOT_FOUND'  // does not exist
  | 'INTERNAL_SERVER_ERROR';  // everything else

export function PasswordReset() {
  const [isValidCode, setIsValidCode] = useState<boolean>();
  const code = useMemo(() => {
    const paramsString = window.location.hash.slice(1);
    const params = new URLSearchParams(paramsString);
    return params.get('code');
  }, []);

  useEffect(() => {
    if (!code) {
      setIsValidCode(false);
      return;
    }
    (async function () {
      const res = await fetch(ENDPOINT + '/oauth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${encodeURIComponent(code)}`,
      });
      if (!res.ok) {
        const { error } = await res.json();
        switch (error as ResponseError) {
          case 'BAD_REQUEST': // either code or password empty and we checked code up there
            setIsValidCode(true);
            break;
          case 'INVALID_CODE': // not base64
          case 'NOT_FOUND': // does not exist
          case 'INTERNAL_SERVER_ERROR': // everything else
          default: // unknown error
            setIsValidCode(false);
        }
      }
    })();
  }, [code]);

  if (isValidCode === undefined) {
    return <Loading />;
  }
  return isValidCode && code ? (
    <Dialog open={true}>
      <DialogTitle>
        Password Reset
      </DialogTitle>
      <DialogContent>
        <PasswordResetForm code={code} />
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={true}>
      <DialogTitle>
        Invalid Password Reset Link
      </DialogTitle>
    </Dialog>
  );
}

type FormError = 'PASSWORD_REQUIRED' | 'WEAK_PASSWORD' | 'PASSWORD_MISMATCH';

interface PasswordResetFormProps {
  code: string;
}

function PasswordResetForm(props: PasswordResetFormProps) {
  const { code } = props;
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const onNavigate = useNavigate();
  return (
    <form
    onSubmit={async e => {
      e.preventDefault();
      e.stopPropagation();
      let password1Error: FormError | undefined = undefined;
      let password2Error: FormError | undefined = undefined;
      if (password1) {
        if (password1.length < 6) {
          password1Error = 'WEAK_PASSWORD';
        }
      } else {
        password1Error = 'PASSWORD_REQUIRED';
      }
      if (password2 !== password1) {
        password2Error = 'PASSWORD_MISMATCH';
      }
      if (password1Error || password2Error) {
        [password1Error, password2Error].forEach((error, i) => {
          if (error) {
            enqueueSnackbar(error, { variant: 'error' });
          }
        });
        return;
      }
      await fetch(ENDPOINT + '/oauth/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${encodeURIComponent(code)}&password=${encodeURIComponent(password1)}`,
      });
      onNavigate('/');
    }}
    >
      <Stack spacing={2}>
        <TextField type='password' name='password1' placeholder='password' value={password1} onChange={e => setPassword1(e.target.value)} />
        <TextField type='password' name='password2' placeholder='confirm password' value={password2} onChange={e => setPassword2(e.target.value)} />
        <AsyncButton type='submit' variant='contained' color='primary'>
          Reset Password
        </AsyncButton>
      </Stack>
    </form>
  );
}
