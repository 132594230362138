import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

const Reaction_ReactionFragment = gql(`
  fragment Reaction_ReactionFragment on Reaction {
    id
    ... on CustomReaction {
      emoji {
        id
        url
      }
    }
    ... on UnicodeReaction {
      character
    }
  }
`);

interface ReactionOwnProps {
  reaction: FragmentType<typeof Reaction_ReactionFragment>;
}

type ReactionProps = ReactionOwnProps;

export function Reaction(props: ReactionProps) {
  const reaction = getFragmentData(Reaction_ReactionFragment, props.reaction);
  switch (reaction.__typename) {
    case 'UnicodeReaction': return (
      // @ts-ignore 2339
      <em-emoji key={reaction.id} id={reaction.character} />
    );
    case 'CustomReaction': return (
      // @ts-ignore 2339
      <em-emoji key={reaction.id} id={reaction.emoji.id} />
    );
    default: return null;
  }
}
