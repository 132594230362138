
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';

import { CreateSquadSlide } from '@/features/squad/Onboarding/CreateSquadSlide';
import { CustomizeReactionSlide } from '@/features/squad/Onboarding/CustomizeReactionSlide';
import { DialogSlideVariant } from '@/features/squad/Onboarding/DialogSlideLayout';
import { JoinSquadSlide } from '@/features/squad/Onboarding/JoinSquadSlide';
import { SquadFinishSlide } from '@/features/squad/Onboarding/SquadFinishSlide';
import { WelcomeSlide } from '@/features/squad/Onboarding/WelcomeSlide';
import SquadRouting from '@/features/squad/squad.routing';
import { useCreateDivisionMutation } from '@/features/squad/useCreateDivisionMutation';
import { useCreateTeamMutation } from '@/features/squad/useCreateTeamMutation';
import { useNavigate } from '@/hooks/useNavigate';

import { AddSquadSlide } from './AddSquadSlide';

type SquadOnboardingSlides = 'welcome' | 'create' | 'reactions' | 'invited' | 'finish' | 'addSquad';

export interface AddSquadDialogContentProps {
  startSlide?: SquadOnboardingSlides;
  onClose?: CloseHandler;
  variant: DialogSlideVariant;
}

export function AddSquadDialogContent(props: AddSquadDialogContentProps) {
  const { onClose, variant, startSlide = 'welcome' } = props;

  const [slideToShow, setSlideToShow] = useState<SquadOnboardingSlides>(startSlide);
  const [createTeam, { data: createTeamData }] = useCreateTeamMutation();
  const [createDivision, { data: createDivisionData, loading: creatingDivision }] = useCreateDivisionMutation();

  const createdSquad = createTeamData?.createTeam2?.team;
  const createdDivision = createDivisionData?.createDivision?.division;

  const onNavigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateTeamAndDivision = useCallback(async (...args: Parameters<typeof createTeam>) => {
    const p = createTeam(...args);
    const { data } = await p;
    if (data && data.createTeam2) {
      createDivision({
        variables: {
          input: {
            name: 'General',
            teamId: data.createTeam2.team.id,
          },
        },
      });
    }
    return p;
  }, [createDivision, createTeam]);

  const handleDone = useCallback(async () => {
    if (createdDivision) {
      onNavigate(SquadRouting.createUrl.teamFolder(createdDivision.team.id, createdDivision.id));
      onClose?.({});
    } else if (createdSquad) {
      try {
        const { data } = await createDivision({
          variables: {
            input: {
              name: 'General',
              teamId: createdSquad.id,
            },
          },
        });
        if (data?.createDivision) {
          onNavigate(SquadRouting.createUrl.teamFolder(data?.createDivision.division.team.id, data?.createDivision.division.id));
          onClose?.({});
        }
      } catch (error) {
        enqueueSnackbar('Failed to create channel', { variant: 'error' });
      }
    }
  }, [createDivision, createdDivision, createdSquad, enqueueSnackbar, onClose, onNavigate]);

  switch (slideToShow) {
    case 'welcome':
      return <WelcomeSlide variant={variant} onClose={onClose} onInvitedClick={() => setSlideToShow('invited')} onCreateSquadClick={() => setSlideToShow('create')} />;
    case 'addSquad':
      return <AddSquadSlide variant={variant} onClose={onClose} onInvitedClick={() => setSlideToShow('invited')} onCreateSquadClick={() => setSlideToShow('create')} />;
    case 'create':
      return <CreateSquadSlide variant={variant} onClose={onClose} onBack={() => setSlideToShow(startSlide)} onCancel={onClose} onNext={() => setSlideToShow('reactions')} onCreateTeam={handleCreateTeamAndDivision} />;
    case 'reactions':
      return createdSquad ? <CustomizeReactionSlide variant={variant} onClose={onClose} onDone={() => setSlideToShow('finish')} createdSquad={createdSquad} /> : null;
    case 'invited':
      return <JoinSquadSlide variant={variant} onClose={onClose} onBack={() => setSlideToShow(startSlide)} />;
    case 'finish':
      return createdSquad ? <SquadFinishSlide createdSquad={createdSquad} onDone={handleDone} creatingDivision={creatingDivision} /> : null;
    default:
      return null;
  }
}
