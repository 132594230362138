export function getCfAccessCredentials() {
  const cfid = window.localStorage.getItem('cf-access-client-id');
  const cfsecret = window.localStorage.getItem('cf-access-client-secret');
  if (!cfid || !cfsecret) {
    return undefined;
    // throw new Error('missing cloudflare access credentials');
  }
  return {
    'cf-access-client-id': cfid,
    'cf-access-client-secret': cfsecret,
  };
}
