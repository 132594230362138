import { useMutation } from '@apollo/client';

import { gql } from '@/graphql/__generated__';
import { CreateDivisionInput } from '@/graphql/__generated__/graphql';
import { useMixpanel } from '@/hooks/useMixpanel';
import { mixpanelSquadHelpers } from '@/mixpanel/helpers/squad-helpers';

const CreateDivisionMutation = gql(`
  mutation CreateDivisionMutation($input: CreateDivisionInput!) {
    createDivision(input: $input) {
      division {
        id
        name
        picture
        banner
        team {
          id
        }
        ...TeamChannelListItem_DivisionFragment
      }
    }
  }
`);

export function useCreateDivisionMutation() {
  const mixpanel = useMixpanel();
  return useMutation(CreateDivisionMutation, {
    onCompleted(data, options) {
      if (data.createDivision?.division.id) {
        const input: CreateDivisionInput | undefined = options?.variables?.input;
        mixpanel.track(...mixpanelSquadHelpers.teamDivisionCreated(input));
      }
    },
  });
}
