import { useBooleanState } from '@insights-gaming/utils';
import { UseBooleanStateOptions } from '@insights-gaming/utils/dist/useBooleanState';
import { useCallback, useMemo } from 'react';

type OpenFunction = VoidFunction;
type CloseFunction = VoidFunction;
type ToggleFunction = VoidFunction;

interface UseDialogStateObject {
  isOpen      : boolean;
  openDialog  : OpenFunction;
  closeDialog : CloseFunction;
  toggleDialog: ToggleFunction;
}
type UseDialogStateTuple = [boolean, OpenFunction, CloseFunction, ToggleFunction];
type UseDialogState = UseDialogStateObject & UseDialogStateTuple;

export function useDialogState(initialState: boolean = false, options?: UseBooleanStateOptions) {
  const [isOpen, openDialog, closeDialog, toggleDialog] = useBooleanState(initialState, options);
  return useMemo(() => {
    const object: UseDialogStateObject = { isOpen, openDialog, closeDialog, toggleDialog };
    const tuple: UseDialogStateTuple = [isOpen, openDialog, closeDialog, toggleDialog];
    const state: UseDialogState = Object.assign([], tuple, object);
    return state;
  }, [closeDialog, isOpen, openDialog, toggleDialog]);
}

export function useContextMenuDialogState(closeMenu: VoidFunction, initialState = false, options?: UseBooleanStateOptions) {
  const [isOpen, _openDialog, closeDialog, toggleDialog] = useDialogState(initialState, options);

  const openDialog = useCallback(() => {
    closeMenu();
    _openDialog();
  }, [_openDialog, closeMenu]);

  return useMemo(() => {
    const object: UseDialogStateObject = { isOpen, openDialog, closeDialog, toggleDialog };
    const tuple: UseDialogStateTuple = [isOpen, openDialog, closeDialog, toggleDialog];
    const state: UseDialogState = Object.assign([], tuple, object);
    return state;
  }, [closeDialog, isOpen, openDialog, toggleDialog]);
}
