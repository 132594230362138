import { recordingEndRewardShardsAC, redeemShardsAC } from '@/features/shards/shards-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface ShardsEarnedProperties {
  [mpProps.shards]: number;
  [mpProps.dailyCap]?: boolean;
  [mpProps.monthlyCap]?: boolean;
  [mpProps.gameClassId]?: number;
}

interface ShardsRedeemedProperties {
  [mpProps.shards]: number;
  [mpProps.prizeId]: ID;
}

export interface MixpanelShardEventMap {
  'Shards Earned': ShardsEarnedProperties;
  'Shards Redeemed': ShardsRedeemedProperties;
}

function shardsEarned(
  action: ReturnType<typeof recordingEndRewardShardsAC.done>,
): MixpanelEventParams<'Shards Earned'> {
  return [
    'Shards Earned',
    {
      [mpProps.shards]: action.payload.result.amount,
      [mpProps.dailyCap]: action.payload.result.daily_cap,
      [mpProps.monthlyCap]: action.payload.result.monthly_cap,
      [mpProps.gameClassId]: action.meta?.gameClassId,
    },
  ];
}

function shardsRedeemed(
  action: ReturnType<typeof redeemShardsAC.done>,
): MixpanelEventParams<'Shards Redeemed'> {
  const { prize } = action.payload.result.redemptionEntry;
  return [
    'Shards Redeemed',
    {
      [mpProps.shards]: prize.cost,
      [mpProps.prizeId]: prize.id,
    },
  ];
}

export const shardHelpers = {
  shardsEarned,
  shardsRedeemed,
};
