import { AnyGameEvent, shouldShowDataAsEvent } from '@insights-gaming/game-events';
import { startCase } from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const namespace = 'gameEvents';

type WeakGameClassId = number | string;

export function useGameEventTranslation() {
  const { t, i18n } = useTranslation([namespace]);

  const tGameEventKeyExists = useCallback((event: AnyGameEvent, gameClassId: WeakGameClassId): boolean => {
    const tKey = `${gameClassId}.${event.name}`;
    return i18n.exists(tKey, { ns: namespace });
  }, [i18n]);

  const tGameEvent = useCallback((event: AnyGameEvent, ...gameClassIds: WeakGameClassId[]): string => {
    for (const gameClassId of gameClassIds) {
      const tKey = `${gameClassId}.${event.name}`;
      const tDataKey = `${gameClassId}.${event.name}.${event.data}`;
      if (shouldShowDataAsEvent(event, gameClassId)) {
        if (i18n.exists(tDataKey, { ns: namespace })) {
          return (t as WTFunction)(`${namespace}:${tDataKey}`);
        }
      } else if (tGameEventKeyExists(event, gameClassId)) {
        return (t as WTFunction)(`${namespace}:${tKey}`);
      }
    }
    return startCase(event.name);
  }, [i18n, t, tGameEventKeyExists]);

  return {
    tGameEventKeyExists,
    tGameEvent,
  };
}
