import { Theme } from '@insights-gaming/theme';
import { SxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import AlertDialog from '@/components/AlertDialog';
import VideoNameList from '@/components/VideoNameList';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

import { useDeleteVideoMutation } from './useDeleteVideoMutation';

const TeamVideoDeleteDialog_VideoFragment = gql(`
  fragment TeamVideoDeleteDialog_VideoFragment on Video {
    id
    name
    directory {
      id
    }
  }
`);

interface TeamVideoDeleteDialogOwnProps {
  sx?: SxProps<Theme>;
  open: boolean;
  onClose: VoidFunction;
  video: FragmentType<typeof TeamVideoDeleteDialog_VideoFragment>;
  onSubmit?: VoidFunction;
}

type TeamVideoDeleteDialogProps = TeamVideoDeleteDialogOwnProps;

function TeamVideoDeleteDialog(props: TeamVideoDeleteDialogProps) {
  const { sx, open, onClose, onSubmit } = props;

  const { t } = useTranslation(['common', 'squad_videos']);

  const [deleteVideo, { loading: deleting }] = useDeleteVideoMutation();
  const video = getFragmentData(TeamVideoDeleteDialog_VideoFragment, props.video);

  const handleDeleteVideo = useCallback(async () => {
    await deleteVideo({
      variables: {
        input: {
          id: video.id,
        },
      },
    });
    onSubmit?.();
    onClose();
  }, [deleteVideo, onClose, onSubmit, video]);

  return (
    <AlertDialog
    sx={sx}
    open={open}
    onClose={onClose}
    titleText={t('squad_videos:deleteMultiple.titleWithCount', { count: 1 })}
    description={(
      <Fragment>
        <Typography>
          {t('squad_videos:deleteMultiple.description', { count: 1 })}
        </Typography>
        <VideoNameList videoNames={[video.name]} />
        {t('squad_videos:deleteMultiple.disclaimer')}
      </Fragment>
    )}
    cancel={{
      children: t('common:cancel'),
      onClick: onClose,
    }}
    confirm={{
      children: t('squad_videos:deleteMultiple.confirm'),
      color: 'negative',
      loading: deleting,
      disabled: deleting,
      onClick: handleDeleteVideo,
    }}
    />
  );
}

const StyledTeamVideoDeleteDialog = styled(TeamVideoDeleteDialog, { name: 'TeamVideoDeleteDialog' })({});

export default memo(StyledTeamVideoDeleteDialog);
