import { makeProductTourSelectors } from '@insights-gaming/product-tour';

import { RootReducer } from '@/app/rootReducer.app';

import { ProductTourId } from './product-tour-ids';

const getProductTourSlice = (state: RootReducer) => state.productTour;

export const productTourSelectors = makeProductTourSelectors<RootReducer, ProductTourId>(getProductTourSlice);
export const {
  getViewedProductTours,
  getViewedProductToursLoaded,
  makeGetTourIdViewed,
  makeShouldShowTourId,
} = productTourSelectors;
