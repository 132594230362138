import {
  makeVideoUploadSlice,
  UploadingVideoState,
  VideoUploadDestination,
  VideoUploadOptions,
  VideoUploadState,
} from '@insights-gaming/video-upload-slice';
import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import actionCreatorFactory from 'typescript-fsa';

import { GetOneTeamDivisionQuery } from '@/apollo/queries/__generated__/GetOneTeamDivisionQuery';
import { logout } from '@/features/authentication/authentication-slice';
import { Video } from '@/features/video-library/video-library-slice';
import { MixpanelEventSource } from '@/mixpanel/common';

const name = 'video-upload';
const actionCreator = actionCreatorFactory(name);

export type UploadSource = MixpanelEventSource<'Video Replay Page' | 'Video Management Drawer' | 'Video Context Menu' | 'Video Card' | 'Selection Tools' | 'Clip Created Dialog' | 'Video Merge Page' | 'Discover Submit Clip Button' | 'Public' | 'Share Dialog'>;
export type LinkCopiedSource = MixpanelEventSource<'Share Dialog' | 'Upload Queue'>;

export interface LoadUploadQueueFromDatabaseResult {
  currentUpload?: UploadingVideoState<Video>;
  uploadQueue: VideoUploadOptions<Video>[];
}

export interface OverloadedVideoUploadState extends VideoUploadState<Video> {
  uploadQueueExpandedView?: boolean;
}

export const loadUploadQueueFromDatabaseAC = actionCreator.async<void, LoadUploadQueueFromDatabaseResult, Error>('LOAD_UPLOAD_QUEUE_FROM_DATABASE');
export const saveUploadQueueToDatabaseAC = actionCreator.async<void, void, Error>('SAVE_UPLOAD_QUEUE_TO_DATABASE');

export const loadRecentUploadDestinationsFromDatabaseAC = actionCreator.async<void, VideoUploadDestination[], Error>('LOAD_RECENT_UPLOAD_DESTINATIONS_FROM_DATABASE');
export const setUploadQueueExpandedView = actionCreator<boolean>('SET_UPLOAD_QUEUE_EXPANDED_VIEW');
export const fetchOneTeamDivisionAC = actionCreator.async<void, GetOneTeamDivisionQuery, Error>('FETCH_ONE_TEAM_DIVISION');

const wrappedSlice = makeVideoUploadSlice<{ id: ID }, Video>({
  extraReducers: (builder: ActionReducerMapBuilder<OverloadedVideoUploadState>) => {
    builder.addCase(loadUploadQueueFromDatabaseAC.done, (state, { payload: { result } }) => {
      state.currentUpload = result.currentUpload;
      state.uploadQueue = result.uploadQueue;
    });
    builder.addCase(loadRecentUploadDestinationsFromDatabaseAC.done, (state, action) => {
      state.recentDestinations = action.payload.result;
    });
    builder.addCase(setUploadQueueExpandedView, (state, action) => {
      state.uploadQueueExpandedView = action.payload;
    });
    builder.addCase(logout, state => {
      state.uploadQueue = [];
    });
  },
});
export const { allVideoUploadSagas, videoUploadActions, videoUploadSagas } = wrappedSlice;
const { videoUploadSlice } = wrappedSlice;

export const {
  clearFailedUploads,
  clearUploadedVideosTemp,
} = videoUploadSlice.actions;

export const videoUploadReducer = videoUploadSlice.reducer;
