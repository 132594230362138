import { AsyncButton, AsyncButtonProps, EnhancedDialogTitle } from '@insights-gaming/material-components';
import { DialogActions, DialogContent, DialogContentText, DialogProps } from '@mui/material';
import { Fragment, memo, PropsWithChildren } from 'react';

type Action = Pick<AsyncButtonProps, 'children' | 'color' | 'disabled' | 'loading' | 'onClick'>;

interface PresetActions {
  cancel: Action;
  confirm: Action;
}

type Actions = {
  actions?: never;
} & AtLeastOne<PresetActions> | {
  actions: JSX.Element;
} & Partial<Record<keyof PresetActions, never>>;

interface AlertDialogOwnProps {
  className?: string;
  onClose: VoidFunction;
  titleText?: string;
  description: string | JSX.Element | false;
  showCloseButton?: boolean;
}

export type AlertDialogProps = PropsWithChildren<
  AlertDialogOwnProps &
  Omit<DialogProps, 'onClose' | 'children'> &
  Actions
>;

function AlertDialogContent(props: Omit<AlertDialogProps, 'open'>) {
  const { actions, cancel, confirm, children, description, titleText, onClose, showCloseButton } = props;
  return (
    <Fragment>
      {titleText && (
        <EnhancedDialogTitle onClose={showCloseButton ? onClose : undefined}>
          {titleText}
        </EnhancedDialogTitle>
      )}
      <DialogContent>
        {typeof description === 'string' ? (
          <DialogContentText>
            {description}
          </DialogContentText>
        ) : (
          description
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {cancel && (
          <AlertAction action={cancel} variant='outlined' />
        )}
        {confirm && (
          <AlertAction action={confirm} variant='contained' color='primary' />
        )}
        {actions}
      </DialogActions>
    </Fragment>
  );
}

function AlertAction({ action: { children: text, ...action }, ...rest }: {action: Action} & Pick<AsyncButtonProps, 'color' | 'variant'>) {
  return (
    <AsyncButton {...rest} {...action}>
      {text}
    </AsyncButton>
  );
}

export default memo(AlertDialogContent);
