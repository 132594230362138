import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(Box, { name: 'AppWrapper' })(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  background: theme.palette.gradient.background?.default,
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  height: '100vh',
  overflow: 'hidden',
}));
