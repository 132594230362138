import { skipToken, useBackgroundQuery } from '@apollo/client';
import { useDidUpdate } from '@insights-gaming/utils';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getLoggingOut } from '@/features/authentication/authentication-selector';
import { useUserProfile } from '@/features/authentication/UserProfileContext';
import { gql } from '@/graphql/__generated__';
import { makeBackgroundQueryContext } from '@/graphql/withBackgroundQueryContext';

const TeamContextQuery = gql(`
  query TeamContextQuery($after: Cursor, $before: Cursor, $limit: Int) {
    queryTeams(after: $after, before: $before, limit: $limit) {
      teamEdges {
        team {
          id
          isVisible @client
          name
          freeTrial
          ...TeamSelectorItemRenderer_TeamFragment
        }
        ...UseAccessControl_TeamEdgeFragment
        ...TeamPicker_TeamEdgeFragment
      }
      pageInfo {
        ...QueryPageInfoFragment
      }
    }
  }
`);

export const TeamContext = makeBackgroundQueryContext(TeamContextQuery);

export function TeamInjector({ children }: PropsWithChildren) {
  const loggingOut = useSelector(getLoggingOut);
  const userProfile = useUserProfile();
  const [queryRef, { refetch }] = useBackgroundQuery(TeamContextQuery, !userProfile || loggingOut ? skipToken : { variables: { limit: 100 } });
  const value = useMemo(() => ({ query: TeamContextQuery, queryRef, variables: { limit: 100 } }), [queryRef]);
  useDidUpdate(!loggingOut && userProfile, (curr, prev) => {
    if (curr && !prev) {
      refetch();
    }
  });
  return (
    <TeamContext.Provider value={value}>
      {children}
    </TeamContext.Provider>
  );
}

export const SelectedTeamContext = createContext<ID | undefined>(undefined);
