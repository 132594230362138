import { QueryReference, skipToken, useBackgroundQuery, useQuery, useSubscription } from '@apollo/client';
import { createContext, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';

import { DocumentType, gql } from '@/graphql/__generated__';

import { getAuthSession } from './authentication-selector';

export const UserProfileQuery = gql(`
  query UserProfileQuery {
    me {
      id
      alias
      features
      picture
      freeTrial
      shards
      ...AuthButton_SelfFragment
      ...DesktopTitleBar_SelfFragment
      ...ReferralButton_SelfFragment
      ...ReferralDialog_SelfFragment
      ...UseEarnedFirstCredit_SelfFragment
      ...IdentityWorker_SelfFragment
      ...PremiumDialogWorker_SelfFragment
      ...LoginFlow_SelfFragment
    }
    __typename # This is hack to prevent apollo from infinite loader
  }
`);

export const UserProfileContext = createContext<[QueryReference<DocumentType<typeof UserProfileQuery>> | undefined]>({} as any);

export function UserProfileInjector({ children }: PropsWithChildren) {
  const authSession = useSelector(getAuthSession);
  const [queryRef] = useBackgroundQuery(UserProfileQuery, authSession ? undefined : skipToken);
  return (
    <UserProfileContext.Provider value={[queryRef]}>
      {children}
    </UserProfileContext.Provider>
  );
}

export type UserProfile = DocumentType<typeof UserProfileQuery>['me'];

/**
 * Kind of a hack to get the current user profile. This is used because not everything is suspense ready. Replacement for `useSelector(getUserProfile)`.
 * @returns The current user profile, or undefined if the user is not logged in.
 */
export function useUserProfile(): UserProfile | undefined {
  const authSession = useSelector(getAuthSession);
  const { data, networkStatus } = useQuery(UserProfileQuery, {
    skip: !authSession,
  });

  return networkStatus < 3 ? undefined : data?.me; // avoid stale data
}

/**
 * Kind of hack to get the current user's features. This is used because not everything is suspense ready. Replacement for `useSelector(getUserFeatures)`.
 * @returns The current user's features, or an empty array if the user is not logged in.
 */
export function useUserFeatures() {
  return useUserProfile()?.features || [];
}

/**
 * Kind of hack to see if the current user has adfree. This is used because not everything is suspense ready. Replacement for `useSelector(getUserHasAdfree)`.
 * @returns Whether the current user has the adfree feature, or false if the user is not logged in.
 */
export function useUserHasAdFree(): boolean {
  const features = useUserFeatures();
  return features.includes('ADFREE');
}

const UserProfileSubscription = gql(`
  subscription UserProfileSubscription {
    userEvents {
      ... on SelfUpdatedEvent {
        me {
          id
          features
        }
        __typename
      }
    }
  }
`);

export function useUserProfileSubscription() {
  useSubscription(UserProfileSubscription);
}

function UserProfileSubscriber() {
  useSubscription(UserProfileSubscription);
  return null;
}

export function UserProfileManager() {
  const user = useUserProfile();
  return user ? <UserProfileSubscriber /> : null;
}
