import { useSnackbar } from 'notistack';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { typedAssetPath } from '@/utils';

const MAX_FILE_SIZE = 1048576;
const MAX_USERNAME_LENGTH = 30;

export interface CreateSquadFormProps {
  picture: File | undefined;
  preview: string;
  teamName: string | undefined;
  onPictureChange: ChangeEventHandler<HTMLInputElement>;
  onTeamNameChange: ChangeEventHandler<HTMLInputElement>;
}

export function useCreateSquadFormProps(): CreateSquadFormProps {
  const { t } = useTranslation(['common', 'squad_onboarding']);
  const { enqueueSnackbar } = useSnackbar();

  const [picture, setPicture] = useState<File | undefined>();
  const [teamName, setTeamName] = useState<string>();

  useEffect(() => {
    if (picture) {
      return;
    }
    const loadImg = async () => {
      const result = await fetch(typedAssetPath('/assets/squad/igg-profile.png'));
      const imgBlob = await result.blob();
      setPicture(new File([imgBlob], 'igg-profile.png', { type: 'image/png' }));
    };
    loadImg();
  }, [picture]);

  const preview = picture ? URL.createObjectURL(picture) : typedAssetPath('/assets/squad/igg-profile.png');

  const handlePictureChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(t('common:picexceedsizelimit', { bytes: MAX_FILE_SIZE }), { variant: 'warning' });
      return;
    }
    setPicture(file);
  }, [enqueueSnackbar, t]);

  const handleTeamNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { currentTarget: { value } } = e;
    if (value.length >= MAX_USERNAME_LENGTH) {
      return;
    }
    setTeamName(value);
  }, []);

  return {
    picture,
    preview,
    teamName,
    onPictureChange: handlePictureChange,
    onTeamNameChange: handleTeamNameChange,
  };
}
