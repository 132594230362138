import { InsightsGGIcon } from '@insights-gaming/material-icons';
import { Theme } from '@insights-gaming/theme';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { IconButton, SxProps } from '@mui/material';
import { useContext } from 'react';

import { UploadQueueItem } from './UploadQueueItem';
import { AbortUploadContext } from './useAbort';
import { videoUploadSelectors } from './video-upload-selector';

interface QueuedUploadQueueItemProps {
  className?: string;
  sx?: SxProps<Theme>;

  detailedView?: boolean;
  item: ReturnType<typeof videoUploadSelectors.getUploadQueue>[0];
  onPromote?: (uploadQueueItem: UUID) => void;
}

export function QueuedUploadQueueItem(props: QueuedUploadQueueItemProps) {
  const { className, detailedView, item, onPromote, sx } = props;

  const { single } = useContext(AbortUploadContext);

  return (
    <UploadQueueItem
    destinationIcon={<InsightsGGIcon fontSize='small' />}
    name={item.videoMetadata.name}
    progress={item.startedUpload?.progress}
    detailedView={detailedView}
    className={className}
    sx={sx}
    secondaryAction={(
      <IconButton size='small' edge='end' onClick={() => single.onOpen(item.upload.uploadItemUuid)}>
        <CloseIcon />
      </IconButton>
    )}
    >
      <IconButton size='small' onClick={() => onPromote?.(item.upload.uploadItemUuid)}>
        <PlayArrowIcon />
      </IconButton>
    </UploadQueueItem>
  );
}
