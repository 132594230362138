import { InsightsGGIcon } from '@insights-gaming/material-icons';
import { Theme } from '@insights-gaming/theme';
import CloseIcon from '@mui/icons-material/Close';
import PauseIcon from '@mui/icons-material/Pause';
import { IconButton, SxProps } from '@mui/material';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { UploadQueueItem, UploadQueueItemProps } from './UploadQueueItem';
import { AbortUploadContext } from './useAbort';
import { videoUploadSelectors } from './video-upload-selector';

interface CurrentUploadQueueItemProps {
  className?: string;
  sx?: SxProps<Theme>;

  detailedView?: UploadQueueItemProps['detailedView'];
  currentUpload: NonNullable<ReturnType<typeof videoUploadSelectors.getCurrentUpload>>;
  onPause?: VoidFunction;
  onRemoveUploaded?: (uploadQueueItem: UUID) => void;
}

export function CurrentUploadQueueItem(props: CurrentUploadQueueItemProps) {
  const { className, currentUpload, onPause, onRemoveUploaded, sx, ...rest } = props;

  const { current } = useSelector(videoUploadSelectors.getEtaInSeconds);

  const { single } = useContext(AbortUploadContext);

  return (
    <UploadQueueItem
    destinationIcon={<InsightsGGIcon fontSize='small' />}
    name={currentUpload.videoMetadata.name}
    progress={currentUpload.upload.progress}
    eta={current}
    className={className}
    sx={sx}
    secondaryAction={(
      <IconButton
      size='small'
      edge='end'
      onClick={currentUpload.upload.progress === 1
        ? () => onRemoveUploaded?.(currentUpload.upload.uploadItemUuid)
        : () => single.onOpen(currentUpload.upload.uploadItemUuid)}
      >
        <CloseIcon />
      </IconButton>
    )}
    {...rest}
    >
      {currentUpload.upload.progress < 1 && (
        <IconButton size='small' onClick={onPause}>
          <PauseIcon />
        </IconButton>
      )}
    </UploadQueueItem>
  );
}
