import { Theme } from '@insights-gaming/theme';
import { IconButton } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import CSS from 'csstype';
import { forwardRef, memo, MouseEvent, PropsWithChildren } from 'react';

interface SeekbarEventBaseOwnProps {
  className?: string;
  style?: CSS.Properties;
  onClick?: (e: MouseEvent) => void;
}

export type SeekbarEventBaseProps = PropsWithChildren<SeekbarEventBaseOwnProps>;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'SeekbarEventBase' });

const SeekbarEventBase = forwardRef<HTMLButtonElement, SeekbarEventBaseProps>(function SeekbarEventBase(props: SeekbarEventBaseProps, ref) {
  const classes = useStyles(props);
  const { children, className, style, ...rest } = props;
  return (
    <IconButton ref={ref} size='small' className={classNames(classes.root, className)} style={style} {...rest}>
      {children}
    </IconButton>
  );
});
SeekbarEventBase.displayName = 'SeekbarEventBase';

export default memo(SeekbarEventBase);
