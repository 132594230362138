// Generated from LogicGrammar.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\n*\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004",
    "\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007",
    "\u0004\b\t\b\u0004\t\t\t\u0003\u0002\u0003\u0002\u0003\u0003\u0003\u0003",
    "\u0003\u0004\u0006\u0004\u0019\n\u0004\r\u0004\u000e\u0004\u001a\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0007\u0003",
    "\u0007\u0003\u0007\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0002",
    "\u0002\n\u0003\u0003\u0005\u0004\u0007\u0005\t\u0006\u000b\u0007\r\b",
    "\u000f\t\u0011\n\u0003\u0002\u0004\b\u0002002;C\\^^aac|\u0005\u0002",
    "\f\f\u000f\u000f\"\"\u0002*\u0002\u0003\u0003\u0002\u0002\u0002\u0002",
    "\u0005\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002\u0002",
    "\t\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002",
    "\r\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002\u0002",
    "\u0011\u0003\u0002\u0002\u0002\u0003\u0013\u0003\u0002\u0002\u0002\u0005",
    "\u0015\u0003\u0002\u0002\u0002\u0007\u0018\u0003\u0002\u0002\u0002\t",
    "\u001c\u0003\u0002\u0002\u0002\u000b\u001f\u0003\u0002\u0002\u0002\r",
    "!\u0003\u0002\u0002\u0002\u000f$\u0003\u0002\u0002\u0002\u0011&\u0003",
    "\u0002\u0002\u0002\u0013\u0014\u0007*\u0002\u0002\u0014\u0004\u0003",
    "\u0002\u0002\u0002\u0015\u0016\u0007+\u0002\u0002\u0016\u0006\u0003",
    "\u0002\u0002\u0002\u0017\u0019\t\u0002\u0002\u0002\u0018\u0017\u0003",
    "\u0002\u0002\u0002\u0019\u001a\u0003\u0002\u0002\u0002\u001a\u0018\u0003",
    "\u0002\u0002\u0002\u001a\u001b\u0003\u0002\u0002\u0002\u001b\b\u0003",
    "\u0002\u0002\u0002\u001c\u001d\u0007(\u0002\u0002\u001d\u001e\u0007",
    "(\u0002\u0002\u001e\n\u0003\u0002\u0002\u0002\u001f \u0007`\u0002\u0002",
    " \f\u0003\u0002\u0002\u0002!\"\u0007~\u0002\u0002\"#\u0007~\u0002\u0002",
    "#\u000e\u0003\u0002\u0002\u0002$%\u0007#\u0002\u0002%\u0010\u0003\u0002",
    "\u0002\u0002&\'\t\u0003\u0002\u0002\'(\u0003\u0002\u0002\u0002()\b\t",
    "\u0002\u0002)\u0012\u0003\u0002\u0002\u0002\u0004\u0002\u001a\u0003",
    "\u0002\u0003\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function LogicGrammarLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

LogicGrammarLexer.prototype = Object.create(antlr4.Lexer.prototype);
LogicGrammarLexer.prototype.constructor = LogicGrammarLexer;

Object.defineProperty(LogicGrammarLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

LogicGrammarLexer.EOF = antlr4.Token.EOF;
LogicGrammarLexer.LPAREN = 1;
LogicGrammarLexer.RPAREN = 2;
LogicGrammarLexer.BOOL = 3;
LogicGrammarLexer.AND = 4;
LogicGrammarLexer.XOR = 5;
LogicGrammarLexer.OR = 6;
LogicGrammarLexer.NOT = 7;
LogicGrammarLexer.WS = 8;

LogicGrammarLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

LogicGrammarLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

LogicGrammarLexer.prototype.literalNames = [ null, "'('", "')'", null, "'&&'", 
                                             "'^'", "'||'", "'!'" ];

LogicGrammarLexer.prototype.symbolicNames = [ null, "LPAREN", "RPAREN", 
                                              "BOOL", "AND", "XOR", "OR", 
                                              "NOT", "WS" ];

LogicGrammarLexer.prototype.ruleNames = [ "LPAREN", "RPAREN", "BOOL", "AND", 
                                          "XOR", "OR", "NOT", "WS" ];

LogicGrammarLexer.prototype.grammarFileName = "LogicGrammar.g4";



exports.LogicGrammarLexer = LogicGrammarLexer;

