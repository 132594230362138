// Generated from LogicGrammar.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');
var LogicGrammarListener = require('./LogicGrammarListener').LogicGrammarListener;
var LogicGrammarVisitor = require('./LogicGrammarVisitor').LogicGrammarVisitor;

var grammarFileName = "LogicGrammar.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\n$\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0003\u0002\u0006",
    "\u0002\b\n\u0002\r\u0002\u000e\u0002\t\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0005",
    "\u0003\u0014\n\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003",
    "\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u0003",
    "\u001f\n\u0003\f\u0003\u000e\u0003\"\u000b\u0003\u0003\u0003\u0002\u0003",
    "\u0004\u0004\u0002\u0004\u0002\u0002\u0002\'\u0002\u0007\u0003\u0002",
    "\u0002\u0002\u0004\u0013\u0003\u0002\u0002\u0002\u0006\b\u0005\u0004",
    "\u0003\u0002\u0007\u0006\u0003\u0002\u0002\u0002\b\t\u0003\u0002\u0002",
    "\u0002\t\u0007\u0003\u0002\u0002\u0002\t\n\u0003\u0002\u0002\u0002\n",
    "\u0003\u0003\u0002\u0002\u0002\u000b\f\b\u0003\u0001\u0002\f\r\u0007",
    "\u0003\u0002\u0002\r\u000e\u0005\u0004\u0003\u0002\u000e\u000f\u0007",
    "\u0004\u0002\u0002\u000f\u0014\u0003\u0002\u0002\u0002\u0010\u0011\u0007",
    "\t\u0002\u0002\u0011\u0014\u0005\u0004\u0003\u0007\u0012\u0014\u0007",
    "\u0005\u0002\u0002\u0013\u000b\u0003\u0002\u0002\u0002\u0013\u0010\u0003",
    "\u0002\u0002\u0002\u0013\u0012\u0003\u0002\u0002\u0002\u0014 \u0003",
    "\u0002\u0002\u0002\u0015\u0016\f\u0006\u0002\u0002\u0016\u0017\u0007",
    "\u0006\u0002\u0002\u0017\u001f\u0005\u0004\u0003\u0007\u0018\u0019\f",
    "\u0005\u0002\u0002\u0019\u001a\u0007\u0007\u0002\u0002\u001a\u001f\u0005",
    "\u0004\u0003\u0006\u001b\u001c\f\u0004\u0002\u0002\u001c\u001d\u0007",
    "\b\u0002\u0002\u001d\u001f\u0005\u0004\u0003\u0005\u001e\u0015\u0003",
    "\u0002\u0002\u0002\u001e\u0018\u0003\u0002\u0002\u0002\u001e\u001b\u0003",
    "\u0002\u0002\u0002\u001f\"\u0003\u0002\u0002\u0002 \u001e\u0003\u0002",
    "\u0002\u0002 !\u0003\u0002\u0002\u0002!\u0005\u0003\u0002\u0002\u0002",
    "\" \u0003\u0002\u0002\u0002\u0006\t\u0013\u001e "].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'('", "')'", null, "'&&'", "'^'", "'||'", "'!'" ];

var symbolicNames = [ null, "LPAREN", "RPAREN", "BOOL", "AND", "XOR", "OR", 
                      "NOT", "WS" ];

var ruleNames =  [ "prog", "expr" ];

function LogicGrammarParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

LogicGrammarParser.prototype = Object.create(antlr4.Parser.prototype);
LogicGrammarParser.prototype.constructor = LogicGrammarParser;

Object.defineProperty(LogicGrammarParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

LogicGrammarParser.EOF = antlr4.Token.EOF;
LogicGrammarParser.LPAREN = 1;
LogicGrammarParser.RPAREN = 2;
LogicGrammarParser.BOOL = 3;
LogicGrammarParser.AND = 4;
LogicGrammarParser.XOR = 5;
LogicGrammarParser.OR = 6;
LogicGrammarParser.NOT = 7;
LogicGrammarParser.WS = 8;

LogicGrammarParser.RULE_prog = 0;
LogicGrammarParser.RULE_expr = 1;


function ProgContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = LogicGrammarParser.RULE_prog;
    return this;
}

ProgContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ProgContext.prototype.constructor = ProgContext;

ProgContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ProgContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterProg(this);
	}
};

ProgContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitProg(this);
	}
};

ProgContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitProg(this);
    } else {
        return visitor.visitChildren(this);
    }
};




LogicGrammarParser.ProgContext = ProgContext;

LogicGrammarParser.prototype.prog = function() {

    var localctx = new ProgContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, LogicGrammarParser.RULE_prog);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 5; 
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        do {
            this.state = 4;
            this.expr(0);
            this.state = 7; 
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        } while((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << LogicGrammarParser.LPAREN) | (1 << LogicGrammarParser.BOOL) | (1 << LogicGrammarParser.NOT))) !== 0));
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExprContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = LogicGrammarParser.RULE_expr;
    return this;
}

ExprContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExprContext.prototype.constructor = ExprContext;


 
ExprContext.prototype.copyFrom = function(ctx) {
    antlr4.ParserRuleContext.prototype.copyFrom.call(this, ctx);
};

function ParensContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ParensContext.prototype = Object.create(ExprContext.prototype);
ParensContext.prototype.constructor = ParensContext;

LogicGrammarParser.ParensContext = ParensContext;

ParensContext.prototype.LPAREN = function() {
    return this.getToken(LogicGrammarParser.LPAREN, 0);
};

ParensContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};

ParensContext.prototype.RPAREN = function() {
    return this.getToken(LogicGrammarParser.RPAREN, 0);
};
ParensContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterParens(this);
	}
};

ParensContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitParens(this);
	}
};

ParensContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitParens(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function NOTContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

NOTContext.prototype = Object.create(ExprContext.prototype);
NOTContext.prototype.constructor = NOTContext;

LogicGrammarParser.NOTContext = NOTContext;

NOTContext.prototype.NOT = function() {
    return this.getToken(LogicGrammarParser.NOT, 0);
};

NOTContext.prototype.expr = function() {
    return this.getTypedRuleContext(ExprContext,0);
};
NOTContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterNOT(this);
	}
};

NOTContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitNOT(this);
	}
};

NOTContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitNOT(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ORContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ORContext.prototype = Object.create(ExprContext.prototype);
ORContext.prototype.constructor = ORContext;

LogicGrammarParser.ORContext = ORContext;

ORContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ORContext.prototype.OR = function() {
    return this.getToken(LogicGrammarParser.OR, 0);
};
ORContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterOR(this);
	}
};

ORContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitOR(this);
	}
};

ORContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitOR(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function BoolContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

BoolContext.prototype = Object.create(ExprContext.prototype);
BoolContext.prototype.constructor = BoolContext;

LogicGrammarParser.BoolContext = BoolContext;

BoolContext.prototype.BOOL = function() {
    return this.getToken(LogicGrammarParser.BOOL, 0);
};
BoolContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterBool(this);
	}
};

BoolContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitBool(this);
	}
};

BoolContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitBool(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function ANDContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

ANDContext.prototype = Object.create(ExprContext.prototype);
ANDContext.prototype.constructor = ANDContext;

LogicGrammarParser.ANDContext = ANDContext;

ANDContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

ANDContext.prototype.AND = function() {
    return this.getToken(LogicGrammarParser.AND, 0);
};
ANDContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterAND(this);
	}
};

ANDContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitAND(this);
	}
};

ANDContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitAND(this);
    } else {
        return visitor.visitChildren(this);
    }
};


function XORContext(parser, ctx) {
	ExprContext.call(this, parser);
    ExprContext.prototype.copyFrom.call(this, ctx);
    return this;
}

XORContext.prototype = Object.create(ExprContext.prototype);
XORContext.prototype.constructor = XORContext;

LogicGrammarParser.XORContext = XORContext;

XORContext.prototype.expr = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExprContext);
    } else {
        return this.getTypedRuleContext(ExprContext,i);
    }
};

XORContext.prototype.XOR = function() {
    return this.getToken(LogicGrammarParser.XOR, 0);
};
XORContext.prototype.enterRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.enterXOR(this);
	}
};

XORContext.prototype.exitRule = function(listener) {
    if(listener instanceof LogicGrammarListener ) {
        listener.exitXOR(this);
	}
};

XORContext.prototype.accept = function(visitor) {
    if ( visitor instanceof LogicGrammarVisitor ) {
        return visitor.visitXOR(this);
    } else {
        return visitor.visitChildren(this);
    }
};



LogicGrammarParser.prototype.expr = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ExprContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 2;
    this.enterRecursionRule(localctx, 2, LogicGrammarParser.RULE_expr, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 17;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case LogicGrammarParser.LPAREN:
            localctx = new ParensContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;

            this.state = 10;
            this.match(LogicGrammarParser.LPAREN);
            this.state = 11;
            this.expr(0);
            this.state = 12;
            this.match(LogicGrammarParser.RPAREN);
            break;
        case LogicGrammarParser.NOT:
            localctx = new NOTContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 14;
            this.match(LogicGrammarParser.NOT);
            this.state = 15;
            this.expr(5);
            break;
        case LogicGrammarParser.BOOL:
            localctx = new BoolContext(this, localctx);
            this._ctx = localctx;
            _prevctx = localctx;
            this.state = 16;
            this.match(LogicGrammarParser.BOOL);
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 30;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,3,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                this.state = 28;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input,2,this._ctx);
                switch(la_) {
                case 1:
                    localctx = new ANDContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, LogicGrammarParser.RULE_expr);
                    this.state = 19;
                    if (!( this.precpred(this._ctx, 4))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 4)");
                    }
                    this.state = 20;
                    this.match(LogicGrammarParser.AND);
                    this.state = 21;
                    this.expr(5);
                    break;

                case 2:
                    localctx = new XORContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, LogicGrammarParser.RULE_expr);
                    this.state = 22;
                    if (!( this.precpred(this._ctx, 3))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
                    }
                    this.state = 23;
                    this.match(LogicGrammarParser.XOR);
                    this.state = 24;
                    this.expr(4);
                    break;

                case 3:
                    localctx = new ORContext(this, new ExprContext(this, _parentctx, _parentState));
                    this.pushNewRecursionContext(localctx, _startState, LogicGrammarParser.RULE_expr);
                    this.state = 25;
                    if (!( this.precpred(this._ctx, 2))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
                    }
                    this.state = 26;
                    this.match(LogicGrammarParser.OR);
                    this.state = 27;
                    this.expr(3);
                    break;

                } 
            }
            this.state = 32;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,3,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


LogicGrammarParser.prototype.sempred = function(localctx, ruleIndex, predIndex) {
	switch(ruleIndex) {
	case 1:
			return this.expr_sempred(localctx, predIndex);
    default:
        throw "No predicate with index:" + ruleIndex;
   }
};

LogicGrammarParser.prototype.expr_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 0:
			return this.precpred(this._ctx, 4);
		case 1:
			return this.precpred(this._ctx, 3);
		case 2:
			return this.precpred(this._ctx, 2);
		default:
			throw "No predicate with index:" + predIndex;
	}
};


exports.LogicGrammarParser = LogicGrammarParser;
