import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export function useBackdropTransitionHack(open: boolean | undefined) {
  const timerRef = useRef(0);
  const [exited, setExited] = useState(false); // use exited state to force unmount the dialog to prevent bug that causes dialog to remain open

  useEffect(() => {
    if (open) {
      window.clearTimeout(timerRef.current);
      setExited(false);
    }
  }, [open]);

  const onExit = useCallback(() => {
    // fallback because onExited doesn't run if Dialog is unmounted
    timerRef.current = window.setTimeout(() => setExited(true), 500);
  }, []);

  const onExited = useCallback(() => {
    window.clearTimeout(timerRef.current);
    setExited(true);
  }, []);

  const TransitionProps = useMemo(() => ({ onExit, onExited }), [onExit, onExited]);

  return [exited, TransitionProps] as const;
}
