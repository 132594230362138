import { DirectoryCard } from '@insights-gaming/material-components';
import { memo } from 'react';

interface FolderCardDragPreviewOwnProps {
  className?: string;
  name: string;
}

type FolderCardDragPreviewProps = FolderCardDragPreviewOwnProps;

function FolderCardDragPreview(props: FolderCardDragPreviewProps) {
  const { className, name } = props;
  return (
    <DirectoryCard name={name} className={className} />
  );
}

export default memo(FolderCardDragPreview);
