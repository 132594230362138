import { Children, Fragment, PropsWithChildren, useEffect, useState } from 'react';

import { randomInt } from '@/utils';

interface MessageRandomizerProps {
  period?: number;
}

export function MessageRandomizer(props: PropsWithChildren<MessageRandomizerProps>) {
  const { children, period = 2000 } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const childrenArray = Children.toArray(children);
  const length = childrenArray.length;

  useEffect(() => {
    if (length > 1) {
      const interval = window.setInterval(() => {
        setCurrentIndex(prev => {
          let next = randomInt(0, length - 1);
          while (next === prev) {
            next = randomInt(0, length - 1);
          }
          return next;
        });
      }, period);
      return () => {
        window.clearInterval(interval);
      };
    }
  }, [length, period]);

  const currentChild = childrenArray[currentIndex];

  return (
    <Fragment>
      {currentChild}
    </Fragment>
  );
}
