import { Theme } from '@insights-gaming/theme';
import { useBooleanState } from '@insights-gaming/utils';
import { SxProps, Typography } from '@mui/material';
import { useLayoutEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router';

import AppWrapper from '@/components/AppWrapper';

import { authSessionUpdated, loginWithProviderAC } from './authentication-slice';

interface LoginCallbackProps {
  className?: string;
  sx?: SxProps<Theme>;
}

export function LoginCallback(props: LoginCallbackProps) {
  const { className, sx } = props;
  const { t } = useTranslation(['common']);
  const location = useLocation();
  const hash = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash]);
  const error = hash.get('error');
  const state = new URLSearchParams(hash.get('state') || '');
  const referPath = state.get('referPath');
  const reload = state.get('reload') === '1';

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const accessToken = hash.get('access_token');
    const expiresIn = Number.parseInt(hash.get('expires_in') || '7200');
    const expiration = Date.now() + ((expiresIn - 60) * 1000);
    const newuser = hash.get('newuser') === '1';
    if (accessToken && expiration) {
      dispatch(authSessionUpdated({ accessToken, refreshToken: accessToken, expiration, newuser }));
      dispatch(loginWithProviderAC.done({ params: { provider: 'google-oauth2' }, result: { accessToken, refreshToken: accessToken, expiration, newuser } }));
    }
  }, [dispatch, hash]);

  const [shouldShowError, showError] = useBooleanState();

  if (!error) {
    if (reload && referPath) {
      window.location.href = referPath;
      return null;
    }

    return <Redirect to={referPath || '/'} />;
  }

  return (
    <AppWrapper className={className} sx={sx}>
      <Typography variant='h1'>
        {t('common:authentication.callbackError.title')}
      </Typography>
      <Typography>
        {t('common:authentication.callbackError.description')}
      </Typography>
      {shouldShowError ? (
        <Typography component='code'>
          {error}
        </Typography>
      ) : (
        <Typography onClick={showError}>
          {t('common:authentication.callbackError.moreDetails')}
        </Typography>
      )}
    </AppWrapper>
  );
}
