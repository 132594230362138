import { Theme } from '@insights-gaming/theme';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import formatDate from 'date-fns/format';
import { useMemo } from 'react';

interface TimeOwnProps {
  className?: string;
  format: string;
  children: string | Date | number;
}

type TimeProps = TimeOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
}), { name: 'Time' });

export function Time(props: TimeProps) {
  const classes = useStyles(props);
  const { className, children, format } = props;
  const formatted = useMemo(() => {
    try {
      return formatDate(new Date(children), format);
    } catch (error) {
      console.error(error, children);
      return formatDate(new Date(0), format);
    }
  }, [children, format]);
  return (
    <time className={classNames(classes.root, className)}>
      {formatted}
    </time>
  );
}
