import { GameClassIdLike, TeamfightTacticsGameEvent } from '@insights-gaming/game-events';
import { partition } from '@insights-gaming/utils';
import { createSelector } from '@reduxjs/toolkit';
import flatten from 'flat';
import { get } from 'lodash';

import { CUSTOM_TAG_ACTIVATE_PREFIX, CUSTOM_TAGS_PER_GROUP } from '@/app/constants';
import { RootReducer } from '@/app/rootReducer.app';
import { evaluateLogicalExpression } from '@/features/logic/LogicEvaluator';
import { getInstalledGameClassIds, getRecentlyPlayedGameClassIds, makeGetMergedOWHotkeyDict } from '@/features/overwolf-data/overwolf-data-selector';
import { isCustomTagOWHotkey } from '@/features/overwolf-data/overwolf-data-slice';
import { Video } from '@/features/video-library/video-library-slice';
import { isExistent } from '@/utils/guard';
import { getActivateHotkeyNameFromProps, getCustomHotkeyNameFromProps, getGameClassIdLikeFromProps } from '@/utils/props-selector';
import customUpdate from '@/utils/update';
import { VideoHelper } from '@/utils/video';

import { DefaultKeybinding, isKeyCommandNegated, Keybinding, makeKeybindingIdentifier } from './keybinding';
import _defaultKeybindings from './keybinding/keybindings.json';
import { CustomHotkeyLabel, CustomHotkeyLabelMap, defaultSettings, MergedCustomHotkeyLabel, settingPath, SettingsState } from './setting-slice';

export const getIsSettingDatabaseLoaded = (state: RootReducer) => state.setting.settingDatabaseLoaded;
export const getUserSettings = (state: RootReducer) => state.setting ?? {};
export const getCustomKeybindings = (state: RootReducer) => state.setting?.customKeybindings ?? [];

const getKeybindingContextFromProps = (_: RootReducer, props: { keybindingContext: string[] }) => props.keybindingContext;

export const getUserSettingNames = createSelector(
  [getUserSettings],
  (userSettings) => new Set(Object.keys(flatten(userSettings))),
);

export const getMergedSettings = createSelector(
  [getUserSettings],
  (userSettings) => customUpdate(defaultSettings, { $deepmerge: userSettings }),
);

export const getMergedWebSettings = createSelector(
  [getMergedSettings],
  ({ appearance, videoReplay }) => {
    return {
      videoReplay,
      appearance: {
        customTheme: {
          backgroundImage: appearance.customTheme.backgroundImage,
        },
      },
    };
  },
);

/**
 * Get montage settings for a game and deep merged default timings with user settings.
 * Needed for games that don't have defaults set in the `defaultSettings` object in setting-slice.ts.
 * `[gameId].montage.events` may exist for a game but `[gameId].montage.events.timings.xx` may not
 * if the event was enabled and timings were not set.
 */
export const getGameMontageSettings = createSelector(
  [getMergedSettings, getGameClassIdLikeFromProps],
  (settings, gameClassId) => {
    const perGameSettings = gameClassId ? (settings as SettingsState)[gameClassId as GameClassIdLike] : {};
    const appMontageEventSetting = get(settings, settingPath.montage.events.$path);
    return customUpdate<SettingsState['montage']['events']>(
      appMontageEventSetting,
      {
        $deepmerge: get(perGameSettings, settingPath.montage.events.$path),
      },
    );
  },
);

export const getSyncSettings = createSelector(
  [getMergedSettings],
  (settings) => settings.videoSync,
);

const _getDefaultKeybindings = (_: RootReducer) => _defaultKeybindings;

const getDefaultKeybindings = createSelector(
  [_getDefaultKeybindings],
  (_defaults) => _defaults.map((k): DefaultKeybinding => ({ ...k, source: 'default' })),
);

export const getDefaultKeybindingDict = createSelector(
  [getDefaultKeybindings],
  (defaultKeybindings) => {
    const keybindingDict: Record<string, DefaultKeybinding[]> = {};
    for (const k of defaultKeybindings) {
      const id = makeKeybindingIdentifier(k);
      const arr = (keybindingDict[id] || [])
        .concat(k);
      keybindingDict[id] = arr;
    }
    return keybindingDict;
  },
);

export const getKeybindingDict = createSelector(
  [getDefaultKeybindingDict, getCustomKeybindings],
  (defaultKeybindingDict, customKeybindings) => {
    const keybindingDict: Record<string, Keybinding[]> = { ...defaultKeybindingDict };

    const [negated, normal] = partition(customKeybindings, k => isKeyCommandNegated(k.command));
    for (const k of normal) {
      const id = makeKeybindingIdentifier(k);
      const arr = (keybindingDict[id] || [])
        .filter((k): k is Keybinding => k.source === 'user')
        .concat(k);
      keybindingDict[id] = arr;
    }

    for (const negate of negated) {
      const id = makeKeybindingIdentifier(negate);
      // const arr = (keybindingDict[id] || [])
      //   .filter(k => k.key !== negate.key);
      keybindingDict[id] = [];
    }

    return keybindingDict;
  },
);

export const makeGetKeybindings = () => createSelector(
  [getKeybindingDict, getKeybindingContextFromProps],
  (keybindingDict, keybindingContext) => {
    const context = new Set(keybindingContext);
    const filterKeybinding = (k: Keybinding): boolean => keybindingContext.some(c => !k.when || evaluateLogicalExpression(k.when, context));
    const allKeybinds = Object.values(keybindingDict)
      .flat()
      .filter(filterKeybinding);
    const lookupTable: Record<string, Keybinding[]> = {}; // lookup by key
    for (const k of allKeybinds) {
      const keybindings = (lookupTable[k.key] || [])
        .concat(k);
      lookupTable[k.key] = keybindings;
    }
    const reverseLookupTable: Record<string, Keybinding[]> = {}; // lookup by command
    for (const k of allKeybinds) {
      const keybindings = (reverseLookupTable[k.command] || [])
        .concat(k);
      reverseLookupTable[k.command] = keybindings;
    }

    return {
      lookupTable,
      reverseLookupTable,
    };
  },
);

const makeCustomTagNumberReplacer = (activateName: CustomTagActivateHotkeyName) => (_match: string, p1: string, p2: string) => {
  const activateGroup = activateName.split(CUSTOM_TAG_ACTIVATE_PREFIX)[1];
  return `${p1}${(+activateGroup - 1) * CUSTOM_TAGS_PER_GROUP + +p2}`;
};

export const makeGetMergedCustomHotkeyLabelDict = () => createSelector(
  [getMergedSettings, makeGetMergedOWHotkeyDict(), getGameClassIdLikeFromProps],
  (settings, hotkeys, gameClassId) => {
    let globalHotkeyLabels = { ...settings.customTagKeybindings.labels };

    if (gameClassId) {
      const perGameLabels = settings[gameClassId as GameClassIdLike]?.customTagKeybindings?.labels || {};
      globalHotkeyLabels = customUpdate(globalHotkeyLabels, { $deepmerge: perGameLabels });
    }

    // use title string from manifest if user did not set title
    return customUpdate(globalHotkeyLabels, {
      $apply: (map) => {
        for (const _activateName in map) {
          const activateName = _activateName as CustomTagActivateHotkeyName;
          // make sure labels are defined
          for (const iHotkey of Object.values(hotkeys).filter(isCustomTagOWHotkey)) {
            const name = iHotkey.name;
            map[activateName] = customUpdate(map[activateName], {
              [name]: {
                $auto: {
                  $apply: (label: CustomHotkeyLabel) => {
                    // fallback title uses different numbers if they are in another hold hotkey group so we don't have duplicate titles
                    return {
                      ...label,
                      title: label?.title || hotkeys[name].title.replace(/([\w+ ]+)(\d+)$/, makeCustomTagNumberReplacer(activateName)),
                      name: name,
                      activateName,
                    };
                  },
                },
            } });
          }
        }
        return map;
      },
    }) as DeepRequiredN<CustomHotkeyLabelMap<MergedCustomHotkeyLabel>, 2>;
  },
);

export const makeGetMergedCustomHotkeyLabelAsArray = () => createSelector(
  [makeGetMergedCustomHotkeyLabelDict()],
  (dict) => Object.values(dict).flatMap(customTagMap => Object.values(customTagMap)).filter(isExistent),
);

export const makeGetCustomHotkeyLabelByName = () => createSelector(
  [makeGetMergedCustomHotkeyLabelDict(), getActivateHotkeyNameFromProps, getCustomHotkeyNameFromProps],
  (dict, activateName, hotkeyName) => {
    return dict[activateName as CustomTagActivateHotkeyName][hotkeyName as CustomTagHotkeyName];
  },
);

export const getRecycleBinDurationDays = createSelector(
  [getMergedSettings],
  (settings) => get(settings, settingPath.videoLibrary.recycleBinAutomaticCleanup.durationDays.$path),
);

export const getGamesWithSettings = createSelector(
  [getUserSettings],
  (settings) => {
    return Object.keys(settings).filter(k => !isNaN(+k) || k === TeamfightTacticsGameEvent.PSEUDO_CLASS_ID);
  },
);

/**
 * Copy of getVideoGameClassIds from video-library-selector.ts to avoid circular dependencies.
 */
const _getVideoGameClassIds = createSelector(
  [(state: RootReducer) => Object.values(state.videoLibrary.videos).filter(isExistent)],
  (videos: Video[]) => Array.from(videos.reduce((p, c) => {
    if (c.gameClassId) {
      p.add(VideoHelper.getGameClassIdLike(c));
    }
    return p;
  }, new Set<number | string>())),
);

/* a game is considered relevant to settings if it has been either:
   1. recently launched
   2. in the list of gameClassIds and is installed
   3. has settings
   4. has a video
*/
export const getRelevantGameClassIds = createSelector(
  [getRecentlyPlayedGameClassIds, getInstalledGameClassIds, _getVideoGameClassIds, getGamesWithSettings],
  (recent, installed, video, settingGames) => {
    const recentlyPlayed = new Set(recent);
    const otherGames = Array
      .from(
        new Set([...installed, ...video.map(v => v.toString()), ...settingGames].map(s => s === TeamfightTacticsGameEvent.PSEUDO_CLASS_ID ? s : +s)),
      )
      .filter(gameId => typeof gameId === 'number' ? !recentlyPlayed.has(gameId) : true);
    return [recent, otherGames];
  },
);
