import data, { EmojiMartData } from '@emoji-mart/data';
import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { Box, Button, Portal, Stack, SxProps, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ContextType, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileInput } from '@/components/FileInput';
import { useTeamEmojis } from '@/features/squad/TeamDirectory/useTeamEmojis';
import { TeamEmojiContext } from '@/features/squad/TeamEmojiContext';
import { useAddReactionEmojiMutation } from '@/features/squad/TeamSettings/useAddReactionEmojiMutation';
import { gql } from '@/graphql/__generated__';
import { TeamEmojiContextQueryDocument } from '@/graphql/__generated__/graphql';
import { withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';


const MAX_FILE_SIZE = 1048576;
const EMOJIS_TO_CUSTOMIZE = ['joy', 'clap', '+1', 'fire', 'cry'];

interface ReactionCustomizerProps {
  className?: string;
  sx?: SxProps<Theme>;
  teamId: ID;
  onDone?: VoidFunction;
  actionsPortal?: Element | null;
}

function _ReactionCustomizer(props: ReactionCustomizerProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamEmojiContext>>) {
  const { actionsPortal, className, onDone, queryRef, sx, teamId } = props;

  useTeamEmojis(teamId, queryRef);

  const emojiData = data as EmojiMartData;
  const [index, setIndex] = useState(0);
  const emojiToCustomize = EMOJIS_TO_CUSTOMIZE[index];

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'squad_onboarding']);

  const [picture, setPicture] = useState<File | undefined>();
  const [loading, setLoading] = useState(false);

  const [addReactionEmoji] = useAddReactionEmojiMutation();

  const emojiInputRef = useRef<HTMLInputElement>(null);
  const preview = picture ? URL.createObjectURL(picture) : undefined;

  const isLastIndex = index === EMOJIS_TO_CUSTOMIZE.length - 1;

  const handleUploadEmojiButtonClick = useCallback((e: React.SyntheticEvent) => {
    emojiInputRef.current?.click();
  }, []);

  const handleOnChange = useCallback(async (e: any) => {
    const file: File = e.target.files[0];
    if (!file) { return; }

    if (file.size > MAX_FILE_SIZE) {
      enqueueSnackbar(t('common:picexceedsizelimit', { bytes: MAX_FILE_SIZE }), { variant: 'warning' });
      return;
    }
    setPicture(file);
  }, [enqueueSnackbar, t]);

  const saveEmoji = useCallback(async (picture: File) => {
    await addReactionEmoji({
      variables: {
        input: {
          image: picture,
          name: `:${emojiToCustomize}:`,
          teamId,
        },
      },
      update: (cache, { data }) => {
        cache.updateQuery(
          {
            query: TeamEmojiContextQueryDocument,
            variables: { teamId },
          },
          queryData => {
            if (!queryData?.queryEmojis) {
              return null;
            }
            if (!data?.createEmoji?.emoji) {
              return null;
            }
            const newEmojiRef = cache.writeFragment({
              fragment: gql(`
                fragment NewEmoji on Emoji {
                  id
                  name
                  url
                  creator {
                    alias
                  }
                }
              `),
              data: data.createEmoji.emoji,
            });
            return {
              queryEmojis: {
                ...queryData.queryEmojis,
                emojis: queryData.queryEmojis.emojis.concat(newEmojiRef as any),
              },
            };
          },
        );
      },
    });
  }, [addReactionEmoji, emojiToCustomize, teamId]);

  const handlePrevClick = useCallback(() => {
    setIndex(prev => prev - 1);
    setPicture(undefined);
  },  []);

  const handleNextClick = useCallback(async () => {
    if (!picture) {
      if (isLastIndex) {
        onDone?.();
        return;
      } else {
        setIndex(prev => prev + 1);
        return;
      }
    }
    if (loading) {
      return;
    }

    setLoading(true);

    try {
      await saveEmoji(picture);
      if (isLastIndex) {
        onDone?.();
      } else {
        setIndex(prev => prev + 1);
        setPicture(undefined);
      }
    } catch {
      // TODO: handle error
    } finally {
      setLoading(false);
    }
  }, [isLastIndex, loading, onDone, picture, saveEmoji]);

  return (
    <Stack className={className} sx={sx} spacing={1}>
      <Typography variant='h6'>
        {t('squad_onboarding:reactions.create', { emojiName: emojiToCustomize })}
      </Typography>
      <Typography variant='caption'>
        {t('squad_onboarding:reactions.uploadEmoji')}
      </Typography>
      <Stack
      sx={{
        px: 3,
      }}
      spacing={1.5}
      direction='row'
      alignItems='center'
      >
        <Box
        sx={{
          p:1,
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme => theme.palette.background.container,
        }}
        >
          {/* @ts-ignore 2339 */}
          <em-emoji id={emojiData.emojis[emojiToCustomize].id} size='2em' />
        </Box>
        <Box
        sx={{
          p:1,
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: theme => theme.palette.background.container,
          backgroundImage: preview ? `url(${preview})` : undefined,
          backgroundSize: 'cover',
        }}
        >
          {!picture ? <QuestionMarkIcon /> : null}
        </Box>
        <FileInput
        onChange={handleOnChange}
        >
          <Button
          variant='outlined'
          color='primary'
          onClick={handleUploadEmojiButtonClick}
          >
            {t('squad_onboarding:reactions.upload')}
          </Button>
        </FileInput>
      </Stack>
      <Portal disablePortal={!actionsPortal} container={actionsPortal}>
        <Stack flex={1} direction='row' justifyContent='space-between'>
          <Button
          disabled={index === 0}
          startIcon={<ChevronLeftIcon />}
          onClick={handlePrevClick}
          >
            {t('common:back')}
          </Button>
          {isLastIndex ? (
            <AsyncButton
            color='primary'
            variant='contained'
            onClick={handleNextClick}
            loading={loading}
            disabled={loading}
            >
              {t('common:done')}
            </AsyncButton>
          ) : (
            <AsyncButton
            loading={loading}
            disabled={loading}
            onClick={handleNextClick}
            >
              {t('common:next')}
              <ChevronRightIcon />
            </AsyncButton>
          )}
        </Stack>
      </Portal>
    </Stack>
  );
}

export const ReactionCustomizer = withBackgroundQuery(TeamEmojiContext, _ReactionCustomizer);
