export function affineTransform1D(x: number, i: [number, number], f: [number, number]) {
  if (i[1] === i[0]) { return 0; }
  return (x - i[0]) * (f[1] - f[0]) / (i[1] - i[0]) + f[0];
}

export function makeAffineTransform(i: [number, number], f: [number, number]): TransformFn<number> {
  if (i[1] === i[0]) {
    return () => 0;
  }

  const di = i[1] - i[0];
  const df = f[1] - f[0];
  const scale = df / di;

  // premature optimizations?
  if (i[0] === 0 && f[0] === 0) {
    return x => scale * x;
  }

  if (i[0] === 0) {
    return x => scale * x + f[0];
  }

  if (f[0] === 0) {
    return x => scale * (x - i[0]);
  }

  return x => scale * (x - i[0]) + f[0];
}

export type TransformFn<T> = (v: T) => T;

export interface InvertibleTransform<T> {
  forward: TransformFn<T>;
  backward: TransformFn<T>;
}

export function makeInvertibleAffineTransform(i: [number, number], f: [number, number]): InvertibleTransform<number> {
  return {
    forward: makeAffineTransform(i, f),
    backward: makeAffineTransform(f, i),
  };
}

export const identity = <T>(x: T) => x;

export const defaultInvertibleTransform = {
  forward: identity,
  backward: identity,
};
