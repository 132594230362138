function isPowerOfTwo(v: number) {
  return v && !(v & (v - 1));
  }

function prevPowerOfTwo(x: number) {
  x = x | (x >> 1);
  x = x | (x >> 2);
  x = x | (x >> 4);
  x = x | (x >> 8);
  x = x | (x >> 16);
  return x - (x >> 1);
}

export function* infiniteBisector(end: number): IterableIterator<number> {
  const A = [end];
  yield A[1] = end / 2;
  let i = 2;
  while (true) {
    const parent = Math.floor(i / 2);
    const isFirstOfRow = isPowerOfTwo(i);
    if (isFirstOfRow) {
      yield A[i] = A[parent] / 2;
    } else {
      const f = prevPowerOfTwo(i); // first of the level
      const pOfF = f / 2; // guaranteed to be even
      yield A[i] = A[f] + (i - f) * A[pOfF];
    }
    i++;
  }
}
