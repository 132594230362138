import { Dialog, DialogProps } from '@mui/material';

import { AddSquadDialogContent } from '@/features/squad/AddSquad/AddSquadDialogContent';

interface AddSquadDialogProps {
  onClose?: VoidFunction;
}

export function AddSquadDialog(props: AddSquadDialogProps & Pick<DialogProps, 'open'>) {
  const { open } = props;
  return (
    <Dialog open={open} fullWidth={true} maxWidth='xs'>
      <AddSquadDialogContent variant='dialog' startSlide='addSquad' {...props} />
    </Dialog>
  );
}
