import { Theme } from '@insights-gaming/theme';
import AddIcon from '@mui/icons-material/Add';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LinkIcon from '@mui/icons-material/Link';
import { Stack, SxProps } from '@mui/material';

import { OnboardingButton } from '@/components/OnboardingButton';

interface AddSquadContentProps {
  className?: string;
  sx?: SxProps<Theme>;
  inviteText?: string;
  createSquadText?: string;
  onInvitedClick?: VoidFunction;
  onCreateSquadClick?: VoidFunction;
}

export function AddSquadContent(props: AddSquadContentProps) {
  const { className, sx, inviteText, createSquadText, onInvitedClick, onCreateSquadClick } = props;
  return (
    <Stack spacing={1} className={className} sx={sx}>
      <OnboardingButton startIcon={<LinkIcon />} endIcon={<ChevronRightIcon />} onClick={onInvitedClick}>
        {inviteText}
      </OnboardingButton>
      <OnboardingButton startIcon={<AddIcon />} endIcon={<ChevronRightIcon />} onClick={onCreateSquadClick}>
        {createSquadText}
      </OnboardingButton>
    </Stack>
  );
}
