import { useMutation, useQueryRefHandlers, useReadQuery, useSuspenseQuery } from '@apollo/client';
import { AsyncButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { Dialog, DialogContent, Stack, SxProps, Typography } from '@mui/material';
import { ContextType } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';

import AlertDialogContent from '@/components/AlertDialogContent';
import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import SquadRouting from '@/features/squad/squad.routing';
import { TeamContext } from '@/features/squad/TeamContext';
import { gql } from '@/graphql/__generated__';
import { withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';
import { useDialogState } from '@/hooks/useDialogState';
import { useNavigate } from '@/hooks/useNavigate';
import { NOOP } from '@/utils';
import { createCdnUrl } from '@/utils/cdn';

import ClaimInvitationErrorDialog from './ClaimInvitationErrorDialog';

const ClaimInvitation_LookupInvitationQuery = gql(`
  query ClaimInvitation_LookupInvitationQuery($code: InvitationCode!) {
    lookupInvitation(code: $code) {
      id
      from {
        id
        alias
        picture
      }
      target {
        __typename
        ... on Team {
          id
          name
          picture
        }
      }
    }
  }
`);

const ClaimInvitationMutation = gql(`
  mutation ClaimInvitationMutation($input: ClaimInvitationInput!) {
    claimInvitation(input: $input) {
      target {
        __typename
      }
    }
  }
`);


interface ClaimInvitationProps {
  className?: string;
  sx?: SxProps<Theme>;

  code: string;
  onClaimed?: () => void;
}

export function ClaimInvitationWithErrorBounds(props: ClaimInvitationProps) {
  const [isClaimInvitationErrorDialogOpen, _, closeClaimInvitationErrorDialog] = useDialogState(true);

  return (
    <ErrorBoundary fallbackRender={() => <ClaimInvitationErrorDialog open={isClaimInvitationErrorDialogOpen} onClose={closeClaimInvitationErrorDialog} />}>
      <ClaimInvitation {...props} />
    </ErrorBoundary>
  );
}

function _ClaimInvitation(props: ClaimInvitationProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamContext>>) {
  const { className, code, onClaimed, queryRef, sx } = props;

  const onNavigate = useNavigate();
  const { t } = useTranslation(['squad_common']);

  const { data: teamData } = useReadQuery(queryRef);
  const { refetch } = useQueryRefHandlers(queryRef);

  const { data } = useSuspenseQuery(ClaimInvitation_LookupInvitationQuery, { variables: { code } });

  const invitation = data?.lookupInvitation;
  const target = invitation?.target;
  const isTeamInvitation = target?.__typename === 'Team';

  const teamName = isTeamInvitation ? target.name : undefined;
  const teamId = isTeamInvitation ? target.id : undefined;

  const alreadyMember = teamData.queryTeams.teamEdges.some(({ team }) => isTeamInvitation && team.id === target.id);

  const [claimInvitation, { loading: claiming }] = useMutation(ClaimInvitationMutation, { variables: { input: { code } } });

  return (
    <Dialog open={true} maxWidth='xs' fullWidth={true} className={className} sx={sx}>
      {alreadyMember ? (
        <AlertDialogContent
        onClose={NOOP}
        titleText={t('squad_common:invitation.alreadyMember.title')}
        description={(
          <Trans
          ns={['squad_common']}
          i18nKey='squad_common:invitation.alreadyMember.description'
          components={{
            Target: <strong />,
          }}
          values={{
            target: teamName,
          }}
          >
            You&quot;re already a member of the team <span>target</span>
          </Trans>
        )}
        confirm={{
          color: 'primary',
          children: t('squad_common:invitation.alreadyMember.confirm'),
          onClick: teamId ? () => {
            onNavigate(SquadRouting.createUrl.team(teamId));
            onClaimed?.();
          } : undefined,
        }}
        />
      ) : (
        <DialogContent>
          {isTeamInvitation && (
            <Stack spacing={1} alignItems='center'>
              <AvatarWithFallback sx={{ borderRadius: '2px' }} src={createCdnUrl(target.picture, { width: 48, aspect_ratio: '1:1', format: 'webp' })} alt={target.name} />
              <Typography variant='h6'>
                <Trans
                ns={['squad_common']}
                i18nKey={`squad_common:invitation.${target.__typename}.description`}
                components={{
                  From: <Typography component='span' fontWeight='bold' />,
                }}
                values={{
                  from: invitation?.from?.alias || t(`squad_common:invitation.${target.__typename}.member`),
                }}
                >
                  <strong>from</strong> invited you to join
                </Trans>
              </Typography>
              <Typography
              variant='h2'
              fontWeight='bold'
              sx={{
                textShadow: theme => `-1px 1px 1px ${theme.palette.primary.main}`,
              }}
              >
                {target.name}
              </Typography>
              <AsyncButton
              loading={claiming}
              disabled={claiming}
              variant='contained'
              color='primary'
              fullWidth={true}
              onClick={async () => {
                await claimInvitation();
                if (teamId) {
                  refetch();
                  onNavigate(SquadRouting.createUrl.team(teamId));
                  onClaimed?.();
                }
              }}
              >
                {t('squad_common:invitation.confirm')}
              </AsyncButton>
            </Stack>
          )}
        </DialogContent>
      )}
    </Dialog>
  );
}

export const ClaimInvitation = withBackgroundQuery(TeamContext, _ClaimInvitation);
