import { ControlButton, VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Slider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, memo, MouseEvent, SyntheticEvent, useCallback, useContext } from 'react';

import { KeybindReverseLookupContext } from '@/features/settings/keybinding/KeybindReverseLookupContext';
import VideoControlsTooltip from '@/features/video-replay/VideoControlsTooltip';
import { FullscreenContext } from '@/utils/fullscreen/FullscreenContext';

interface VideoReplayVolumeControllerOwnProps {
  className?: string;
}

type VideoReplayVolumeControllerProps = VideoReplayVolumeControllerOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  sliderWrapper: {
    padding: '0 6px',
    lineHeight: 0,
  },
  volumeSlider: {
    width: 60,
  },
}), { name: 'VideoVolumeController' });

function VideoReplayVolumeController(props: VideoReplayVolumeControllerProps) {
  const classes = useStyles(props);
  const {
    state: { muted, volume },
    changeVolume,
    toggleMuteState,
  } = useContext(VideoReplayContext);

  const keybindLookup = useContext(KeybindReverseLookupContext);

  const handleMuteClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();
    toggleMuteState();
  }, [toggleMuteState]);

  const handleVolume = useCallback((__: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      changeVolume({
        amount: newValue,
      });
    }
  }, [changeVolume]);

  const handleVolumeCommitted = useCallback((__: Event | SyntheticEvent, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      changeVolume({
        amount: newValue,
        changeCommitted: true,
      });
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  }, [changeVolume]);

  const fullscreenEl = useContext(FullscreenContext);

  return (
    <Fragment>
      <VideoControlsTooltip tKey={muted ? 'hotkeys:labels.unmute' : 'hotkeys:labels.mute'} keybindings={keybindLookup['video.volume.mute.toggle']} container={fullscreenEl}>
        <div>
          <ControlButton onClick={handleMuteClick}>
            {muted ? (
              <VolumeOffIcon />
            ) : (
              <VolumeUpIcon />
            )}
          </ControlButton>
        </div>
      </VideoControlsTooltip>
      <div className={classes.sliderWrapper}>
        <Slider className={classes.volumeSlider} value={volume} min={0} max={1} step={0.01} onChange={handleVolume} onChangeCommitted={handleVolumeCommitted} />
      </div>
    </Fragment>
  );
}

export default memo(VideoReplayVolumeController);
