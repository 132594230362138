import { CreateDivisionInput, CreateFolderInput, CreateInvitationInput, CreateTeam2Input, DeleteDirectoryInput, UpdateDivisionInput, UpdateTeam2Input } from '@/graphql/__generated__/graphql';
import { mpProps } from '@/mixpanel/common';

interface VideoReactionAddedParams {
  [mpProps.nativeEmoji]?: string;
  [mpProps.customEmoji]?: boolean;
}

type VideoReactionRemovedParams = VideoReactionAddedParams;

interface TeamCreatedParams {
  [mpProps.pictureSpecified]: boolean;
}

interface TeamDivisionCreatedParams {
  [mpProps.pictureSpecified]: boolean;
  [mpProps.bannerSpecified]: boolean;
}

type TeamDivisionUpdatedParams = TeamDivisionCreatedParams;

interface TeamFolderCreatedParams {
  [mpProps.folderName]?: string;
}

type TeamFolderDeletedParams = TeamFolderCreatedParams;

interface TeamInvitationCreated {
  [mpProps.expiry]: unknown;
  [mpProps.globalRole]: boolean;
  [mpProps.customRole]: boolean;
  [mpProps.useLimit]?: number;
}

interface TeamUpdated {
  [mpProps.teamName]?: string;
  [mpProps.pictureSpecified]: boolean;
}

interface VideoWatched {
  [mpProps.isOwner]: boolean;
  [mpProps.videoId]: string;
}

interface ReactionCount {
  [mpProps.videoId]: string;
  [mpProps.reactionCount]: number;
}

export interface MixpanelSquadEventMap {
  'Video Reaction Added': VideoReactionAddedParams;
  'Video Reaction Removed': VideoReactionRemovedParams;
  'Team Created': TeamCreatedParams;
  'Team Division Created': TeamDivisionCreatedParams;
  'Team Division Updated': TeamDivisionUpdatedParams;
  'Team Folder Created': TeamFolderCreatedParams;
  'Team Folder Deleted': TeamFolderDeletedParams;
  'Emoji Created': {};
  'Emoji Deleted': {};
  'Team Invitation Created': TeamInvitationCreated;
  'Team Invitation Claimed': {};
  'Team Updated': TeamUpdated;
  'Video Watched': VideoWatched;
  'Reaction Count': ReactionCount;
}

type MixpanelEventParams<Name extends keyof EventMap, EventMap = MixpanelSquadEventMap> = [Name, EventMap[Name]];

function videoReactionAdded(params: {
  nativeEmoji?: string;
  customEmoji?: boolean;
}): MixpanelEventParams<'Video Reaction Added'> {
  return [
    'Video Reaction Added',
    {
      [mpProps.nativeEmoji]: params.nativeEmoji,
      [mpProps.customEmoji]: params.customEmoji,
    },
  ];
}

function videoReactionRemoved(params: {
  nativeEmoji?: string;
  customEmoji?: boolean;
}): MixpanelEventParams<'Video Reaction Removed'> {
  return [
    'Video Reaction Removed',
    {
      [mpProps.nativeEmoji]: params.nativeEmoji,
      [mpProps.customEmoji]: params.customEmoji,
    },
  ];
}

function teamCreated(
  input?: CreateTeam2Input,
): MixpanelEventParams<'Team Created'> {
  return [
    'Team Created',
    {
      [mpProps.pictureSpecified]: Boolean(input?.picture),
    },
  ];
}

function teamDivisionCreated(
  input?: CreateDivisionInput,
): MixpanelEventParams<'Team Division Created'> {
  return [
    'Team Division Created',
    {
      [mpProps.pictureSpecified]: Boolean(input?.picture),
      [mpProps.bannerSpecified]: Boolean(input?.banner),
    },
  ];
}

function teamFolderCreated(
  input?: CreateFolderInput,
): MixpanelEventParams<'Team Folder Created'> {
  return ['Team Folder Created', {
    [mpProps.folderName]: input?.name,
  }];
}

function teamFolderDeleted(
  input?: DeleteDirectoryInput,
): MixpanelEventParams<'Team Folder Deleted'> {
  return ['Team Folder Deleted', {}];
}

function teamDivisionUpdated(
  input?: UpdateDivisionInput,
): MixpanelEventParams<'Team Division Updated'> {
  return [
    'Team Division Updated',
    {
      [mpProps.pictureSpecified]: Boolean(input?.picture),
      [mpProps.bannerSpecified]: Boolean(input?.banner),
    },
  ];
}

function emojiCreated(): MixpanelEventParams<'Emoji Created'> {
  return ['Emoji Created', {}];
}

function emojiDeleted(): MixpanelEventParams<'Emoji Deleted'> {
  return ['Emoji Deleted', {}];
}

function teamInvitationCreated(
  input?: CreateInvitationInput,
): MixpanelEventParams<'Team Invitation Created'> {
  return [
    'Team Invitation Created',
    {
      [mpProps.expiry]: input?.expiry,
      [mpProps.globalRole]: Boolean(input?.globalRoles?.length),
      [mpProps.customRole]: Boolean(input?.roles?.length),
      [mpProps.useLimit]: input?.limit ?? undefined,
    },
  ];
}

function teamInvitationClaimed(): MixpanelEventParams<'Team Invitation Claimed'> {
  return ['Team Invitation Claimed', {}];
}

function teamUpdated(
  input?: UpdateTeam2Input,
): MixpanelEventParams<'Team Updated'> {
  return ['Team Updated', {
    [mpProps.pictureSpecified]: Boolean(input?.picture),
    [mpProps.teamName]: input?.name ?? undefined,
  }];
}

function videoWatched(
  input: {
    isOwner: boolean;
    videoId: string;
  },
): MixpanelEventParams<'Video Watched'> {
  return ['Video Watched', {
    [mpProps.isOwner]: input.isOwner,
    [mpProps.videoId]: input.videoId,
  }];
}

function reactionCount(
  input: {
    videoId: string;
    reactionCount: number;
  },
): MixpanelEventParams<'Reaction Count'> {
  return ['Reaction Count', {
    [mpProps.videoId]: input.videoId,
    [mpProps.reactionCount]: input.reactionCount,
  }];
}

export const mixpanelSquadHelpers = {
  emojiCreated,
  emojiDeleted,
  teamCreated,
  teamDivisionCreated,
  teamDivisionUpdated,
  teamInvitationCreated,
  teamInvitationClaimed,
  teamFolderCreated,
  teamFolderDeleted,
  videoReactionAdded,
  videoReactionRemoved,
  teamUpdated,
  videoWatched,
  reactionCount,
};
