import { END } from 'redux-saga';

import { TFT_QUEUE_IDS, TFTQueueId } from '@/app/constants';
import { CaptureMode } from '@/features/settings/setting-slice';

type Truthy<T> = T extends NonNullable<T> | 0 | false | undefined | null ? never : T;

export const isExistent = <T>(t: T): t is NonNullable<T> => t != null;
export const isTruthy = <T>(t: T): t is Truthy<T> => !!t;
export const isInteger = (n: unknown): n is number => Number.isInteger(n);

// https://fettblog.eu/typescript-hasownproperty/
export function hasOwnProperty<X extends {}, Y extends PropertyKey>(obj: X, prop: Y): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

export function assertIsDefined<T>(val: T, message?: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(
      message,
    );
  }
}

export const isHTMLInputElement = (el: HTMLElement): el is HTMLInputElement => el.tagName === 'INPUT';

export const isTFTQueueId = (n: number): n is TFTQueueId => TFT_QUEUE_IDS.some(id => id === n);

export const isFulfilled = <T>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> => p.status === 'fulfilled';

export const isCaptureMode = (s: string): s is CaptureMode => ['auto', 'session', 'manual'].includes(s);

export const isChannelEND = <T = any>(item: T | END): item is END => item === END;
