import { GameClassIdLike, GameEventIconChooser } from '@insights-gaming/game-events';
import { VideoReplayContext } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import { SecondsFormatter } from '@insights-gaming/utils';
import { ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import { useCallback, useContext } from 'react';

import { useGameEventTranslation } from '@/features/game-events/useGameEventTranslation';
import { TeamVideoPlayerControls_VideoBookmarkFragmentFragment } from '@/graphql/__generated__/graphql';

interface TeamVideoBookmarkListItemProps {
  className?: string;
  sx?: SxProps<Theme>;
  bookmark: TeamVideoPlayerControls_VideoBookmarkFragmentFragment;
}

export function TeamVideoBookmarkListItem(props: TeamVideoBookmarkListItemProps) {
  const { className, sx, bookmark } = props;

  const { tGameEvent } = useGameEventTranslation();

  const {
    seekTo,
  } = useContext(VideoReplayContext);

  const handleClick = useCallback(() => {
    seekTo({ type: 'seconds', amount: bookmark.time, seekCommitted: true });
  }, [bookmark.time, seekTo]);

  if (bookmark.bookmark.__typename !== 'GameBookmark') {
    return null;
  }

  const event = {
    name: bookmark.bookmark.event,
    data: bookmark.bookmark.data,
  };

  const eventName = tGameEvent(event, bookmark.bookmark.gameId as GameClassIdLike);


  return (
    <ListItemButton onClick={handleClick} className={className} sx={sx}>
      <ListItemIcon>
        <GameEventIconChooser
        classId={bookmark.bookmark.gameId as GameClassIdLike}
        event={event}
        />
      </ListItemIcon>
      <ListItemText primary={SecondsFormatter.format(bookmark.time) + ' - ' + eventName} />
    </ListItemButton>
  );
}
