import { Video } from '@/features/video-library/video-library-slice';
import { VideoHelper } from '@/utils/video';

export function humanizeVideoName(video: Video): string {
  if (video.userProvidedName) {
    return video.userProvidedName;
  }
  return videoFilename(video);
}

export function getFilenameFromPath(path: string): string {
  const filename = path.split('\\').pop()?.split('/').pop();
  return filename || '';
}

export function videoFilename(video: Video): string {
  const url = VideoHelper.getVideoFilePath(video) || video.result.url;
  return getFilenameFromPath(url);
}

export function toWindowsUrl(path: string): string {
  return path.replace(/\//g, '\\');
}

/**
 * Make the filename valid for Windows
 * https://gist.github.com/doctaphred/d01d05291546186941e1b7ddc02034d3?permalink_comment_id=3359528#gistcomment-3359528
 * https://gist.github.com/doctaphred/d01d05291546186941e1b7ddc02034d3?permalink_comment_id=3584903
 * https://learn.microsoft.com/en-us/windows/win32/fileio/naming-a-file
 * @param filename
 * @returns
 */
export function sanitizeWindowsFilename(filename: string): string {
  const fname = filename
    .replace(/\n/g, ' ')
    // eslint-disable-next-line no-control-regex
    .replace(/[<>:"/\\|?*\x00-\x1F]| +$/g, '')
    .replace(/\.$/, '')
    .replace(/^(CON|PRN|AUX|NUL|COM[0-9]|LPT[0-9])(\..+)?$/, x => x + '_');

  if (!fname.length) {
    return '_';
  }
  return fname;
}

/**
 * Sanitize name for Windows and replace '.' with '_' to avoid filename having multiple periods.
 * @param name {string} user provided name
 * @returns sanitized name
 */
export const sanitizeUserProvidedName = (name: string) => sanitizeWindowsFilename(name).replaceAll('.', '_');
