import { AsyncButton, DiscordButton, FlexSpacer, GoogleButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import {
  ChangeEvent,
  Dispatch,
  FormEvent,
  Fragment,
  memo,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import IconTextField from '@/components/IconTextField';
import { usePromiseSagaDispatch } from '@/utils/promisify-saga';

import { registerWithEmailAC, SignInSource } from './authentication-slice';
import { useLoginState } from './useLoginState';
import { useOAuth } from './useOAuth';

interface SignUpFormOwnProps {
  signInSource?: SignInSource;
  className?: string;
  onLoggedIn?: VoidFunction;
  thirdPartyProvider?: boolean;
  onEmailClick?: VoidFunction;
  onSignInClick?: VoidFunction;
  setShowVerifyEmail?: Dispatch<SetStateAction<boolean>>;
}

type SignUpFormProps = SignUpFormOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center',
  },
  emailIcon: {
    margin: theme.spacing(1),
  },
  signUpButton: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 'unset',
  },
}), { name: 'SignUpForm' });

const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

function testPasswords(
  a: string | undefined,
  b: string | undefined,
): string | void {
  if (!a) {
    return 'PASSWORD_REQUIRED';
  }

  if (a.length < 6) {
    return 'WEAK_PASSWORD';
  }

  if (a !== b) {
    return 'PASSWORD_MISMATCH';
  }
}

function SignUpForm(props: SignUpFormProps) {
  const classes = useStyles(props);
  const { className, onLoggedIn, signInSource, thirdPartyProvider = true, onEmailClick, onSignInClick, setShowVerifyEmail } = props;

  const [formField, setFormField] = useState<{email?: string; password1?: string; password2?: string}>({});
  const [loading, setLoading] = useState(false);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t, i18n } = useTranslation(['common']);
  const wt = t as WTFunction;
  const { enqueueSnackbar } = useSnackbar();

  const { referrer } = useLoginState();

  const handleTextFieldChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormField((prev) => ({ ...prev, [name]: value }));
  }, []);

  const registerWithProvider = useOAuth({ onLoggedIn, signInSource });

  const handleSignUpWithGoogle = useCallback(() => {
    registerWithProvider('google-oauth2');
  }, [registerWithProvider]);

  const handleSignUpWithDiscord = useCallback(() => {
    registerWithProvider('discordapp');
  }, [registerWithProvider]);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }

    const { email, password1, password2 } = formField;
    if (!email || !validEmailRegex.test(email)) {
      enqueueSnackbar(t('common:authentication.errors.INVALID_EMAIL'), { variant: 'error' });
      return;
    }

    const msg = testPasswords(password1, password2);
    if (msg) {
      const tKey = 'common:authentication.errors.' + msg;
      const text = i18n.exists(tKey) ? wt(tKey) : msg;
      enqueueSnackbar(text, { variant: 'error' });
      return;
    }

    setLoading(true);

    try {
      // marketing option can be added in the future
      const result = await promiseSagaDispatch(registerWithEmailAC, { email: email || '', password: password1 || '', marketing: false, referrer }, { signInSource });
      setShowVerifyEmail?.(result);
    } catch (error) {
      const tKey = 'common:authentication.errors.' + error.message;
      const text = i18n.exists(tKey) ? wt(tKey) : error.message;
      enqueueSnackbar(text, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, i18n, loading, promiseSagaDispatch, signInSource, wt, referrer, formField, t, setShowVerifyEmail]);

  const thirdPartySignUpForm = (
    <Fragment>
      <DiscordButton
      className={classes.signUpButton}
      label={t('common:authentication.discord')}
      onClick={handleSignUpWithDiscord}
      />
      <GoogleButton
      type='light'
      fullWidth={true}
      label={t('common:authentication.google')}
      onClick={handleSignUpWithGoogle}
      center='content'
      shape='rounded'
      />
    </Fragment>
  );

  const emailSignUpForm = (
    <Fragment>
      <IconTextField
      name='email'
      icon={<EmailIcon />}
      label={t('common:authentication.email')}
      placeholder={t('common:authentication.email')}
      value={formField.email}
      autoFocus={true}
      fullWidth={true}
      onChange={handleTextFieldChange}
      />
      <IconTextField
      type='password'
      name='password1'
      icon={<LockIcon />}
      label={t('common:authentication.password')}
      placeholder={t('common:authentication.password')}
      value={formField.password1}
      onChange={handleTextFieldChange}
      />
      <IconTextField
      type='password'
      name='password2'
      icon={<LockIcon />}
      label={t('common:authentication.confirmpw')}
      placeholder={t('common:authentication.confirmpw')}
      value={formField.password2}
      onChange={handleTextFieldChange}
      />
    </Fragment>
  );

  return (
    <form onSubmit={handleSubmit}>
      <FlexSpacer orientation='vertical' spacing={2} className={classNames(classes.root, className)}>
        {thirdPartyProvider ? (
          <Fragment>
            {thirdPartySignUpForm}
            <Button startIcon={<EmailIcon className={classes.emailIcon} />} className={classes.signUpButton} variant='contained' color='primary' fullWidth={true} onClick={onEmailClick}>
              <Typography variant='h4' textTransform='capitalize' fontWeight={400}>
                {t('common:authentication.email')}
              </Typography>
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {emailSignUpForm}
            <AsyncButton type='submit' variant='contained' color='primary' loading={loading} disabled={loading} fullWidth={true}>
              {t('common:authentication.sign_up')}
            </AsyncButton>
          </Fragment>
        )}
        <FlexSpacer spacing={0} flexJustifyContent='center' flexAlignItems='center'>
          <Typography>
            {t('common:authentication.haveAccount')}
          </Typography>
          <Button onClick={onSignInClick}>
            {t('common:authentication.login')}
          </Button>
        </FlexSpacer>
      </FlexSpacer>
    </form>
  );
}

export default memo(SignUpForm);
