import { FetchStatus } from '@insights-gaming/redux-utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import actionCreatorFactory from 'typescript-fsa';

import { UpdateUserMutation_updateUser2 } from '@/apollo/mutations/__generated__/UpdateUserMutation';
import { GetUserFeaturesQuery_me } from '@/apollo/queries/__generated__/GetUserFeaturesQuery';
import { UpdateUser2Input } from '@/apollo/types/globalTypes';
import { MixpanelEventSource } from '@/mixpanel/common';
import { Session } from '@/utils/insightsgg';


const name = 'authentication';
const actionCreator = actionCreatorFactory(name);

export type SignInSource = MixpanelEventSource<
  | 'Onboarding'
  | 'Login Button'
  | 'Upgrade Button'
  | 'Upload Button'
  | 'Share Button'
  | 'Copy Referral Link'
  | 'Discover Upvote Button'
  | 'Discover Submit Clip Button'
  | 'Discover Report Submission Button'
  | 'Elite Badge'
  | 'Video Card'
  | 'Squad Button'
>;

export type OAuth2Provider = 'google-oauth2' | 'discordapp';

export const registerWithEmailAC = actionCreator.async<{ email: string; password: string; marketing: boolean; referrer?: string }, boolean, Error>('REGISTER_WITH_EMAIL');
export const loginWithPasswordAC = actionCreator.async<{ email: string; password: string }, Session, Error>('LOGIN_WITH_PASSWORD');
export const loginWithProviderAC = actionCreator.async<{ provider: OAuth2Provider }, Session, Error>('LOGIN_WITH_PROVIDER');
export const refreshAuthSessionAC = actionCreator.async<{ refreshToken: string }, Session, Error>('REFRESH_AUTH_SESSION');
export const fetchUserNotificationCountAC = actionCreator.async<void, number, Error>('FETCH_USER_NOTIFICATION_COUNT');
export const fetchUserFeaturesAC = actionCreator.async<void, GetUserFeaturesQuery_me, Error>('FETCH_USER_FEATURES');
export const updateProfileAC = actionCreator.async<UpdateUser2Input, UpdateUserMutation_updateUser2, Error>('UPDATE_USER');
export const resetPasswordAC = actionCreator.async<{ email: string }, void, Error>('RESET_PASSWORD');
export const apolloStoreReset = actionCreator<void>('APOLLO_STORE_RESET');

interface AuthenticationState {
  session?: Session;
  sessionChecked?: boolean;
  userProfileStatus?: FetchStatus;
  notificationCount: number;
  premium: boolean;
  loggingOut: boolean;
}

const initialState: AuthenticationState = {
  notificationCount: 0,
  premium: false,
  loggingOut: false,
};

export const authSessionUpdated = createAsyncThunk(`${name}/authSessionUpdated`, async (session: Session) => {
  window.sessionStorage.setItem('refreshToken', session.refreshToken);
  return session;
});

const authenticationSlice = createSlice({
  name,
  initialState,
  reducers: {
    authSessionChecked(state) {
      state.sessionChecked = true;
    },
    // authSessionUpdated(state, { payload }: PayloadAction<Session>) {
    //   state.session = payload;
    // },
    logout() {
      return { ...initialState, sessionChecked: true, loggingOut: true };
    },
    logoutFinished() {
      return { ...initialState, sessionChecked: true, loggingOut: false };
    },
  },
  extraReducers: builder => {
    const async = fetchUserNotificationCountAC;
    builder.addCase(async.done, (state, action) => {
      state.notificationCount = action.payload.result;
    });

    builder.addCase(authSessionUpdated.fulfilled, (state, action) => {
      state.session = action.payload;
    });
    // process.env.NODE_ENV === 'development' && (function () {
    //   builder.addCase('__TOGGLE_PREMIUM', (state, action) => {
    //     if (state.userProfile) {
    //       if (state.userProfile.features.includes('ADFREE')) {
    //         state.userProfile.features = state.userProfile.features.filter((feature) => feature !== 'ADFREE');
    //       } else {
    //         state.userProfile.features.push('ADFREE');
    //       }
    //     }
    //   });
    // })();
  },
});

export const { authSessionChecked, logout, logoutFinished } = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
