import { lazy, Suspense } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { SuspenseLoader } from '@/components/SuspenseLoader';

const SquadDashboard = lazy(() => import('./SquadDashboard'));
const TeamPageWrapper = lazy(() => import('./TeamPageWrapper'));

const basePath = '/squad';
const teamPath = '/squad/:teamId';
const teamFolderPath = `${teamPath}/folder/:folderId`;
const teamVideoPath = `${teamPath}/video/:videoId`;
const teamSettingsPath = `${teamPath}/settings`;
const teamSettingsTabPath = `${teamPath}/settings/:tab`;
const teamSettingsAddReactionPath = `${teamPath}/settings/reactions/add`;

const path = {
  base: basePath,
  team: teamPath,
  teamFolder: teamFolderPath,
  teamVideo: teamVideoPath,
  teamSettings: teamSettingsPath,
  teamSettingsTab: teamSettingsTabPath,
  teamSettingsAddReaction: teamSettingsAddReactionPath,
} as const;

const createBaseUrl = () => '/squad';
const createTeamUrl = (teamId: ID) => `/squad/${teamId}`;
const createTeamFolderUrl = (teamId: ID, folderId: ID) => `${createTeamUrl(teamId)}/folder/${folderId}`;
const createTeamVideoUrl = (teamId: ID, videoId: ID) => `${createTeamUrl(teamId)}/video/${videoId}`;
const createTeamSettingsUrl = (teamId: ID) => `${createTeamUrl(teamId)}/settings`;
const createTeamSettingsTabUrl = (teamId: ID, tab: string) => `${createTeamUrl(teamId)}/settings/${tab}`;
const createTeamSettingsAddReactionUrl = (teamId: ID) => `${createTeamUrl(teamId)}/settings/reactions/add`;

function SquadRoutingComponent() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Switch>
        <Route path={SquadRouting.path.team} component={TeamPageWrapper} />
        <Route path={SquadRouting.path.base} component={SquadDashboard} />
      </Switch>
    </Suspense>
  );
}

class SquadRouting {
  public static readonly path = path;
  public static readonly paths = Object.values(this.path);
  public static readonly createUrl = {
    base: createBaseUrl,
    team: createTeamUrl,
    teamFolder: createTeamFolderUrl,
    teamVideo: createTeamVideoUrl,
    teamSettings: createTeamSettingsUrl,
    teamSettingsTab: createTeamSettingsTabUrl,
    teamSettingsAddReaction: createTeamSettingsAddReactionUrl,
  };
  public static readonly Component = SquadRoutingComponent;
}

declare namespace SquadRouting {
  interface BaseParams {}
  interface TeamParams extends BaseParams {
    teamId: ID;
  }
  interface TeamFolderParams extends TeamParams {
    folderId: ID;
  }
  interface TeamVideoParams extends TeamParams {
    videoId: ID;
  }
  interface TeamSettingsParams extends TeamParams {}
}

export default SquadRouting;

export function useSelectedTeamId() {
  return useRouteMatch<SquadRouting.TeamParams>(SquadRouting.path.team)?.params.teamId;
}

export function useSelectedDirectoryId() {
  return useRouteMatch<SquadRouting.TeamFolderParams>(SquadRouting.path.teamFolder)?.params.folderId;
}
