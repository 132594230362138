import { useEffect } from 'react';
import { FallbackProps } from 'react-error-boundary';

import { useUserProfile } from '@/features/authentication/UserProfileContext';

export function ResetBoundaryOnLogin(props: FallbackProps) {
  useResetBoundaryOnLogin(props);
  return null;
}

export function useResetBoundaryOnLogin(props: FallbackProps) {
  const { resetErrorBoundary } = props;
  const userProfile = useUserProfile();
  const userId = userProfile?.id;
  useEffect(() => {
    return () => {
      resetErrorBoundary();
    };
  }, [resetErrorBoundary, userId]);
}
