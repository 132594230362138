import { Dialog, Typography } from '@mui/material';
import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import AlertDialogContent, { AlertDialogProps } from '@/components/AlertDialogContent';
import VideoNameList from '@/components/VideoNameList';

import AbortUploadDialog from './AbortUploadDialog';

interface AbortMultipleUploadsDialogOwnProps {
  className?: string;
  videoNames: string[];
  onClose?: VoidFunction;
  onAbort?: VoidFunction;
}

type AbortMultipleUploadsDialogProps = AbortMultipleUploadsDialogOwnProps & Pick<AlertDialogProps, 'open' | 'onClose'>;

function AbortMultipleUploadsDialog(props: AbortMultipleUploadsDialogProps) {
  const { onAbort, videoNames, ...rest } = props;

  if (props.videoNames.length === 1) {
    return (
      <AbortUploadDialog
      videoName={props.videoNames[0]}
      onAbort={onAbort}
      {...rest}
      />
    );
  }

  return (
    <Dialog {...rest}>
      <AbortMultipleUploadsDialogContent {...props} />
    </Dialog>
  );
}

function AbortMultipleUploadsDialogContent(props: AbortMultipleUploadsDialogProps) {
  const { onAbort, videoNames, ...rest } = props;

  const { t } = useTranslation(['common', 'features']);

  return (
    <AlertDialogContent
    titleText={t('features:uploads.abortMultiple.titleWithCount', { count: videoNames.length })}
    description={(
      <Fragment>
        <Typography>
          {t('features:uploads.abortMultiple.description')}
        </Typography>
        <VideoNameList videoNames={videoNames} />
      </Fragment>
    )}
    cancel={{
      children: t('features:uploads.abort.cancel'),
      onClick: props.onClose,
    }}
    confirm={{
      children: t('features:uploads.abortMultiple.titleWithCount', { count: videoNames.length }),
      color: 'negative',
      onClick: onAbort,
    }}
    {...rest}
    />
  );
}

export default memo(AbortMultipleUploadsDialog);
