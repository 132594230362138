import { AsyncButton, DiscordButton, GoogleButton } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FormEvent, Fragment, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconTextField from '@/components/IconTextField';
import { usePromiseSagaDispatch } from '@/utils/promisify-saga';

import { loginWithPasswordAC, SignInSource } from './authentication-slice';
import { useOAuth } from './useOAuth';

interface LoginFormOwnProps {
  className?: string;
  sx?: SxProps<Theme>;

  signInSource?: SignInSource;
  thirdPartyProvider?: boolean;

  onLoggedIn?: VoidFunction;
  onEmailClick?: VoidFunction;
  onSignUpClick?: VoidFunction;
  onForgotPasswordClick?: VoidFunction;
  onOAuthClick?: (url: string) => void;
}

type LoginFormProps = LoginFormOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    textAlign: 'center',
  },
  divider: {
    textTransform: 'uppercase',
  },
  emailIcon: {
    margin: theme.spacing(1),
  },
  loginButton: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minWidth: 'unset',
  },
}), { name: 'LoginForm' });

function LoginForm(props: LoginFormProps) {
  const classes = useStyles(props);
  const {
    className,
    onEmailClick,
    onForgotPasswordClick,
    onLoggedIn,
    onOAuthClick,
    onSignUpClick,
    signInSource,
    sx,
    thirdPartyProvider = true,
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const promiseSagaDispatch = usePromiseSagaDispatch();
  const { t, i18n } = useTranslation(['common']);
  const wt = t as WTFunction;
  const { enqueueSnackbar } = useSnackbar();

  const handleTextFieldChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
    }
  }, []);

  const loginWithProvider = useOAuth({ onLoggedIn, signInSource, setUrl: onOAuthClick });

  const handleSigninWithGoogle = useCallback(() => {
    loginWithProvider('google-oauth2');
  }, [loginWithProvider]);

  const handleSigninWithDiscord = useCallback(() => {
    loginWithProvider('discordapp');
  }, [loginWithProvider]);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (loading) {
      return;
    }
    setLoading(true);

    try {
      await promiseSagaDispatch(loginWithPasswordAC, { email, password }, { signInSource });
      onLoggedIn?.();
    } catch (error) {
      const tKey = 'common:authentication.errors.' + error.message;
      const text = i18n.exists(tKey) ? wt(tKey) : error.message;
      enqueueSnackbar(text, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [email, enqueueSnackbar, i18n, loading, onLoggedIn, password, promiseSagaDispatch, signInSource, wt]);

  const thirdPartyLoginForm = (
    <Fragment>
      <DiscordButton
      className={classes.loginButton}
      label={t('common:authentication.discord')}
      onClick={handleSigninWithDiscord}
      />
      <GoogleButton
      type='light'
      fullWidth={true}
      label={t('common:authentication.google')}
      onClick={handleSigninWithGoogle}
      center='content'
      shape='rounded'
      />
    </Fragment>
  );

  const emailLoginForm = (
    <Fragment>
      <IconTextField
      // type='email'
      name='email'
      icon={<EmailIcon />}
      label={t('common:authentication.email')}
      placeholder={t('common:authentication.email')}
      value={email}
      autoFocus={true}
      fullWidth={true}
      onChange={handleTextFieldChange}
      />
      <IconTextField
      type='password'
      name='password'
      icon={<LockIcon />}
      label={t('common:authentication.password')}
      placeholder={t('common:authentication.password')}
      value={password}
      onChange={handleTextFieldChange}
      />
    </Fragment>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} textAlign='center' className={className} sx={sx}>
        {thirdPartyProvider ? (
          <Fragment>
            {thirdPartyLoginForm}
            <Button startIcon={<EmailIcon className={classes.emailIcon} />} className={classes.loginButton} variant='contained' color='primary' fullWidth={true} onClick={onEmailClick}>
              <Typography variant='h4' textTransform='capitalize' fontWeight={400}>
                {t('common:authentication.email')}
              </Typography>
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            {emailLoginForm}
            <Button key='forgot' onClick={onForgotPasswordClick}>
              Forgot password?
            </Button>
            <AsyncButton key='login' type='submit' variant='contained' color='primary' loading={loading} disabled={loading} fullWidth={true}>
              {t('common:authentication.login')}
            </AsyncButton>
          </Fragment>
        )}
        <Stack direction='row' justifyContent='center' alignItems='center'>
          <Typography>
            {t('common:authentication.no_account')}
          </Typography>
          <Button onClick={onSignUpClick}>
            {t('common:authentication.sign_up')}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default memo(LoginForm);
