import { ActionMatchingPattern, Channel, Task } from '@redux-saga/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { buffers, channel, SagaIterator } from 'redux-saga';
import { actionChannel, ActionPattern, call, CallEffect, cancel, cancelled, debounce, delay, fork, ForkEffect, HelperWorkerParameters, put, race, RaceEffect, select, take, TakeEffect, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action, ActionCreator, AsyncActionCreators } from 'typescript-fsa';

import { RootReducer } from '@/app/rootReducer.app';
import { uuidv4 } from '@/app/uuid';
import { registerExitBlocker, unregisterExitBlocker } from '@/features/background-window/background-slice';

import { createDeferredPromise } from './DeferedPromise';

export function raceAsyncAC<P, R, E>(asyncAC: AsyncActionCreators<P, R, E>): RaceEffect<TakeEffect> {
  return race({
    done: take(asyncAC.done),
    failed: take(asyncAC.failed),
  });
}

type BatchableActionPattern = ActionPattern & {
  meta?: {
    batch: boolean;
  };
};

export function batchEffect<P extends BatchableActionPattern>(ms: number, pattern: P, worker: (actions: ActionMatchingPattern<P>[]) => any): ForkEffect<never>;
export function batchEffect<P extends BatchableActionPattern, Fn extends(...args: any[]) => any>(ms: number, pattern: P, worker: (actions: ActionMatchingPattern<P>[], ...args: HelperWorkerParameters<P, Fn>) => any): ForkEffect<never>;
export function batchEffect<P extends BatchableActionPattern, Fn extends(...args: any[]) => any>(
  ms: number,
  pattern: P,
  worker: (actions: ActionMatchingPattern<P>[], ...args: HelperWorkerParameters<P, Fn>) => any,
  ...args: HelperWorkerParameters<P, Fn>
) {
  return fork(function* () {
    const chan: Channel<ActionMatchingPattern<P>> = yield call(channel);

    yield takeEvery(pattern, function* (action) {
      if (pattern.meta?.batch === false) { // explicitly compare to false to default to batching
        yield fork(worker, [action], ...args);
      } else {
        yield put(chan,  action);
      }
    });

    let actions: ActionMatchingPattern<P>[] = [];
    yield takeLatest(chan, function* (action): SagaIterator {
      try {
        yield delay(ms);
      } finally {
        actions.push(action);
        if (!(yield cancelled())) {
          yield fork(worker, actions, ...args);
          actions = [];
        }
      }
    });
  });
}

/**
 * Creates an action channel to buffer `pattern`. The `worker` function is run on each buffered action until the
 * buffer is empty. When the buffer is empty. `asyncAC.done` will be called. `asyncAC.done` will also be called
 * when the `asyncAC.started` action is dispatched and there is no task working on the buffered action. i.e. the
 * buffer is empty.
 * @param pattern ActionPattern to queue
 * @param worker function to call for each buffered
 * @param asyncAC Async action's .done will be called when the buffer is empty
 * @returns forked effect
 */
export function processingQueue<P extends ActionPattern>(
  pattern: P,
  worker: (action: ActionMatchingPattern<P>) => any,
  asyncAC: AsyncActionCreators<void, void, Error>,
) {
  return fork(function* (): SagaIterator {
    const buffer = buffers.expanding<ActionMatchingPattern<P>>();
    const chan = yield actionChannel(pattern, buffer);

    let task: Task | undefined;
    yield takeEvery(pattern, function* () {
      if (!task) {
        task = yield fork(function* () {
          while (true) {
            const action: ActionMatchingPattern<P> = yield take(chan);
            yield call(worker, action);
            if (buffer.isEmpty()) {
              yield put(asyncAC.done({}));
              task = undefined;
            }
          }
        });
      }
    });

    yield takeEvery(asyncAC.started, function* () {
      if (!task) {
        yield put(asyncAC.done({}));
      }
    });
  });
}

export function debounceById<P extends ID>(
  ms: number,
  actionCreator: ActionCreator<P>,
  worker: (action: Action<P>, count: number) => SagaIterator,
): ForkEffect;
export function debounceById<P extends { id: ID }>(
  ms: number,
  actionCreator: ActionCreator<P>,
  worker: (action: Action<P>, count: number) => SagaIterator,
): ForkEffect;
export function debounceById<P extends ID | { id: ID }>(
  ms: number,
  actionCreator: ActionCreator<P>,
  worker: (action: Action<P>, count: number) => SagaIterator,
): ForkEffect {
  const tasks: Partial<Dictionary<Task>> = {};
  const counts: Partial<Dictionary<number>> = {};
  return takeEvery(actionCreator, function* (action): SagaIterator {
    const id = typeof action.payload === 'string' ? action.payload : action.payload.id;
    const idSpecificActionName = actionCreator.type + ':' + id;
    const task = tasks[id];
    if (!task || !task.isRunning()) {
      tasks[id] = yield debounce(ms, idSpecificActionName, function* () {
        yield call(worker, action, counts[id] || 0);
        counts[id] = 0;
      });
    }

    counts[id] = (counts[id] || 0) + 1;
    yield put({ type: idSpecificActionName });
  });
}

export function debounceOnce<P extends ActionPattern>(
  ms: number,
  pattern: P,
  worker: (action: ActionMatchingPattern<P>) => any,
): ForkEffect {
  return fork(function* (): SagaIterator {
    const outerTask = yield fork(function* (): SagaIterator {
      let task;
      while (true) {
        const action: ActionMatchingPattern<P> = yield take(pattern);
        if (task) {
          yield cancel(task);
        }
        task = yield fork(function* () {
          yield delay(ms);
          yield call(worker, action);
          yield cancel(outerTask);
        });
      }
    });
  });
}

export function collectIds(actions: PayloadAction<ID | ID[]>[]) {
  return Array.from(new Set(actions.flatMap(action => action.payload)));
}

export function waitForState(
  predicateSelector: (state: RootReducer) => boolean,
): CallEffect {
  return call(function* () {
    const selectRef = yield select(predicateSelector);
    console.log('waiting for state', selectRef);
    while (!(yield select(predicateSelector))) {
      yield take('*');
    }
  });
}

export function blockExit(worker: VoidFunction) {
  return fork(function* (): SagaIterator {
    const uuid = uuidv4();
    const { promise, resolve } = createDeferredPromise<void>();

    yield put(registerExitBlocker({
      uuid,
      promise,
    }));

    yield fork(function* () {
      yield call(worker);
      yield call(resolve);
      yield put(unregisterExitBlocker(uuid));
    });
  });
}

export function sagafyOverwolf<R extends overwolf.Result>(fn: (callback: (result: R) => void) => void): CallEffect<R> {
  return call(function* (): SagaIterator<R> {
    const chan = channel();
    yield call(fn, chan.put);
    const result = yield take(chan);
    chan.close();
    return result;
  });
}
