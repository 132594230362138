import { Atom, atom, PrimitiveAtom, WritableAtom } from 'jotai';
import { groupBy } from 'lodash';
import { createContext, useMemo } from 'react';

type WriteOnlyAtom<T> = WritableAtom<null, [T], void>;
type MultiSelectAtom = WriteOnlyAtom<UUID[]>;

interface MultiSelectAtoms {
  makeIsSelectedAtom: (id: UUID) => Atom<boolean>;
  makeAreAllSelectedAtom: (ids: UUID[]) => Atom<boolean>;
  makeAreSomeSelectedAtom: (ids: UUID[]) => Atom<boolean>;
  selectedAtom: PrimitiveAtom<Set<UUID>>;
  selectedCountAtom: Atom<number>;
  addToSelectionAtom: MultiSelectAtom;
  removeFromSelectionAtom: MultiSelectAtom;
  toggleSelectionAtom: MultiSelectAtom;
  deselectAllAtom: WriteOnlyAtom<void>;
}

function makeMultiSelectAtoms(initialSelection?: UUID[]): MultiSelectAtoms {
  const selectedAtom = atom(new Set<UUID>(initialSelection));
  const makeIsSelectedAtom = (id: UUID): Atom<boolean> => {
    return atom((get) => get(selectedAtom).has(id));
  };
  const makeAreAllSelectedAtom = (ids: UUID[]): Atom<boolean> => {
    return atom((get) => {
      const selectedUuids = get(selectedAtom);
      return ids.every((id) => selectedUuids.has(id));
    });
  };
  const makeAreSomeSelectedAtom = (ids: UUID[]): Atom<boolean> => {
    return atom((get) => {
      const selectedUuids = get(selectedAtom);
      return ids.some((id) => selectedUuids.has(id));
    });
  };
  const addToSelectionAtom = atom(null, (get, set, videoIds: UUID[]) => {
    const selectedUuids = get(selectedAtom);
    const filtered = videoIds.filter((id) => !selectedUuids.has(id));
    if (filtered.length > 0) {
      set(selectedAtom, new Set([...selectedUuids, ...filtered]));
    }
  });
  const selectedCountAtom = atom((get) => get(selectedAtom).size);
  const removeFromSelectionAtom = atom(null, (get, set, videoIds: UUID[]) => {
    const selectedUuids = get(selectedAtom);
    const filtered = videoIds.filter((id) => selectedUuids.has(id));
    if (filtered.length > 0) {
      const clone = new Set([...selectedUuids]);
      for (const id of videoIds) {
        clone.delete(id);
      }
      set(selectedAtom, clone);
    }
  });
  const toggleSelectionAtom = atom(null, (get, set, videoIds: UUID[]) => {
    const selectedUuids = get(selectedAtom);
    const { toRemove = [], toAdd = [] } = groupBy(videoIds, (id) =>
      selectedUuids.has(id) ? ('toRemove' as const) : ('toAdd' as const),
    );
    if (toRemove.length > 0 || toAdd.length > 0) {
      const clone = new Set([...selectedUuids]);
      for (const id of toRemove) {
        clone.delete(id);
      }
      for (const id of toAdd) {
        clone.add(id);
      }
      set(selectedAtom, clone);
    }
  });
  const deselectAllAtom = atom(null, (_, set) => {
    set(selectedAtom, new Set<UUID>());
  });

  return {
    makeIsSelectedAtom,
    makeAreAllSelectedAtom,
    makeAreSomeSelectedAtom,
    selectedAtom,
    selectedCountAtom,
    addToSelectionAtom,
    removeFromSelectionAtom,
    toggleSelectionAtom,
    deselectAllAtom,
  };
}

export const MultiSelectAtomsContext = createContext<Record<'selectedVideosAtoms' | 'selectedFoldersAtoms', MultiSelectAtoms>>({
  selectedVideosAtoms: makeMultiSelectAtoms(),
  selectedFoldersAtoms: makeMultiSelectAtoms(),
});

export function useMultiSelectAtomsContextValue(options: Partial<Record<'videos' | 'folders', UUID[]>> = {}): Record<'selectedVideosAtoms' | 'selectedFoldersAtoms', MultiSelectAtoms> {
  const selectedVideosAtoms = useMemo(() => makeMultiSelectAtoms(options.videos), [options.videos]);
  const selectedFoldersAtoms = useMemo(() => makeMultiSelectAtoms(options.folders), [options.folders]);
  return useMemo(() => ({
    selectedVideosAtoms,
    selectedFoldersAtoms,
  }), [selectedFoldersAtoms, selectedVideosAtoms]);
}
