import { VideoUploadOptions } from '@insights-gaming/video-upload-slice';

import { WebRootReducer } from '@/app/rootReducer.web';
import { makeVideoUploadSelectors } from '@/features/video-upload/upload-queue/video-upload-selector/makeVideoUploadSelectors';
import { UploadVideoInput } from '@/features/video-upload/upload-queue/video-upload-slice';

export function getMetadata(options: VideoUploadOptions<UploadVideoInput>) {
  return { name: options.displayName ?? options.video.file.name, size: options.video.file.size };
}

export const videoUploadSelectors = makeVideoUploadSelectors(
  (root: WebRootReducer) => root.videoUpload,
  getMetadata,
);
