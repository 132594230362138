
import { Theme } from '@insights-gaming/theme';
import { SystemStyleObject } from '@mui/system/styleFunctionSx';

import { useWebSetting, webSettingPath } from '@/features/settings/WebSettingsContext';

type SxArray = ReadonlyArray<
  boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
>;

export function useTeamCardSx(): SxArray {
  const backgroundImage = useWebSetting(webSettingPath.appearance.customTheme.backgroundImage);
  return [
    {
      backgroundColor: theme => theme.palette.background.default,
    },
    !backgroundImage && {
      background: 'inherit',
      backgroundAttachment: 'fixed',
    },
  ];
}
