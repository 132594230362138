import deepmerge from 'deepmerge';
import update, { Context, extend, Spec } from 'immutability-helper';
import { isEmpty } from 'lodash';

const context = new Context();

interface AutoSpec {
  $auto: {};
}

extend<{}>('$auto', function (value, old) {
  return old ? update(old, value) : update({}, value);
});

context.extend<{}>('$auto', function (value, old) {
  return old ? context.update(old, value) : context.update({}, value);
});

type MapUpdateParams<K, V> = [K, Partial<V>][];
interface MapUpdateSpec<K, V> {
  $updateItem: MapUpdateParams<K, V>;
}

context.extend<Map<any, any>>('$updateItem', function (params: MapUpdateParams<any, any>, old: Map<any, any>) {
  const $add: [any, any][] = params.reduce<[any, any][]>((p, [k, partialUpdate]) => {
    const o = old.get(k);
    if (o) {
      p.push([k, context.update(o, { $deepmerge: partialUpdate })]);
    } else {
      console.log(`object with key ${k} not found`);
    }
    return p;
  }, []);
  return update(old, { $add });
});

type CustomMapSpec<K, V> = MapUpdateSpec<K, V>;

interface DeepmergeSpec {
  $deepmerge: {};
}

context.extend<{}>('$deepmerge', function (params: any, old: any) {
  return update(old, old => deepmerge(old, params));
});

interface DeepunsetSpec {
  $deepunset: string[];
}

context.extend<{}>('$deepunset', function (params: string[], obj: any) {
  for (const path of params) {
    try {
      obj = recursiveUnsetAtPath(obj, path.split('.'));
    } catch (error) {
      // key doesn't exist?
    }
  }
  return obj;
});

function recursiveUnsetAtPath(old: any, keys: string[]): any {
  const [key, ...rest] = keys;
  if (rest.length === 0) {
    return update(old, { $unset: [key] });
  }
  let next = update(old, { $merge: { [key]: recursiveUnsetAtPath(old[key], rest) } });
  if (isEmpty(next[key])) {
    next = update(old, { $unset: [key] });
  }
  return next;
}

type CustomObjectSpec = DeepmergeSpec | DeepunsetSpec | AutoSpec;

type CustomCommandSpec<T> = T extends Map<infer K, infer V> ? (
  CustomMapSpec<K, V>
) : T extends object ? (
  CustomObjectSpec | {
    [K in keyof T]?: CustomCommandSpec<T[K]>
  }
) : never;

type CustomSpec<T> = Spec<T> | CustomCommandSpec<T>;

export default function customUpdate<T>(object: T, spec: Partial<CustomSpec<T>>) {
  return context.update<T, any>(object, spec);
}

// const t = {
//   level: {
//     map: new Map<string, string>(),
//     deepmap: new Map() as DeepMap,
//   },
//   a: {
//     b: {
//       c: 'hello',
//     },
//   },
// };

// const asdf = customUpdate(t, {
//   level: {
//     map: {$add: [['k', 'v']]},
//     deepmap: {$autoMap: [['k1', [['k2', 'v']]]]},
//     $merge: {},
//   },
//   a: {
//     b: {
//       c: {$set: 'world'},
//     }
//   }
// });

// const sadasdfsdaas = update(t, {
//   a: {b: {}},
//   $merge: {},
// });
