import { Theme } from '@insights-gaming/theme';
import { TransitionProps } from '@mui/material/transitions';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ComponentType,
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';

// Omit 'timeout' because mui transition components may define their own 'timeout' prop which may be different
// See 'Collapse' component for example.
type CustomTransitionProps = Omit<TransitionProps, 'timeout'> & { children?: ReactNode };

export function withCustomTransition<P extends CustomTransitionProps>(
  TransitionComponent: ComponentType<P>,
  customComponentName: string,
) {
  const useStyles = makeStyles((theme: Theme) => createStyles({
    exit: {
      pointerEvents: 'none',
    },
  }), { name: customComponentName });

  const WithCustomTransition = forwardRef(function WithCustomTransition(props: P, ref: Ref<HTMLElement>) {
    const classes = useStyles(props);
    const { onExiting, onExited } = props;

    const [exiting, setExiting] = useState(false);

    useEffect(() => {
      const listener = (e: KeyboardEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };
      if (exiting) {
        window.addEventListener('keydown', listener);
      }

      return () => {
        window.removeEventListener('keydown', listener);
      };
    }, [exiting]);

    const handleExiting = useCallback((node: HTMLElement) => {
      onExiting?.(node);
      setExiting(true);
    }, [onExiting]);

    const handledExited = useCallback((node: HTMLElement) => {
      onExited?.(node);
      setExiting(false);
    }, [onExited]);

    return (
      <TransitionComponent
      ref={ref}
      {...props}
      onExiting={handleExiting}
      onExited={handledExited}
      TransitionComponent={CSSTransition}
      classNames={classes}
      />
    );
  });
  WithCustomTransition.displayName = `withCustomTransition(${TransitionComponent.displayName || ''})`;

  return WithCustomTransition;
}
