import { Theme, WithMakeStyles } from '@insights-gaming/theme';
import { Card, CardActionArea, CardActionAreaProps, CardActions, CardContent, Stack, SxProps, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { forwardRef, Fragment, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { AuthedCardActionArea } from '@/features/authentication/withAuthDialog';

interface VideoCardBaseOwnProps {
  className?: string;
  name: ReactNode;
  disabled?: boolean;
  thumbnail?: ReactNode;
  newVideoIndicator?: ReactNode;
  metadata?: ReactNode;
  thumbnailOverlay?: ReactNode;
  overlay?: ReactNode;
  secondaryAction?: ReactNode;

  authForced?: boolean;

  onClick?: MouseEventHandler;
  onPointerLeave?: React.PointerEventHandler<HTMLDivElement>;
  onMouseMove?: (e: React.MouseEvent<Element, MouseEvent>) => void;
  sx?: SxProps<Theme>;
}

export type VideoCardBaseProps = PropsWithChildren<VideoCardBaseOwnProps> & WithMakeStyles<typeof useStyles>;

const thumbnailAspectRatio = (9 / 16 * 100) + '%';
const lineHeight = 1.5;
const lineClamp = 2;
const height = `${lineHeight * lineClamp}em`;

const useStyles = makeStyles(theme => createStyles({
  root: {
    position: 'relative',
    '&$disabled': {
      opacity: .3,
    },
  },
  thumbnail: {
    position: 'relative',
    height: 0,
    paddingBottom: thumbnailAspectRatio,
    '&$overlay > *': {
      pointerEvents: 'none',
    },
  },
  content: {
    padding: theme.spacing(1, 1),
  },
  title: {
    flex: 1,
    fontWeight: 'bold',
    display: 'box',
    height,
    lineHeight,
    overflow: 'hidden',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    lineClamp,
    boxOrient: 'vertical',
    position: 'relative',
  },
  contextMenuActions: {
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: theme.spacing(1),
    paddingTop: thumbnailAspectRatio,
    zIndex: 1,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left:0,
    borderRadius: 'inherit',
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'all',
    },
  },
  contextMenu: {},
  favoriteButton: {},
  disabled: {},
}), { name: 'VideoCardBase' });

export const VideoCardBase = forwardRef<
  HTMLDivElement,
  VideoCardBaseProps
>(function VideoCardBase(props: VideoCardBaseProps, ref) {
  const classes = useStyles(props);
  const {
    children,
    className,
    disabled,
    authForced,
    onMouseMove,
    metadata,
    name,
    newVideoIndicator,
    onClick,
    onPointerLeave,
    overlay,
    secondaryAction,
    sx,
    thumbnail,
    thumbnailOverlay,
  } = props;

  const { ref: actionsRef, width } = useResizeDetector({ handleWidth: true, handleHeight: false });

  const actionChildren = (
    <Fragment>
      <div className={classes.thumbnail}>
        {thumbnail}
      </div>
      <CardContent className={classNames(classes.content)}>
        <Stack
        direction='row'
        spacing={1}
        sx={{
          pr: `${width}px`,
        }}
        >
          <div className={classes.title}>
            <Typography>
              {name}
            </Typography>
          </div>
          {newVideoIndicator}
        </Stack>
        {metadata}
      </CardContent>
    </Fragment>
  );

  const commonActionProps: CardActionAreaProps = {
    onClick,
    disabled,
    children: actionChildren,
  };

  return (
    <Card
    ref={ref}
    onPointerLeave={onPointerLeave}
    className={classNames(classes.root, { [classes.disabled]: disabled }, className)}
    onMouseMove={onMouseMove}
    sx={sx}
    >
      {authForced ? (
        <AuthedCardActionArea signInSource='Video Card' {...commonActionProps} />
      ) : (
        <CardActionArea {...commonActionProps} />
      )}
      {(secondaryAction) && (
        <CardActions ref={actionsRef} disableSpacing={true} className={classes.contextMenuActions}>
          {secondaryAction}
        </CardActions>
      )}
      {thumbnailOverlay && (
        <div className={classNames(classes.thumbnail, classes.overlay)}>
          {thumbnailOverlay}
        </div>
      )}
      {overlay && (
        <div className={classes.overlay}>
          {overlay}
        </div>
      )}
      {children}
    </Card>
  );
});
VideoCardBase.displayName = 'VideoCardBase';
