import { useCallback } from 'react';

import { OAuth2Provider } from '@/features/authentication/authentication-slice';
import { useLoginState } from '@/features/authentication/useLoginState';
import { ENDPOINT } from '@/utils/insightsgg';

import { UseOAuthOptions } from './types';

export function useOAuth({ onLoggedIn, signInSource }: UseOAuthOptions) {
  const loginState = useLoginState();

  return useCallback(async (provider: OAuth2Provider) => {
    window.location.href = ENDPOINT + '/authorize?' + new URLSearchParams({
      redirect_uri: window.location.origin + '/callback',
      provider,
      state: new URLSearchParams(loginState).toString(),
    });
  }, [loginState]);
}
