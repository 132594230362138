import { GameFeature, SUPPORTED_CLASS_IDS } from '@insights-gaming/game-events';

export const REQUIRED_FEATURES: GameFeature[] = [
  'match_info',
  'kill',
  'death',
  'assist',
  'headshot',
  'round_start',
  'match_start',
  'match_info',
  'match_end',
  'team_round_win',
  'bomb_planted',
  'bomb_change',
  'reloading',
  'fired',
  'weapon_change',
  'weapon_acquired',
  'info',
  'roster',
  'player_activity_change',
  'team_set',
  'replay',
  'counters',
];
export const REGISTER_RETRY_TIMEOUT = 10000;
export const WINDOW_NAMES = {
  BACKGROUND: 'background',
  INGAME: 'in_game',
  INGAME_SECONDARY: 'in_game_secondary',
  DESKTOP: 'desktop',
  NOTE_EDITOR: 'note_editor',
  NOTIFICATION: 'notification',
  INPUT_OVERLAY: 'input_overlay',
  WEBCAM: 'webcam',
} as const;
const _WINDOW_NAMES: Record<string, WindowName> = WINDOW_NAMES as Record<string, WindowName>; // catches invalid window names

export const RECAPTCHA_SITE_KEY = '6LfKwqUUAAAAALlstwRsDowqbQHqRaxb62CqZfoX';

export const UPLOAD_CHUNK_SIZE = 50 * 2 ** 20; // going over around 50 MB causes 500 errors
export const UPLOAD_UI_THROTTLE_DURATION = 500;

export const INSIGHTS_GG_ONBOARD_LOGIN_LINK = 'https://insights.gg/dashboard?utm_source=insights_capture&utm_medium=onboard-login&utm_campaign=cta_insightsgg';
export const INSIGHTS_GG_SYSTEM_TRAY_LINK = 'https://insights.gg/dashboard?utm_source=insights_capture&utm_medium=system-tray&utm_campaign=cta_insightsgg';
export const DISCORD_LINK = 'https://discord.com/invite/t23sK4V';

export const HOTKEY_NAMES = {
  createBookmark: 'create_Bookmark',
  instantReplay: 'instantReplay',
  reviewReplay: 'review_Replay',
  startStopRecording: 'startstop_Recording',
  toggleInsights: 'toggle_Insights',
  toggleWebcam: 'toggle_Webcam',
  toggleNoteWindow: 'toggle_noteWindow',
  toggleInputOverlay: 'toggle_InputOverlay',
  customTagActivate1: 'customTagActivate1',
  customTagActivate2: 'customTagActivate2',
  customTag1: 'customTag1',
  customTag2: 'customTag2',
  customTag3: 'customTag3',
  customTag4: 'customTag4',
  customTag5: 'customTag5',
  dismissInGameNotification: 'dismissInGameNotification',
  // toggleMic: 'toggleMic',
  pushToTalk: 'pushToTalk',
} as const;
const _HOTKEY_NAMES: Record<string, HotkeyName> = HOTKEY_NAMES; // catch invalid hotkey name values

export const CUSTOM_TAG_PREFIX = 'customTag';
export const CUSTOM_TAG_ACTIVATE_PREFIX = 'customTagActivate';
export const CUSTOM_TAGS_PER_GROUP = 5;

export type CustomTagDefaultColors = Record<CustomTagActivateHotkeyName, Partial<Record<CustomTagHotkeyName, string>>>;
export const CUSTOM_TAG_DEFAULT_COLORS: CustomTagDefaultColors = {
  customTagActivate1: {
    customTag1: '#FE5552',
    customTag2: '#4D9EFE',
    customTag3: '#FEBA37',
    customTag4: '#88FC76',
    customTag5: '#FB77DF',
  },
  customTagActivate2: {
    customTag1: '#4CAF50',
    customTag2: '#FF9900',
    customTag3: '#49E9FF',
    customTag4: '#8A88FF',
    customTag5: '#6B39D6',
  },
};

export const TITLEBAR_HEIGHT = 30;
export const SIDEBAR_WIDTH = 400;
export const OUTER_EDGE_SPACING = 1.5;
export const SCROLLBAR_WIDTH = 12;

export const BASE2_B_PER_GB = 2 ** 30;

// Max comment labels to display
export const MAX_LABELS_TO_SHOW = 2;

export const SupportedGameClassIds = Object.values(SUPPORTED_CLASS_IDS);

export const AUTOMATIC_BACKUP_REGEX = /^Insights-Capture-(\d+)\.(json|bak)$/;
export const HOURS_BETWEEN_UPGRADE_DIALOG = 336; // TWO WEEKS

export const LEAGUE_LAUNCHER_QUEUE_ID = {
  // other queue ids: https://static.developer.riotgames.com/docs/lol/queues.json
  tftRegular: 1090,
  tftRanked: 1100,
  tftTutorial: 1110,
  tftTest: 1111,
  tftHyperoll: 1130,
} as const;

export const TFT_QUEUE_IDS = [
  LEAGUE_LAUNCHER_QUEUE_ID.tftRegular,
  LEAGUE_LAUNCHER_QUEUE_ID.tftRanked,
  LEAGUE_LAUNCHER_QUEUE_ID.tftTutorial,
  LEAGUE_LAUNCHER_QUEUE_ID.tftTest,
  LEAGUE_LAUNCHER_QUEUE_ID.tftHyperoll,
] as const;

export type TFTQueueId = (typeof TFT_QUEUE_IDS)[number];

export const LEAGUE_LAUNCHER_CLASS_ID = 10902;

export const TFTClassIdDelimiter = '_';

export const VIDEO_FILE_FILTERS = '*.mp4';
export const ACCEPTED_IMPORT_FILE_MIME_TYPES = ['video/mp4'];

export const WINDOWS_DEFAULT_DPI = 96; // 96 dpi on Windows means 100% scale
export const DESKTOP_MIN_SIZE = {
  default: { w: 1440, h: 862 },
  small: { w: 1280, h: 730 },
  smaller: { w: 1212, h: 650 },
};
// In minutes
export const MINIMUM_VIDEO_DURATION_FOR_SHARDS = 5;

const gameFeatures: GameFeature[] = [
  'announcer',
  'assist',
  'death',
  'kill',
  'team_round_win',
  'headshot',
  'round_start',
  'match',
  'match_state_changed',
  'matchState',
  'match_state',
  'match_start',
  'match_end',
  'match_ended',
  'match_info',
  'stats',
  'summoner_info',
  'roster', // Needed for R6 to detect whether player is in game or not to start autorecord.
  'teamGoal', /* does nothing right now. Event comes from stats feature. Delete if OW deletes this feature and uses stats instead */
  'opposingTeamGoal', /* does nothing right now. Event comes from stats feature. Delete if OW deletes this feature and uses stats instead */
];

const gameFeaturesForAutoMontage: GameFeature[] = [
  'game',
  'game_info',
  'match',
  'match_info',
  'match_state',
  'matchState',
  'phase',
  'roster',
];

export const allGameFeatures = Array.from(new Set(gameFeatures.concat(gameFeaturesForAutoMontage)));

export const STREAM_ERROR = {
  Unkown: 'Unknown',
  Unauthorized: 'Unauthorized',
  Invalid_Server: 'Invalid_Server',
  No_Internet: 'No_Internet',
  Invalid_Video_Settings: 'Invalid_Video_Settings',
  No_Playback_Device: 'No_Playback_Device',
  Not_InGame: 'Not_InGame',
  Internet_Congested: 'Internet_Congested',
  Game_Quit_Mid_Stream: 'Game_Quit_Mid_Stream',
  Twitch_Dll_Load_Error: 'Twitch_Dll_Load_Error',
  Twitch_Init_Error: 'Twitch_Init_Error',
  No_Encoder: 'No_Encoder',
  Out_Of_Disk_Space: 'Out_Of_Disk_Space',
  Update_Driver: 'Update_Driver',
};

export const STREAM_WARNING = {
  low_disk_space_warning: 'low_disk_space_warning',
};
