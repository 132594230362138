import {
  AsyncButton,
  StyledButton,
  StyledIconButton,
  TooltipButton,
  TooltipButtonProps,
} from '@insights-gaming/material-components';
import { Button, CardActionArea, MenuItem } from '@mui/material';
import type { ReactNode } from 'react';
import {
  ComponentType,
  ElementType,
  ExoticComponent,
  forwardRef,
  MouseEventHandler,
  Ref,
  useImperativeHandle,
  useRef,
} from 'react';

import NavigationButton from '@/features/desktop-window/navigation/NavigationButton';
import { getDisplayName } from '@/utils';

import { SignInSource } from './authentication-slice';
import { useAuthDialog } from './useAuthDialog';

interface CommonProps {
  onClick?: MouseEventHandler<any>;
}

interface InjectedProps extends CommonProps {}

interface AdditionalProps extends CommonProps {
  signInSource: SignInSource;
}

export function withAuthDialog<P extends InjectedProps>(
  Component: ComponentType<P> | ExoticComponent<P>,
) {
  type RemovedProps = Omit<InjectedProps, keyof CommonProps>;
  type Props = Omit<AdditionalProps & P, keyof RemovedProps>;

  const WithAuthDialog = forwardRef(function WithAuthDialog(props: Props, forwardedRef: Ref<HTMLElement | null>) {
    const { onClick, signInSource, ...rest } = props;

    const ref = useRef<HTMLElement>(null);
    useImperativeHandle(forwardedRef, () => ref.current);

    const handleClick = useAuthDialog(ref, props);

    return (
      <Component ref={ref} {...rest as unknown as P} onClick={handleClick} />
    );
  });
  WithAuthDialog.displayName = `WithAuthDialog(${getDisplayName(Component)})`;

  return WithAuthDialog;
}

export const AuthedButton = withAuthDialog(StyledButton);
export const AuthedAsyncButton = withAuthDialog(AsyncButton);
export const AuthedIconButton = withAuthDialog(StyledIconButton);
export const AuthedMenuItem = withAuthDialog(MenuItem);
export const AuthedTooltipButton: <C extends ElementType = typeof Button>(props: AdditionalProps & TooltipButtonProps<C>) => ReactNode | null = withAuthDialog(TooltipButton);
export const AuthedCardActionArea = withAuthDialog(CardActionArea);
export const AuthedNavigationButton = withAuthDialog(NavigationButton);
