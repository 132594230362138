import { buildKeystring } from '@insights-gaming/utils';
import update from 'immutability-helper';
import { TFuncKey } from 'react-i18next';

import { isExistent } from '@/utils/guard';

interface IKeybinding {
  source: 'user' | 'default';
  key: string;
  command: string;
  when?: string;
}

export interface CustomKeybinding extends IKeybinding {
  source: 'user';
}

export interface DefaultKeybinding extends IKeybinding {
  source: 'default';
}

export type Keybinding = CustomKeybinding | DefaultKeybinding;

export interface OWHotkeySetting {
  type: 'owhotkey';
  hotkey: overwolf.settings.hotkeys.IHotkey;
}

export function makeKeybindingIdentifier(keybinding: Keybinding) {
  return [unnegateKeyCommand(keybinding.command), keybinding.when].filter(isExistent).join(':');
}

export function isKeyCommandNegated(str: string) {
  return str.charAt(0) === '-';
}

export function negateKeyCommand(command: string) {
  if (isKeyCommandNegated(command)) {
    return command;
  }
  return `-${command}`;
}

export function unnegateKeyCommand(command: string) {
  if (isKeyCommandNegated(command)) {
    return command.slice(1);
  }
  return command;
}

export function negateKeybinding(keybinding: Keybinding) {
  return update(keybinding, {
    command: negateKeyCommand,
  });
}

export function unnegateKeybinding(keybinding: Keybinding) {
  return update(keybinding, {
    command: unnegateKeyCommand,
  });
}

export function blockKeybind(e: KeyboardEvent): boolean {
  const keystring = buildKeystring(e);
  const element = e.target as HTMLElement;
  const tagName = element.tagName;
  if (['INPUT', 'SELECT', 'TEXTAREA'].includes(tagName)) {
    return true;
  }
  if (element instanceof HTMLDivElement && element.classList.contains('public-DraftEditor-content')) {
    return true;
  }

  switch (keystring) {
    case 'space':
      if (['BUTTON', 'DIV', 'LI'].includes(tagName)) {
        return true;
      }
      break;
    case 'left':
    case 'right':
      if (['SPAN'].includes(tagName)) {
        return true;
      }
      break;
    case 'down':
    case 'up':
      if (['DIV', 'LI'].includes(tagName)) {
        return true;
      }
      break;
  }

  return false;
}

export const CUSTOM_TAG_KEYBINDINGS = 'customTagKeybindings';
export const IN_GAME_KEYBINDINGS = 'inGameKeybindings';
export const VIDEO_KEYBINDINGS = 'videoKeybindings';

export type KeyCommand = TFuncKey<'keybindings'>;
