import { createBackoffFunction } from '@insights-gaming/backoff';
import { FetchStatus } from '@insights-gaming/redux-utils';
import { memo } from 'react';

import { CdnOptions, createCdnSearchParams } from './cdn';
import { isExistent } from './guard';
import { endpoints } from './insightsgg/common';

export const NOOP = () => {};
export const exid = <T extends {id: ID}>(t: T) => t.id;
export const createPair = <T extends {id: ID}>(t: T): [ID, T] => [exid(t), t];
export const getFromDictWithKey = <T>(dict: Partial<Dictionary<T>>, id: ID) => dict[id];
export const getFromDictWithOptionalKey = <T>(dict: Partial<Dictionary<T>>, id?: ID) => id ? dict[id] : undefined;
export const getFromDictWithKeys = <T>(dict: Partial<Dictionary<T>>, ids: ID[]) => ids.map(id => dict[id]);

export function getDisplayName<P>(WrappedComponent: React.ComponentClass<P> | React.FunctionComponent<P>) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const createDefaultFetchStatus = (): FetchStatus => ({ done: false, fetching: false });

export const backoff = createBackoffFunction({
  jitter: 0,
  max: 10000,
  multiplier: 1000,
});

export function wait(ms: number): Promise<false> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(false), ms);
  });
}

export function typedAssetPath(path: PublicAsset) {
  return path;
}

export function typedCdnAssetPath(path: CdnAsset, options?: CdnOptions) {
  const params = options ? createCdnSearchParams(options) : undefined;
  return [endpoints.cdn + path, params].filter(isExistent).join('?');
}

export function typedAssetUrl(path: PublicAsset) {
  return `url(${path})`;
}

// https://stackoverflow.com/a/18391400
export function replaceErrors(key: string, value: any) {
  if (value instanceof Error) {
    const error: {[key: string]: any} = {};

    Object.getOwnPropertyNames(value).forEach(function (key) {
      error[key] = (value as any)[key];
    });

    return error;
  }

  return value;
}

export function objLogger(arg: any, message?: string, isError?: boolean) {
  if (process.env.NODE_ENV === 'production') {
    arg = JSON.stringify(arg, replaceErrors, 2);
  }
  const logFn = isError ? console.error : console.log;
  message ? logFn(message, arg) : logFn(arg);
}

function errorObjLogger(arg: any, message?: string) {
  objLogger(arg, message, true);
}

export function logAsyncFunction<P extends any[], R>(fn: (...args: P) => Promise<R>, message?: string): (...args: P) => Promise<R> {
  return function (...args: P) {
    const promise = fn.call(null, ...args);
    Promise.resolve(promise)
      .then(res => objLogger(res, message))
      .catch(error => errorObjLogger(error, message));
    return promise;
  };
}

export function logParams<P extends any[], R>(fn: (...args: P) => R, message?: string, isError?: boolean): (...args: P) => R {
  return function (...args: P) {
    objLogger(args[0], message, isError);
    const result = fn.call(null, ...args);
    return result;
  };
}

export function makeAutoBlurHandler<E extends HTMLElement>(cb: React.MouseEventHandler<E>) {
  return function (e: React.MouseEvent<E>) {
    e.currentTarget.blur();
    cb(e);
  };
}

export function randomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function interleave<T>(arr: T[], item: T) {
  return arr.flatMap(e => [e, item]).slice(0, -1);
}

// https://stackoverflow.com/a/76453035/15417072
export const genericMemo: <T>(
  component: T,
  propsAreEqual?: (
    prevProps: React.PropsWithChildren<T>,
    nextProps: React.PropsWithChildren<T>
  ) => boolean
) => T = memo;
