import { IconTab } from '@insights-gaming/material-components';
import { createRemFromPx, Theme } from '@insights-gaming/theme';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import InfoIcon from '@mui/icons-material/Info';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Button, IconButton, List, Paper, Stack, SxProps, Tabs, Toolbar, Typography } from '@mui/material';
import fileSize from 'filesize';
import { throttle } from 'lodash';
import { cloneElement, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { UPLOAD_UI_THROTTLE_DURATION } from '@/app/constants';
import AbortMultipleUploadsDialog from '@/features/video-upload/AbortMultipleUploadsDialog';
import AbortUploadDialog from '@/features/video-upload/AbortUploadDialog';

import { CurrentUploadQueueItem } from './CurrentUploadQueueItem';
import { FailedUploadQueueItem } from './FailedUploadQueueItem';
import { QueuedUploadQueueItem } from './QueuedUploadQueueItem';
import { UploadedUploadQueueItem } from './UploadedUploadQueueItem';
import { AbortUploadContext } from './useAbort';
import { videoUploadSelectors } from './video-upload-selector';
import { videoUploadActions } from './video-upload-slice';

export interface UploadQueueProps {
  className?: string;
  sx?: SxProps<Theme>;

  detailedView?: boolean;
  onAbort?: VoidFunction;
  onClearHistory?: VoidFunction;
  onClearTemp?: VoidFunction;
  onCollapse?: VoidFunction;
  onExpand?: VoidFunction;

  additionalTabs?: string[];
  renderTabContent?: (tab: string) => ReactElement;

  shouldRenderUploadItem?: (uploadSource?: string) => boolean;
}

export function UploadQueue(props: PropsWithChildren<UploadQueueProps>) {
  const {
    additionalTabs,
    children,
    className,
    detailedView,
    // onAbort,
    onClearTemp,
    onCollapse,
    onExpand,
    renderTabContent,
    shouldRenderUploadItem = () => true,
    sx,
  } = props;

  const { t } = useTranslation(['common']);

  const currentUpload = useSelector(videoUploadSelectors.getCurrentUpload);
  const startedCurrentUpload = currentUpload?.startedUpload;

  const size = currentUpload?.videoMetadata.size;

  const { total } = useSelector(videoUploadSelectors.getEtaInSeconds);
  const uploadQueue = useSelector(videoUploadSelectors.getUploadQueue);
  const uploadedVideosTemp = useSelector(videoUploadSelectors.getUploadedVideosTemp2);
  const failedUploads = useSelector(videoUploadSelectors.getFailedUploads2);

  const dispatch = useDispatch();

  const [speed, setSpeed] = useState<string>();
  const throttledSetSpeed = useMemo(() => {
    return throttle((rate: number) => {
      setSpeed(t('common:time.ratePerSecond', { rate: fileSize(rate * (size ?? 0)) }));
    }, UPLOAD_UI_THROTTLE_DURATION);
  }, [size, t]);

  useEffect(() => {
    if (startedCurrentUpload && startedCurrentUpload.status !== 'paused') {
      throttledSetSpeed(startedCurrentUpload?.rate ?? 0);
    }
  }, [startedCurrentUpload, throttledSetSpeed]);

  useEffect(() => {
    return () => {
      throttledSetSpeed.cancel();
    };
  }, [throttledSetSpeed]);

  useEffect(() => {
    if (!startedCurrentUpload) {
      throttledSetSpeed.cancel();
      setSpeed(undefined);
    }
  }, [startedCurrentUpload, throttledSetSpeed]);

  const [tab, setTab] = useState<'uploading' | string>('uploading');

  const {
    itemCount,
    pendingCount,
    startedOrPending,
  } = useSelector(videoUploadSelectors.getUploadQueueInfo);

  const handlePromote = useCallback((uploadItemUuid: UUID) => {
    dispatch(videoUploadActions.promoteVideoUploadAC2.started({ uploadItemUuid }));
  }, [dispatch]);

  const handleRemoveFailed = (uploadItemUuid: UUID) => {
    dispatch(videoUploadActions.removeFailedUploadVideoAC({ uploadItemUuid }));
  };

  const handleRemoveUploaded = (uploadItemUuid: UUID) => {
    dispatch(videoUploadActions.removeTempUploadedVideoAC({ uploadItemUuid }));
  };

  const { single, multiple } = useContext(AbortUploadContext);

  const handleCloseOrAbort = () => {
    if (multiple.videoNames.length > 0) {
      multiple.onOpen();
    } else {
      onClearTemp?.();
    }
  };

  const handlePause = useCallback(() => {
    if (currentUpload?.upload.resumeInfo) {
      dispatch(videoUploadActions.pauseVideoUploadAC({ id: currentUpload.upload.resumeInfo.videoId }));
    }
  }, [currentUpload?.upload.resumeInfo, dispatch]);

  const handleResume = useCallback(() => {
    if (uploadQueue.length > 0) {
      handlePromote(uploadQueue[0].upload.uploadItemUuid);
    }
  }, [handlePromote, uploadQueue]);

  if (itemCount === 0 && !additionalTabs && !renderTabContent) {
    return null;
  }

  return (
    <Stack
    component={Paper}
    className={className}
    sx={[
      {
        width: 400,
        maxHeight: 250,
        overflow: 'hidden',
      },
      detailedView && {
        width: 460,
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <Toolbar
      sx={{
        backgroundColor: theme => theme.palette.background.container,
        display: 'flex',
        pr: 1,
      }}
      >
        <Stack direction='row' flex={1} justifyContent='space-between' alignItems='center' spacing={1}>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Stack flexShrink={0}>
              {startedOrPending ? (
                <Typography variant='h6'>
                  Uploading ({pendingCount})
                </Typography>
              ) : (
                <Typography variant='h6'>
                  No Active Uploads
                </Typography>
              )}
            </Stack>
            {typeof total === 'number' && startedCurrentUpload?.status !== 'paused' && (
              <Stack direction='row' overflow='hidden'>
                <Typography variant='caption'>
                  Upload Speed {speed}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction='row' alignItems='center' flexShrink={0}>
            {detailedView && (
              currentUpload?.upload ? (
                <IconButton size='small' onClick={handlePause}>
                  <PauseIcon />
                </IconButton>
              ) : uploadQueue.length > 0 && (
                <IconButton size='small' onClick={handleResume}>
                  <PlayArrowIcon />
                </IconButton>
              )
            )}
            {process.env.REACT_APP_BUILD_TARGET !== 'web' && startedOrPending && detailedView && (
              <Button onClick={handleCloseOrAbort}>
                Abort
              </Button>
            )}
            {detailedView ? (
              <IconButton size='large' onClick={onCollapse}>
                <HorizontalRuleIcon />
              </IconButton>
            ) : (
              <Button onClick={onExpand}>
                Expand
              </Button>
            )}
            {process.env.REACT_APP_BUILD_TARGET === 'web' && (
              <IconButton size='large' onClick={handleCloseOrAbort}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Toolbar>
      {(detailedView || process.env.REACT_APP_BUILD_TARGET !== 'web') && (
        tab === 'uploading' && (
          itemCount === 0 ? (
            <NoUploads icon={<InfoIcon />} message='There are no videos in the upload queue.' />
          ) : (
            <List disablePadding={true} sx={{ overflowY: 'scroll' }}>
              {currentUpload && shouldRenderUploadItem(currentUpload.upload.uploadSource) && (
                <CurrentUploadQueueItem detailedView={detailedView} currentUpload={currentUpload} onPause={handlePause} onRemoveUploaded={handleRemoveUploaded} />
              )}
              {uploadQueue
              .filter((item) => shouldRenderUploadItem(item.upload.uploadSource))
              .map((item) => (
                <QueuedUploadQueueItem key={item.upload.uploadItemUuid} detailedView={detailedView} item={item} onPromote={handlePromote} />
              ))}
              {children}
              {Object.entries(uploadedVideosTemp)
              .filter(([, uploadedVideo]) => shouldRenderUploadItem(uploadedVideo.uploadSource))
              .map(([uploadItemUuid, uploadedVideo]) => (
                <UploadedUploadQueueItem key={uploadItemUuid} detailedView={detailedView} uploadItemUuid={uploadItemUuid} uploadedVideo={uploadedVideo} onRemoveUploaded={handleRemoveUploaded} />
              ))}
              {Object.entries(failedUploads)
              .filter(([, failedUpload]) => shouldRenderUploadItem(failedUpload.options.uploadSource))
              .map(([uploadItemUuid, failedUpload]) => (
                <FailedUploadQueueItem key={uploadItemUuid} detailedView={detailedView} uploadItemUuid={uploadItemUuid} failedUpload={failedUpload} onRemoveFailed={handleRemoveFailed} />
              ))}
            </List>
          )
        )
      )}
      {tab !== 'uploading' && renderTabContent?.(tab)}
      {additionalTabs && (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Tabs
          value={tab}
          textColor='inherit'
          indicatorColor='primary'
          onChange={(_: unknown, newTab) => setTab(newTab)}
          sx={{
            minHeight: 25,
          }}
          >
            <IconTab size='small' value='uploading' label='Uploading' />
            {additionalTabs.map(tab => (
              <IconTab key={tab} size='small' value={tab} label={tab} />
            ))}
          </Tabs>
        </Stack>
      )}
      {/* TODO: fix the i18n there */}
      <AbortUploadDialog {...single} />
      <AbortMultipleUploadsDialog {...multiple} />
    </Stack>
  );
}

function NoUploads(props: {
  icon: ReactElement;
  message: string;
}) {
  return (
    <Stack alignItems='center' spacing={1} padding={3}>
      {cloneElement(props.icon, { sx: { fontSize: createRemFromPx(48) } })}
      <Typography variant='caption'>
        {props.message}
      </Typography>
    </Stack>
  );
}
