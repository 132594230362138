import { Theme } from '@insights-gaming/theme';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo, ReactNode, useEffect, useState } from 'react';

export interface FlashFadeIconProps {
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    pointerEvents: 'none',
    '&$fading': {
      opacity: 0,
      transform: 'scale(2)',
      transition: 'transform .7s, opacity .7s',
    },
  },
  overlayIcon: {
    fontSize: '3rem',
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    padding: theme.spacing(1),
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    lineHeight: 0,
  },
  fading: {},
}), { name: 'FlashFadeIcon' });

function FlashFadeIcon({
  className,
  children,
}: FlashFadeIconProps) {
  const classes = useStyles();

  const [fade, setFade] = useState(true);

  useEffect(() => {
    setFade(false);
    const immediate = setImmediate(setFade, true);
    return () => {
      clearImmediate(immediate);
    };
  }, [children]);

  return (
    <div className={classNames(classes.root, { [classes.fading]: fade }, className)}>
      <span className={classNames(classes.overlayIcon, { [classes.fading]: fade })}>
        {children}
      </span>
    </div>
  );
}

export default memo(FlashFadeIcon);
