import { memo, Suspense, SuspenseProps } from 'react';
import { ErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

import { ResetBoundaryOnLogin } from './ResetBoundaryOnLogin';

function SuspenseBoundary(props: SuspenseProps & Pick<ErrorBoundaryProps, 'resetKeys'>) {
  const { resetKeys, ...rest } = props;
  return (
    <ErrorBoundary FallbackComponent={ResetBoundaryOnLogin} resetKeys={resetKeys}>
      <Suspense {...rest} />
    </ErrorBoundary>
  );
}

export default memo(SuspenseBoundary);
