export interface DeferredPromise<T> {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (err: any) => void;
}

export function createDeferredPromise<T>(): DeferredPromise<T> {
  let resolve: ((value: T | PromiseLike<T>) => void) | undefined = undefined;
  let reject: ((err: any) => void) | undefined = undefined;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    promise,
    resolve: resolve as any,
    reject: reject as any,
  };
}
