import { Button, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import { Fragment, Suspense, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SuspenseLoader } from '@/components/SuspenseLoader';
import { useDialogState } from '@/hooks/useDialogState';

import { ClaimInvitationWithErrorBounds } from './ClaimInvitation';

const codePath = '/code/:code';

const path = {
  code: codePath,
};

const createCodeUrl = (code: string) => `/code/${code}`;

function InvitationRoutingComponent() {
  const { code } = useParams<InvitationRouting.CodeParams>();

  const { t } = useTranslation(['squad_common']);

  const [displayClaimInvitationOnWeb, setDisplayClaimInvitationOnWeb] = useState<boolean>(false);
  const [isContinueOnWebDialogOpen, openContinueOnWebDialog, closeContinueOnWebDialog] = useDialogState(true);

  useEffect(() => {
    window.open('insights-capture://code/' + code);
    openContinueOnWebDialog();
  }, [code, openContinueOnWebDialog]);

  const handleClaimInvitation = useCallback(() => {
    setDisplayClaimInvitationOnWeb(true);
    closeContinueOnWebDialog();
  }, [closeContinueOnWebDialog]);

  return (
    <Fragment>
      {displayClaimInvitationOnWeb && (
        <Suspense fallback={<SuspenseLoader />}>
          <ClaimInvitationWithErrorBounds code={code} />
        </Suspense>
      )}
      <Dialog open={isContinueOnWebDialogOpen} maxWidth='xs'>
        <DialogContent>
          <Stack spacing={2} padding={3}>
            <Typography variant='h5' textAlign='center'>
              {t('squad_common:invitation.browser.appOpened')}
            </Typography>
            <Typography textAlign='center'>
              {t('squad_common:invitation.browser.description')}
            </Typography>
            <Button variant='contained' color='primary' onClick={handleClaimInvitation}>
              {t('squad_common:invitation.browser.continue')}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

class InvitationRouting {
  public static readonly path = path;
  public static readonly paths = Object.values(this.path);
  public static readonly createUrl = {
    code: createCodeUrl,
  };
  public static readonly Component = InvitationRoutingComponent;
}

declare namespace InvitationRouting {
  interface CodeParams {
    code: string;
  }
}

export default InvitationRouting;
