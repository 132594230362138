import { uuidv4 } from '@/app/uuid';
import theme from '@/theme';

export function SVGGradientDefs() {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' style={{ position: 'absolute' }}>
      <defs>
        {createLayeredFilter(theme.palette.gradient.gradient1.main.colors, 'gradient1')}
      </defs>
    </svg>
  );
}

function createLayeredFilter(colors: string[][], filterId: string) {
  const rectIds: string[] = [];
  const els = colors.flatMap((colors, i) => {
    if (colors.length === 1) {
      const rectId = uuidv4();
      rectIds.push(rectId);
      return (
        <rect key={rectId} id={rectId} x={0} y={0} width={window.devicePixelRatio * 24} height={window.devicePixelRatio * 24} fill={colors[0]}></rect>
      );
    }

    const gradientId = uuidv4();
    const rectId = uuidv4();
    rectIds.push(rectId);
    return [
      <linearGradient key={gradientId} id={gradientId} x1='0' x2='0' y1='0' y2='1'>
        {colors.map((color, i) => (
          <stop key={color} offset={`${i * 100 / (colors.length - 1)}%`} stopColor={color}></stop>
        ))}
      </linearGradient>,
      <rect key={rectId} id={rectId} x='0' y='0' width={window.devicePixelRatio * 24} height={window.devicePixelRatio * 24} fill={`url(#${gradientId})`} />,
    ];
  });
  return els.concat((
    <filter key={filterId} id={filterId} width={24} height={24} style={{ position: 'absolute' }}>
      {rectIds.map((id) => (
        <feImage key={id} xlinkHref={`#${id}`} result={id} />
      ))}
      <feBlend in={rectIds[0]} in2={rectIds[1]} mode='multiply' result='blended' />
      <feComposite in='blendedGrad' in2='SourceGraphic' operator='in' />
    </filter>
  ));
}
