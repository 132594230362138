import { EnhancedDialogTitle, InteractivePopover } from '@insights-gaming/material-components';
import { Theme } from '@insights-gaming/theme';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { Box, Button, Dialog, DialogContent, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Popover, Stack, SxProps, Typography } from '@mui/material';
import { getEmojiDataFromNative } from 'emoji-mart';
import { groupBy } from 'lodash';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { ContextType, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import { StackOverflow } from '@/components/StackOverflow';
import SuspenseBoundary from '@/components/SuspenseBoundary';
import { Reaction } from '@/features/squad/Reaction';
import { isCustomEmoji, TeamEmojiPicker } from '@/features/squad/TeamDirectory/TeamEmojiPicker';
import { useTeamEmojis } from '@/features/squad/TeamDirectory/useTeamEmojis';
import { TeamEmojiContext } from '@/features/squad/TeamEmojiContext';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { AddVideoReactionInput } from '@/graphql/__generated__/graphql';
import { withBackgroundQuery, WithBackgroundQueryComponentProps } from '@/graphql/withBackgroundQueryContext';
import { useDialogState } from '@/hooks/useDialogState';
import { useMixpanel } from '@/hooks/useMixpanel';
import { mixpanelSquadHelpers } from '@/mixpanel/helpers/squad-helpers';

import { MAX_USERS_TO_SHOW } from './TeamVideoBottomToolbar';
import { useAddVideoReactionMutation } from './useAddVideoReactionMutation';
import { useRemoveVideoReactionMutation } from './useRemoveVideoReactionMutation';

const TeamVideoReactions_VideoFragment = gql(`
  fragment TeamVideoReactions_VideoFragment on Video {
    id
    queryReactions {
      reactions {
        id
        ... on CustomReaction {
          emoji {
            id
            url
          }
        }
        ... on UnicodeReaction {
          character
        }
        user {
          id
          alias
          picture
        }
        ...ReactionGroup_ReactionFragment
      }
      pageInfo {
        ...QueryPageInfoFragment
      }
    }
  }
`);

interface TeamVideoReactionsProps {
  className?: string;
  sx?: SxProps<Theme>;

  teamId: ID;
  videoId: ID;
  video: FragmentType<typeof TeamVideoReactions_VideoFragment>;

  AddReactionComponent?: React.ComponentType<AddReactionProps>;
  ReactionGroupComponent?: React.ComponentType<ReactionGroupProps>;

  displayShowAllButton?: boolean;
}

function _TeamVideoReactions(props: TeamVideoReactionsProps & WithBackgroundQueryComponentProps<ContextType<typeof TeamEmojiContext>>) {
  const {
    AddReactionComponent = AddReaction,
    className,
    displayShowAllButton,
    ReactionGroupComponent = ReactionGroup,
    queryRef,
    sx,
    teamId,
    videoId,
  } = props;

  const { t } = useTranslation(['squad_videos']);

  const video = getFragmentData(TeamVideoReactions_VideoFragment, props.video);

  const [isTeamVideoReactionsDialogOpen, openTeamVideoReactionsDialog, closeTeamVideoReactionsDialog] = useDialogState();

  const [selectedReactionEmojiCode, setSelectedReactionEmojiCode] = useState<string | undefined>(undefined);

  const handleOthersClick = useCallback((emojiCode: string) => {
    setSelectedReactionEmojiCode(emojiCode);
    openTeamVideoReactionsDialog();
  }, [openTeamVideoReactionsDialog]);

  const mixpanel = useMixpanel();

  useTeamEmojis(teamId, queryRef);

  const _reactions = video.queryReactions?.reactions;
  const reactions = useMemo(() => {
    const reactions = _reactions ?? [];
    const grouped = groupBy(reactions, reaction => {
      switch (reaction.__typename) {
        case 'CustomReaction': return reaction.emoji.id;
        case 'UnicodeReaction': return reaction.character;
      }
    });
    return grouped;
  }, [_reactions]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'reaction-picker' });
  const [addVideoReaction] = useAddVideoReactionMutation();
  const [removeVideoReaction] = useRemoveVideoReactionMutation();

  const handleVideoReaction = (input: AddVideoReactionInput, native?: string) => {
    mixpanel.track(...mixpanelSquadHelpers.videoReactionAdded({
      nativeEmoji: native,
      customEmoji: !!input.emojiId,
    }));
    addVideoReaction({
      variables: {
        input,
      },
      update: (cache, { data }) => {
        const addedReaction = data?.addVideoReaction?.reaction;
        if (!addedReaction) {
          return;
        }
        cache.updateFragment(
          {
            id: cache.identify(video),
            fragment: TeamVideoReactions_VideoFragment,
            fragmentName: 'TeamVideoReactions_VideoFragment',
          },
          (video) => {
            if (!video || !video.queryReactions) {
              return video;
            }
            const addedReactionRef = cache.writeFragment({
              fragment: gql(`
                fragment NewReaction on Reaction {
                  id
                  ... on CustomReaction {
                    emoji {
                      id
                      url
                    }
                  }
                  ... on UnicodeReaction {
                    character
                  }
                  user {
                    id
                    alias
                  }
                }
              `),
              data: addedReaction,
            });
            return {
              ...video,
              queryReactions: {
                ...video.queryReactions,
                reactions: {
                  ...video.queryReactions.reactions,
                  reactions: [
                    ...video.queryReactions.reactions,
                    addedReactionRef,
                  ],
                },
              },
            };
          },
        );
      },
      onCompleted: () => {
        const reactions = video.queryReactions?.reactions.length ? video.queryReactions?.reactions.length + 1 : 1;
        mixpanel.track(...mixpanelSquadHelpers.reactionCount({ reactionCount: reactions, videoId }));
      },
    });
  };

  return (
    <Stack
    direction='row'
    spacing={0.5}
    className={className}
    sx={sx}
    >
      <StackOverflow
      direction='row'
      fallbackPlacement='opposite'
      spacing={0.5}
      justifyContent='flex-end'
      fallback={displayShowAllButton && (
        <Button size='small' variant='outlined' onClick={openTeamVideoReactionsDialog}>
          {t('squad_videos:reactions.showAll')}
        </Button>
      )}
      >
        {Object.entries(reactions).map(([key, reactions], i) => {
          const reaction = reactions[0];
          const selfReaction = reactions.find(reaction => reaction.user.__typename === 'Self');
          return (
            <ReactionGroupComponent
            key={reaction.id}
            emojiCode={key}
            reactions={reactions}
            onOthersClick={handleOthersClick}
            onClick={() => {
              if (selfReaction) {
                mixpanel.track(...mixpanelSquadHelpers.videoReactionRemoved({
                  nativeEmoji: selfReaction.__typename === 'UnicodeReaction' ? selfReaction.character : undefined,
                  customEmoji: selfReaction.__typename === 'CustomReaction',
                }));
                removeVideoReaction({
                  variables: {
                    input: {
                      reactionId: selfReaction.id,
                      videoId,
                    },
                  },
                  optimisticResponse: ({ input }) => ({
                    removeVideoReaction: {
                      video: {
                        id: input.videoId,
                      },
                    },
                  }),
                  update: (cache, { data }, { variables }) => {
                    cache.updateFragment(
                      {
                        id: cache.identify(video),
                        fragment: TeamVideoReactions_VideoFragment,
                        fragmentName: 'TeamVideoReactions_VideoFragment',
                      },
                      (video) => {
                        if (!video?.queryReactions) {
                          return video;
                        }
                        return {
                          ...video,
                          queryReactions: {
                            ...video.queryReactions,
                            reactions: video.queryReactions.reactions.filter(reaction => reaction.id !== variables?.input.reactionId),
                          },
                        };
                      },
                    );
                  },
                  onCompleted: () => {
                    const reactions = video.queryReactions?.reactions.length ? video.queryReactions?.reactions.length - 1 : 0;
                    mixpanel.track(...mixpanelSquadHelpers.reactionCount({ reactionCount: reactions, videoId }));
                  },
                });
              } else {
                handleVideoReaction({
                  videoId,
                  emojiId: reaction.__typename === 'CustomReaction' ? reaction.emoji.id : undefined,
                  character: reaction.__typename === 'UnicodeReaction' ? reaction.character : undefined,
                });
              }
            }}
            sx={{
              'em-emoji': {
                width: 24,
                height: 24,
              },
            }}
            />
          );
        })}
      </StackOverflow>
      <AddReactionComponent {...bindTrigger(popupState)} />
      <Dialog sx={sx} open={isTeamVideoReactionsDialogOpen} onClose={closeTeamVideoReactionsDialog} maxWidth='sm' fullWidth={true}>
        <EnhancedDialogTitle onClose={closeTeamVideoReactionsDialog}>
          {t('squad_videos:reactions.title')}
        </EnhancedDialogTitle>
        <DialogContent>
          <Stack direction='row' spacing={1}>
            <Stack spacing={1}>
              {Object.entries(reactions).map(([key, reactions]) => {
                const reaction = getFragmentData(ReactionGroup_ReactionFragment, reactions[0]);
                return (
                  <ReactionDialogReactionButton
                  key={reaction.id}
                  selected={selectedReactionEmojiCode === key}
                  reactions={reactions}
                  emojiCode={key}
                  onClick={setSelectedReactionEmojiCode}
                  />
                );
              })}
            </Stack>
            {selectedReactionEmojiCode && (
              <List
              sx={{
                flex: 1,
                maxHeight: 350,
                overflow: 'auto',
                padding: 0,
                background: theme => theme.palette.background.container,
                border: theme => `1px solid ${theme.palette.divider}`,
              }}
              >
                {reactions[selectedReactionEmojiCode].map(reaction => (
                  <ListItem key={reaction.user.id} divider={true}>
                    <ListItemAvatar>
                      <AvatarWithFallback src={reaction.user.picture} alt={reaction.user.alias} size={32} />
                    </ListItemAvatar>
                    <ListItemText primary={reaction.user.alias} />
                  </ListItem>
                ))}
              </List>
            )}
          </Stack>
        </DialogContent>
      </Dialog>

      <Popover {...bindPopover(popupState)}>
        <SuspenseBoundary>
          <TeamEmojiPicker
          teamId={teamId}
          onEmojiSelect={(emoji) => {
            const input: AddVideoReactionInput = { videoId };
            if (isCustomEmoji(emoji)) {
              input.emojiId = emoji.id;
            } else {
              input.character = emoji.native;
            }
            handleVideoReaction(input, emoji.native);
            popupState.close();
          }}
          />
        </SuspenseBoundary>
      </Popover>
    </Stack>
  );
}

export const ReactionGroup_ReactionFragment = gql(`
  fragment ReactionGroup_ReactionFragment on Reaction {
    id
    user {
      id
      alias
    }
    ... on CustomReaction {
      emoji {
        name
      }
    }
    ... on UnicodeReaction {
      character
    }
    ...Reaction_ReactionFragment
  }
`);

export interface ReactionGroupProps {
  className?: string;
  sx?: SxProps<Theme>;
  reactions: FragmentType<typeof ReactionGroup_ReactionFragment>[];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onOthersClick?: (emojiCode: string) => void;
  emojiCode: string;
}

function ReactionGroup(props: ReactionGroupProps) {
  const { className, onClick, sx, reactions, onOthersClick, emojiCode } = props;
  const reaction = getFragmentData(ReactionGroup_ReactionFragment, reactions[0]);
  const reactionsData = getFragmentData(ReactionGroup_ReactionFragment, props.reactions);

  const { t } = useTranslation(['squad_videos']);
  const [emojiName, setEmojiName] = useState<string>('');

  const firstFiveReactions = reactionsData.slice(0, MAX_USERS_TO_SHOW);
  const remainingReactions = reactionsData.slice(MAX_USERS_TO_SHOW);

  const handleOthersClick = useCallback(() => {
    onOthersClick?.(emojiCode);
  }, [onOthersClick, emojiCode]);

  useEffect(() => {
    const getEmojiName = async () => {
      switch (reaction.__typename) {
        case 'CustomReaction':
          return setEmojiName(reaction.emoji.name);
        case 'UnicodeReaction':
          const emojiNativeData = await getEmojiDataFromNative(reaction.character);
          return setEmojiName(emojiNativeData ? emojiNativeData.shortcodes : '');
        default:
          return setEmojiName('');
      }
    };
    getEmojiName();
  }, [reaction]);


  const inner = <Reaction reaction={reaction} />;

  const reactionButton = inner ? (
    <IconButton size='small' onClick={onClick} className={className} sx={sx}>
      {inner}
    </IconButton>
  ) : inner;

  return (
    <InteractivePopover
    disableEnforceFocus={true}
    exitDelay={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    trigger={(
      reactionButton
    )}
    >
      <Paper
      sx={{
        p: 1,
        maxWidth: 320,
      }}
      >
        <Stack direction='row' alignItems='center'>
          <Box sx={{ fontSize: '2em' }}>
            <Reaction reaction={reaction} />
          </Box>
          <Typography>
            {t('squad_videos:reactions.reactedBy', { emojiName, usernames: firstFiveReactions.map(reaction => reaction.user.alias).join(', ') })}
            {remainingReactions.length > 0 && (
              <Typography component='span'>
                <Trans
                ns={['squad_videos']}
                i18nKey='squad_videos:reactions.remainingReactions'
                values={{
                  reaction: reaction,
                  count: remainingReactions.length,
                }}
                components={{
                  a: (
                    <Typography
                    component='span'
                    onClick={handleOthersClick}
                    sx={{
                      color: theme => theme.palette.primary.main,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                    />
                  ),
                }}
                />
              </Typography>
            )}
          </Typography>
        </Stack>
      </Paper>
    </InteractivePopover>
  );
}

export interface AddReactionProps {
  className?: string;
  sx?: SxProps<Theme>;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function AddReaction(props: AddReactionProps) {
  return (
    <IconButton size='small' {...props}>
      <InsertEmoticonIcon />
    </IconButton>
  );
}

interface ReactionDialogReactionButtonProps {
  sx?: SxProps<Theme>;
  emojiCode: string;
  reactions: FragmentType<typeof ReactionGroup_ReactionFragment>[];
  onClick: (key: string) => void;
  selected?: boolean;
}

function ReactionDialogReactionButton(props: ReactionDialogReactionButtonProps) {
  const { emojiCode, onClick, reactions, selected, sx } = props;
  const reaction = getFragmentData(ReactionGroup_ReactionFragment, reactions[0]);

  const handleReactionClick = useCallback(() => {
    onClick(emojiCode);
  }, [emojiCode, onClick]);

  return (
    <InteractivePopover
    disableEnforceFocus={true}
    exitDelay={0}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    trigger={(
      <Button
      sx={[
        selected && {
          backgroundColor: theme => theme.palette.action.selected,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      onClick={handleReactionClick}
      >
        <Typography>
          {reactions.length}
        </Typography>
        <Reaction reaction={reaction} />
      </Button>
    )}
    >
      <Paper
      sx={{
        p: 1,
        fontSize: '2em',
      }}
      >
        <Reaction reaction={reaction} />
      </Paper>
    </InteractivePopover>
  );
}

export const TeamVideoReactions = withBackgroundQuery(TeamEmojiContext, _TeamVideoReactions);
