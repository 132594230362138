import { GameClassId, GameClassIdLike, GameFeature } from '@insights-gaming/game-events';
import { FetchStatus, fetchUpdate } from '@insights-gaming/redux-utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import update from 'immutability-helper';
import actionCreatorFactory from 'typescript-fsa';

import { CUSTOM_TAG_ACTIVATE_PREFIX, CUSTOM_TAG_PREFIX } from '@/app/constants';
import { createDefaultFetchStatus } from '@/utils';
import { GameListParserPlugin, searchGame } from '@/utils/gameListParser';
import customUpdate from '@/utils/update';
import { makeGameClassIdLike } from '@/utils/video';

import { castOWResult } from './castowresult';

const name = 'overwolf-data';
const actionCreator = actionCreatorFactory(name);
export const getStreamEncodersAC = actionCreator.async<void, overwolf.streaming.GetStreamEncodersResult, Error>('GET_STREAM_ENCODERS');
export const getAudioDevicesAC = actionCreator.async<void, overwolf.streaming.GetAudioDevicesResult, Error>('GET_AUDIO_DEVICES');
export const getGameDBInfoAC = actionCreator.async<(number | string)[], GetGameDbInfoResult, Error>('GET_GAME_DB_INFO');
export const getGameOverlayEnabledAC = actionCreator.async<(number | string)[], GetGameOverlayEnabledResult, Error>('GET_GAME_OVERLAY_ENABLED');
export const getGameAutoLaunchEnabledAC = actionCreator.async<(number | string)[], GetGameAutoLaunchEnabledResult, Error>('GET_AUTO_LAUNCH_ENABLED');
export const getHotkeysAC = actionCreator.async<void, overwolf.settings.hotkeys.GetAssignedHotkeyResult, Error>('GET_HOTKEYS');
export const healthCheckAC = actionCreator.async<void, OverwolfGameEventHealth[], Error>('HEALTH_CHECK');
export const gameSpecificHealthCheckAC = actionCreator.async<GameClassId, OverwolfGameSpecificEventHealth, Error>('GAME_SPECIFIC_HEALTH_CHECK');
export const getCurrentUserAC = actionCreator.async<void, overwolf.profile.GetCurrentUserResult>('GET_CURRENT_USER');
export const getAudioCaptureSettingsAC = actionCreator.async<void, overwolf.settings.GetAudioCaptureSettingsResult>('GET_AUDIO_CAPTURE_SETTINGS');
export const getVideoCaptureSettingsAC = actionCreator.async<void, overwolf.settings.GetVideoCaptureSettingsResult>('GET_VIDEO_CAPTURE_SETTINGS');
export const getConflictingAppsAC = actionCreator.async<void, overwolf.extensions.Extension[]>('GET_CONFLICTING_APPS');
export const getVideosFolderAC = actionCreator.async<void, overwolf.settings.FolderResult>('GET_VIDEO_FOLDER');
export const setVideosFolderAC = actionCreator.async<{ path: string }, overwolf.settings.SetFolderResult, Error>('SET_VIDEO_FOLDER');
export const getRecentlyPlayedGamesAC = actionCreator.async<void, overwolf.games.GetRecentlyPlayedResult, Error>('GET_RECENTLY_PLAYED_GAMES');
export const getExtensionSettingsAC = actionCreator.async<void, overwolf.settings.GetExtensionSettingsResult, Error>('GET_EXTENSION_SETTINGS');
export const getStreamingCapabilitiesAC = actionCreator.async<void, overwolf.streaming.StreamingCapabilitiesResult, Error>('GET_STREAMING_CAPABILITIES');
export const getSystemInformationAC = actionCreator.async<void, overwolf.utils.GetSystemInformationResult, Error>('GET_SYSTEM_INFORMATION');
export const getMonitorsListAC = actionCreator.async<void, overwolf.utils.getMonitorsListResult, Error>('GET_MONITORS_LIST');
export const loadGamesListAC = actionCreator.async<void, void, Error>('LOAD_GAMES_LIST');
export const parseNextGamesOnGamesListAC = actionCreator.async < number, AsyncReturnType<GameListParserPlugin['GetNextGames']>, Error>('PARSE_NEXT_GAMES_ON_GAMES_LIST');
export const searchGamesListAC = actionCreator.async<string, AsyncReturnType<typeof searchGame>, Error>('SEARCH_GAMES_LIST');

type CustomTagIHotkey =  Omit<overwolf.settings.hotkeys.IHotkey, 'name'> & { name: CustomTagHotkeyName };
type CustomTagActivateIHotkey = Omit<overwolf.settings.hotkeys.IHotkey, 'name'> & { name: CustomTagActivateHotkeyName };

const customTagRegex = new RegExp(`^${CUSTOM_TAG_PREFIX}\\d+`);

export const isCustomTagOWHotkey = (hotkey: overwolf.settings.hotkeys.IHotkey): hotkey is CustomTagIHotkey => {
  return customTagRegex.test(hotkey.name);
};

export const isCustomTagName = (name: string): name is CustomTagHotkeyName => {
  return customTagRegex.test(name);
};

const customTagActivateRegex = new RegExp(`^${CUSTOM_TAG_ACTIVATE_PREFIX}\\d+`);

export const isCustomTagActivateOWHotkey = (hotkey: overwolf.settings.hotkeys.IHotkey): hotkey is CustomTagActivateIHotkey => {
  return customTagActivateRegex.test(hotkey.name);
};

export const isCustomTagActivateName = (name: string): name is CustomTagActivateHotkeyName => {
  return customTagActivateRegex.test(name);
};

export const isAnyCustomTagOWHotkey = (hotkey: overwolf.settings.hotkeys.IHotkey): hotkey is CustomTagActivateIHotkey | CustomTagIHotkey => {
  return isCustomTagActivateOWHotkey(hotkey) || isCustomTagOWHotkey(hotkey);
};

interface GetGameDbInfoResult {
  fulfilled: Dictionary<overwolf.games.GetGameDBInfoResult>;
  rejected: Dictionary<Error>;
};

interface GetGameOverlayEnabledResult {
  fulfilled: Dictionary<overwolf.settings.games.OverlayEnabledResult>;
  rejected: Dictionary<Error>;
}

interface GetGameAutoLaunchEnabledResult {
  fulfilled: Dictionary<overwolf.settings.games.AutolaunchEnabledResult>;
  rejected: Dictionary<Error>;
}

export enum EventHealthLevel {
  UNSUPPORTED = 0,
  HEALTHY = 1,
  DEGRADED = 2,
  UNHEALTHY = 3,
}

export interface OverwolfGameEventHealth {
  game_id: number;
  state: EventHealthLevel;
}

interface OverwolfHealth {
  games: OverwolfGameEventHealth[];
  status?: FetchStatus;
}

interface OverwolfGameSpecificEventFeatureHealth {
  name: GameFeature;
  state: EventHealthLevel;
  keys: {
    name: string;
    state: EventHealthLevel;
  }[];
}

export interface OverwolfGameSpecificEventHealth {
  game_id: number;
  state: EventHealthLevel;
  features: OverwolfGameSpecificEventFeatureHealth[];
}

export interface CurrentGameState {
  gameRunning: boolean;
  classId?: number;
  title: string;
  leagueTftQueueId?: number;
  classIdLike?: number | GameClassIdLike;
  processName?: string;
}

export type CurrentGameStatePayload = Omit<CurrentGameState, 'classIdLike'>;

interface OverwolfDataState {
  extensions: {
    conflictingApps: overwolf.extensions.Extension[];
  };
  games: {
    dbStatus: Partial<Dictionary<FetchStatus>>;
    gameDBInfoResults: Partial<Dictionary<overwolf.games.GetGameDBInfoResult>>;
    recentlyPlayed: Partial<overwolf.games.GetRecentlyPlayedResult>;
    gameOverlayEnabledStatus?: FetchStatus;
    gameOverlayEnabledResults: Partial<Dictionary<overwolf.settings.games.OverlayEnabledResult>>;
    gameAutoLaunchEnabledStatus?: FetchStatus;
    gameAutoLaunchEnabledResults: Partial<Dictionary<overwolf.settings.games.AutolaunchEnabledResult>>;
    currentGameState: CurrentGameState;
    gameEventHealthDict: Partial<Dictionary<OverwolfGameSpecificEventHealth>>;
    gameEventHealthFetchStatus?: Partial<Dictionary<FetchStatus>>;
  };
  health: OverwolfHealth;
  media: {
    videos?: any[];
    webcams?: MediaDeviceInfo[];
  };
  profile: {
    currentUser?: OWResult<overwolf.profile.GetCurrentUserResult>;
  };
  settings: {
    audioCaptureSettings?: OWResult<overwolf.settings.GetAudioCaptureSettingsResult>;
    videoCaptureSettings?: OWResult<overwolf.settings.GetVideoCaptureSettingsResult>;
    extensionSettings?: OWResult<overwolf.settings.GetExtensionSettingsResult>;
    videosFolder?: OWResult<overwolf.settings.FolderResult>;
    badVideoFolder: boolean;
    hotkeys: {
      globals: overwolf.settings.hotkeys.IHotkey[];
      games: {[gameClassId in GameClassId]?: overwolf.settings.hotkeys.IHotkey[]};
    };
  };
  streaming: {
    audioDevices?: OWResult<overwolf.streaming.GetAudioDevicesResult>;
    streamEncoders?: OWResult<overwolf.streaming.GetStreamEncodersResult>;
    capabilities?: overwolf.streaming.StreamingCapabilitiesResult;
  };
  utils: {
    systemInformation?: OWResult<overwolf.utils.GetSystemInformationResult>;
    monitorsList?: OWResult<overwolf.utils.getMonitorsListResult>;
  };
}

const initialState: OverwolfDataState = {
  extensions: {
    conflictingApps: [],
  },
  health: {
    games: [],
  },
  games: {
    dbStatus: {},
    gameDBInfoResults: {},
    recentlyPlayed: {},
    gameOverlayEnabledResults: {},
    gameAutoLaunchEnabledResults: {},
    gameEventHealthDict: {},
    gameEventHealthFetchStatus: {},
    currentGameState: {
      gameRunning: false,
      title: '',
    },
  },
  media: {
    videos: [],
  },
  profile: {},
  settings: {
    badVideoFolder: false,
    hotkeys: {
      globals: [],
      games: {},
    },
  },
  streaming: {},
  utils: {},
};

export interface OverwolfDataInitPayload {
  games: Partial<OverwolfDataState['games']>;
  media: Partial<OverwolfDataState>['media'];
  health: Partial<OverwolfDataState['health']>;
  profile: Partial<OverwolfDataState['profile']>;
  settings: Partial<OverwolfDataState['settings']>;
  streaming: Partial<OverwolfDataState['streaming']>;
  utils: Partial<OverwolfDataState['utils']>;
};

const overwolfDataSlice = createSlice({
  name,
  initialState,
  reducers: {
    overwolfDataInit(state, action: PayloadAction<OverwolfDataInitPayload>) {
      return customUpdate(state, { $deepmerge: action.payload });
    },
    hotkeyChanged(state, { payload }: PayloadAction<overwolf.settings.hotkeys.OnChangedEvent>) {
      // WIP: probably doesn't work properly
      // console.log(payload, null, 2);
      // if (payload.gameId === 0) { // global
      //   const i = state.hotkeys.globals.findIndex(hotkey => hotkey.name === payload.name);
      //   if (i < 0) {
      //     return;
      //   }

      //   state.hotkeys.globals[i].binding = payload.binding;
      // } else {
      //   const gameId: GameIdType = payload.gameId as any;
      //   const hotkeys = state.hotkeys.games[gameId];
      //   console.log(gameId, JSON.stringify(hotkeys, null, 2));
      //   if (!hotkeys) {
      //     state.hotkeys.games[gameId] = [payload as any];
      //     return;
      //   } else {
      //     const i = hotkeys.findIndex(hotkey => hotkey.name === payload.name);
      //     if (i < 0) {
      //       return;
      //     }
      //     state.hotkeys.globals[i].binding = payload.binding;
      //   }
      // }
    },
    conflictingAppClosed(state, action: PayloadAction<UUID>) {
      state.extensions.conflictingApps = state.extensions.conflictingApps.filter(app => app.UID !== action.payload);
    },
    setBadVideoFolder(state, action: PayloadAction<boolean>) {
      state.settings.badVideoFolder = action.payload;
    },
    currentGameStateChanged(state, action: PayloadAction<CurrentGameStatePayload>) {
      const { classId, leagueTftQueueId } = action.payload;
      state.games.currentGameState = update(state.games.currentGameState, (p) => ({
        ...action.payload,
        classIdLike: makeGameClassIdLike(classId, leagueTftQueueId),
      }));
    },
    gamesAutoLaunchEnabledLoaded(state, action: PayloadAction<overwolf.settings.games.AutolaunchEnabledResult[]>) {
      for (const result of action.payload) {
        state.games.gameAutoLaunchEnabledResults = update(state.games.gameAutoLaunchEnabledResults, {
          [result.gameClassId]: { $set: result },
        });
      }
    },
    setWebcams(state, action: PayloadAction<MediaDeviceInfo[]>) {
      state.media.webcams = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getStreamEncodersAC.done, (state, action) => {
      state.streaming.streamEncoders = castOWResult(action.payload.result);
    });

    builder.addCase(getAudioDevicesAC.done, (state, action) => {
      state.streaming.audioDevices = castOWResult(action.payload.result);
    });

    builder.addCase(getVideoCaptureSettingsAC.done, (state, action) => {
      state.settings.videoCaptureSettings = castOWResult(action.payload.result);
    });

    builder.addCase(getAudioCaptureSettingsAC.done, (state, action) => {
      state.settings.audioCaptureSettings = castOWResult(action.payload.result);
    });

    { // getGameDBInfoAC
      const async = getGameDBInfoAC;
      builder.addCase(async.started, (state, action) => {
        for (const gameClassId of action.payload) {
          state.games.dbStatus[gameClassId] = fetchUpdate(state.games.dbStatus[gameClassId] || createDefaultFetchStatus(), { $fetchStarted: true });
        }
      });
      builder.addCase(async.done, (state, action) => {
        const { payload: { result: { fulfilled, rejected } } } = action;
        state.games.gameDBInfoResults = update(state.games.gameDBInfoResults, {
          $merge: fulfilled,
        });
        for (const gameClassId of Object.keys(fulfilled)) {
          state.games.dbStatus = update(state.games.dbStatus, {
            [gameClassId]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchDone: true }),
          });
        }
        for (const [gameClassId, error] of Object.entries(rejected)) {
          state.games.dbStatus = update(state.games.dbStatus, {
            [gameClassId]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchFailed: [error as Error] }),
          });
        }
      });
      builder.addCase(async.failed, (state, action) => {
        const { payload: { params, error } } = action;
        for (const gameClassId of params) {
          state.games.dbStatus = update(state.games.dbStatus, {
            [gameClassId as string]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchFailed: [error as Error] }),
          });
        }
      });
    }

    { // getGameOverlayEnabledAC
      const async = getGameOverlayEnabledAC;
      builder.addCase(async.started, (state, action) => {
        state.games.gameOverlayEnabledStatus = fetchUpdate(state.games.gameOverlayEnabledStatus || createDefaultFetchStatus(), { $fetchStarted: true });
      });
      builder.addCase(async.done, (state, action) => {
        const { payload: { result: { fulfilled } } } = action;
        state.games.gameOverlayEnabledResults = update(state.games.gameOverlayEnabledResults, {
          $merge: fulfilled,
        });
        state.games.gameOverlayEnabledStatus = fetchUpdate(state.games.gameOverlayEnabledStatus || createDefaultFetchStatus(), { $fetchDone: true });
      });
      builder.addCase(async.failed, (state, action) => {
        state.games.gameOverlayEnabledStatus = fetchUpdate(state.games.gameOverlayEnabledStatus || createDefaultFetchStatus(), { $fetchFailed: [action.payload.error] });
      });
    }

    { // getAutoLaunchEnabledAC
      const async = getGameAutoLaunchEnabledAC;
      builder.addCase(async.started, (state, action) => {
        state.games.gameAutoLaunchEnabledStatus = fetchUpdate(state.games.gameAutoLaunchEnabledStatus || createDefaultFetchStatus(), { $fetchStarted: true });
      });
      builder.addCase(async.done, (state, action) => {
        const { payload: { result: { fulfilled } } } = action;
        state.games.gameAutoLaunchEnabledResults = update(state.games.gameAutoLaunchEnabledResults, {
          $merge: fulfilled,
        });
        state.games.gameAutoLaunchEnabledStatus = fetchUpdate(state.games.gameAutoLaunchEnabledStatus || createDefaultFetchStatus(), { $fetchDone: true });
      });
      builder.addCase(async.failed, (state, action) => {
        state.games.gameAutoLaunchEnabledStatus = fetchUpdate(state.games.gameAutoLaunchEnabledStatus || createDefaultFetchStatus(), { $fetchFailed: [action.payload.error] });
      });
    }

    { // getHotkeysAC
      const async = getHotkeysAC;
      builder.addCase(async.done, (state, action) => {
        const { result: { globals, games } } = action.payload;
        state.settings.hotkeys.globals = globals;
        if (games) {
          state.settings.hotkeys.games = games;
        }
      });
    }

    {
      // gameSpecificHealthCheckAC
      const async = gameSpecificHealthCheckAC;
      builder.addCase(async.started, (state, action) => {
        state.games.gameEventHealthFetchStatus = update(state.games.gameEventHealthFetchStatus, {
          [action.payload]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchStarted: true }),
        });
      });
      builder.addCase(async.done, (state, action) => {
        const { result, params } = action.payload;
        state.games.gameEventHealthDict = update(state.games.gameEventHealthDict, {
          [params]: { $set: result },
        });
        state.games.gameEventHealthFetchStatus = update(state.games.gameEventHealthFetchStatus, {
          [params]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchDone: true }),
        });
      });
      builder.addCase(async.failed, (state, action) => {
        const { error, params } = action.payload;
        state.games.gameEventHealthFetchStatus = update(state.games.gameEventHealthFetchStatus, {
          [params]: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchFailed: [error] }),
        });
      });
    }

    { // healthCheckAC
      const async = healthCheckAC;
      builder.addCase(async.started, (state, action) => {
        state.health = update(state.health, {
          status: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchStarted: true }),
        });
      });
      builder.addCase(async.done, (state, action) => {
        const { result } = action.payload;
        state.health = update(state.health, {
          games: { $set: result },
          status: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchDone: true }),
        });
        // state.health.status = fetchUpdate(state.health.status || createDefaultFetchStatus(), {$fetchDone: true});
        // if (!isEqual(state.health.games, result)) {
        //   state.health.games = result;
        // }
      });
      builder.addCase(async.failed, (state, action) => {
        const { error } = action.payload;
        state.health = update(state.health, {
          status: status => fetchUpdate(status || createDefaultFetchStatus(), { $fetchFailed: [error] }),
        });
      });
    }

    { // getConflictingAppsAC
      const async = getConflictingAppsAC;
      builder.addCase(async.done, (state, action) => {
        state.extensions.conflictingApps = action.payload.result;
      });
    }

    { // getVideoFolderAC
      const async = getVideosFolderAC;
      builder.addCase(async.done, (state, action) => {
        state.settings.videosFolder = castOWResult(action.payload.result);
      });
    }

    { // setVideoFolderAC
      const async = setVideosFolderAC;
      builder.addCase(async.done, (state, action) => {
        state.settings.videosFolder = update(state.settings.videosFolder, {
          path: { Value: { $set: action.payload.result.path } },
        });
        state.settings.badVideoFolder = false;
      });
    }

    { // getCurrentUserAC
      const async = getCurrentUserAC;
      builder.addCase(async.done, (state, action) => {
        state.profile.currentUser = castOWResult(action.payload.result);
      });
    }

    {
      const async = getRecentlyPlayedGamesAC;
      builder.addCase(async.done, (state, action) => {
        state.games.recentlyPlayed = castOWResult(action.payload.result);
      });
    }

    {
      const async = getExtensionSettingsAC;
      builder.addCase(async.done, (state, action) => {
        state.settings.extensionSettings = castOWResult(action.payload.result);
      });
    }

    {
      const async = getStreamingCapabilitiesAC;
      builder.addCase(async.done, (state, action) => {
        state.streaming.capabilities = action.payload.result;
      });
    }

    {
      const async = getSystemInformationAC;
      builder.addCase(async.done, (state, action) => {
        state.utils.systemInformation = castOWResult(action.payload.result);
      });
    }

    {
      const async = getMonitorsListAC;
      builder.addCase(async.done, (state, action) => {
        state.utils.monitorsList = castOWResult(action.payload.result);
      });
    }
  },
});

export const {
  conflictingAppClosed,
  currentGameStateChanged,
  gamesAutoLaunchEnabledLoaded,
  hotkeyChanged,
  overwolfDataInit,
  setBadVideoFolder,
  setWebcams,
} = overwolfDataSlice.actions;
export const overwolfDataReducer = overwolfDataSlice.reducer;
