import gql from 'graphql-tag';

export const UpdateUser_Mutation = gql`
    mutation UpdateUserMutation(
        $input: UpdateUser2Input!
    ) {
        updateUser2(
            input: $input
        ) {
            me {
                alias
                biography
                picture
            }
        }
    }
`;
