import { OverridedMixpanel } from 'mixpanel-browser';
import { createContext } from 'react';

import { mixpanelProxy } from '@/mixpanel/mixpanel-proxy';

interface GlobalContextStateValue {
  mixpanel: OverridedMixpanel;
}

export const WebGlobalContext = createContext<GlobalContextStateValue>({
  mixpanel: mixpanelProxy,
});
