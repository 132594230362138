import { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

export type OnNavigate<S extends LocationState = LocationState> = (path: string, config?: OnNavigateConfig<S>) => void;

interface LocationState {
  referPath?: string;
}

export interface OnNavigateConfig<S extends LocationState = LocationState> {
  replaceUrl?: boolean;
  state?: S;
}

export function useNavigate<S = {}>(): OnNavigate<S & LocationState> {
  const history = useHistory();
  const { location } = history;
  const referPathRef = useRef(location.pathname + location.search);
  useEffect(() => {
    referPathRef.current = location.pathname + location.search;
  }, [location.pathname, location.search]);
  return useCallback((path: string, { replaceUrl, state }: OnNavigateConfig<S & LocationState> = {}) => {
    const s = { referPath: referPathRef.current, ...state };
    if (replaceUrl) {
      history.replace(path, s);
    } else {
      history.push(path, s);
    }
  }, [history]);
}
