import { useEffect, useMemo, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import invariant from 'ts-invariant';

import SquadRouting from '@/features/squad/squad.routing';
import { ENDPOINT } from '@/utils/insightsgg';

export function VerifyCallback(props: RouteComponentProps) {
  const { location } = props;
  invariant(location, 'Expected location to be defined');
  const hash = useMemo(() => new URLSearchParams(location.hash.slice(1)), [location.hash]);
  const code = hash.get('code');

  const [verified, setVerified] = useState<boolean>(false);

  useEffect(() => {
    if (!code) {
      throw new Error('no code');
    }
    (async function () {
      const res = await fetch(ENDPOINT + '/oauth/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${encodeURIComponent(code)}`,
      });
      if (!res.ok) {
        const { error } = await res.json();
        throw error;
      } else {
        setVerified(true);
        const location = res.headers.get('location');
        if (location) {
          window.location.href = location;
        }
      }
    })();
  }, [code]);

  if (verified) {
    return <Redirect to={SquadRouting.createUrl.base()} />;
  }
  return null;
}
