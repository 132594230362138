import antlr4 from 'antlr4';

import { LogicGrammarLexer } from './__generated__/LogicGrammarLexer';
import { LogicGrammarParser } from './__generated__/LogicGrammarParser';
import { LogicGrammarVisitor } from './__generated__/LogicGrammarVisitor';

export class LogicEvaluator extends LogicGrammarVisitor {
  private conditions: Set<string>;

  constructor(conditions: Set<string>) {
    super();
    this.conditions = new Set(conditions);
  }

  public visitBool(ctx: any) {
    const [condition] = ctx.children;
    const text = condition.getText();
    switch (text) {
      case 'modal.open':
        const modals = ['modal.open', 'dialog.open', 'menu.open', 'drawer.open', 'popover.open'];
        return modals.some(m => this.conditions.has(m));
      case 'video.replay':
        const videoAndNoModal = this.conditions.has(text) && evaluateLogicalExpression('!modal.open', this.conditions);
        const dialogIsVideo = this.conditions.has('video.dialog');
        return videoAndNoModal || dialogIsVideo;
    }
    return this.conditions.has(text);
  }

  public visitNOT(ctx: any) {
    const [_, thingToBeNegated] = ctx.children;
    return !thingToBeNegated.accept(this);
  }

  public visitAND(ctx: any) {
    const [first, _, second] = ctx.children;
    return first.accept(this) && second.accept(this);
  }

  public visitXOR(ctx: any) {
    const [first, _, second] = ctx.children;
    return !first.accept(this) !== !second.accept(this);
  }

  public visitOR(ctx: any) {
    const [first, _, second] = ctx.children;
    return first.accept(this) || second.accept(this);
  }

  public visitParens(ctx: any) {
    const [_open, expr, _close] = ctx.children;
    return expr.accept(this);
  }
}

export function evaluateLogicalExpression(input: string, conditions: Set<string>): boolean {
  const chars = new antlr4.InputStream(input);
  const lexer: antlr4.Lexer = new LogicGrammarLexer(chars) as any;
  const tokens = new antlr4.CommonTokenStream(lexer);
  const parser = new LogicGrammarParser(tokens);
  (parser as any as antlr4.Parser).buildParseTrees = true;

  const tree = parser.expr();
  const visitor = new LogicEvaluator(conditions);
  const result = tree.accept(visitor);
  return result;
}
