import { Theme } from '@insights-gaming/theme';
import { Box, SxProps } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

interface SlideLayoutProps {
  className?: string;
  sx?: SxProps<Theme>;
  graphic?: ReactNode;
  actions?: ReactNode;
}

export function SlideLayout(props: SlideLayoutProps & PropsWithChildren) {
  const { actions, className, sx, children, graphic } = props;
  return (
    <Box
    sx={[
      {
        display: 'grid',
        gap: 2,
        gridTemplateColumns: 'auto 1fr',
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    className={className}
    >
      {graphic}
      {children}
      <Box gridColumn={2}>
        {actions}
      </Box>
    </Box>
  );
}
