import { Theme } from '@insights-gaming/theme';
import { Badge } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, memo, useContext, useEffect, useState } from 'react';
import { DragLayerMonitor, XYCoord } from 'react-dnd';
import { CSSTransition } from 'react-transition-group';

import { jotaiStore } from '@/features/desktop-window/jotaiStore';
import { TeamAvatar } from '@/features/squad/TeamAvatar';
import { getFragmentData } from '@/graphql/__generated__';
import useEfficientDragLayer from '@/hooks/useEfficientDragLayer';

import { CustomDragLayerContext, CustomDragLayerFolder, CustomDragLayerVideo } from './CustomDragLayerContext';
import FolderCardDragPreview from './folder-card/FolderCardDragPreview';
import { DragItem, ItemTypes, ReorderDragItem, TeamDragItem, TeamDragItem_TeamFragment, TeamResourceDragItem } from './item-types';
import VideoCardDragPreview from './video-card/VideoCardDragPreview';

const COMPLEX_PREVIEW_THRESOLD = 24;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: theme.zIndex.tooltip,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
}), { name: 'CustomDragLayer' });

const monitor = (monitor: DragLayerMonitor<DragItem>) => ({
  item: monitor.getItem(),
  // itemType: monitor.getItemType(),
  clientOffset: monitor.getClientOffset(),
  initialClientOffset: monitor.getInitialClientOffset(),
  initialSourceClientOffset: monitor.getInitialSourceClientOffset(),
  sourceClientOffset: monitor.getSourceClientOffset(),
  differenceFromInitialOffset: monitor.getDifferenceFromInitialOffset(),
  isDragging: monitor.isDragging(),
});

function CustomDragLayer(props: {}) {
  const classes = useStyles(props);

  const {
    folderCardRefs,
    videoCardRefs,
  } = useContext(CustomDragLayerContext);

  const {
    item,
    // itemType,
    clientOffset,
    initialClientOffset,
    sourceClientOffset,
    differenceFromInitialOffset,
    isDragging,
  } = useEfficientDragLayer(monitor);

  const delta = calculateDelta(sourceClientOffset, clientOffset); // mouse position relative to top left corner of the source item
  const inner = useInnerItemStyles(delta);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (isDragging) {
      const timer = window.setTimeout(() => {
        setDone(true);
      }, 300);
      return () => {
        window.clearTimeout(timer);
      };
    } else {
      setDone(false);
    }
  }, [isDragging]);

  if (!isDragging) {
    return null;
  }

  const showComplexPreview = !done && (folderCardRefs.length + videoCardRefs.length) <= COMPLEX_PREVIEW_THRESOLD;

  return (
    <div className={classes.root}>
      {showComplexPreview && folderCardRefs.map(([item, card]) => (
        <div
        key={item.uuid}
        style={getComplexPreviewWrapperStyles(card, differenceFromInitialOffset)}
        >
          <InnerFolder folder={item} delta={calculateDelta(card.getBoundingClientRect(), initialClientOffset)} />
        </div>
      ))}
      {showComplexPreview && videoCardRefs.map(([item, card]) => (
        <div
        key={item.uuid}
        style={getComplexPreviewWrapperStyles(card, differenceFromInitialOffset)}
        >
          <InnerVideo video={item} delta={calculateDelta(card.getBoundingClientRect(), initialClientOffset)} />
        </div>
      ))}
      <div style={getItemWrapperStyles(sourceClientOffset, item.width, item.height)}>
        <CSSTransition
        in={true}
        timeout={0}
        className={inner.root}
        classNames={{
          enter: inner.enter,
          enterActive: inner.enterActive,
          enterDone: inner.enterDone,
          exit: inner.exit,
          exitActive: inner.exitActive,
          exitDone: inner.exitDone,
        }}
        appear={true}
        >
          <Badge style={{ width: '100%', height: '100%' }} color='secondary' badgeContent={getBadgeContent(item)}>
            <div style={{ width: '100%', height: '100%' }}>
              {renderItem(item)}
            </div>
          </Badge>
        </CSSTransition>
      </div>
    </div>
  );
}

const calculateDelta = (p1: XYCoord | null, p2: XYCoord | null): XYCoord => {
  if (!p1 || !p2) {
    return { x: 0, y: 0 };
  }
  return {
    x: p2.x - p1.x,
    y: p2.y - p1.y,
  };
};

const transform = ({ x, y }: XYCoord) => `translate3d(${x}px, ${y}px, 0px)`;
// transition the dragged item so that the top left corner of the item is at the mouse position
const useInnerItemStyles = makeStyles(({
  root: {
    transition: 'transform ease 300ms',
  },
  enter: {
    transform,
  },
  enterActive: {
    transform,
  },
  enterDone: {
    transform,
  },
  exit: {
    transform: 'translate3d(0px, 0px, 0px)',
  },
  exitActive: {
    transform: 'translate3d(0px, 0px, 0px)',
  },
  exitDone: {
    transform: 'translate3d(0px, 0px, 0px)',
  },
}));

function getBadgeContent(item: DragItem | TeamResourceDragItem) {
  switch (item.type) {
    case ItemTypes.DIRECTORY_CARD:
    case ItemTypes.VIDEO_CARD:
    case ItemTypes.TEAM_FOLDER_CARD:
    case ItemTypes.TEAM_VIDEO_CARD:
      const count = jotaiStore.get(item.selectionAtoms.folders).size + jotaiStore.get(item.selectionAtoms.videos).size + (item.currentItem.videoUuid ? 1 : 0) + (item.currentItem.folderUuid ? 1 : 0);
      if (count > 1) {
        return count;
      }
      return undefined;
    default:
      return undefined;
  }
}

// position the dragged item at the mouse position
function getItemWrapperStyles(
  sourceClientOffset: XYCoord | null,
  width: number,
  height: number,
): CSSProperties {
  if (!sourceClientOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = sourceClientOffset;
  const transform = `translate3d(${x}px, ${y}px, 0)`;
  return {
    transform,
    width,
  };
}

// maintain relative position of the complex preview to the dragged item
function getComplexPreviewWrapperStyles(
  container: HTMLDivElement,
  differenceFromInitialOffset: XYCoord | null,
): CSSProperties {
  if (!differenceFromInitialOffset) {
    return {
      display: 'none',
    };
  }
  const rect = container.getBoundingClientRect();
  const transform = `translate3d(${rect.x + differenceFromInitialOffset.x}px, ${rect.y + differenceFromInitialOffset.y}px, 0)`;
  return {
    position: 'absolute',
    transform,
    width: container.clientWidth,
    height: container.clientHeight,
  };
}

function renderItem(item: DragItem | ReorderDragItem | TeamResourceDragItem | TeamDragItem) {
  switch (item.type) {
    case ItemTypes.DIRECTORY_CARD:
    case ItemTypes.TEAM_FOLDER_CARD:
      return (
        <FolderCardDragPreview name={item.name} />
      );
    case ItemTypes.VIDEO_CARD:
    case ItemTypes.TIMELINE_VIDEO_CARD:
    case ItemTypes.TEAM_VIDEO_CARD:
      return (
        <VideoCardDragPreview name={item.name} thumbnailUrl={item.thumbnailUrl} />
      );
    case ItemTypes.TEAM:
      return (
        <TeamAvatar team={getFragmentData(TeamDragItem_TeamFragment, item.team)} />
      );
    default:
      return null;
  }
}

export default memo(CustomDragLayer);

interface InnerFolderProps {
  folder: CustomDragLayerFolder;
  delta: XYCoord;
}

const InnerFolder = memo(function InnerFolder(props: InnerFolderProps) {
  const { delta, folder } = props;
  const inner = useInnerItemStyles(delta);

  return (
    <CSSTransition
    in={true}
    timeout={0}
    className={inner.root}
    classNames={{
      enter: inner.enter,
      enterActive: inner.enterActive,
      enterDone: inner.enterDone,
      exit: inner.exit,
      exitActive: inner.exitActive,
      exitDone: inner.exitDone,
    }}
    appear={true}
    >
      <FolderCardDragPreview name={folder.name} />
    </CSSTransition>
  );
}, () => true);

interface InnerVideoProps {
  video: CustomDragLayerVideo;
  delta: XYCoord;
}

const InnerVideo = memo(function InnerVideo(props: InnerVideoProps) {
  const { delta, video }  = props;
  const inner = useInnerItemStyles(delta);

  return (
    <CSSTransition
    in={true}
    timeout={0}
    className={inner.root}
    classNames={{
      enter: inner.enter,
      enterActive: inner.enterActive,
      enterDone: inner.enterDone,
      exit: inner.exit,
      exitActive: inner.exitActive,
      exitDone: inner.exitDone,
    }}
    appear={true}
    >
      <VideoCardDragPreview name={video.name} thumbnailUrl={video.thumbnailUrl} />
    </CSSTransition>
  );
}, () => true);
