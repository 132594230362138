import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import RenameDialog from '@/components/RenameDialog';
import SquadRouting from '@/features/squad/squad.routing';
import { TeamShareVideoDialog } from '@/features/squad/TeamVideo/TeamShareVideoDialog';
import { useAccessControl } from '@/features/squad/useAccessControl';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';
import { TeamVideoContextMenu_VideoFragmentFragment } from '@/graphql/__generated__/graphql';
import { useContextMenuDialogState } from '@/hooks/useDialogState';
import { useNavigate } from '@/hooks/useNavigate';

import TeamVideoDeleteDialog from './TeamVideoDeleteDialog';
import { useUpdateVideo2Mutation } from './useUpdateVideo2Mutation';

const TeamVideoContextMenu_VideoFragment = gql(`
  fragment TeamVideoContextMenu_VideoFragment on Video {
    id
    name
    streamUrls
    directory {
      id
    }
    ...TeamVideoDeleteDialog_VideoFragment
    ...TeamShareVideoDialog_VideoFragment
  }
`);

interface TeamVideoContextMenuProps {
  teamId: ID;
  video: FragmentType<typeof TeamVideoContextMenu_VideoFragment>;
}

export function TeamVideoContextMenu(props: TeamVideoContextMenuProps) {
  const { teamId } = props;
  const video = getFragmentData(TeamVideoContextMenu_VideoFragment, props.video);

  const { canUpdateVideo, canDeleteVideo } = useAccessControl(teamId);

  const onNavigate = useNavigate();

  const { t } = useTranslation(['common', 'squad_videos']);
  const popupState = usePopupState({ popupId: 'team-video-context-menu', variant: 'popover' });

  const [updateVideo, { loading: updating }] = useUpdateVideo2Mutation();

  const download = useMemo(() => makeDownloadFromVideo(video), [video]);

  const handleRename = useCallback((newName: string) => {
    updateVideo({
      variables: {
        input: {
          teamId,
          id: video.id,
          name: newName,
        },
      },
      optimisticResponse: ({ input }) => ({
        updateVideo2: {
          video: {
            __typename: 'Video' as const,
            id: input.id,
            name: input.name,
            directory: video.directory,
          },
        },
      }),
    });
  }, [teamId, updateVideo, video.directory, video.id]);

  const [isRenameDialogOpen, openRenameDialog, closeRenameDialog] = useContextMenuDialogState(popupState.close);
  const [isShareDialogOpen, openShareDialog, closeShareDialog] = useContextMenuDialogState(popupState.close);
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useContextMenuDialogState(popupState.close);

  const handleVideoDeleted = useCallback(() => {
    if (video.directory) {
      onNavigate(SquadRouting.createUrl.teamFolder(teamId, video.directory?.id));
    }
  }, [onNavigate, teamId, video.directory]);

  return (
    <Fragment>
      <IconButton sx={{ alignSelf: 'center' }} size='small' {...bindTrigger(popupState)}>
        <MoreVertIcon />
      </IconButton>
      <Menu {...bindMenu(popupState)}>
        {canUpdateVideo && (
          <MenuItem onClick={openRenameDialog}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>
              {t('squad_videos:menuItems.rename')}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={openShareDialog}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary={t('squad_videos:share.title')} />
        </MenuItem>
        {download && (
          <MenuItem component='a' href={download.url}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('common:download')} />
          </MenuItem>
        )}
        {canDeleteVideo && (
          <MenuItem onClick={openDeleteDialog}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>
              {t('squad_videos:menuItems.delete')}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
      <RenameDialog
      open={isRenameDialogOpen}
      fullWidth={true}
      onClose={closeRenameDialog}
      initialName={video.name}
      onRename={handleRename}
      renaming={updating}
      title={t('squad_videos:rename.title')}
      confirmText={t('squad_videos:rename.confirm')}
      />
      <TeamVideoDeleteDialog
      open={isDeleteDialogOpen}
      onClose={closeDeleteDialog}
      video={video}
      onSubmit={handleVideoDeleted}
      />
      <TeamShareVideoDialog
      open={isShareDialogOpen}
      onClose={closeShareDialog}
      teamId={teamId}
      video={video}
      />
    </Fragment>
  );
}

function makeDownloadFromVideo(video: TeamVideoContextMenu_VideoFragmentFragment) {
  const urlString = video.streamUrls?.find((url) => !url.endsWith('.m3u8'));
  if (!urlString) {
    return;
  }

  const url = new URL(urlString);
  return {
    url: url.toString(),
  };
}
