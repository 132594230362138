// Generated from LogicGrammar.g4 by ANTLR 4.7.2
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by LogicGrammarParser.
function LogicGrammarListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

LogicGrammarListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
LogicGrammarListener.prototype.constructor = LogicGrammarListener;

// Enter a parse tree produced by LogicGrammarParser#prog.
LogicGrammarListener.prototype.enterProg = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#prog.
LogicGrammarListener.prototype.exitProg = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#parens.
LogicGrammarListener.prototype.enterParens = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#parens.
LogicGrammarListener.prototype.exitParens = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#NOT.
LogicGrammarListener.prototype.enterNOT = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#NOT.
LogicGrammarListener.prototype.exitNOT = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#OR.
LogicGrammarListener.prototype.enterOR = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#OR.
LogicGrammarListener.prototype.exitOR = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#bool.
LogicGrammarListener.prototype.enterBool = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#bool.
LogicGrammarListener.prototype.exitBool = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#AND.
LogicGrammarListener.prototype.enterAND = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#AND.
LogicGrammarListener.prototype.exitAND = function(ctx) {
};


// Enter a parse tree produced by LogicGrammarParser#XOR.
LogicGrammarListener.prototype.enterXOR = function(ctx) {
};

// Exit a parse tree produced by LogicGrammarParser#XOR.
LogicGrammarListener.prototype.exitXOR = function(ctx) {
};



exports.LogicGrammarListener = LogicGrammarListener;