import { Theme } from '@insights-gaming/theme';
import { List, ListItem, ListItemText } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { memo } from 'react';

interface VideoNameListOwnProps {
  className?: string;
  videoNames: string[];
  dense?: boolean;
}

type VideoNameListProps = VideoNameListOwnProps;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.container,
    padding: 0,
    margin: theme.spacing(2, 0),
    '&$dense': {
      margin: 0,
    },
  },
  videoName: {
    fontWeight: 600,
  },
  dense: {},
}), { name: 'VideoNameList' });

function VideoNameList(props: VideoNameListProps) {
  const classes = useStyles(props);
  const { className, dense, videoNames } = props;
  return (
    <List dense={true} className={classNames(classes.root, { [classes.dense]: dense }, className)}>
      {videoNames.map((name, i) => (
        <ListItem key={`${name}-${i}`}>
          <ListItemText className={classes.videoName}>
            {name}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}

export default memo(VideoNameList);
