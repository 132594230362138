import { GameClassId } from '@insights-gaming/game-events';

import { ManagementStyle } from '@/features/settings/setting-slice';
import { RecordingSource } from '@/features/video-library/video-library-slice';
import { MixpanelEventParams, mpProps } from '@/mixpanel/common';

interface VideoRecordingFailedProperties {
  [mpProps.eventSource]?: RecordingSource;
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
  [mpProps.streamResult]?: overwolf.streaming.StreamEvent & overwolf.Result;
}

interface VideoRecordingFailedToStartProperties {
  [mpProps.eventSource]?: RecordingSource;
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
  [mpProps.streamResult]?: overwolf.streaming.StreamResult;
}

interface VideoRecordingWarningProperties {
  [mpProps.eventSource]?: RecordingSource;
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
  [mpProps.error]?: string;
}

interface VideoRecordingStartTimedOutProperties {
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
}

interface StorageWarningDisplayedProperties {
  [mpProps.managementStrategy]?: 'Automatic' | 'Manual';
}

interface StorageNotificationDisplayedProperties {
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
}

interface BadVideoFolderProperties {}

interface InstantReplayDisabledNotificationProperties {
  [mpProps.gameClassId]?: GameClassId;
  [mpProps.queueId]?: number;
}

export interface MixpanelErrorEventMap {
  'Video Recording Failed': VideoRecordingFailedProperties;
  'Video Recording Failed To Start': VideoRecordingFailedToStartProperties;
  'Video Recording Warning': VideoRecordingWarningProperties;
  'Video Recording Start Timed Out': VideoRecordingStartTimedOutProperties;
  'Storage Warning Displayed': StorageWarningDisplayedProperties;
  'Storage Notification Displayed': StorageNotificationDisplayedProperties;
  'Bad Video Folder': BadVideoFolderProperties;
  'Instant Replay Disabled Notification': InstantReplayDisabledNotificationProperties;
}

function recordingFailed(
  params: { recordingSource?: RecordingSource; gameClassId?: GameClassId; queueId?: number; result?: overwolf.streaming.StreamEvent & overwolf.Result },
): MixpanelEventParams<'Video Recording Failed'> {
  return [
    'Video Recording Failed',
    {
      [mpProps.eventSource]: params.recordingSource,
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
      [mpProps.streamResult]: params.result,
    },
  ];
}

function recordingFailedToStart(
  params: { recordingSource?: RecordingSource; gameClassId?: GameClassId; queueId?: number; result?: overwolf.streaming.StreamResult },
): MixpanelEventParams<'Video Recording Failed To Start'> {
  return [
    'Video Recording Failed To Start',
    {
      [mpProps.eventSource]: params.recordingSource,
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
      [mpProps.streamResult]: params.result,
    },
  ];
}

function recordingWarning(
  params: { recordingSource?: RecordingSource; gameClassId?: GameClassId; queueId?: number; error?: string },
): MixpanelEventParams<'Video Recording Warning'> {
  return [
    'Video Recording Warning',
    {
      [mpProps.eventSource]: params.recordingSource,
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
      [mpProps.error]: params.error,
    },
  ];
}

function recordingStartTimedOut(
  params: { gameClassId?: GameClassId; queueId?: number },
): MixpanelEventParams<'Video Recording Start Timed Out'> {
  return [
    'Video Recording Start Timed Out',
    {
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
    },
  ];
}

function storageWarningDisplayed(
  managementStrategy: ManagementStyle,
): MixpanelEventParams<'Storage Warning Displayed'> {
  return [
    'Storage Warning Displayed',
    {
      [mpProps.managementStrategy]: managementStrategy === 'auto' ? 'Automatic' : 'Manual',
    },
  ];
}

function storageNotificationDisplayed(
  params: { gameClassId?: GameClassId; queueId?: number },
): MixpanelEventParams<'Storage Notification Displayed'> {
  return [
    'Storage Notification Displayed',
    {
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
    },
  ];
}

function badVideoFolder(): MixpanelEventParams<'Bad Video Folder'> {
  return ['Bad Video Folder', {}];
}

// Track when replay is disabled and hotkey is pressed
function instantReplayDisabledNotification(
  params: { gameClassId?: GameClassId; queueId?: number },
): MixpanelEventParams<'Instant Replay Disabled Notification'> {
  return [
    'Instant Replay Disabled Notification',
    {
      [mpProps.gameClassId]: params.gameClassId,
      [mpProps.queueId]: params.queueId,
    },
  ];
}

export const errorHelpers = {
  recordingFailed,
  recordingFailedToStart,
  recordingStartTimedOut,
  recordingWarning,
  storageWarningDisplayed,
  storageNotificationDisplayed,
  badVideoFolder,
  instantReplayDisabledNotification,
};
