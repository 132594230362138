import { useCreateSelector } from '@insights-gaming/redux-utils';
import { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useDeleteVideoMutation } from '@/features/squad/TeamDirectory/useDeleteVideoMutation';
import { useDialogState } from '@/hooks/useDialogState';
import { NOOP } from '@/utils';
import { isExistent } from '@/utils/guard';

import { videoUploadSelectors } from './video-upload-selector';
import { videoUploadActions } from './video-upload-slice';

interface Options {
  onCollapse: VoidFunction;
}

function useAbortAll({ onCollapse }: Options) {
  const [isDialogOpen, openDialog, closeDialog] = useDialogState();
  const dispatch = useDispatch();

  const currentUpload = useSelector(videoUploadSelectors.getCurrentUpload);
  const uploadQueue = useSelector(videoUploadSelectors.getUploadQueue);
  const [deleteVideo] = useDeleteVideoMutation();

  const videoNames = useMemo(() => {
    return [currentUpload, ...uploadQueue].filter(isExistent).map((upload) => upload.videoMetadata.name);
  }, [currentUpload, uploadQueue]);

  const handleAbort = useCallback(() => {
    for (const { upload } of [currentUpload, ...uploadQueue].filter(isExistent)) {
      if (upload.resumeInfo) {
        deleteVideo({ variables: { input:{ id: upload.resumeInfo.videoId } } });
        dispatch(videoUploadActions.abortVideoUploadAC.started({ id: upload.resumeInfo.videoId }));
      } else {
        dispatch(videoUploadActions.dequeueVideoUploadAC.started({ uploadItemUuid: upload.uploadItemUuid }));
      }
    }
    onCollapse();
    closeDialog();
  }, [closeDialog, currentUpload, deleteVideo, dispatch, onCollapse, uploadQueue]);

  return {
    open: isDialogOpen,
    onOpen: openDialog,
    onClose: closeDialog,
    onAbort: handleAbort,
    videoNames,
  } as const;
}

function useAbortSingle({ onCollapse }: Options) {
  const [isDialogOpen, openDialog, closeDialog] = useDialogState();
  const dispatch = useDispatch();

  const [uploadItemUuid, setUploadItemUuid] = useState<UUID>();
  const onAbortRef = useRef<(uploadItemUuid: UUID) => void>();

  const uploadWithState = useCreateSelector(videoUploadSelectors.makeGetUploadWithState, { uploadItemUuid });

  const { itemCount } = useSelector(videoUploadSelectors.getUploadQueueInfo);
  const shouldCollapse = itemCount === 1;
  const [deleteVideo] = useDeleteVideoMutation();

  const openAbortDialog = useCallback((uploadItemUuid: UUID, onAbort?: (uploadItemUuid: UUID) => void) => {
    setUploadItemUuid(uploadItemUuid);
    onAbortRef.current = onAbort;
    openDialog();
  }, [openDialog]);

  const handleAbort = useMemo(() => {
    function close() {
      if (shouldCollapse) {
        onCollapse();
      }
      closeDialog();
    }
    if (uploadItemUuid && uploadWithState) {
      switch (uploadWithState.state) {
        case 'PAUSED':
        case 'UPLOADING':
          return () => {
            deleteVideo({ variables: { input:{ id: uploadWithState.upload.id } } });
            dispatch(videoUploadActions.abortVideoUploadAC.started({ id: uploadWithState.upload.id }));
            close();
          };
        case 'FAILED':
          return () => {
            const id = uploadWithState.upload.options.resumeInfo?.videoId;
            if (id) {
              deleteVideo({ variables: { input:{ id } } });
              dispatch(videoUploadActions.abortVideoUploadAC.started({ id }));
            }
            close();
          };
        case 'QUEUED':
          return () => {
            dispatch(videoUploadActions.dequeueVideoUploadAC.started({ uploadItemUuid }));
            close();
          };
      }
    }
    return () => {
      if (uploadItemUuid) {
        dispatch(videoUploadActions.dequeueVideoUploadAC.started({ uploadItemUuid }));
        onAbortRef.current?.(uploadItemUuid);
      }
      close();
    };
  }, [closeDialog, deleteVideo, dispatch, onCollapse, shouldCollapse, uploadItemUuid, uploadWithState]);

  return {
    open: isDialogOpen,
    onOpen: openAbortDialog,
    onClose: closeDialog,
    onAbort: handleAbort,
    videoName: uploadWithState?.videoMetadata?.name || '',
  } as const;
}

export function useAbortUploadContextValue(options: Options) {
  const multiple = useAbortAll(options);
  const single = useAbortSingle(options);
  return useMemo(() => ({ multiple, single }), [multiple, single]);
}

interface _Common {
  open: boolean;
  onClose: VoidFunction;
  onAbort?: VoidFunction;
}

interface AbortUploadContextState {
  multiple: _Common & {
    onOpen: VoidFunction;
    videoNames: string[];
  };
  single: _Common & {
    onOpen: (uploadItemUuid: UUID, onAbort?: () => void) => void;
    videoName: string;
  };
}

export const AbortUploadContext = createContext<AbortUploadContextState>({
  multiple: {
    open: false,
    onOpen: NOOP,
    onClose: NOOP,
    onAbort: NOOP,
    videoNames: [],
  },
  single: {
    open: false,
    onOpen: NOOP,
    onClose: NOOP,
    onAbort: NOOP,
    videoName: '',
  },
});
