import { Fragment, PropsWithChildren, useEffect, useState } from 'react';

import { Loader } from './Loader';

interface SuspenseLoaderProps {
  delay?: number;
}

export function SuspenseLoader(props: PropsWithChildren<SuspenseLoaderProps>) {
  const { children, delay = 200 } = props;

  const [pastDelay, setPastDelay] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPastDelay(true);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  if (!pastDelay) {
    return null;
  }

  return (
    <Fragment>
      {children || <Loader />}
    </Fragment>
  );
}
