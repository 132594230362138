import { likeDiscoverSubmissionAC, submitDiscoverSubmissionAC } from '@/features/discover/discover-slice';
import { MixpanelEventParams, MixpanelEventSource, mpProps } from '@/mixpanel/common';


interface VideoSubmittedProperties {
  [mpProps.challengeId]: ID;
}

interface VideoUpvotedProperties {
  [mpProps.challengeId]: ID;
  [mpProps.submissionId]: ID;
}

export type DiscoverWatchSource = MixpanelEventSource<'Discover Tab' | 'Discover Leaderboard Item' | 'Discover Filter' | 'Discover Feed' | 'Discover Submissions' | 'Discover Submit Clip Dialog'>;

interface DiscoverWatchVideoStarted {
  [mpProps.eventSource]?: DiscoverWatchSource;
  [mpProps.challengeId]: ID;
  [mpProps.submissionId]: ID;
}

type DiscoverWatchVideoEnded = DiscoverWatchVideoStarted;

export interface MixpanelDiscoverEventMap {
  'Video Submitted': VideoSubmittedProperties;
  'Video Upvoted': VideoUpvotedProperties;
  'Discover Watch Video Started': DiscoverWatchVideoStarted;
  'Discover Watch Video Ended': DiscoverWatchVideoEnded;
}

function submitted(
  action: ReturnType<typeof submitDiscoverSubmissionAC.done>,
): MixpanelEventParams<'Video Submitted'> {
  return [
    'Video Submitted',
    {
      [mpProps.challengeId]: action.payload.params.input.challengeId,
    },
  ];
}

function upvoted(
  action: ReturnType<typeof likeDiscoverSubmissionAC.done>,
): MixpanelEventParams<'Video Upvoted'> {
  return [
    'Video Upvoted',
    {
      [mpProps.challengeId]: action.payload.result.submission.challenge.id,
      [mpProps.submissionId]: action.payload.params.id,
    },
  ];
}

interface WatchStartedParams {
  watchSource?: DiscoverWatchSource;
  challengeId: ID;
  submissionId: ID;
}

function watchStarted(
  params: WatchStartedParams,
): MixpanelEventParams<'Discover Watch Video Started'> {
  return [
    'Discover Watch Video Started',
    {
      [mpProps.eventSource]: params.watchSource,
      [mpProps.challengeId]: params.challengeId,
      [mpProps.submissionId]: params.submissionId,
    },
  ];
}

type WatchEndedParams = WatchStartedParams;

function watchEnded(
  params: WatchEndedParams,
): MixpanelEventParams<'Discover Watch Video Ended'> {
  return [
    'Discover Watch Video Ended',
    {
      [mpProps.eventSource]: params.watchSource,
      [mpProps.challengeId]: params.challengeId,
      [mpProps.submissionId]: params.submissionId,
    },
  ];
}

export const discoverHelpers = {
  submitted,
  upvoted,
  watchStarted,
  watchEnded,
};
