import { lazy, Suspense } from 'react';

import { SuspenseLoader } from '@/components/SuspenseLoader';

const LegacyPigeonPage = lazy(() => import('./LegacyPigeonPage'));

const teamPath = '/dashboard/team/:teamId';
const folderPath = '/dashboard/folder/:folderId';
const videoPath = '/dashboard/video/:videoId';
const settingsPath = '/dashboard/team/:teamId/settings/:tab?';

const path = {
  team: teamPath,
  folder: folderPath,
  video: videoPath,
  settings: settingsPath,
} as const;

const createTeamUrl = (teamId: ID) => `/dashboard/team/${teamId}`;
const createFolderUrl = (folderId: ID) => `/dashboard/folder/${folderId}`;
const createVideoUrl = (videoId: ID) => `/dashboard/video/${videoId}`;
const createSettingsUrl = (teamId: ID) => `/dashboard/team/${teamId}/settings`;

function LegacyPigeonRoutingComponent() {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <LegacyPigeonPage />
    </Suspense>
  );
}

class LegacyPigeonRouting {
  public static readonly path = path;
  public static readonly paths = Object.values(this.path);
  public static readonly createUrl = {
    team: createTeamUrl,
    folder: createFolderUrl,
    video: createVideoUrl,
    settings: createSettingsUrl,
  };
  public static readonly Component = LegacyPigeonRoutingComponent;
}

declare namespace LegacyPigeonRouting {
  interface TeamParams {
    teamId: ID;
  }

  interface FolderParams {
    folderId: ID;
  }

  interface VideoParams {
    videoId: ID;
  }

  interface TeamSettingsParams {
    teamId: ID;
    tab?: string;
  }
}

export default LegacyPigeonRouting;
