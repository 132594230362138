import { Theme } from '@insights-gaming/theme';
import { Box, SxProps } from '@mui/material';
import { ListChildComponentProps } from 'react-window';

import { AvatarWithFallback } from '@/components/AvatarWithFallback';
import NavigationButton from '@/features/desktop-window/navigation/NavigationButton';
import { FragmentType, getFragmentData, gql } from '@/graphql/__generated__';

import { TeamAvatarButton } from './TeamAvatarButton';

const TeamSelectorItemRenderer_TeamFragment = gql(`
  fragment TeamSelectorItemRenderer_TeamFragment on Team {
    id
    ...TeamAvatarButton_TeamFragment
  }
`);

interface TeamSelectorItemRendererProps {
  className?: string;
  sx?: SxProps<Theme>;
}

interface ItemData {
  onClick: (teamId: ID) => void;
  onUpdateTeamOrder: (dragId: ID, dropId: ID, insertAbove: boolean) => void;
  openCreateTeamDialog: VoidFunction;
  selectedTeamId?: ID;
  teams: FragmentType<typeof TeamSelectorItemRenderer_TeamFragment>[];
}

export function TeamSelectorItemRenderer(props: TeamSelectorItemRendererProps & ListChildComponentProps<ItemData>) {
  const { className, data, index, style: styleProp, sx } = props;
  const { onClick, onUpdateTeamOrder, openCreateTeamDialog, selectedTeamId, teams } = data;

  const style = {
    ...styleProp,
    display: 'flex',
    justifyContent: 'center',
  };

  if (index >= (teams.length)) {
    return (
      <NavigationButton hideLabel={true} onClick={openCreateTeamDialog} className={className} sx={sx} style={style}>
        <AvatarWithFallback alt='+' />
      </NavigationButton>
    );
  }
  const team = getFragmentData(TeamSelectorItemRenderer_TeamFragment, teams[index]);
  return team ? (
    <Box className={className} sx={sx} style={style}>
      <TeamAvatarButton team={team} selected={team.id === selectedTeamId} onClick={onClick} onUpdateTeamOrder={onUpdateTeamOrder} />
    </Box>
  ) : null;
}
