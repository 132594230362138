import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { styled } from '@mui/material/styles';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { PropsWithChildren } from 'react';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  '&.notistack-MuiContent': {
    ...theme.typography.body1,
    borderRadius: theme.shape.borderRadius,
    letterSpacing: 'normal',
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
  },
}));

export function StyledSnackbarProvider(props: PropsWithChildren) {
  const { children } = props;
  return (
    <SnackbarProvider
    maxSnack={4}
    autoHideDuration={2000}
    Components={{
      success: StyledMaterialDesignContent,
      error: StyledMaterialDesignContent,
      warning: StyledMaterialDesignContent,
      info: StyledMaterialDesignContent,
    }}
    iconVariant={{
      error: <CancelIcon fontSize='small' sx={{ mr:1 }} />,
      success: <CheckCircleIcon fontSize='small' sx={{ mr:1 }} />,
      warning: <WarningRoundedIcon fontSize='small' sx={{ mr:1 }} />,
      info: <InfoIcon fontSize='small' sx={{ mr:1 }} />,
    }}
    >
      {children}
    </SnackbarProvider>
  );
}
