import { Theme } from '@insights-gaming/theme';
import { Box, Slider, sliderClasses, SliderProps, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { forwardRef, memo, ReactElement } from 'react';

interface CustomSlider2OwnProps {
  trackRadius?: number;
  thumbRadius?: number;
  className?: string;
  sx?: SxProps<Theme>;
  railOverlay?: ReactElement;
  trackOverlay?: ReactElement;
}

const defaultTrackRadius = 1;
const defaultThumbRadius = 6;
const defaultOrientation = 'horizontal';

type CustomSlider2Props = CustomSlider2OwnProps & SliderProps;

const CustomSlider2 = forwardRef<HTMLSpanElement, CustomSlider2Props>(function CustomSlider2(props: CustomSlider2Props, ref) {
  const {
    className,
    orientation = defaultOrientation,
    railOverlay,
    sx,
    trackOverlay,
    thumbRadius = defaultThumbRadius,
    trackRadius = defaultTrackRadius,
    ...rest
  } = props;

  return (
    <Box
    className={className}
    sx={[
      {
        position: 'relative',
        lineHeight: 0,
      },
      orientation === 'horizontal' && {
        width: '100%',
        [`${OverlayBox}`]: {
          height: 2 * trackRadius,
        },
      },
      orientation === 'vertical' && {
        height: '100%',
        [`${OverlayBox}`]: {
          width: 2 * trackRadius,
        },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    >
      <Slider
      ref={ref}
      orientation={orientation}
      {...rest}
      sx={[
        {
          [`& .${sliderClasses.track}`]: {
            zIndex: 1,
          },
          [`& .${sliderClasses.thumb}`]: {
            zIndex: 2,
            width: 2 * thumbRadius,
            height: 2 * thumbRadius,
          },
        },
        orientation === 'horizontal' && {
          padding: `${14 - trackRadius}px 0`,
          [`&,& .${sliderClasses.rail},.${sliderClasses.track}`]: {
            height: 2 * trackRadius,
          },
        },
        orientation === 'vertical' && {
          padding: `0 ${14 - trackRadius}px`,
          [`&,& .${sliderClasses.rail},.${sliderClasses.track}`]: {
            width: 2 * trackRadius,
          },
        },
      ]}
      />
      {railOverlay && (
        <OverlayBox>
          {railOverlay}
        </OverlayBox>
      )}
      {trackOverlay && (
        <OverlayBox sx={{ zIndex: 1 }}>
          {trackOverlay}
        </OverlayBox>
      )}
    </Box>
  );
});

const StyledCustomSlider2 = styled(CustomSlider2, { name: 'CustomSlider2' })({});

const OverlayBox = styled(Box, { name: 'CustomSlider2_OverlayBox' })({
  position: 'absolute',
  margin: 'auto',
  width: '100%',
  height: '100%',
  bottom: 0,
  top: 0,
  pointerEvents: 'none',
});

export default memo(StyledCustomSlider2);
